import { MdLogout } from "react-icons/md";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import logouticon from '../../assets/images/dashboard/logouticon.svg'

export default function LogoutModal({ showModal, setShowModal }) {

    const auth = useAuth();

    const handleLogout = () => {
        auth.logout()
        setTimeout(() => {
            setShowModal(false);
        }, 150);
    }

    return (
        <>
            {showModal ? (
                <>
                    <div className="flex fixed inset-0 top-5 z-50 outline-none focus:outline-none">
                        <div className="relative m-auto lg:w-2/5 w-2/3">
                            <div className="border-0 rounded-2xl shadow-[0px,0px,23px,rgba(35,35,35,0.16)] relative flex gap-5 flex-col w-full bg-white outline-none focus:outline-none py-12">
                                <img src={logouticon} color="#D91E1E" className="mx-auto w-10 h-10" alt="logout" />
                                <p className="text-[#D91E1E] font-bold mx-auto text-xl lg:text-3xl px-5 mb-2 md:mb-6">هل انت متأكد برغبتك للخروج؟</p>
                                <button
                                    className="w-2/5 mx-auto bg-persianIndigo-100 text-white shadow-button rounded-lg py-4 text-sm"
                                    type="button"
                                    onClick={() => {
                                        setTimeout(() => {
                                            setShowModal(false);
                                        }, 150);
                                    }}
                                >
                                    لا، الرجوع للمنصة
                                </button>
                                <Link to="/logout"
                                    className="text-[#D91E1E] underline text-sm mx-auto text-center"
                                    type="button"
                                    onClick={handleLogout}
                                >
                                    نعم، تم تسجيل الخروج
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}
