import { useState, useEffect } from 'react'
import Card from './Card'
import BodyMeasurements from '../newStudents/BodyMeasurements'
import FitnessMeasurements from '../students/FitnessMeasurements'
import ReviewStudents from '../students/ReviewStudents'
import { IoClose } from 'react-icons/io5'
import StudentsFilter from './StudentsFilter'

const options = [
    { value: 'عرض الكل', label: 'عرض الكل' },
    { value: 'الأول الابتدائي', label: 'الصف الأول' },
    { value: 'الثاني الابتدائي', label: 'الصف الثاني' },
    { value: 'الثالث الابتدائي', label: 'الصف الثالث' },
    { value: 'الرابع الابتدائي', label: 'الصف الرابع' },
    { value: 'الخامس الابتدائي', label: 'الصف الخامس' },
    { value: 'السادس الابتدائي', label: 'الصف السادس' },
]

export default function StudentsList({ students, selectedStudents, setSelectedStudents }) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const [studentsList, setStudentsList] = useState(students)
    const [filtersObj, setFiltersObj] = useState({})
    const [q, setQ] = useState("");
    const [studentsLength, setStudentsLength] = useState(null)
    const [isSelectAll, setIsSelectAll] = useState(selectedStudents.length === students.length)

    useEffect(() => {
        const result = [...students.reduce((mp, o) => {
            if (!mp.has(o.grade)) mp.set(o.grade, { name: o.grade, count: 0 });
            mp.get(o.grade).count++;
            return mp;
        }, new Map).values()];
        setStudentsLength(result)
    }, [])

    useEffect(() => {
        if (isSelectAll) {
            setSelectedStudents(students.map((s) => s.id))
        } else {
            setSelectedStudents([])
        }
    }, [isSelectAll])


    useEffect(() => {
        if (Object.keys(filtersObj).length) {
            setStudentsList(() => {
                return students.filter((item) => {
                    return Object.entries(filtersObj).every((newItem) => {
                        if (newItem[0] === 'name') {
                            return `${item.first_name} ${item.father_name} ${item.last_name}`.toLowerCase().includes(newItem[1].toLowerCase()) ? true : false
                        }
                        if (newItem[1] === 'عرض الكل') {
                            return true
                        }
                        return newItem[1].includes(item[newItem[0]])
                    });
                })
            })
        } else {
            setStudentsList(students)
        }
    }, [filtersObj])

    const handleFilter = (e, filterType) => {
        setFiltersObj((prev) => {
            return { ...prev, [filterType]: e }
        })
    }

    const handleRemoveFilter = (i) => {
        setFiltersObj((prev) => {
            const temp = { ...prev }
            delete temp[Object.keys(temp).find((_, index) => index === i)]
            return {
                ...temp,
            }
        })
    }

    const onChange = (option) => handleFilter(option.value, 'grade')

    return (
        <>
            <div className="flex gap-4 w-4/5 mx-auto mt-10">
                <div className="text-[#555] w-1/2 flex p-1 rounded-md m-[0,0,30px] border-2 border-[#f9f9f9]">
                    <input
                        type="text"
                        style={{ padding: "8px 11% 8px 40px" }}
                        className="search-input"
                        placeholder="ابحث عن طريق اسم الطالب"
                        value={q}
                        onChange={(e) => {
                            setFiltersObj((prev) => {
                                if (e.target.value == '') {
                                    const temp = prev
                                    delete temp.name
                                    return {
                                        ...temp,
                                    }
                                } else {
                                    return {
                                        ...prev,
                                        name: e.target.value
                                    }
                                }
                            })
                            setQ(e.target.value)
                        }}
                    />
                </div>
                <div className="w-[215px] mr-auto">
                    <StudentsFilter options={options} onChange={onChange} students={students} studentsLength={studentsLength} />
                </div>
            </div>
            <div className={`flex gap-4 flex-wrap w-4/5 mx-auto ${Object.values(filtersObj).length ? "mt-6" : ""}`}>
                {Object.entries(filtersObj).map((s, i) => (
                    s[0] === 'name' || s[1] === 'عرض الكل' ? null : <div key={s[1] + "-" + i} onClick={() => handleRemoveFilter(i)} className="bg-Shamrock-10 rounded-lg text-Shamrock-100 cursor-pointer py-2 px-4 text-sm font-light flex items-center">
                        <span>{s[1]}</span>
                        <span className='pl-4 pr-2 text-Shamrock-60'>{studentsLength.find((f) => s[1].includes(f.name))?.count || 0}</span>
                        <IoClose className="inline text-xs" />
                    </div>
                ))}
            </div>
            <div className="w-4/5 mx-auto my-4 pr-3">
                <input id='select-all' type="checkbox" checked={isSelectAll} onChange={() => { setIsSelectAll(prev => !prev) }} className='border-suvaGrey-40 form-checkbox focus:ring-0 peer text-darkViolet-100 rounded-sm bg-white' />
                <label htmlFor='select-all' className='text-suvaGrey-40 mr-4 font-light select-none cursor-pointer'>إختيار الجميع</label>
            </div>
            <div className="overflow-y-auto custom-scroll w-4/5 mx-auto flex flex-col h-[50vh] pl-2">
                {currentStepIndex == 0 ?
                    studentsList.map((s) => (
                        <Card key={s.id} student={s} selectedStudents={selectedStudents} setCurrentStepIndex={setCurrentStepIndex} setSelectedStudents={setSelectedStudents} />
                    ))
                    :
                    currentStepIndex == 1 ?
                        <BodyMeasurements /> :
                        currentStepIndex == 2 ?
                            <FitnessMeasurements /> :
                            <ReviewStudents students={students} />

                }
            </div>
        </>
    )
}
