import { useEffect, useState } from "react";
import cover from "../assets/pdf/Mcover.png";
import header from "../assets/pdf/Mheader.png";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import calculateBMI from "../hooks/calculateBMI";
import convertDateToLocalString from "../hooks/convertDateToLocalString";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

export default function PdfTeacher() {
  const { token } = useAuth();

  const [data, setData] = useState(null);

  let pageNumber = 2;
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/teacher-combined-results/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
      });
  }, []);

  useEffect(() => {
    document.title = "تقرير عام";
  }, []);

  function chunkSectionsByStudentCount(sections) {
    const sectionChunks = [];

    for (const section of sections) {
      if (section.students.length > 3) {
        const studentChunks = chunkArray(section.students, 3);
        for (const studentChunk of studentChunks) {
          sectionChunks.push({
            ...section,
            students: studentChunk,
          });
        }
      } else {
        sectionChunks.push(section);
      }
    }

    return sectionChunks;
  }

  function chunkArray(array, chunkSize) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
  }

  const date = new Date().toLocaleDateString('ar-UA', { year: 'numeric', month: 'long', day: 'numeric' })

  return (
    <div className="flex flex-col items-center print:items-start gap-16 print:gap-0 py-16 print:p-0 bg-gray-200">
      <button
        className="bg-daisyBush text-white px-4 py-2 print:hidden rounded-md fixed top-14 right-10 font-bold z-50"
        onClick={() => {
          window.print();
        }}
      >
        طباعة
      </button>

      <div
        id="page-1"
        className="w-[210mm] h-[297mm] text-white shadow-2xl relative isolate flex flex-col items-center justify-center"
      >
        <img className="absolute w-full h-full -z-10" src={cover} alt="" />
        <div
          className="absolute top-[42px] left-[3px] flex gap-2 text-l text-white p-2"
        >
          <div>{date}</div>
        </div>
        <div className=" text-2xl">إدارة التعليم</div>
        <div className=" text-7xl font-bold mt-7 text-center">
          {data?.administration_of_education}
        </div>

        <div className="flex mt-20 gap-10 items-start text-center">
          <div className="flex flex-col items-center justify-center">
            <div className="">مكتب الإشراف</div>
            <div className=" text-2xl font-bold text-center mt-4 max-w-[200px]">
              {data?.education_office}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="">المدرسة</div>
            <div className=" text-2xl font-bold text-center mt-4 max-w-[200px]">
              {data?.school}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="">المعلم</div>
            <div className=" text-2xl font-bold text-center mt-4 max-w-[260px]">
              {data?.name}
            </div>
          </div>
        </div>
      </div>

      <div
        id="page-2"
        className="bg-white w-[210mm] h-[297mm] relative shadow-2xl"
      >
        <div className="header h-[180px] relative isolate text-white">
          <img className="-z-10 w-full h-full" src={header} alt="" />
          <div className="absolute top-[25%] left-[50%] translate-x-[-50%] flex flex-col items-center justify-center gap-4">
            <div className="">المعلم</div>
            <div className=" text-xl font-bold text-center max-w-[200px]">
              {data?.name}
            </div>
          </div>
          <div className="absolute top-[38px] left-[30px] flex flex-col gap-2">
            <div className="flex gap-2 items-start">
              <div className="">إدارة التعليم</div>
              <div className="font-bold max-w-[180px] text-center">
                {data?.administration_of_education}
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <div className="">مكتب التعليم</div>
              <div className="font-bold max-w-[170px] text-center">
                {data?.education_office}
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <div className="">المدرسة</div>
              <div className="font-bold max-w-[170px] text-center">
                {data?.school}{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="flex p-10 flex-col">
          <div className="w-full flex flex-col gap-14 pl-7">
            <StatsBlock
              title={"الطلاب"}
              total={data?.general_results.all_student}
              completed={data?.general_results.total_student_completed}
              remainder={data?.general_results.total_student_not_completed}
              isWide={true}
            />
          </div>

          <div className="mt-14">
            <div className="text-2xl">الصفوف</div>
            <div className="grid grid-cols-3 gap-5 gap-y-8 pt-7">
              {data?.sections_results.map((item, index) => {
                const p = ((
                  (item.completed_students / item.total_students) *
                  100
                ) || 0).toFixed(0);

                return (
                  <OfficeStats
                    key={index}
                    percentage={p}
                    name={item.my_section}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <div className="absolute bottom-[44px] right-[0] pr-10 text-[#240A63] flex gap-3 items-center font-bold text-l">
          تاريخ التصدير
          <div className=" flex gap-2">
            <div>{date}</div>
          </div>
        </div>
        <div className="absolute bottom-[44px] left-[0] pl-10 text-[#240A63] font-bold text-l">
          01
        </div>
      </div>

      <div
        id="page-3"
        className="bg-white w-[210mm] h-[297mm] relative shadow-2xl"
      >
        <div className="header h-[180px] relative isolate text-white">
          <img className="-z-10 w-full h-full" src={header} alt="" />
          <div className="absolute top-[25%] left-[50%] translate-x-[-50%] flex flex-col items-center justify-center gap-4">
            <div className="">المعلم</div>
            <div className=" text-xl font-bold text-center max-w-[200px]">
              {data?.name}
            </div>
          </div>
          <div className="absolute top-[38px] left-[30px] flex flex-col gap-2">
            <div className="flex gap-2 items-start">
              <div className="">إدارة التعليم</div>
              <div className="font-bold max-w-[170px] text-center">
                {data?.administration_of_education}
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <div className="">مكتب التعليم</div>
              <div className="font-bold max-w-[170px] text-center">
                {data?.education_office}
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <div className="">المدرسة</div>
              <div className="font-bold max-w-[170px] text-center">
                {data?.school}
              </div>
            </div>
          </div>
        </div>

        <div className="p-10">
          <div className="stats mt-9">
            <div className="w-full flex gap-10">
              <StatsBlock
                title={"الطلاب"}
                total={data?.general_results.all_student}
                completed={data?.general_results.total_student_completed}
                remainder={data?.general_results.total_student_not_completed}
              />
            </div>
          </div>

          <div className="second-table mt-11">
            <div className=" flex gap-[2px] text-white font-bold mb-2">
              <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center rounded-r-2xl">
                الصف
              </div>
              <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                الحالة
              </div>

              <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                الطلاب
              </div>
              <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                الطلاب المنجزون
              </div>
              <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center rounded-l-2xl">
                الطلاب المتبقين
              </div>
            </div>

            <div className="flex flex-col gap-[2px]">
              {data?.sections_results?.map((section, index) => (
                <div key={index} className=" flex gap-[2px]">
                  <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                    {section.my_section}
                  </div>
                  <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                    <span
                      className={`${section.completed_students === section.total_students
                        ? "text-Shamrock-100 bg-Shamrock-10"
                        : "text-gold-100 bg-gold-10"
                        } py-1 px-2`}
                    >
                      {section.completed_students === section.total_students
                        ? "منجز"
                        : "غير منجز"}
                    </span>
                  </div>
                  <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                    {section.total_students}
                  </div>
                  <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center text-Shamrock-100">
                    {section.completed_students}
                  </div>
                  <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center text-gold-100">
                    {section.incompleted_students}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="absolute bottom-[44px] right-[0] pr-10 text-[#240A63] flex gap-3 items-center font-bold text-l">
          تاريخ التصدير
          <div className=" flex gap-2">
            <div>{date}</div>
          </div>
        </div>
        <div className="absolute bottom-[44px] left-[0] pl-10 text-[#240A63] font-bold text-l">
          02
        </div>
      </div>

      {data?.sections_results?.length ? chunkSectionsByStudentCount(data.sections_results).map((section, index) => {
        pageNumber++
        return (
          <div
            key={section.name + '-' + index}
            id="page-4"
            className="bg-white w-[210mm] h-[297mm] relative shadow-2xl"
          >
            <div className="header h-[180px] relative isolate text-white">
              <img className="-z-10 w-full h-full" src={header} alt="" />
              <div className="absolute top-[25%] left-[50%] translate-x-[-50%] flex flex-col items-center justify-center gap-4">
                <div className="">الصف</div>
                <div className=" text-xl font-bold text-center max-w-[200px]">
                  {section?.name}
                </div>
              </div>
              <div className="absolute top-[38px] left-[30px] flex flex-col gap-2">
                <div className="flex gap-2 items-start">
                  <div className="">إدارة التعليم</div>
                  <div className="font-bold max-w-[170px] text-center">
                    {data?.administration_of_education}
                  </div>
                </div>
                <div className="flex gap-2 items-start">
                  <div className="">مكتب التعليم</div>
                  <div className="font-bold max-w-[170px] text-center">
                    {data?.education_office}
                  </div>
                </div>
                <div className="flex gap-2 items-start">
                  <div className="">المدرسة</div>
                  <div className="font-bold max-w-[170px] text-center">
                    {data?.school}
                  </div>
                </div>
              </div>
            </div>
            <div className="p-2">

              <div className="second-table mt-2">
                <div className="w-full">
                  {section?.students?.map((student, index) => (
                    <div key={student.id}>
                      <div className="flex flex-col gap-y-[1px]">
                        <div className="flex flex-row font-bold gap-[2px] text-white mb-1 w-full">
                          <div className="w-1/2 py-1 h-8 grow shrink basis-0 bg-[#712FED] flex items-center justify-center rounded-r-2xl">الطالب</div>
                          <div className="w-1/2 py-1 h-8 grow shrink basis-0 bg-[#712FED] flex items-center justify-center rounded-l-2xl">الحالة</div>
                        </div>

                        <div className="flex flex-row gap-[2px] text-sm">
                          <div className="h-8 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">{student.first_name}{" "} {student.father_name} {" "} {student.last_name}</div>
                          <div className={`h-8 w-1/4 py-2 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center ${student.completed ? 'text-Shamrock-100' : 'text-gold-100'}`}>
                            {student.completed ? 'مكتمل' : 'غير مكتمل'}
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="w-full text-center py-1 font-bold text-darkViolet-100 pr-2"> القياسات الجسدية</div>
                        </div>

                        <div className="flex flex-row bg-[#f9f9f9] px-2 text-center text-xs">
                          <div className="w-[40%] py-1 font-bold">الطول جلوساً</div>
                          <div className="w-[40%] py-1 font-bold">الطول وقوفاً</div>
                          <div className="w-[40%] py-1 font-bold">طول الذراعين</div>
                          <div className="w-[40%] py-1 font-bold">وزن الجسم</div>
                          <div className="w-[40%] py-1 font-bold">مؤشر كتلة الجسم</div>
                        </div>


                        <div className="flex flex-row">
                          <div className="w-[40%] py-1 flex gap-2 text-xs flex-col text-center">
                            <span>{Math.round(student?.records?.height_sitting) || "00"} سم</span>
                            <span>{convertDateToLocalString(student?.records?.height_sitting_date)}</span>
                          </div>
                          <div className="w-[40%] py-1 flex gap-2 text-xs flex-col text-center">
                            <span>{Math.round(student?.records?.height_standing) || "00"} سم</span>
                            <span>{convertDateToLocalString(student?.records?.height_standing_date)}</span>
                          </div>
                          <div className="w-[40%] py-1 flex gap-2 text-xs flex-col text-center">
                            <span>{Math.round(student?.records?.arms_length) || "00"} سم</span>
                            <span>{convertDateToLocalString(student?.records?.arms_length_date)}</span>
                          </div>
                          <div className="w-[40%] py-1 flex gap-2 text-xs flex-col text-center">
                            <span>{Math.round(student?.records?.body_weight) || "00"} كغم</span>
                            <span>{convertDateToLocalString(student?.records?.body_weight_date)}</span>
                          </div>
                          <div className="w-[40%] py-1 flex gap-2 text-xs flex-col text-center justify-center">
                            <span>{calculateBMI(student?.records?.height_standing, student?.records?.body_weight) || "00"}</span>
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="w-full text-center py-1 font-bold text-darkViolet-100 pr-2">قياسات اللياقة البدنية</div>
                        </div>

                        <div className="flex flex-row bg-[#f9f9f9] px-2 text-center text-xs">
                          <div className="w-[40%] py-1 font-bold">رمي الكرة الطبية</div>
                          <div className="w-[40%] py-1 font-bold">سرعة الجري</div>
                          <div className="w-[40%] py-1 font-bold">الجري المكوكي</div>
                          <div className="w-[40%] py-1 font-bold"> القفز العمودي</div>
                          <div className="w-[40%] py-1 font-bold"> القفز الافقي</div>
                        </div>


                        <div className="flex flex-row">
                          <div className="w-[40%] py-1 flex gap-2 text-xs flex-col text-center">
                            <span>{Math.round(student?.records?.ball_length) || "00"} سم</span>
                            <span>{convertDateToLocalString(student?.records?.ball_length_date)}</span>
                          </div>
                          <div className="w-[40%] py-1 flex gap-2 text-xs flex-col text-center">
                            <span>{Math.round(student?.records?.total_run) || "00"} دقيقة</span>
                            <span>{convertDateToLocalString(student?.records?.run_time_1_date)}</span>
                          </div>
                          <div className="w-[40%] py-1 flex gap-2 text-xs flex-col text-center">
                            <span>{Math.round(student?.records?.muk_runing) || "00"} جولات</span>
                            <span>{convertDateToLocalString(student?.records?.muk_runing_date)}</span>
                          </div>
                          <div className="w-[40%] py-1 flex gap-2 text-xs flex-col text-center">
                            <span>{Math.round(student?.records?.total_jump_v) || "00"} سم</span>
                            <span>{convertDateToLocalString(student?.records?.total_jump_v_date)}</span>
                          </div>
                          <div className="w-[40%] py-1 flex gap-2 text-xs flex-col text-center">
                            <span>{Math.round(student?.records?.total_jump_h) || "00"} سم</span>
                            <span>{convertDateToLocalString(student?.records?.total_jump_h_date)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="absolute bottom-[44px] right-[0] pr-10 text-[#240A63] flex gap-3 items-center font-bold text-l">
              تاريخ التصدير
              <div className=" flex gap-2">
                <div>{date}</div>
              </div>
            </div>
            <div className="absolute bottom-[44px] left-[0] pl-10 text-[#240A63] font-bold text-l">
              {pageNumber.toFixed(0).padStart(2, "0")}
            </div>
          </div>
        )
      }) : null}

    </div>
  );
}

const StatsBlock = ({ title, total, completed, remainder, isWide }) => {
  let percentage = (completed / total) * 100;

  if (isNaN(percentage)) {
    percentage = 0;
  }

  const color = (t) => {
    // commented class names has to be here for tailwind compiler
    // text-Shamrock-100
    // bg-Shamrock-100
    // text-gold-100
    // bg-gold-100
    if (percentage > 50) {
      return `${t}-Shamrock-100`;
    } else {
      return `${t}-gold-100`;
    }
  };

  return (
    <div className="text-black flex flex-col gap-6">
      <h3 className="text-2xl">{title}</h3>
      <div className={`flex ${isWide ? "gap-16" : "gap-7"}`}>
        <div className="">
          <p className="leading-none font-bold text-5xl">{total || "00"}</p>
          <p className="">العدد الكلي</p>
        </div>
        <div className="">
          <p
            className={`leading-none font-bold text-5xl ${completed ? "text-Shamrock-100" : "text-[#E8E8E8]"
              }`}
          >
            {completed || "00"}
          </p>
          <p className="">العدد المنجز</p>
        </div>
        <div className="">
          <p className={`leading-none font-bold text-5xl text-[#E8E8E8]`}>
            {remainder || "00"}
          </p>
          <p className="">العدد المتبقي</p>
        </div>
      </div>
      <div className="flex items-center gap-2 overflow-hidden">
        <div className={`${color("text")} w-fit`}>{percentage.toFixed(1)}%</div>
        <div className="h-2 grow bg-[#E8E8E8] mt-1">
          <div
            className={`${color("bg")} h-2`}
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const OfficeStats = ({ name, percentage }) => {
  return (
    <div className="flex items-center gap-2 ">
      <CircularProgressbar
        value={percentage}
        className="!w-14 !h-14"
        strokeWidth={14}
        styles={buildStyles({
          strokeLinecap: "butt",
          pathColor: percentage > 50 ? "#28DC9C" : "#EDD306",
          textColor: percentage > 50 ? "#28DC9C" : "#EDD306",
        })}
        text={`${percentage}%`}
      />

      <div className="mt-1 font-bold text-base">{name}</div>
    </div>
  );
};
