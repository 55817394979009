import standingHeightComplete from '../../../../assets/images/dashboard/measurement/standing-height-complete.svg'
import standingHeight from '../../../../assets/images/dashboard/measurement/standing-height.svg'
import RecordDate from '../MeasurementsElements/RecordDate'

export default function HeightStanding({ value, date = "" }) {
    console.log(date)
    return (
        <div className='rounded-lg p-5 text-center bg-[#F9F9F9]'>
            {value > 0 ?
                <img src={standingHeightComplete} className="mx-auto" />
                :
                <img src={standingHeight} className="mx-auto" />
            }

            <p className='pb-3 pt-2'>الطول وقوفاً</p>
            <p>
                <span className="text-nero pl-1">{value}</span>

                <span className='font-bold'>سم</span>
            </p>
            <RecordDate record={date} />
        </div>
    )
}
