import { useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useNavigate } from "react-router-dom";

export default function DashboardCard({ section }) {
    const [onHover, setOnHover] = useState(false)
    const navigate = useNavigate();

    return (
        <div
            onMouseOver={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            onClick={() => navigate(`/dashboard/incomplete-data/${section.id}`)}
            className='bg-white w-[260px] h-[205px] shadow-dashboardCard hover:shadow-[0px_0px_24px_2px_rgba(40,220,156,0.4)] rounded-lg flex flex-col p-4 cursor-pointer group hover:bg-Shamrock-100 active:bg-Shamrock-100'
        >
            <p className={`${onHover ? 'group-hover:text-white' : 'text-persianIndigo-100'} text-2xl font-bold leading-10 mb-2 pl-3`}>{section.my_section}</p>
            <p className={`${onHover ? 'group-hover:text-white' : 'text-persianIndigo-100'}`}>{section.total_students} طالب</p>
            <div className="flex items-center gap-6 my-3">
                <p className={`${onHover ? 'text-white' : 'text-suvaGrey-80'} font-light text-xs`}>{new Date(section.last_change).toLocaleTimeString([], { timeStyle: 'short' })}</p>
                <p className={`${onHover ? 'text-white' : 'text-suvaGrey-80'} font-light text-xs`}>{new Date(section.last_change).toLocaleDateString('ar-MA', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
            </div>
            <div className='flex items-center gap-2'>
                {section.total_students == 0 ?
                    <div className={`w-9 h-9 text-xl border-[5px] rounded-full flex items-center ${onHover ? 'border-white' : 'border-suvaGrey-40'} justify-center`}></div>
                    :
                    <div className='w-10 h-10'>
                        <CircularProgressbar
                            className='w-[40px]' counterClockwise value={(100 * section.incompleted_students) / section.total_students} strokeWidth="15"
                            styles={buildStyles({
                                trailColor: onHover ? '#FFFFFF' : '#D6D9E0',
                                pathColor: onHover ? '#712FED' : '#DB0000',
                                strokeLinecap: 'butt',
                            })}
                        />
                    </div>
                }
                <div>
                    <p className={`${onHover ? 'text-white' : 'text-suvaGrey-80'} mb-2 text-sm`}> {section.completed_students} بيانات طلاب مكتملة</p>
                    {section.total_students == 0 ?
                        <p className={`${onHover ? 'text-white' : 'text-suvaGrey-40'} font-bold text-sm`}> {section.incompleted_students} طالب متبقي</p>
                        : <p className={`${onHover ? 'text-darkViolet-100' : 'text-[#DB0000]'} font-bold text-sm`}> {section.incompleted_students} طالب متبقي</p>
                    }
                </div>
            </div>
        </div>
    )
}
