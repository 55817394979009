import { Form, Formik } from "formik";
import { FiSave } from "react-icons/fi";
import validationSchema from "../utils/form//signup/validationSchema";
import formInitialValues from "../utils/form/signup/initialValues";
import logo from "../assets/images/logo/tamheed-white.svg";
import { useEffect, useState } from "react";
import { IoChevronBackOutline, IoChevronForwardOutline, IoClose } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { RiErrorWarningLine } from "react-icons/ri";
import PersonalInfoForm from "../components/forms/ReviewInfo";
import Steps from "../components/Steps";
import NewAccountForm from "../components/forms/NewAccount";
import SignupForm from "../components/forms/Signup";
import axios from "axios";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { useWalkthrough } from '../context/WalkthroughContext'

const steps = [
  {
    name: "signup",
    label: "إنشاء حساب جديد",
  },
  {
    name: "information-review",
    label: "مراجعة المعلومات",
  },
  {
    name: "email",
    label: "البريد الإلكتروني",
  },
];

export default function SignupPage() {
  useEffect(() => {
    document.title = "تمهيد | انشاء حساب";
  }, []);

  let stepsIndex = 3;

  const [activeStep, setActiveStep] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(false);
  const [profileId, setProfileId] = useState(null);
  const [userProfile, setUserProfile] = useState("");
  const [showVerification, setShowVerification] = useState(false);
  const [message, setMessage] = useState("");
  const { isWalkthrough, enableWalkthrough, disableWalkthrough, popuopPos, setPopuopPos } = useWalkthrough()
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  useEffect(() => {
    enableWalkthrough()
    setPopuopPos('init')
  }, []);

  async function submitForm(values, actions) {
    actions.setSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/signup/`, {
        profile: profileId,
        email: values.email.toLowerCase(),
        password: values.password,
      })
      .then(function (response) {
        setShowVerification(true);
        actions.setSubmitting(false);
      })
      .catch(function () {
        actions.setSubmitting(false);
        setEmailErrorMessage(true);
      });
  }

  function handleSubmit(values, actions) {
    if (activeStep == 0) {
      actions.setSubmitting(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/check_user/`, {
          national_id: values.national_id,
          // date: `${values.year}-${values.month
          //   .toString()
          //   .padStart(2, "0")}-${values.day.toString().padStart(2, "0")}`,
          // is_hijri: values.isHijri,
        })
        .then(function (response) {
          actions.setSubmitting(false);
          if (response.data == false) {
            setShowMessage(true);
            setMessage("بياناتك غير مسجلة، الرجاء التواصل مع المشرف لتزويدك برابط التسجيل");
          } else if (response.data === "user already registered") {
            setShowMessage(true);
            setMessage("رقم الهوية مرتبط بحساب");
          } else {
            setActiveStep(activeStep + 1);
            setShowMessage(false);
            setProfileId(response.data.id);
            setUserProfile(response.data);
          }
        })
        .catch(function (error) {
          actions.setSubmitting(false);
        });
    } else if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  return (
    <>
      {showVerification ? (
        <div className="custom-bg flex flex-col justify-around gap-5 py-4 md:py-16">
          <img src={logo} alt="Logo" className="mx-auto w-32 md:w-44" />

          <div className="mx-auto w-4/5 md:w-auto bg-white rounded-2xl my-auto flex flex-col gap-5 items-center py-4 md:py-10 px-2">
            <MdOutlineMailOutline className="text-darkViolet-100 text-3xl md:text-5xl" />
            <span className="text-persianIndigo-100 text-xl md:text-3xl font-bold w-2/3 text-center">
              تم إرسال لبريدك الإلكتروني رابط التحقق
            </span>
            <p className="font-light mb-4 text-sm md:text-base">الرجاء إستخدام الرابط للمتابعة</p>
          </div>
        </div>
      ) : (
        <div className="custom-bg flex flex-col text-white pm-4 sm:pb-20">
          <Steps currentPageIndex={activeStep} steps={steps} />

          <Formik
            enableReinitialize={true}
            validationSchema={currentValidationSchema}
            initialValues={formInitialValues}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isValid, dirty, values, isSubmitting }) => (
              <Form>
                <div className="relative">
                  {isWalkthrough && popuopPos === 'init' ? (
                    <div className="absolute top-24 right-1/4 z-[100] outline-none focus:outline-none  bg-persianIndigo-100 rounded-2xl w-[247px] h-[164.07px]">
                      <div className="flex justify-between items-center p-2">
                        <RiErrorWarningLine className="text-[#28DC9C] text-2xl" />
                        <IoClose className="text-persianIndigo-40 cursor-pointer" onClick={() => {
                          disableWalkthrough()
                          setPopuopPos('')
                        }} />
                      </div>
                      <p className="px-3 text-xs mt-3">للمستخدمين الجدد ننصح بإستخدام الجولة التعريفية لتسهيل التعرف على المنصة وكيفية إستخدامها .</p>
                      <div className="mx-3 mt-4">
                        <button onClick={() => setPopuopPos('checkUser')} className="bg-white w-full text-xs text-persianIndigo-100 rounded-lg py-2 font-bold text-right px-2">إبدأ الجولة التعريفية</button>
                      </div>
                    </div>
                  ) : null}
                </div>
                {activeStep == 0 ? (
                  <NewAccountForm
                    values={values}
                    errors={errors}
                    touched={touched}
                    showMessage={showMessage}
                    message={message}
                    stepsIndex={stepsIndex}
                    isWalkthrough={isWalkthrough}
                    popuopPos={popuopPos}
                    setPopuopPos={setPopuopPos}
                  />
                ) : activeStep == 1 ? (
                  <PersonalInfoForm
                    userProfile={userProfile}
                    stepsIndex={stepsIndex}
                    isWalkthrough={isWalkthrough}
                    popuopPos={popuopPos}
                    setPopuopPos={setPopuopPos}
                  />
                ) : (
                  <SignupForm
                    values={values}
                    errors={errors}
                    touched={touched}
                    emailErrorMessage={emailErrorMessage}
                    isWalkthrough={isWalkthrough}
                    stepsIndex={stepsIndex}
                    popuopPos={popuopPos}
                    setPopuopPos={setPopuopPos}
                  />
                )}
                <div className="w-2/3 mx-auto mb-2 sm:mb-10 flex">
                  {activeStep !== 0 ? (
                    <button
                      type="button"
                      onClick={() => setActiveStep((prev) => prev - 1)}
                      className="flex items-center border border-white rounded-lg text-white px-6"
                    >
                      <IoChevronForwardOutline />
                    </button>
                  ) : (
                    <Link
                      to="/"
                      className="border border-persianIndigo-100 text-persianIndigo-100 flex justify-center items-center px-5 text-xl rounded-lg"
                    >
                      <BiChevronRight />
                    </Link>
                  )}
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="flex items-center bg-[#F9F9F9] shadow-button rounded-lg text-persianIndigo-100 disabled:text-HawkesBlue-90 font-[600] px-10 xl:w-1/4 lg:w-1/3 sm:w-1/2 w-2/3 mr-auto h-14"
                  >
                    <span className={`${isSubmitting && ""} ml-auto`}>
                      {activeStep == 0
                        ? "حفظ والإستمرار"
                        : isLastStep
                          ? "الحفظ والإتمام"
                          : "التالي"}
                    </span>
                    {isSubmitting ? (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-persianIndigo-100"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : isLastStep ? (
                      <FiSave />
                    ) : (
                      <IoChevronBackOutline />
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}
