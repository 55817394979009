import { IoFemaleSharp, IoMaleSharp } from "react-icons/io5";
import WalkThrough from "../modals/WalkThrough";
import { useState } from "react";

export default function ReviewInfo({
  userProfile,
  isWalkthrough,
  stepsIndex,
  popuopPos,
  setPopuopPos
}) {


  return (
    <>
      <div className="w-3/4 sm:w-2/3 mx-auto mb-10">
        <div className="bg-[#F9F9F9] px-12 rounded-lg shadow-page-card">
          <p className="text-darkViolet-100 font-bold text-center text-2xl pt-10 mb-6">
            المعلومات الشخصية
          </p>
          <div className="relative">
            {isWalkthrough && popuopPos === 'review' ? (
              <WalkThrough
                title="انشاء حساب"
                number={`2/${stepsIndex}`}
                subTitle="مراجعة المعلومة الشخصية"
                text="الرجاء مراجعة معلوماتك الشخصية والوظيفية قبل المتابعة."
                colorOne
                colorTwo
                onclosenow={() => setPopuopPos('singup')}
                stepsIndex={stepsIndex}
                arrowClass="bottom-right"
                top="-130px"
                left="1vw"
              />
            ) : null}
          </div>
          <hr className="bg-HawkesBlue-40 mr-5 mb-10" />
          <div className="grid md:grid-flow-col gap-x-16 gap-y-2 md:grid-rows-4 pb-10 mr-5 text-nero">
            <div className="font-bold">الإسم الرباعي</div>
            <div>{userProfile ? userProfile.name : "-"}</div>
            <div className="font-bold mt-2">الجنسية</div>
            <div>{userProfile ? userProfile.nationality || "-" : "-"}</div>
            <div className="font-bold">رقم الهاتف</div>
            <div className="text-right" dir="ltr">
              {userProfile ? userProfile.phone_number : "-"}
            </div>
            <div className="font-bold mt-2">الجنس</div>
            <p>
              {
                userProfile ? userProfile.gender === 'M' ?
                  <>
                    <IoMaleSharp className='font-bold mx-auto text-Shamrock-100 text-2xl inline ml-2' />
                    <span className='font-bold'>ذكر</span>
                  </>
                  : userProfile.gender === 'F' ?
                    <>
                      <span className='font-bold'>أنثى</span>
                      <IoFemaleSharp className='font-bold mx-auto text-Shamrock-100 text-2xl inline ml-2' />
                    </>
                    : '-' : '-'
              }
            </p>
          </div>
        </div>
      </div>
      {userProfile && userProfile.user_type !== "M" ? (
        <div className="w-3/4 sm:w-2/3 mx-auto mb-10">
          <div className="bg-[#F9F9F9] px-12 rounded-lg shadow-page-card">
            <p className="text-darkViolet-100 font-bold text-center text-2xl pt-10 mb-6">
              المعلومات الوظيفية
            </p>
            <hr className="bg-HawkesBlue-40 mr-5 mb-10" />
            <div className="grid md:grid-flow-col grid-cols-2 gap-x-16 gap-y-4 grid-rows-4 pb-4 mr-5 text-nero">
              <div className="font-bold">إدارة التعليم</div>
              <div>
                {userProfile ? userProfile.administration_of_education || "-" : "-"}
              </div>
              <div className="font-bold mt-2">مكتب التعليم</div>
              <div>{userProfile ? userProfile.educational_office || "-" : "-"}</div>
              <div className="font-bold mt-2">اسم المدرسة</div>
              <div>{userProfile ? userProfile.school_name || "-" : "-"}</div>
              <div className="font-bold mt-2">نوع المدرسة</div>
              <div>{userProfile ? userProfile.school_type || "-" : "-"}</div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
