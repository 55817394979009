import { Field, useFormikContext } from "formik";
import Checkbox from "../../FormTools/CustomCheckbox";
import { Select } from "../../FormTools/CustomSelect";
import { BiErrorCircle } from "react-icons/bi";
import options from "../../../data/register.json";
import dates from "../../../data/dates.json";
import nationalities from "../../../data/nationalities.json";
import { getEducationalAdministrations, getEducationalOfficesAll } from "../../../utils/apis";
import { useEffect, useState } from "react";

const SupPersonalDataForm = ({
  showMessage,
  message,
  errors,
  touched,
}) => {

  const genders = [
    { label: "أنثى", value: "F" },
    { label: "ذكر", value: "M" },
  ];

  const [educationalOffices, setEducationalOffices] = useState([]);
  const [educationalAdministrations, setEducationalAdministrations] = useState(
    []
  );

  const { values, setFieldValue } = useFormikContext();

  const handleGender = () => {
    if (values.gender === "F") {
      return '[F,B]'
    } else if (values.gender === "M") {
      return '[M,B]'
    }
  }

  const fetchEducationalOffices = async () => {
    const officesOptions = await getEducationalOfficesAll('', `?gender=${handleGender()}`);
    setEducationalOffices(
      officesOptions.results.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );
  };

  const fetchEducationalAdministrations = async () => {
    const administrationOptions = await getEducationalAdministrations();
    setEducationalAdministrations(
      administrationOptions.results.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );
  };

  useEffect(() => {
    fetchEducationalAdministrations();
  }, []);

  useEffect(() => {
    if (values.administration_of_education) {
      getEducationalOfficesAll(values.administration_of_education, `&gender=${handleGender()}`).then(
        (res) => {
          setFieldValue("educational_office", "");
          setEducationalOffices(
            res.results.map((option) => {
              return { label: option.name, value: option.id };
            })
          );
        }
      );
    } else {
      fetchEducationalOffices();
    }
  }, [values.administration_of_education]);

  return (
    <>
      <div className="w-2/3 mx-auto my-10">
        <div className="bg-[#F9F9F9] px-12 rounded-lg shadow-page-card">
          <p className="text-darkViolet-100 font-bold text-center text-2xl pt-10 mb-6">
            المعلومات الشخصية
          </p>
          <hr className="bg-HawkesBlue-40 mb-5 lg:mb-10" />
          {showMessage && (
            <div className="bg-[rgba(217,30,30,0.08)] rounded-lg py-2 px-4 text-[#D91E1E] font-bold flex items-center gap-2 mb-4">
              <BiErrorCircle />
              {message === 'national_id' ?
                <span> رقم الهوية موجود مسبقًا، يمكنك التسجيل من خلال {" "}
                  <a className="underline" href={`${window.location.origin}/signup`}>هذا الرابط</a>
                </span>
                :
                <span>{message}</span>
              }
            </div>
          )}

          <div className="text-nero pb-10">
            <div className="grid lg:grid-cols-2 lg:gap-5">
              <div>
                <label htmlFor="name" className="font-bold">
                  الأسم رباعي{" "}
                </label>
                <Field
                  className={`number-arrow border ${errors.name && touched.name
                    ? "border-[#DB0000] placeholder:text-[#DB0000]"
                    : "border-[#D6D9E0]"
                    } w-full py-4 mt-3 placeholder:text-[#D6D9E0] px-6 text-black rounded-lg`}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="الأسم رباعي"
                />
                <div className="my-5">
                  {errors.name && touched.name ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.name}</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="id" className="font-bold">
                  ادخل/ي رقم بطاقة الأحوال/الإقامة
                </label>
                <Field
                  className={`number-arrow border ${errors.national_id && touched.national_id
                    ? "border-[#DB0000] placeholder:text-[#DB0000]"
                    : "border-[#D6D9E0]"
                    } w-full py-4 mt-3 placeholder:text-[#D6D9E0] px-6 text-black rounded-lg`}
                  type="number"
                  id="id"
                  name="national_id"
                  placeholder="رقم الأحوال/الإقامة"
                />
                <div className="my-5">
                  {errors.national_id && touched.national_id ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.national_id}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div>
              <p className="font-bold">
                ادخل/ي تاريخ انتهاء الهوية{" "}
              </p>

              <div className="grid md:grid-cols-3 mt-3 gap-x-5 gap-y-3">
                <Field
                  name="IDday"
                  component={Select}
                  // options={dates[values.isHijriID ? "hijri" : "gregorian"].days}
                  options={dates["gregorian"].days}
                  placeholder="اليوم"
                />
                <Field
                  name="IDmonth"
                  component={Select}
                  // options={
                  //   dates[values.isHijriID ? "hijri" : "gregorian"].months
                  // }
                  options={dates["gregorian"].months}
                  placeholder="الشهر"
                />
                <Field
                  name="IDyear"
                  component={Select}
                  // options={
                  //   dates[values.isHijriID ? "hijri" : "gregorian"].years
                  // }
                  options={dates["gregorian"].years.filter(
                    (year) => year.value > 2022
                  )}
                  placeholder="السنة"
                />

                <div>
                  {errors.IDday && touched.IDday ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.IDday}</span>
                    </div>
                  ) : null}
                </div>

                <div>
                  {errors.IDmonth && touched.IDmonth ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.IDmonth}</span>
                    </div>
                  ) : null}
                </div>

                <div>
                  {errors.IDyear && touched.IDyear ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.IDyear}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="mt-5">
              <p className="font-bold mt-3">
                ادخل/ي تاريخ الميلاد
              </p>

              <div className="grid md:grid-cols-3 mt-3 gap-x-5 gap-y-3">
                <Field
                  name="Bday"
                  component={Select}
                  options={dates[values.isHijri ? "hijri" : "gregorian"].days}
                  placeholder="اليوم"
                />
                <Field
                  name="Bmonth"
                  component={Select}
                  options={dates[values.isHijri ? "hijri" : "gregorian"].months}
                  placeholder="الشهر"
                />
                <Field
                  name="Byear"
                  component={Select}
                  options={dates[values.isHijri ? "hijri" : "gregorian"].years}
                  placeholder="السنة"
                />

                <div>
                  {errors.Bday && touched.Bday ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.Bday}</span>
                    </div>
                  ) : null}
                </div>

                <div>
                  {errors.Bmonth && touched.Bmonth ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.Bmonth}</span>
                    </div>
                  ) : null}
                </div>

                <div>
                  {errors.Byear && touched.Byear ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.Byear}</span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="lg:mt-3 mt-4">
                <Checkbox
                  label="تاريخ هجري"
                  name="isHijri"
                  id="isHijri"
                  bgColor="#522EA1"
                />
              </div>
            </div>

            <div className="mt-5 grid lg:grid-cols-2 lg:gap-5">
              <div className="">
                <p className="font-bold mb-3">الجنسية</p>
                <div className="w-full">
                  <Field
                    name="nationality"
                    component={Select}
                    options={nationalities}
                    placeholder="إختار الجنسية"
                  />
                </div>

                <div className="my-5">
                  {errors.nationality && touched.nationality ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.nationality}</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="">
                <p className="font-bold mb-3">الجنس</p>
                <div className="w-full">
                  <Field
                    component={Select}
                    name="gender"
                    options={genders}
                    placeholder="إختار الجنس"
                  />
                </div>

                <div className="my-5">
                  {errors.gender && touched.gender ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.gender}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="mt-5">
              <p className="font-bold mb-3">رقم الجوال</p>
              <div className="w-full relative">
                <p className="absolute left-4 top-4 text-suvaGrey-80">966+</p>
                <Field
                  className={`form-input focus:ring-0 focus:border-darkViolet-100 rounded-lg ${errors.phone_number && touched.phone_number
                    ? "border-[#DB0000] placeholder:text-[#DB0000]"
                    : "border-[#D6D9E0] placeholder:text-[#D6D9E0]"
                    } w-full py-4 pl-16 pr-6 text-nero number-arrow`}
                  dir="ltr"
                  type="number"
                  name="phone_number"
                  placeholder="رقم الجوال"
                />
              </div>
              <div className="my-5">
                {errors.phone_number && touched.phone_number ? (
                  <div className="flex gap-1">
                    <BiErrorCircle className="text-error text-2xl font-light" />
                    <span className="text-error">{errors.phone_number}</span>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="grid lg:grid-cols-2 lg:gap-5">
              <div className="">
                <label
                  htmlFor="administration_of_education"
                  className="font-bold mb-3 block"
                >
                  الادارة التعليمية
                </label>
                <div className="w-full">
                  <Field
                    id="administration_of_education"
                    component={Select}
                    name="administration_of_education"
                    options={educationalAdministrations}
                    placeholder="إختار الإدارة التعليمية"
                    onChange={(e) => {
                      console.log(e);
                    }}
                  />
                </div>

                <div className="my-5">
                  {errors.administration_of_education &&
                    touched.administration_of_education ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">
                        {errors.administration_of_education}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="">
                <label
                  htmlFor="educational_office"
                  className="font-bold mb-3 block"
                >
                  المكتب التعليمي
                </label>
                <div className="w-full">
                  <Field
                    id="educational_office"
                    name="educational_office"
                    component={Select}
                    options={educationalOffices}
                    placeholder="إختار المكتب التعليمي"
                  />
                </div>

                <div className="my-5">
                  {errors.educational_office && touched.educational_office ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">
                        {errors.educational_office}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="">
              <label
                htmlFor="educational_region"
                className="font-bold mb-3 block"
              >
                المنطقة التعليمية
              </label>
              <div className="w-full">
                <Field
                  id="educational_region"
                  name="educational_region"
                  component={Select}
                  options={options.educationalRegion}
                  placeholder="إختار المنطقة التعليمية"
                />
              </div>

              <div className="my-5">
                {errors.educational_region && touched.educational_region ? (
                  <div className="flex gap-1">
                    <BiErrorCircle className="text-error text-2xl font-light" />
                    <span className="text-error">
                      {errors.educational_region}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default SupPersonalDataForm;
