
export default function JobInfo({ teacher }) {

    return (
        <div className='rounded-lg shadow-[0px_0px_24px_rgba(35,35,35,0.06)] flex flex-col md:grid grid-cols-2 gap-5 p-8 mb-4 mx-10'>
            <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                <p className='text-suvaGrey-60 text-sm'>اسم المدرسة</p>
                <p className='font-bold'>{teacher ? teacher.school || '-' : '-'}</p>
            </div>
            <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                <p className='text-suvaGrey-60 text-sm'>نوع المدرسة</p>
                <p className='font-bold'>{teacher ? teacher.school_type || '-' : '-'}</p>
            </div>
            <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                <p className='text-suvaGrey-60 text-sm'>إدارة التعليم</p>
                <p className='font-bold'>{teacher ? teacher.administration_of_education || '-' : '-'}</p>
            </div>
            <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                <p className='text-suvaGrey-60 text-sm'>مكتب التعليم</p>
                <p className='font-bold'>{teacher ? teacher.educational_office || '-' : '-'}</p>
            </div>
        </div>
    )
}
