
export default function Steps({ currentStepIndex, steps }) {
    return (
        <div className="students-stepper py-5">
            {steps.map((s, i) => {
                const icon = i == currentStepIndex ? 'icon-current' : i < currentStepIndex ? 'icon-prev prev' : 'icon-next';
                const textColor = i == currentStepIndex ? 'text-darkViolet-100 font-bold' : i < currentStepIndex ? 'text-Shamrock-100 font-bold' : 'text-suvaGrey-40';
                return <div key={s} className={`students-stepper__item ${icon}`}>
                    <h3 className={`text-xs sm:text-sm ${textColor}`}>{s}</h3>
                </div>
            })}
        </div>
    )
}
