import { useState } from "react"
import { useAuth } from "../../../context/AuthContext";
import profileImage from "../../../assets/images/dashboard/profile-image.png";

function ProfileImage({ imageURL }) {
    const [isEmpty, setIsEmpty] = useState(imageURL ? false : true)
    const [imagePreviewUrl, setImagePreviewUrl] = useState(imageURL ? imageURL : profileImage)
    const { token } = useAuth();

    const photoUpload = async (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            setImagePreviewUrl(reader.result)
        }
        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append("file", file);

        fetch(`${process.env.REACT_APP_API_URL}/upload_profile_photo/`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Token ${token}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                setIsEmpty(false)
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <div>
            <label htmlFor="photo-upload" className="rounded-full inline-block relative cursor-pointer border border-persianIndigo-100">
                <div className="img-wrap img-upload" >
                    <img className="profile-img" src={imagePreviewUrl} />
                </div>
                {isEmpty ?
                    <div className="absolute -bottom-4 right-8">

                        <svg width="35" height="35" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="21" cy="21" r="20" fill="white" stroke="#712FED" strokeWidth="2" />
                            <path d="M21 16V26M16 21H26" stroke="#712FED" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
                        </svg>

                    </div> :
                    <div className="absolute -bottom-4 right-8">
                        <svg width="35" height="35" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="21" cy="21" r="20" fill="white" stroke="#240A63" strokeWidth="2" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M20.1992 27.497C20.1992 27.0552 20.1992 26.697 20.1992 26.697H28.9992C28.9992 26.697 28.9992 27.0552 28.9992 27.497C28.9992 27.9388 28.9992 28.297 28.9992 28.297H20.1992C20.1992 28.297 20.1992 27.9388 20.1992 27.497Z" fill="#240A63" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M25.8 14.6C25.5621 14.6 25.3339 14.6945 25.1657 14.8627L15.3224 24.706L14.8995 26.3975L16.591 25.9747L26.4343 16.1314C26.5176 16.0481 26.5837 15.9492 26.6288 15.8403C26.6739 15.7315 26.6971 15.6149 26.6971 15.4971C26.6971 15.3793 26.6739 15.2626 26.6288 15.1538C26.5837 15.0449 26.5176 14.946 26.4343 14.8627C26.351 14.7794 26.2521 14.7134 26.1433 14.6683C26.0345 14.6232 25.9178 14.6 25.8 14.6ZM24.0343 13.7314C24.5026 13.2631 25.1377 13 25.8 13C26.1279 13 26.4526 13.0646 26.7556 13.1901C27.0585 13.3156 27.3338 13.4995 27.5657 13.7314C27.7976 13.9632 27.9815 14.2385 28.107 14.5415C28.2325 14.8444 28.2971 15.1691 28.2971 15.4971C28.2971 15.825 28.2325 16.1497 28.107 16.4526C27.9815 16.7556 27.7976 17.0309 27.5657 17.2627L17.5657 27.2627C17.4632 27.3652 17.3347 27.438 17.194 27.4731L13.994 28.2731C13.7214 28.3413 13.433 28.2614 13.2343 28.0627C13.0356 27.864 12.9557 27.5756 13.0239 27.303L13.8239 24.103C13.8591 23.9623 13.9318 23.8339 14.0343 23.7314L24.0343 13.7314Z" fill="#240A63" />
                        </svg>
                    </div>
                }
                <input id="photo-upload" accept="image/*" className="hidden" type="file" onChange={photoUpload} />
            </label>

        </div>
    )
}

export default ProfileImage