import { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { BsCheck } from 'react-icons/bs'
import { IoFemaleSharp, IoMaleSharp } from 'react-icons/io5'
import weight from '../../../assets/images/dashboard/measurement/weight.svg'
import weightComplete from '../../../assets/images/dashboard/measurement/weight-complete.svg'
import medBallComplete from '../../../assets/images/dashboard/measurement/med-ball-complete.svg'
import medBall from '../../../assets/images/dashboard/measurement/med-ball.svg'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { useNavigate } from 'react-router-dom'
import HeightStanding from '../../../components/dashboard/students/ReviewCards/HeightStanding'
import HeightSitting from '../../../components/dashboard/students/ReviewCards/HeightSitting'
import Arms from '../../../components/dashboard/students/ReviewCards/Arms'
import Run from '../../../components/dashboard/students/ReviewCards/Run'
import ShuttleRun from '../../../components/dashboard/students/ReviewCards/ShuttleRun'
import Jump from '../../../components/dashboard/students/ReviewCards/Jump'
import handlePercentage from '../../../hooks/handleRecordPercentage'


export default function SummrayCard({ student, index }) {

    const [expand, setExpand] = useState(false)

    const navigate = useNavigate()

    return (
        <div>
            <div
                onClick={() => setExpand((prev) => !prev)}
                className='shadow[0px_4px_6px_rgba(35,35,35,0.06)] bg-[#F9F9F9] flex items-center gap-3 rounded-md px-8 py-5 my-2 cursor-pointer'>

                <p className='text-suvaGrey-40'>{index + 1}</p>
                <div className="w-8">
                    {student.completed ?
                        <BsCheck className='inline border-4 stroke-1 border-Shamrock-100 rounded-full text-Shamrock-100 text-lg h-6 w-6' />
                        : student.records == "No record found" ?
                            <span className='border-4 border-HawkesBlue-100 rounded-full text-lg w-6 h-6 block'></span>
                            :
                            <span className='w-6 h-6 block'>
                                <CircularProgressbar
                                    value={handlePercentage(student)} strokeWidth="16"
                                    counterClockwise
                                    styles={buildStyles({
                                        pathColor: '#D91E1E',
                                        trailColor: '#D6D9E0',
                                        strokeLinecap: 'butt',
                                    })}
                                />
                            </span>
                    }
                </div>
                <p className='font-bold text-nero'>{student.name}</p>
                {expand ?
                    <FiChevronUp className={`text-HawkesBlue-100 text-3xl mr-auto`} />
                    :
                    <FiChevronDown className='text-HawkesBlue-100 text-3xl mr-auto' />
                }
            </div>
            {expand &&
                <div className="flex flex-col sm:grid sm:grid-cols-2 lg:grid-cols-3 gap-3">

                    <div className='px-4 py-5'>
                        <p className='font-bold text-suvaGrey-80 text-xl text-center mb-6'>معلومات عامة</p>
                        <div className="grid grid-cols-1 gap-4">
                            <div className='rounded-lg bg-[#F9F9F9] text-center py-5'>
                                <p className='pb-4'>رقم الأحوال/الإقامة</p>
                                <p className='font-bold'>{student.national_id || "-"}</p>
                            </div>
                            <div className='rounded-lg bg-[#F9F9F9] text-center py-5'>
                                <p className='pb-4'>تاريخ الميلاد</p>
                                <p className='font-bold'>{student.dateOfBirth || "-"}</p>
                            </div>
                            <div className='rounded-lg bg-[#F9F9F9] text-center py-5'>
                                <p className='pb-4'>الجنس</p>
                                <p>
                                    {student.gender === 'M' ?
                                        <>
                                            <IoMaleSharp className='font-bold mx-auto text-Shamrock-100 text-2xl inline ml-2' />
                                            <span className='font-bold'>ذكر</span>
                                        </>
                                        : student.gender === 'F' ?
                                            <>
                                                <span className='font-bold'>أنثى</span>
                                                <IoFemaleSharp className='font-bold mx-auto text-Shamrock-100 text-2xl inline ml-2' />
                                            </>
                                            : "-"}
                                </p>
                            </div>
                            <div className='rounded-lg bg-[#F9F9F9] text-center py-5'>
                                <p className='pb-4'>الجنسية</p>
                                <p className='font-bold'>{student.nationality || "-"}</p>
                            </div>
                            <div className='rounded-lg bg-[#F9F9F9] text-center py-5'>
                                <p className='pb-4'>مكان الميلاد</p>
                                <p className='font-bold'>{student.city || "-"}</p>
                            </div>
                        </div>

                    </div>
                    <div className='p-4 mb-10 border-l border-r'>
                        <p className='font-bold text-suvaGrey-80 text-xl text-center mb-6'>القياسات الجسدية</p>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="cursor-pointer">
                                <HeightStanding value={student.records.height_standing || 0} />
                            </div>

                            <div className="cursor-pointer">
                                <HeightSitting value={student.records.height_sitting || 0} />
                            </div>

                            <div className="cursor-pointer">
                                <Arms value={student.records.arms_length || 0} />
                            </div>

                            <div className="rounded-lg p-5 text-center bg-[#F9F9F9] cursor-pointer">
                                {student.records.body_weight > 0 ?
                                    <img src={weightComplete} className="mx-auto" />
                                    :
                                    <img src={weight} className="mx-auto" />
                                }

                                <p className='pb-4 pt-2'>وزن الجسم</p>
                                <p>
                                    <span className="text-nero pl-1">{student.records.body_weight || 0}</span>
                                    <span className='font-bold'>كغم</span>
                                </p>
                            </div>

                            <div className="col-span-2 p-3 text-center rounded-lg bg-[#F9F9F9]">
                                <p className='pb-4 font-light'>مؤشر كتلة الجسم</p>
                                <p>
                                    <span>{student.records.bmi || 0}</span>
                                    {student.records.bmi &&
                                        <span className='font-bold pr-3'>طبيعي</span>
                                    }
                                </p>
                            </div>

                            <div className="col-span-2 p-3 text-center rounded-lg bg-[#F9F9F9]">
                                <p className='pb-4 font-light'>الملاحظات</p>
                                <p>{student.records.notes_1 || "-"}</p>
                            </div>

                        </div>
                    </div>

                    <div className='p-4 mb-10 sm:col-span-2 md:col-span-1'>
                        <p className='font-bold text-suvaGrey-80 text-xl text-center mb-6'>قياسات اللياقة البدنية</p>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="rounded-lg p-5 text-center bg-[#F9F9F9] cursor-pointer">

                                {student.records.ball_length > 0 ?
                                    <img src={medBallComplete} className="mx-auto" />
                                    :
                                    <img src={medBall} className="mx-auto" />
                                }

                                <p className='pt-2'>رمي الكرة الطبية</p>
                                <p className='py-2'>مسافة بعد الكرة</p>
                                <p className='mt-10'>
                                    <span className="text-nero pl-1">{student.records.ball_length || 0}</span>
                                    <span className='font-bold'>سم</span>
                                </p>
                            </div>


                            <div className="cursor-pointer">
                                <Run values={[student.records.run_time_1 || 0, student.records.run_time_2 || 0, student.records.run_time_3 || 0]} />
                            </div>

                            <div className="cursor-pointer">
                                <ShuttleRun value={student.records.muk_runing || 0} />
                            </div>

                            <div className="cursor-pointer">
                                <Jump values={[student.records.jump_1 || 0, student.records.jump_2 || 0, student.records.jump_2 || 0]} />
                            </div>


                            <div className="col-span-2 p-3 text-center rounded-lg bg-[#F9F9F9]">
                                <p className='pb-4 font-light'>الملاحظات</p>
                                <p>{student.records.notes_2 || "-"}</p>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )

}
