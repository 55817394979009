import { useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { BiCheck } from "react-icons/bi";
import { RxDotFilled } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

export default function ManagerDashboardCard({ supervisor }) {
    const [onHover, setOnHover] = useState(false)
    const navigate = useNavigate();

    return (
        <>
            {supervisor &&
                <div
                    onMouseOver={() => setOnHover(true)}
                    onMouseLeave={() => setOnHover(false)}
                    onClick={() => navigate(`/dashboard/${supervisor.type === "S" ? "supervisor" : "teachers"}/${supervisor.id}`)}
                    className='bg-white w-[234px] h-[200px] shadow-dashboardCard hover:shadow-[0px_0px_24px_2px_rgba(40,220,156,0.4)] rounded-lg flex flex-col pr-4 py-4 cursor-pointer group hover:bg-Shamrock-100 active:bg-Shamrock-100'
                >
                    <div className={`${supervisor.type === "S" ? "text-[#712FED]" : onHover ? "text-white" : "text-[#00DAF7]"} flex items-center font-bold text-xs`}>
                        <RxDotFilled className="text-xl" />
                        <p>{supervisor.type === "S" ? "مشرف" : "معلم"}</p>
                    </div>
                    <p className={`${onHover ? 'group-hover:text-white' : 'text-persianIndigo-100'} text-xl font-bold leading-10 pl-3`}>{supervisor.name}</p>
                    <p className={`my-4 text-sm ${onHover ? 'group-hover:text-white' : 'text-persianIndigo-100'}`}>
                        {supervisor.type === "S" ?
                            <span className="pl-2 ml-2 border-l-2 border-l-suvaGrey-10">{supervisor.all_teacher} معلمون</span>
                            : null}
                        <span>{supervisor.all_student} طالب</span>
                    </p>
                    <div className='flex items-center gap-2'>
                        {supervisor.all_student == 0 ?
                            <div className={`w-9 h-9 text-xl border-[5px] rounded-full flex items-center ${onHover ? 'border-white' : 'border-suvaGrey-40'} justify-center`}></div>
                            : supervisor.all_student == supervisor.total_student_completed && supervisor.total_student_not_completed == 0 ?
                                <div className={`w-8 h-8 text-xl border-4 rounded-full flex items-center justify-center ${onHover ? 'border-white' : 'border-Shamrock-100'}`}>
                                    <BiCheck className={`${onHover ? 'text-white' : 'text-Shamrock-100'} stroke-2`} />
                                </div>
                                :
                                <div className='w-9 h-9'>
                                    <CircularProgressbar
                                        counterClockwise
                                        value={(100 * supervisor.total_student_not_completed) / supervisor.all_student} strokeWidth="15"
                                        styles={buildStyles({
                                            trailColor: onHover ? '#FFFFFF' : '#D6D9E0',
                                            pathColor: onHover ? '#712FED' : '#DB0000',
                                            strokeLinecap: 'butt',
                                        })}
                                    />
                                </div>
                        }
                        <div className="mt-2">
                            <p className={`${onHover ? 'text-white' : 'text-suvaGrey-80'} mb-2 text-xs`}> {supervisor.total_student_completed} بيانات طلاب مكتملة</p>
                            {
                                supervisor.all_student == 0 ?
                                    <p className={`${onHover ? 'text-white' : 'text-suvaGrey-80'} mb-2 text-xs`}> {supervisor.total_student_not_completed} طالب متبقي</p>
                                    : supervisor.total_students !== supervisor.total_student_completed && supervisor.total_student_not_completed !== 0 ?
                                        <p className={`${onHover ? 'text-darkViolet-100' : 'text-[#DB0000]'} font-bold text-sm`}> {supervisor.total_student_not_completed} طالب متبقي</p>
                                        :
                                        <p className={`${onHover ? 'text-white' : 'text-Shamrock-100'} font-bold text-sm`}> {supervisor.total_student_not_completed} طالب متبقي</p>
                            }
                        </div>
                    </div>

                </div>
            }
        </>
    )
}
