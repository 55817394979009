import HighestBall from "./HighestBall";
import HighestBasketBall from "./HighestBasketBall";
import HighestJump from "./HighestJump";
import Rounds from "./Rounds";
import Runs from "./Runs";
import HighestVJump from "./HighestVJump";

export default function ActiveStats({ activeStat, children, total_jump, total_ball, total_run, muk_runing }) {

    return (
        <>
            {children}
            <div className="py-10 px-5 mx-auto reports-cards-bg w-4/5">
                {activeStat === "ball_length" ? (
                    <>
                        {total_ball.hasOwnProperty("ball_length") && typeof total_ball.ball_length !== "undefined" ?
                            <>
                                <p className="pr-12 mt-20 pb-6 font-bold">رمي الكرة الطبية</p>
                                <HighestBall data={total_ball?.ball_length?.top_10_ball_length_virtical} />
                            </>
                            : <div className="">
                                <p className="pr-12 mt-10 font-bold">رمي الكرة الطبية</p>
                                <div className="pr-12 mt-10 pb-6 text-center">لا يوجد بيانات لعرضها</div>
                            </div>}
                        {total_ball.hasOwnProperty("basketball") && typeof total_ball.basketball !== "undefined" ?
                            <>
                                <p className="pr-12 pt-12 pb-6 font-bold">رمي كرة السلة</p>

                                <HighestBasketBall data={total_ball?.basketball?.top_10_basketball_virtical} />
                            </>
                            :
                            <div className="">
                                <p className="pr-12 mt-10 font-bold">رمي كرة السلة</p>
                                <div className="pr-12 mt-10 pb-6 text-center">لا يوجد بيانات لعرضها</div>
                            </div>
                        }
                    </>
                ) : activeStat === "total_jump" ? (
                    <>
                        {total_jump.hasOwnProperty("total_jump_v") && typeof total_jump.total_jump_v !== "undefined" ?
                            <>
                                <p className="pr-12 mt-20 pb-6 font-bold">القفز العامودي</p>
                                <HighestJump data={total_jump?.total_jump_v?.top_10_total_jump_v_virtical} />
                            </>
                            :
                            <div className="">
                                <p className="pr-12 mt-20 font-bold">القفز العامودي</p>
                                <div className="pr-12 mt-10 pb-6 text-center">لا يوجد بيانات لعرضها</div>
                            </div>
                        }
                        {total_jump.hasOwnProperty("total_jump_h") && typeof total_jump.total_jump_h !== "undefined" ?
                            <>
                                <p className="pr-12 mt-20 pb-6 font-bold">القفز العرضي</p>
                                <HighestVJump data={total_jump?.total_jump_h?.top_10_total_jump_h_virtical} />
                            </>
                            :
                            <div className="">
                                <p className="pr-12 mt-10 font-bold">القفز العرضي</p>
                                <div className="pr-12 mt-10 pb-6 text-center">لا يوجد بيانات لعرضها</div>
                            </div>
                        }
                    </>
                ) : activeStat === "muk_runing" ? (
                    <>
                        {muk_runing && typeof muk_runing?.top_10_muk_runing_virtical !== "undefined" ?
                            <>
                                <p className="pr-12 mt-20 pb-6 font-bold">الجري المكوكي</p>
                                <Rounds data={muk_runing.top_10_muk_runing_virtical} />
                            </>
                            : <div className="">
                                <p className="pr-12 mt-10 font-bold">الجري المكوكي</p>
                                <div className="pr-12 mt-10 pb-6 text-center">لا يوجد بيانات لعرضها</div>
                            </div>}
                    </>
                ) : activeStat === "total_run" ? (
                    <>
                        {total_run && typeof total_run.top_10_total_run_virtical !== "undefined" ?
                            <>
                                <p className="pr-12 mt-20 pb-6 font-bold">سرعة الجري</p>
                                <Runs data={total_run.top_10_total_run_virtical} />
                            </>
                            : <div className="">
                                <p className="pr-12 mt-10 font-bold">سرعة الجري</p>
                                <div className="pr-12 mt-10 pb-6 text-center">لا يوجد بيانات لعرضها</div>
                            </div>}
                    </>
                ) : null}

            </div>
        </>
    )
}
