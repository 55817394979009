import shuttleComplete from '../../../../assets/images/dashboard/measurement/shuttle-complete.svg'
import shuttle from '../../../../assets/images/dashboard/measurement/shuttle.svg'
import RecordDate from '../MeasurementsElements/RecordDate'

export default function ShuttleRun({ value, date = "" }) {
    return (
        <div className="rounded-lg p-5 h-full text-center bg-[#F9F9F9]">
            {value > 0 ?
                <img src={shuttleComplete} className="mx-auto" />
                :
                <img src={shuttle} className="mx-auto" />
            }

            <p className=''>الجري المكوكي</p>
            <p className='my-4'>عدد الجولات</p>
            <p>
                <span className="text-nero pl-1">{value}</span>
                <span className='font-bold'>جولات</span>
            </p>
            <RecordDate record={date} />
        </div>
    )
}
