
import { createContext, useContext, useEffect, useState } from "react";
import { getCourses, getUserProgress } from "../utils/apis";

const CoursesContext = createContext(null);

export const CoursesProvider = ({ children }) => {
    const [courses, setCourses] = useState([]);
    const [userProgress, setUserProgress] = useState(null);

    useEffect(() => {
        const getData = async () => {
            const coursesList = await getCourses()
            setCourses(coursesList.results.sort((a, b) => a.id - b.id))
            const progress = await getUserProgress()
            setUserProgress(progress)
        }
        getData()
    }, [])

    return (
        <CoursesContext.Provider value={{ courses, userProgress }}>
            {children}
        </CoursesContext.Provider>
    );
};

export const useCourses = () => {
    return useContext(CoursesContext);
};