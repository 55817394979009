export default {
  national_id: "",
  name: "",
  phone_number: "",
  school_name: "",
  school_ministery_number: "",
  email: "",
  password: "",
  passwordConfirmation: "",
  IDday: "",
  IDmonth: "",
  IDyear: "",
  Bday: "",
  Bmonth: "",
  Byear: "",
  nationality: "",
  gender: "",
  school_type: "",
  authority: "",
  educational_office: "",
  administration_of_education: "",
  educational_region: "",
};
