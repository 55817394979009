import { useState } from "react";

const HighestSVG = ({ data }) => {
    const { first, seconed, third } = data

    const [animationClass, setAnimationClass] = useState('animationball-base')

    return (
        <svg className="mx-auto" width="85%" height="100%" viewBox="0 0 560 352" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1201_119663)">
                <path d="M11.998 299.514C5.70624 299.514 0.589844 294.446 0.589844 288.214C0.589844 281.981 5.70624 276.913 11.998 276.913C18.2898 276.913 23.4062 281.981 23.4062 288.214C23.4062 294.446 18.2898 299.514 11.998 299.514ZM11.998 277.484C6.02889 277.484 1.16601 282.301 1.16601 288.214C1.16601 294.127 6.02889 298.944 11.998 298.944C17.9671 298.944 22.83 294.127 22.83 288.214C22.83 282.301 17.9671 277.484 11.998 277.484Z" fill="white" />
                <path d="M1.67385 288.214C1.67385 282.585 6.30546 277.992 11.9961 277.992C17.6867 277.992 22.3183 282.585 22.3183 288.214C22.3183 293.843 17.6867 298.436 11.9961 298.436C6.30546 298.436 1.67385 293.843 1.67385 288.214Z" fill="white" />
                <path d="M60.5337 352H7.49139C4.61054 352 2.25977 349.672 2.25977 346.818V310.268C2.25977 307.06 4.89863 304.446 8.13671 304.446H19.5449L38.1782 291.148C40.0911 289.79 42.73 290.052 44.3317 291.753C45.2306 292.712 45.68 294.013 45.5532 295.326C45.438 296.627 44.7581 297.826 43.6864 298.613L22.4834 314.24V339.467H60.5337C64.0252 339.467 66.86 342.275 66.86 345.734C66.86 349.192 64.0252 352 60.5337 352ZM8.12518 305.017C5.20976 305.017 2.82441 307.368 2.82441 310.268V346.818C2.82441 349.363 4.91015 351.43 7.47987 351.43H60.5337C63.7026 351.43 66.2838 348.873 66.2838 345.734C66.2838 342.594 63.7026 340.038 60.5337 340.038H21.9072V313.955L22.0224 313.875L43.3407 298.157C44.2741 297.472 44.8733 296.422 44.9771 295.28C45.0808 294.139 44.689 292.997 43.9054 292.152C42.511 290.657 40.1833 290.429 38.5124 291.616L19.7293 305.017H8.12518Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M37.8825 290.733C40.0029 289.228 42.9269 289.518 44.7034 291.405L44.7042 291.405C45.7016 292.469 46.201 293.913 46.0609 295.372C45.9322 296.819 45.1762 298.149 43.9895 299.021M43.9895 299.021L22.9931 314.496V338.958H60.5337C64.3038 338.958 67.3698 341.991 67.3698 345.733C67.3698 349.475 64.3038 352.508 60.5337 352.508H7.49141C4.33205 352.508 1.75 349.955 1.75 346.817V310.267C1.75 306.776 4.62014 303.937 8.13672 303.937H19.3812L37.8815 290.734L37.8825 290.733M38.8083 292.029L19.8929 305.525H8.12519C5.48755 305.525 3.3342 307.652 3.3342 310.267V346.817C3.3342 349.079 5.18866 350.921 7.47988 350.921H60.5337C63.4241 350.921 65.7741 348.588 65.7741 345.733C65.7741 342.878 63.4241 340.545 60.5337 340.545H21.3974V313.689L21.7252 313.461L43.0385 297.747C43.0411 297.745 43.0437 297.743 43.0462 297.741C43.8594 297.141 44.3793 296.225 44.4694 295.234C44.56 294.237 44.2175 293.237 43.5314 292.497C42.3182 291.197 40.2898 290.991 38.8274 292.016C38.821 292.02 38.8146 292.025 38.8083 292.029Z" fill="white" />
                <path d="M43.0462 297.741L38.8274 292.016C38.821 292.02 38.8146 292.025 38.8083 292.029L19.8929 305.525H8.12519C5.48755 305.525 3.3342 307.652 3.3342 310.267V346.817C3.3342 349.079 5.18866 350.921 7.47988 350.921H60.5337C63.4241 350.921 65.7741 348.588 65.7741 345.733C65.7741 342.878 63.4241 340.545 60.5337 340.545H21.3974V313.689L21.7252 313.461L43.0385 297.747C43.0411 297.745 43.0437 297.743 43.0462 297.741Z" fill="white" />
                <path d="M44.4694 295.234C44.56 294.237 44.2175 293.237 43.5314 292.497C42.3182 291.197 40.2898 290.991 38.8274 292.016L43.0462 297.741C43.8594 297.141 44.3793 296.225 44.4694 295.234Z" fill="white" />
            </g>
            <path d="M73 249C85.5 227.5 125.894 167.246 166.5 139.5C196.5 119.001 250.5 80.5 351 80.5C423.347 80.5 500 95.1278 541 138.501" stroke="#503B82" strokeWidth={2} strokeLinecap="round" strokeDasharray="8 8" />
            <circle cx={166.5} cy={139.5} r={4.5} fill="#503B82" />
            <circle cx={350.5} cy={80.5} r={4.5} fill="#503B82" />
            <circle cx={540.5} cy={138.5} r={4.5} fill="#503B82" />
            <line x1={350.5} y1={135.5} x2={350.5} y2={81.5} stroke="#503B82" strokeLinecap="round" />

            <g onMouseOver={() => setAnimationClass('animationball-3')} onMouseLeave={() => setAnimationClass('animationball-base')} className="cursor-pointer">
                <text x={170} y={15} fill="white" fontWeight={300}>3rd</text>
                <text x={170} y={50} fill="white" fontWeight={700}>
                    {third?.student_first_name || ''} {" "}
                    {third?.student_father_name || ''} {" "}
                    {third?.student_last_name || ''}
                </text>
                <text x={170} y={75} fill="white" fontSize="10px">{third?.student_grade || ''}</text>
                <text x={190} y={167.5} fill="#A79DC1" fontSize="12px">{Math.round(third?.ball_length) || '0'} سم</text>
            </g>

            <g onMouseOver={() => setAnimationClass('animationball-2')} onMouseLeave={() => setAnimationClass('animationball-base')} className="cursor-pointer">
                <text x={350.5} y={160.5} fill="white" fontWeight={300}>2nd</text>
                <text x={355} y={65} fill="#A79DC1" fontSize="12px">{Math.round(seconed?.ball_length) || '0'} سم</text>
                <text x={350} y={195} fill="white" fontWeight={700}>
                    {seconed?.student_first_name || ''} {" "}
                    {seconed?.student_father_name || ''} {" "}
                    {seconed?.student_last_name || ''}
                </text>
                <text x={350} y={220} fill="white" fontSize="10px">{seconed?.student_grade || ''}</text>
            </g>

            <g onMouseOver={() => setAnimationClass('animationball-1')} onMouseLeave={() => setAnimationClass('animationball-base')} className="cursor-pointer">
                <text x={545} y={15} fill="white" fontWeight={300}>1st</text>
                <text x={545} y={50} fill="white" fontWeight={700}>
                    {first?.student_first_name || ''} {" "}
                    {first?.student_father_name || ''} {" "}
                    {first?.student_last_name || ''}
                </text>
                <text x={545} y={75} fill="white" fontSize="10px">{first?.student_grade || ''}</text>
                <text x={545} y={164.5} fill="#A79DC1" fontSize="12px">{Math.round(first?.ball_length) || '0'} سم</text>
            </g>

            <line x1={540.5} y1={136.5} x2={540.5} y2={82.5} stroke="#503B82" strokeLinecap="round"
            />
            <line x1={166.5} y1={138.5} x2={166.5} y2={84.5} stroke="#503B82" strokeLinecap="round" />
            <g className={`transition-all duration-700 ${animationClass}`} filter="url(#filter0_d_1201_119663)">
                <path d="M63.1142 274C69.8046 274 75.2283 268.627 75.2283 262C75.2283 255.373 69.8046 250 63.1142 250C56.4237 250 51 255.373 51 262C51 268.627 56.4237 274 63.1142 274Z" fill="#A79DC1" />
                <defs>
                    <filter
                        id="filter0_d_1201_119663"
                        x={11}
                        y={210}
                        width={104.229}
                        height={104}
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_1201_119663"
                            result="shape"
                        />
                    </filter>
                </defs>
            </g>

        </svg>
    )
}
export default HighestSVG;
