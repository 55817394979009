import { useEffect } from 'react'
import axios from 'axios';
import { IoClose } from 'react-icons/io5';
import { useAuth } from '../../context/AuthContext';

export default function MessageModal({ showModal, setShowModal, message, msgDate }) {

    const { token } = useAuth()

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_URL}/massages/${message.id}/read/`, {}, {
            headers: {
                Authorization: `Token ${token}`,
            }
        }).then((res) => {
            console.log(res)
        }).catch((e) => {
            console.log(e)
        })
    }, [])

    return (
        <>
            {showModal ? (
                <>
                    <div className="flex fixed inset-0 top-5 z-50 outline-none focus:outline-none">
                        <div className="relative my-auto mx-auto max-w-3xl w-3/4">

                            <div className="border-0 rounded-lg shadow-lg relative flex gap-3 flex-col w-full bg-white outline-none focus:outline-none py-5">
                                <div className="flex justify-between text-suvaGrey-60 text-xs px-4">
                                    <IoClose className='text-lg cursor-pointer' onClick={() => setShowModal(false)} />
                                    <p>{msgDate}</p>

                                </div>
                                <div className='flex gap-1 justify-center text-lg'>
                                    <span className='text-light text-suvaGrey-40 pl-1'>رسالة من</span>
                                    <span className='text-darkViolet-100'>{message.type === 'M' ? ' رئيس القسم ' : message.type === 'S' ? ' المشرف ' : message.type === 'T' ? ' المعلم ' : null}</span>
                                    <span className='text-darkViolet-100 font-bold'>{message.sender}</span>
                                </div>
                                <p className='font-bold text-nero px-10 my-2'>{message.title}</p>
                                <p className='text-nero text-sm px-10'>{message.content}</p>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}
