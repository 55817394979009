import Vertical from './Jump/Vertical';
import Horizontal from './Jump/Horizontal';
import { Field } from 'formik';


export default function Jump({ showModal, setShowModal, handleActiveVideo, values, is_horizntal, touched }) {

    return (
        <div onClick={() => showModal ? setShowModal(false) : null}>
            <div className="relative mx-auto flex h-9">
                <div className="absolute lg:left-[20%] xl:left-20 md:left-[25.5%] sm:left-[29%] left-[34%] text-sm sm:text-base">
                    <div className="flex gap-3">
                        <span className={`${!is_horizntal && 'font-bold text-darkViolet-100'}`}>العمودي</span>
                        <div>
                            <Field className="hidden custom-checkbox2" name="is_horizntal" type="checkbox" id="toggle_2" />
                            <label className="flex items-center justify-start w-12 bg-darkViolet-100 h-6 p-1 rounded-full cursor-pointer" htmlFor="toggle_2">
                                <span className="w-5 h-5 bg-white rounded-full"></span>
                            </label>
                        </div>
                        <span className={`${is_horizntal && 'font-bold text-darkViolet-100'}`}>العرضي</span>
                    </div>
                </div>
            </div>

            {!is_horizntal ?
                <Vertical values={values} touched={touched} />
                :
                <Horizontal values={values} touched={touched} />
            }
        </div>
    )
}
