import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ImCheckmark } from "react-icons/im";
import Success from "../components/Success";
import { useNavigate, useParams } from "react-router-dom";
import { getQuizzes } from "../utils/apis";
import convertSecToMinAndSec from "../utils/convertSecToMinAndSec";
import WalkThrough from "../components/modals/WalkThrough";
import { useWalkthrough } from "../context/WalkthroughContext";
import { useCourses } from "../context/CoursesContext";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { BsFillPlayCircleFill, BsPauseCircleFill } from "react-icons/bs";

export default function CoursesPage() {
  useEffect(() => {
    document.title = "تمهيد | البرنامج التدريبي";
  }, []);

  const { isWalkthrough, popuopPos, setPopuopPos } = useWalkthrough()

  const { id } = useParams();
  const { courses } = useCourses()
  const elementRef = useRef(null)

  const [currentVideo, setCurrentVideo] = useState({});
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [nextQuiz, setNexyQuiz] = useState("");
  const navigate = useNavigate();
  const [isDone, setIsDone] = useState(false)

  let stepsIndex = 5;
  const [courseSteps, setCourseSteps] = useState(1);
  const [arrowDisable, setArrowDisable] = useState(true)
  const [isOverflowing, setIsOverflowing] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)

  const handleIsDone = () => {
    if (courses[0].id == id && isWalkthrough && popuopPos !== 'last') {
      setCourseSteps(3);
      setPopuopPos('third')
      setIsDone(true)
    } else {
      navigate(`/learning/quiz/${nextQuiz}`);
    }
  };


  useEffect(() => {
    const getData = async () => {
      const quizzes = await getQuizzes();
      const getNextQuiz = quizzes.find((q) => q.course == id);
      setNexyQuiz(getNextQuiz.id);
    };
    getData();
  }, []);

  useEffect(() => {
    if (courses.length) {
      setCurrentVideo(courses.find((l) => l.id == id));
      if (courses && courses[0].id == id && popuopPos === '') {
        setPopuopPos('first')
      }
    }
  }, [courses]);

  const calcTotalDur = () => {
    return Math.round(
      courses.reduce((accumulator, object) => {
        return accumulator + ~~object.duration;
      }, 0) / 60
    );
  };

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0
    const slideTimer = setInterval(() => {
      element.scrollLeft += step
      scrollAmount += Math.abs(step)
      if (scrollAmount >= distance) {
        clearInterval(slideTimer)
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true)
      } else {
        setArrowDisable(false)
      }
    }, speed)
  }

  useEffect(() => {
    if (elementRef) {
      if (elementRef.current.scrollWidth === elementRef.current.offsetWidth) {
        setIsOverflowing(false)
      } else {
        setIsOverflowing(true)
      }
    }
  }, [elementRef])

  return (
    <>
      {showSuccessPage ? (
        <Success
          msg1="تم حفظ تقدمك"
          msg2="فضلا أكمل التدريات للدخول إلى المنصة"
          btnMsg="إكمال  التدريبات"
          btnLink="/dashboard"
          onClick={() => setShowSuccessPage(false)}
        />
      ) : (
        currentVideo ? (
          <div className="min-h-full w-full learning-bg">
            <div className="bg-black">
              <div className="relative">
                {isWalkthrough &&
                  courseSteps === 2 ? (
                  <WalkThrough
                    title="البرنامج التدريبي"
                    number={`2/${stepsIndex}`}
                    stepsIndex={stepsIndex}
                    subTitle="مشاهدة الفيديو"
                    text=" إبدأ بمشاهدة أول فيديو تدريبي لتعلم كيف تأخذ القياسات الجسمية للطلاب. "
                    colorOne
                    colorTwo
                    onclosenow={() => {
                      setCourseSteps(0)
                      setPopuopPos('')
                    }}
                    top="5rem"
                    right="45vw"
                    arrowClass="bottom-right"
                  />
                ) : null}
              </div>
              <div className="relative">
                {isMouseOver ?
                  isPlaying ?
                    <div className="absolute text-7xl top-1/2 left-1/2 cursor-pointer z-20" onMouseOver={() => setIsMouseOver(true)} onClick={() => setIsPlaying(false)}>
                      <BsPauseCircleFill className="text-white" />
                    </div>
                    :
                    <div className="absolute text-7xl top-1/2 left-1/2 cursor-pointer z-20" onMouseOver={() => setIsMouseOver(true)} onClick={() => setIsPlaying(true)}>
                      <BsFillPlayCircleFill className="text-white" />
                    </div>
                  : null}
                <div onMouseOver={() => setIsMouseOver(true)} onMouseLeave={() => setIsMouseOver(false)}>
                  <ReactPlayer
                    width="100%"
                    height="500px"
                    url={currentVideo.video_url}
                    playing={isPlaying}
                    onEnded={handleIsDone}
                    onSeek={() => console.log('seeeek')}
                  />
                </div>
              </div>
            </div>
            <div className="relative">
              {isWalkthrough &&
                courseSteps === 1 &&
                popuopPos === 'first' ? (
                <WalkThrough
                  title="البرنامج التدريبي"
                  number={`1/${stepsIndex}`}
                  stepsIndex={stepsIndex}
                  subTitle="البرنامج التدريبي"
                  text="قبل استخدامك للمنصة يجب إكمال البرنامج التدريبي لجمع قياسات الطلاب "
                  colorOne
                  onclosenow={() => {
                    setCourseSteps(2)
                    setPopuopPos('')
                  }}
                  top="-1rem"
                  right="2vw"
                  arrowClass="bottom-right"
                />
              ) : null}
              {isWalkthrough &&
                courseSteps === 3 && popuopPos === 'third' ? (
                <WalkThrough
                  title="البرنامج التدريبي"
                  number={`3/${stepsIndex}`}
                  stepsIndex={stepsIndex}
                  subTitle="إكمال الفيديو"
                  text=" بعد إكمال كل فيديو تدريبي يجب أن تكمل تقييم قصير للمتابعة في البرنامج."
                  colorOne
                  colorTwo
                  colorThree
                  onclosenow={() => {
                    setPopuopPos('quiz')
                    navigate(`/learning/quiz/${nextQuiz}`)
                  }}
                  top="5rem"
                  right="2vw"
                  arrowClass="bottom-right"
                />
              ) : null}
            </div>
            <div className="flex px-10 my-5 items-center">
              <div className="text-blueDiamond font-bold">
                <span className="ml-5"> {currentVideo.title} </span>
                <span className="text-Shamrock-100">
                  {courses.length}/
                  {courses.findIndex((i) => i.id == currentVideo.id) + 1}
                </span>
              </div>
              <button
                onClick={() => setShowSuccessPage(true)}
                className="mr-auto border rounded-xl border-persianIndigo-100 text-persianIndigo-100 bg-white px-8 py-5 font-bold"
              >
                حفظ التقدم والمتابعة لاحقًا
              </button>
            </div>
            <hr className="bg-HawkesBlue-40 mr-5 mb-10" />
            {currentVideo.id === 5 ?
          <div className="mx-6 text-blueDiamond mb-6 flex flex-col gap-4 text-lg">
            <p>المواد المساندة</p>
            <div class="flex gap-5 text-darkViolet-60 font-bold"><span>كيفية اداء الاختبار</span></div>
            <audio
              controls
              src="https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/how.mp3">
              <a href="https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/how.mp3">
              </a>
            </audio>
            <div class="flex gap-5 text-darkViolet-60 font-bold"><span>الصافرة</span></div>

            <audio
              controls
              src="https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/whistle.mp3">
              <a href="https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/whistle.mp3">
              </a>
            </audio>
            <div class="flex gap-5 text-darkViolet-60 font-bold"><span>معلومات حول الاختبار</span></div>

            <audio
              controls
              src="https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/info.mp3">
              <a href="https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/info.mp3">
              </a>
            </audio>
          </div> : null}

            <div className="flex my-5 around px-10">
              <div>
                <p className="text-nero font-bold text-3xl mb-3">
                  البرنامج التدريبي
                </p>
                <div className="flex gap-5 text-darkViolet-60 font-bold">
                  <span>{courses.length} فيديوهات</span>
                  <span className="font-light">
                    {calcTotalDur()}{""}د مدة إجمالية
                  </span>
                </div>
              </div>
            </div>
            {isOverflowing === true ?
              <div className="flex mx-10 text-xl gap-6 justify-end select-none">
                <FiChevronRight className={`cursor-pointer ${arrowDisable ? 'text-suvaGrey-40' : 'text-nero'}`} onClick={() => {
                  handleHorizantalScroll(elementRef.current, 10, 300, 10)
                }} />
                <FiChevronLeft className="cursor-pointer"
                  onClick={() => {
                    handleHorizantalScroll(elementRef.current, 10, 300, -10)
                  }}
                />
              </div>
              : null}
            <div className="flex gap-5 m-10 pb-12 overflow-x-auto hidden-scroll" ref={elementRef}>
              {courses.map((l, i) => (
                <div key={l.id} className="min-w-[220px]">
                  <div
                    className={`relative rounded-xl ${l.id !== id &&
                      l.id < id &&
                      "border-b-8 border-b-Shamrock-100"
                      } ${l.id == currentVideo.id &&
                      "border-4 border-darkViolet-100"
                      }`}
                  >
                    {l.id !== id && l.id < id ? (
                      <div className="bg-[#B9B9B93D] absolute w-full h-full rounded-lg"></div>
                    ) : null}
                    {(l.id !== id && l.id < id) || (isDone && id == l.id) ? (
                      <div className="video-complete tag">
                        <ImCheckmark className="text-Shamrock-100" />
                      </div>
                    ) : l.id == id ? (
                      <CircularProgressbar
                        className="tag"
                        value={l.progress}
                        strokeWidth="15"
                        counterClockwise={true}
                        styles={buildStyles({
                          pathColor: "#6E3FB6",
                          trailColor: "#D6D9E0",
                          strokeLinecap: "butt",
                        })}
                      />
                    ) : (
                      <div className="tag w-12 h-12 border-[6px] rounded-full"></div>
                    )}
                    <img
                      src={l.thumbnail}
                      alt="thumbnail"
                      className="object-cover w-80 aspect-video rounded-lg"
                    />
                  </div>
                  <div className="flex justify-between mt-2 gap-10 text-nero">
                    <span className="font-bold">{l.title}</span>
                    <span>
                      {i + 1}/{courses.length}
                    </span>
                  </div>
                  <p className="text-darkViolet-100 mt-2 text-xs">
                    {convertSecToMinAndSec(l.duration)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ) : null
      )
      }
    </>
  );
}
