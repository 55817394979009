import { Field } from "formik";
import Checkbox from "../FormTools/CustomCheckbox";
import { Select } from "../FormTools/CustomSelect";
import { BiErrorCircle } from "react-icons/bi";
import dates from "../../data/dates.json";
import WalkThrough from "../modals/WalkThrough";

const NewAccountForm = ({
  errors,
  touched,
  values,
  showMessage,
  message,
  stepsIndex,
  isWalkthrough,
  popuopPos,
  setPopuopPos
}) => {


  return (
    <>
      {isWalkthrough && popuopPos === 'checkUser' ? (
        <WalkThrough
          title="انشاء حساب"
          number={`1/${stepsIndex}`}
          stepsIndex={stepsIndex}
          subTitle="إدخال البيانات الشخصية"
          text="للدخول الى المنصة يجب إدخال بياناتك الشخصية: رقم بطاقة الأحوال أو
          الإقامة وتاريخ الميلاد المسجل في البطاقة."
          colorOne
          onclosenow={() => setPopuopPos('review')}
          top="130px"
          right="20vw"
          arrowClass="bottom-right"
        />
      ) : null}
      <div className="sm:w-2/3 w-3/4 mx-auto my-4 sm:my-10">
        <div className="bg-[#F9F9F9] px-12 rounded-lg shadow-page-card">
          <p className="text-darkViolet-100 font-bold text-center text-2xl pt-10 mb-6">
            إنشاء حساب جديد
          </p>
          <hr className="bg-HawkesBlue-40 mb-10" />
          {showMessage && (
            <div className="bg-[rgba(217,30,30,0.08)] rounded-lg py-2 px-4 text-[#D91E1E] font-bold flex items-center gap-2 mb-4">
              <BiErrorCircle />
              <span>{message}</span>
            </div>
          )}

          <div className="text-HawkesBlue-100 pb-10">
            <label htmlFor="id" className="text-HawkesBlue-100 font-bold">
              ادخل/ي رقم بطاقة الأحوال/الإقامة
            </label>
            <Field
              className={`number-arrow border ${errors.national_id && touched.national_id
                ? "border-[#DB0000] placeholder:text-[#DB0000]"
                : "border-[#D6D9E0]"
                } w-full py-6 mt-3 placeholder:text-[#D6D9E0] px-6 text-black rounded-lg`}
              type="number"
              id="id"
              name="national_id"
              placeholder="رقم الأحوال/الإقامة"
            />
            {/* <div className="h-10 pt-4">
              {errors.national_id && touched.national_id ? (
                <div className="flex gap-1">
                  <BiErrorCircle className="text-error text-2xl font-light" />
                  <span className="text-error">{errors.national_id}</span>
                </div>
              ) : null}
            </div>

            <p className="text-HawkesBlue-100 font-bold mt-3">
              ادخل/ي تاريخ الميلاد
            </p>

            <div className="grid md:grid-cols-3 mt-3 gap-x-5 gap-y-3">
              <Field
                name="day"
                component={Select}
                options={dates[values.isHijri ? "hijri" : "gregorian"].days}
                placeholder="اليوم"
                isHijri={values.isHijri}
              />
              <Field
                name="month"
                component={Select}
                options={dates[values.isHijri ? "hijri" : "gregorian"].months}
                placeholder="الشهر"
                isHijri={values.isHijri}
              />
              <Field
                name="year"
                component={Select}
                options={dates[values.isHijri ? "hijri" : "gregorian"].years}
                placeholder="السنة"
                isHijri={values.isHijri}
              />

              <div>
                {errors.day && touched.day ? (
                  <div className="flex gap-1">
                    <BiErrorCircle className="text-error text-2xl font-light" />
                    <span className="text-error">{errors.day}</span>
                  </div>
                ) : null}
              </div>

              <div>
                {errors.month && touched.month ? (
                  <div className="flex gap-1">
                    <BiErrorCircle className="text-error text-2xl font-light" />
                    <span className="text-error">{errors.month}</span>
                  </div>
                ) : null}
              </div>

              <div>
                {errors.year && touched.year ? (
                  <div className="flex gap-1">
                    <BiErrorCircle className="text-error text-2xl font-light" />
                    <span className="text-error">{errors.year}</span>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="mt-3">
              <Checkbox
                label="تاريخ هجري"
                name="isHijri"
                id="isHijri"
                bgColor="#522EA1"
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewAccountForm;
