import { useEffect, useState } from "react";
// import cover from "../assets/pdf/Mcover.png";
import header from "../assets/pdf/Mheader.png";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Loading from "../components/Loading";

export default function PdfAdmin() {
  const { token } = useAuth();

  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin-combined-results/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setLoaded(true)
        setData(res.data);
      });
  }, []);

  useEffect(() => {
    document.title = "تقرير عام";
  }, []);

  const date = new Date().toLocaleDateString("ar-UA", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  function divideArray(largeArray) {
    const result = [];
    let tempArray = [];

    for (const item of largeArray) {
      // const supervisorsSize = item.supervisors ? item.supervisors.length : 0;

      // if (supervisorsSize > 12) {
      //   if (tempArray.length) {
      //     result.push(tempArray);
      //     tempArray = [];
      //   }
      //   result.push([item]);
      // } else {
      tempArray.push(item);
      if (tempArray.length === 8) {
        result.push(tempArray);
        tempArray = [];
      }
      // }
    }

    if (tempArray.length) {
      result.push(tempArray);
    }

    return result;
  }

  let pageNumber = 1;

  const showTable = (names) => {
    const nameRows = [];
    for (let i = 0; i < names.length; i += 2) {
      const name1 = names[i];
      const name2 = names[i + 1];
      const row = (
        <div key={i} className=" flex gap-[2px]">
          <div className="h-8 grow basis-0 bg-[#f9f9f9] text-center">
            {name1 ? name1.name : ''}
          </div>
          <div className="h-8 grow shrink basis-0 bg-[#f9f9f9] text-center">
            {name2 ? name2.name : ''}
          </div>
        </div>
      );
      nameRows.push(row);
    }
    return nameRows
  }

  function divideArray2(x) {
    const dividedArray = [];

    dividedArray.push(x.slice(0, 12));

    for (let i = 12; i < x.length; i += 16) {
      dividedArray.push(x.slice(i, i + 16));
    }

    return dividedArray;
  }


  return (
    <>
      <Loading isLoading={loaded} />
      {loaded ?
        <div className="flex flex-col items-center print:items-start gap-16 print:gap-0 py-16 print:p-0 bg-gray-200">
          <button
            className="bg-daisyBush text-white px-4 py-2 rounded-md fixed print:hidden top-14 right-10 font-bold z-50"
            onClick={() => {
              window.print();
            }}
          >
            طباعة
          </button>

          <div
            id="page-1"
            className="bg-white w-[210mm] h-[297mm] relative shadow-2xl"
          >
            <div className="header h-[180px] relative isolate text-white">
              <img className="-z-10 w-full h-full" src={header} alt="" />
              <div className="absolute top-[30%] left-[50%] translate-x-[-50%] flex flex-col items-center justify-center gap-4">
                <div className="">رئيس القسم</div>
                <div className=" text-xl font-bold text-center">{data?.name}</div>
              </div>

              <div className="absolute top-[38px] left-[37px] flex gap-2 items-center">
                <div className="">إدارة التعليم</div>
                <div className="text-xs font-bold max-w-[100px] text-center">
                  {data?.administration_of_education}
                </div>
              </div>
            </div>

            <div className="flex p-10">
              <div className="w-2/3 flex flex-col gap-14 pl-7">
                <div className="text-black flex flex-col gap-6">
                  <h3 className="text-2xl">رؤساء الأقسام</h3>
                  <div className="flex gap-4">
                    <div className="">
                      <p className="leading-none font-bold text-5xl">{data?.general_results.all_managers || "00"}</p>
                      <p className="">العدد الكلي</p>
                    </div>
                  </div>
                </div>
                <StatsBlock
                  title={"المشرفين"}
                  total={data?.general_results.all_supervisor}
                  completed={data?.general_results.total_completed_supervisor}
                  remainder={data?.general_results.total_not_completed_supervisor}
                />
                <StatsBlock
                  title={"المعلمين"}
                  total={data?.general_results.all_teacher}
                  completed={data?.general_results.total_completed_teacher}
                  remainder={data?.general_results.total_not_completed_teacher}
                />
                <StatsBlock
                  title={"الطلاب"}
                  total={data?.general_results.all_student}
                  completed={data?.general_results.total_student_completed}
                  remainder={data?.general_results.total_student_not_completed}
                />
              </div>
            </div>

            <div className="absolute bottom-[44px] right-[0] pr-10 text-[#240A63] flex gap-3 items-center font-bold text-l">
              تاريخ التصدير
              <div className=" flex gap-2">
                <div>{date}</div>
              </div>
            </div>
            <div className="absolute bottom-[44px] left-[0] pl-10 text-[#240A63] font-bold text-l">
              {pageNumber.toFixed(0).padStart(2, "0")}
            </div>
          </div>

          {data?.administration_offices_results?.map((office, index) => {
            const sups = divideArray(office.office_data);
            const sups2 = divideArray2(office.managers_data);

            return <>
              {
                sups2[0].length ? sups2.map((supData1, index) => {
                  return <div
                    key={index}
                    className="bg-white w-[210mm] h-[297mm] relative shadow-2xl"
                  >
                    <div className="header h-[125px] relative isolate text-white">
                      <img className="-z-10 w-full h-full" src={header} alt="" />

                      <div className="absolute top-[38px] left-[37px] flex gap-2 items-center">
                        <div className="asolute w-48 flex gap-2 items-center">
                          <div className="w-36 text-center text-xl">رؤساء الأقسام</div>
                        </div>
                        <div className="w-24">إدارة التعليم:</div>
                        <div className="text-sm font-bold max-w-[130px] w-[130px]">
                          {office.name}
                        </div>
                      </div>
                    </div>


                    <div className="absolute bottom-[44px] right-[0] pr-10 text-[#240A63] flex gap-3 items-center font-bold text-l">
                      تاريخ التصدير
                      <div className=" flex gap-2">
                        <div>{date}</div>
                      </div>
                    </div>
                    <div className="absolute bottom-[44px] left-[0] pl-10 text-[#240A63] font-bold text-l">
                      {pageNumber.toFixed(0).padStart(2, "0")}
                    </div>

                    {supData1.map((supData) => {
                      return <div className="p-4">
                        {index === 0 && (
                          <div className="first-table flex">
                            <div className="grow ">
                              <div className=" flex gap-[2px] text-white font-bold mb-2">
                                <div className="h-8 grow shrink basis-0 bg-[#712FED] flex items-center justify-center rounded-r-2xl">
                                  رئيس القسم
                                </div>
                                <div className="h-8 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                                  مشرفين
                                </div>
                                <div className="h-8 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                                  معلمين
                                </div>
                                <div className="h-8 grow shrink basis-0 bg-[#712FED] flex items-center justify-center rounded-l-2xl">
                                  طلاب
                                </div>
                              </div>
                              <div className="flex flex-col gap-[2px]">
                                <div className=" flex gap-[2px]">
                                  <div className="h-8 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                                    {supData?.name || "0"}
                                  </div>
                                  <div className="h-8 grow gap-2 shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                                    <div className="text-Shamrock-100">
                                      {supData?.general_results?.total_completed_supervisor || "0"}
                                    </div>
                                    <div className="text-gold-100">
                                      {
                                        supData?.general_results?.total_not_completed_supervisor || "0"
                                      }
                                    </div>
                                    <div className="justify-center">
                                      {((
                                        supData?.general_results?.total_completed_supervisor /
                                        supData?.general_results?.all_supervisor
                                      ).toFixed(1) * 100) || "0"}
                                      %
                                    </div>
                                  </div>
                                  <div className="h-8 grow gap-2 shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                                    <div className="text-Shamrock-100">
                                      {supData?.general_results?.total_completed_teacher || "0"}
                                    </div>
                                    <div className="text-gold-100">
                                      {
                                        supData?.general_results?.total_not_completed_teacher || "0"
                                      }
                                    </div>
                                    <div className="justify-center">
                                      {((
                                        supData?.general_results?.total_completed_teacher /
                                        supData?.general_results?.all_teacher
                                      ).toFixed(1) * 100) || "0"}
                                      %
                                    </div>
                                  </div>
                                  <div className="h-8 grow gap-2 shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                                    <div className="text-Shamrock-100">
                                      {supData?.general_results?.total_student_completed || "0"}
                                    </div>
                                    <div className="text-gold-100">
                                      {
                                        supData?.general_results?.total_student_not_completed || "0"
                                      }
                                    </div>
                                    <div className="justify-center">
                                      {((
                                        supData?.general_results?.total_student_completed /
                                        supData?.general_results?.all_student
                                      ).toFixed(1) * 100) || "0"}
                                      %
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    })}
                  </div>
                })
                  : null}

              {sups.map((supData1, index) => {
                pageNumber++;
                return (
                  <>
                    <div
                      key={index}
                      className="bg-white w-[210mm] h-[297mm] relative shadow-2xl"
                    >
                      <div className="header h-[125px] relative isolate text-white">
                        <img className="-z-10 w-full h-full" src={header} alt="" />

                        <div className="absolute top-[38px] left-[37px] flex gap-2 items-center">
                          <div className="asolute w-48 flex gap-2 items-center">
                            <div className="w-36 text-center text-xl">المكاتب التعليمية</div>
                          </div>
                          <div className="w-24">إدارة التعليم:</div>
                          <div className="text-sm font-bold max-w-[130px] w-[130px]">
                            {office.name}
                          </div>
                        </div>
                      </div>

                      {supData1.map((supData) => {
                        return <div className="p-4">
                          <div className="first-table flex">
                            <div className="grow ">
                              <div className=" flex gap-[2px] text-white font-bold mb-2">
                                <div className="h-8 grow shrink basis-0 bg-[#712FED] flex items-center justify-center rounded-r-2xl">
                                  المكتب التعليمي
                                </div>
                                <div className="h-8 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                                  مشرفين
                                </div>
                                <div className="h-8 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                                  معلمين
                                </div>
                                <div className="h-8 grow shrink basis-0 bg-[#712FED] flex items-center justify-center rounded-l-2xl">
                                  طلاب
                                </div>
                              </div>
                              <div className="flex flex-col gap-[2px]">
                                <div className=" flex gap-[2px]">
                                  <div className="h-8 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                                    {supData?.name || "0"}
                                  </div>
                                  <div className="h-8 grow gap-2 shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                                    <div >
                                      {
                                        supData?.general_results?.all_supervisor || "0"
                                      }
                                    </div>
                                  </div>
                                  <div className="h-8 grow gap-2 shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                                    <div className="">
                                      {supData?.general_results?.all_teacher || "0"}
                                    </div>
                                  </div>
                                  <div className="h-8 grow gap-2 shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                                    <div className="text-Shamrock-100">
                                      {supData?.general_results?.total_student_completed || "0"}
                                    </div>
                                    <div className="text-gold-100">
                                      {
                                        supData?.general_results?.total_student_not_completed || "0"
                                      }
                                    </div>
                                    <div className="justify-center">
                                      {((
                                        supData?.general_results?.total_student_completed /
                                        supData?.general_results?.all_student
                                      ).toFixed(1) * 100) || "0"}
                                      %
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* {supData.supervisors.length ?
                            <div className="my-4">
                              <div className="grow">
                                <div className=" flex gap-[2px] text-white font-bold mb-2">
                                  <div className="h-8 grow shrink basis-0 bg-[#28DC9C] flex items-center justify-center rounded-r-2xl rounded-l-2xl">
                                    المشرفين
                                  </div>
                                </div>
                              </div>
                              {showTable(supData.supervisors)}
                            </div>
                            :
                            null
                          } */}
                        </div>
                      })}
                      <div className="absolute bottom-[44px] right-[0] pr-10 text-[#240A63] flex gap-3 items-center font-bold text-l">
                        تاريخ التصدير
                        <div className=" flex gap-2">
                          <div>{date}</div>
                        </div>
                      </div>
                      <div className="absolute bottom-[44px] left-[0] pl-10 text-[#240A63] font-bold text-l">
                        {pageNumber.toFixed(0).padStart(2, "0")}
                      </div>
                    </div>
                  </>
                )
              })
              }
            </>
          }
          )}
        </div>
        : null}
    </>
  );
}

const StatsBlock = ({ title, total, completed, remainder }) => {
  let percentage = (completed / total) * 100;

  if (isNaN(percentage)) {
    percentage = 0;
  }

  const color = (t) => {
    // commented class names has to be here for tailwind compiler
    // text-Shamrock-100
    // bg-Shamrock-100
    // text-gold-100
    // bg-gold-100
    if (percentage > 50) {
      return `${t}-Shamrock-100`;
    } else {
      return `${t}-gold-100`;
    }
  };

  return (
    <div className="text-black flex flex-col gap-6">
      <h3 className="text-2xl">{title}</h3>
      <div className="flex gap-4">
        <div className="">
          <p className="leading-none font-bold text-5xl">{total || "00"}</p>
          <p className="">العدد الكلي</p>
        </div>
        <div className="">
          <p
            className={`leading-none font-bold text-5xl ${completed ? "text-Shamrock-100" : "text-[#E8E8E8]"
              }`}
          >
            {completed || "00"}
          </p>
          <p className="">العدد المنجز</p>
        </div>
        <div className="">
          <p className={`leading-none font-bold text-5xl text-[#E8E8E8]`}>
            {remainder || "00"}
          </p>
          <p className="">العدد المتبقي</p>
        </div>
      </div>
      <div className="flex items-center gap-2 overflow-hidden">
        <div className={`${color("text")} w-fit`}>{percentage.toFixed(1)}%</div>
        <div className="h-2 grow bg-[#E8E8E8] mt-1">
          <div
            className={`${color("bg")} h-2`}
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const OfficeStats = ({ name, percentage }) => {
  return (
    <div className="flex items-center gap-2 ">
      <CircularProgressbar
        value={percentage}
        className="!w-16 !h-16"
        strokeWidth={18}
        styles={buildStyles({
          strokeLinecap: "butt",
          pathColor: percentage > 50 ? "#28DC9C" : "#EDD306",
          textColor: percentage > 50 ? "#28DC9C" : "#EDD306",
        })}
        text={`${percentage}%`}
      />

      <div className="mt-1 font-bold text-base">{name}</div>
    </div>
  );
};
