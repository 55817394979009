import { Field } from "formik";
import { BiErrorCircle } from "react-icons/bi";
import CustomPasswordInput from "../FormTools/CustomPasswordInput";
import WalkThrough from "../modals/WalkThrough";
import { useState } from "react";

export default function SignupForm({
  errors,
  touched,
  emailErrorMessage,
  isWalkthrough,
  stepsIndex,
  popuopPos,
  setPopuopPos
}) {

  return (
    <>
      {isWalkthrough && popuopPos === 'singup' ? (
        <WalkThrough
          title="انشاء حساب"
          number={`3/${stepsIndex}`}
          stepsIndex={stepsIndex}
          subTitle="البريد الإلكتروني"
          text="الرجاء إستخدام بريد إلكتروني فعال وكلمة مرور لتسهيل الدخول الى المنصة في المستقبل."
          colorOne
          colorTwo
          colorThree
          onclosenow={() => setPopuopPos('')}
          top="130px"
          right="20vw"
          arrowClass="bottom-right"

        />
      ) : null}

      <div className="sm:w-2/3 w-4/5 mx-auto py-10">
        <div className="bg-[#F9F9F9] px-12 rounded-lg shadow-page-card">
          <p className="text-darkViolet-100 font-bold text-center text-2xl pt-10 mb-6">
            البريد الإلكتروني
          </p>
          <hr className="bg-HawkesBlue-40 mb-10" />
          <div>
            <div className="text-HawkesBlue-100">
              <label htmlFor="email" className="text-HawkesBlue-100 font-bold">
                اكتب البريد الإلكتروني
              </label>
              <Field
                className={`border ${(errors.email && touched.email) || emailErrorMessage
                  ? "border-[#DB0000] placeholder:text-[#DB0000]"
                  : "border-[#D6D9E0]"
                  } w-full py-6 mt-3 placeholder:font-light form-input focus:ring-0 focus:border-darkViolet-100 placeholder:text-[#D6D9E0] px-6 text-black rounded-lg`}
                type="email"
                id="email"
                name="email"
                placeholder="example@email.com"
              />
              <div className="h-12 pt-4 pm-2">
                {emailErrorMessage && (
                  <div className="flex gap-1">
                    <BiErrorCircle className="text-error text-2xl font-light" />
                    <span className="text-error">
                      البريد الإلكتروني مسجل مسبقاً
                    </span>
                  </div>
                )}
                {errors.email && touched.email ? (
                  <div className="flex gap-1">
                    <BiErrorCircle className="text-error text-2xl font-light" />
                    <span className="text-error">{errors.email}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="pb-10">
            <CustomPasswordInput
              errors={errors}
              touched={touched}
              name="password"
              label="اكتب كلمة المرور"
            />
            <CustomPasswordInput
              errors={errors}
              touched={touched}
              name="passwordConfirmation"
              label="تأكيد كلمة المرور"
            />
          </div>
        </div>
      </div>
    </>
  );
}
