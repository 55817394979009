import { useState } from "react";
import { BiCheck } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export default function CompletedCard({ section }) {
    const [onHover, setOnHover] = useState(false)
    const navigate = useNavigate();

    return (
        <div
            onMouseOver={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            onClick={() => navigate(`/dashboard/archive`)}
            className='bg-white w-[340px] h-[201px] shadow-dashboardCard hover:shadow-[0px_0px_24px_2px_rgba(40,220,156,0.4)] rounded-lg flex flex-col p-4 cursor-pointer group hover:bg-Shamrock-100 active:bg-Shamrock-100'
        >
            <p className={`${onHover ? 'group-hover:text-white' : 'text-persianIndigo-100'} text-2xl font-bold leading-10 mb-2 pl-3`}>{section.my_section}</p>
            <p className={`${onHover ? 'group-hover:text-white' : 'text-persianIndigo-100'}`}>{section.total_students || "0"} طالب</p>
            <div className="flex items-center gap-6 my-4">
                <p className={`${onHover ? 'text-white' : 'text-suvaGrey-80'} font-light text-xs`}>{new Date(section.last_change).toLocaleTimeString([], { timeStyle: 'short' })}</p>
                <p className={`${onHover ? 'text-white' : 'text-suvaGrey-80'} font-light text-xs`}>{new Date(section.last_change).toLocaleDateString('ar-MA', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
            </div>
            <div className='flex items-center gap-2'>
                <div className={`w-7 h-7 border-4 rounded-full flex items-center justify-center ${onHover ? 'border-white' : 'border-Shamrock-100'}`}>
                    <BiCheck className={`${onHover ? 'text-white' : 'text-Shamrock-100'} stroke-2`} />
                </div>
                <p className={`${onHover ? 'text-white' : 'text-suvaGrey-80'} mb-2 text-xs mt-2`}> {section.completed_students || "0"} بيانات طلاب مكتملة</p>
            </div>

        </div>
    )
}


