import { IoMdCheckmarkCircleOutline } from "react-icons/io"
import { Link } from "react-router-dom"
import logo from "../assets/images/logo/tamheed-white.svg"

export default function SuccessSignup() {
    return (
        <div className="custom-bg flex flex-col justify-around py-16">
            <img src={logo} alt="Logo" className="mx-auto w-52" />

            <div className="mx-auto bg-white rounded-lg my-auto flex flex-col gap-5 items-center py-10 px-24">

                <IoMdCheckmarkCircleOutline className="text-Shamrock-100" size="30px" />
                <span className="text-persianIndigo-100 text-3xl font-bold">تم إنشاء حسابك بنجاح</span>
                <p className='font-light mb-4'>الأن بإمكانك مشاهدة الفيديوهات التدريبية</p>
                <Link to='/learning/1'>
                    <button className='bg-persianIndigo-100 text-white shadow-button rounded-lg w-full px-16 py-5'>
                        ابدأ البرنامج التدريبي
                    </button>
                </Link>
            </div>
        </div>
    )
}
