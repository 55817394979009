import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import profileImage from "../../assets/images/dashboard/profile-image.png";
import PersonalInfo from "../../components/dashboard/supervisor/PersonalInfo";
import JobInfo from "../../components/dashboard/supervisor/JobInfo";
import Qualifications from "../../components/dashboard/supervisor/Qualifications";
import { getProfile, getTeacherResultsById } from "../../utils/apis";
import TeacherSections from "../../components/dashboard/supervisor/TeacherSections";
import { BiChevronRight } from "react-icons/bi";
import { TbMessageCircle2 } from "react-icons/tb";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { FiDownload } from "react-icons/fi";
import padWithLeadingZeros from "../../utils/padWithLeadingZeros";
import { useForm } from "react-hook-form";

const tabs = [
  "البيانات المسجلة",
  "المعلومات الشخصية",
  "المؤهلات العلمية",
  "المعلومات الوظيفية",
];

export default function TeacherProfile() {
  useEffect(() => {
    document.title = "تمهيد | بيانات المعلم";
  }, []);

  const [activeTab, setActiveTab] = useState(0);
  const [teacherInfo, setTeacherInfo] = useState(null);
  const [teacher, setTeacher] = useState(null);
  const [messsageModal, setShowMessageModal] = useState(false);
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  const { id } = useParams();
  const { token } = useAuth();

  useEffect(() => {
    const getData = async () => {
      const t = await getProfile(id);
      const i = await getTeacherResultsById(id);
      setTeacher(t);
      setTeacherInfo(i);
    };
    getData();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const sendMessage = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/send_massage/?id=${id}`,
        { content: data.content, title: data.subject },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function (res) {
        setShowMessageModal(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {messsageModal && (
        <div className="flex absolute inset-0 bg-[#0000006c] z-50 outline-none focus:outline-none">
          <form
            onSubmit={handleSubmit(sendMessage)}
            className="bg-white shadow-[0px_0px_23px_rgba(35,35,35,0.16)] w-3/4 mx-auto p-6 my-auto rounded-lg flex gap-3 justify-center flex-col text-suvaGrey-60 font-bold"
          >
            <IoClose
              className="cursor-pointer"
              onClick={() => setShowMessageModal(false)}
            />
            <p className="text-suvaGrey-40 text-center text-xl font-normal">
              تواصل مع{" "}
              <span className="px-1 text-darkViolet-100 font-bold">
                {teacherInfo.name}
              </span>
            </p>
            <p>الموضوع</p>
            <div>
              <input
                type="text"
                className="block rounded-lg w-full border-suvaGrey-20 focus:ring-0 focus:border-darkViolet-100 placeholder:text-suvaGrey-40 placeholder:text-sm placeholder:font-light text-nero font-normal py-4 pr-5"
                placeholder="أكتب موضوعك هنا"
                {...register("subject", {
                  required: {
                    message: "مطلوب",
                    value: true,
                  },
                })}
              />
              <p className="text-xs text-red-600 mt-1">
                {errors?.subject?.message}
              </p>
            </div>

            <p>الرسالة</p>
            <div>
              <textarea
                className="block rounded-lg w-full border-suvaGrey-20 placeholder:text-suvaGrey-40 placeholder:text-sm placeholder:font-light py-4 text-nero font-normal pr-5"
                placeholder="أكتب رسالتك هنا..."
                {...register("content", {
                  required: {
                    message: "مطلوب",
                    value: true,
                  },
                })}
              />
              <p className="text-xs text-red-600 mt-1">
                {errors?.content?.message}
              </p>
            </div>

            <button className="block border-persianIndigo-100 w-2/5 mx-auto font-normal border text-persianIndigo-100 mt-4 rounded-lg text-center py-2">
              إرسال
            </button>
          </form>
        </div>
      )}
      {teacherInfo && teacher && (
        <div className={`w-full ${messsageModal ? "h-screen" : "h-full"}`}>
          <div className="relative">
            <Link to={-1} className="absolute top-3 right-2">
              <BiChevronRight className="text-white text-xl" />
            </Link>
          </div>
          <div className="bg-darkViolet-100 bg-[url('/src/assets/images/dashboard/profile-teacher.svg')] flex flex-col justify-center bg-no-repeat sm:bg-contain xs:bg-cover bg-right-top h-[220px]">
            <div className="flex items-center gap-10 px-10">
              <div className="flex lg:flex-row flex-col gap-4 mb-auto">
                <div className="mx-auto">
                  <div className="img-wrap img-upload">
                    {teacher.profile_photo ? (
                      <img
                        className="profile-img"
                        src={teacher.profile_photo}
                      />
                    ) : (
                      <img className="profile-img" src={profileImage} />
                    )}
                  </div>
                </div>
                <div className="text-white my-auto">
                  <p className="text-2xl lg:text-3xl font-bold pb-2">
                    {teacher ? teacher.name : "-"}
                  </p>
                  <p className="text-xl text-darkViolet-20">معلم</p>
                </div>
              </div>
              <div className="mr-auto flex flex-col justify-end items-center">
                <div className="mr-auto flex gap-12">
                  <div>
                    <p
                      className={`font-bold text-xl md:text-4xl text-white ${teacherInfo
                        ? teacherInfo.all_student == 0
                          ? "opacity-[24%]"
                          : ""
                        : ""
                        }`}
                    >
                      {teacherInfo
                        ? padWithLeadingZeros(teacherInfo.all_student) ||
                        "00"
                        : "00"}
                    </p>
                    <p className="text-white mt-2">مجموع الطلاب</p>
                  </div>
                  <div>
                    <p
                      className={`font-bold ${teacherInfo && teacherInfo.total_student_completed
                        ? "text-Shamrock-100"
                        : "text-white opacity-[24%]"
                        } text-xl md:text-4xl`}
                    >
                      {teacherInfo
                        ? padWithLeadingZeros(teacherInfo.total_student_completed) || "00"
                        : "00"}
                    </p>
                    <p className="text-white mt-2">مكتمل</p>
                  </div>
                  <div>
                    <p
                      className={`font-bold text-white text-xl md:text-4xl ${teacherInfo
                        ? teacherInfo.total_student_not_completed == 0
                          ? "opacity-[24%]"
                          : ""
                        : ""
                        }`}
                    >
                      {teacherInfo
                        ? padWithLeadingZeros(teacherInfo.total_student_not_completed) || "00"
                        : "00"}
                    </p>
                    <p className="text-white mt-2">طالب متبقي</p>
                  </div>
                </div>
                <div className="mt-10 flex gap-5">
                  {/* <button className="flex items-center gap-5 lg:gap-10 justify-between font-bold text-xs sm:text-base mr-auto text-white border border-white rounded-lg p-1 sm:p-3 md:px-5 lg:py-4">
                    <p>تحميل تقارير المعلم </p>
                    <FiDownload className="text-xl" />
                  </button> */}
                  <button
                    className="flex items-center gap-5 lg:gap-10 justify-between font-bold text-xs sm:text-base bg-white rounded-lg p-3 lg:px-5 lg:py-4"
                    onClick={() => setShowMessageModal(true)}
                  >
                    <p>تواصل مع المعلم</p>
                    <TbMessageCircle2 className="text-xl" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="relative">
            <Link to={-1} className="absolute top-3 right-2">
              <BiChevronRight className="text-white text-xl" />
            </Link>
          </div>

          <div className="flex my-6 px-10 gap-10 text-center">
            {tabs.map((t, i) => (
              <div
                key={t}
                onClick={() => setActiveTab(i)}
                className={`${activeTab == i
                  ? "text-Shamrock-100 border-b-4 rounded border-b-Shamrock-100"
                  : "text-persianIndigo-100"
                  } font-bold cursor-pointer pb-2 w-full text-sm sm:text-base`}
              >
                {t}
              </div>
            ))}
          </div>

          {teacher ? (
            <>
              {activeTab == 0 ? (
                <TeacherSections id={id} />
              ) : activeTab == 1 ? (
                <PersonalInfo teacher={teacher} />
              ) : activeTab == 2 ? (
                <Qualifications teacher={teacher} />
              ) : (
                <JobInfo teacher={teacher} />
              )}
            </>
          ) : null}
        </div>
      )}
    </>
  );
}
