const handlePercentage = (student) => {
    let percentage = 0
    const tempList = { ...student.records }

    delete tempList.id
    delete tempList.student
    delete tempList.notes_1
    delete tempList.notes_2
    delete tempList.is_horizntal
    delete tempList.date
    delete tempList.student_father_name
    delete tempList.student_first_name
    delete tempList.student_first_name
    delete tempList.student_last_name
    delete tempList.student_grade
    delete tempList.basketball
    delete tempList.arms_length_date
    delete tempList.ball_length_date
    delete tempList.basketball_date
    delete tempList.body_weight_date
    delete tempList.height_sitting_date
    delete tempList.height_standing_date
    // delete tempList.jump_1
    // delete tempList.jump_2
    // delete tempList.jump_3
    delete tempList.jump_1_date
    delete tempList.jump_2_date
    delete tempList.jump_3_date
    delete tempList.muk_runing_date
    delete tempList.run_time_1_date
    delete tempList.run_time_2_date
    delete tempList.run_time_3_date
    delete tempList.total_jump
    delete tempList.average_jump
    delete tempList.total_run
    // delete tempList.run_time_2
    // delete tempList.run_time_3

    if (tempList.jump_1) {
        delete tempList.jump_2
        delete tempList.jump_3
    } else if (tempList.jump_2) {
        delete tempList.jump_1
        delete tempList.jump_3
    } else if (tempList.jump_3) {
        delete tempList.jump_1
        delete tempList.jump_2
    }
    if (tempList.run_time_1) {
        delete tempList.run_time_2
        delete tempList.run_time_3
    } else if (tempList.run_time_2) {
        delete tempList.run_time_1
        delete tempList.run_time_3
    } else if (tempList.run_time_3) {
        delete tempList.run_time_1
        delete tempList.run_time_2
    }


    if (student.records !== 'No record found') {
        const emptyValues = new Set(["", null, undefined, 0, "0.00"]);
        percentage = (100 * Object.values(tempList).filter(x => emptyValues.has(x)).length) / Object.values(tempList).length;
    }

    return 100 - percentage
}

export default handlePercentage