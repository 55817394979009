import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import StatsBlock from "../StatsBlock";

export default function ReportHeaderEmpty({ userType, children }) {

    return (
        <div className="h-[226px] bg-[url('/src/assets/images/dashboard/reports-bg.svg')] bg-no-repeat xs:bg-cover sm:bg-contain sm:bg-[295%_top] md:bg-[140%_top]  xl:bg-[115%_top] 3xl:bg-contain   flex items-center xl:gap-16 gap-8 px-10 bg-persianIndigo-100 text-white text-sm ">
            {userType() === "teacher" || userType() === "supervisor" ? (
                <>
                    <div>
                        <CircularProgressbar
                            value="0"
                            text="0"
                            strokeWidth="15"
                            className="xl:min-w-[80px] min-w-[70px] h-auto max-h-[80px]"
                            styles={buildStyles({
                                pathColor: "#28DC9C",
                                trailColor: "#d6d9e066",
                                strokeLinecap: "butt",
                                textColor: "#28DC9C"
                            })}
                        />
                        {children}
                    </div>
                </>
            ) : null}

            {userType() === "manager" && (
                <StatsBlock
                    title={"المشرفين"}
                    total="0"
                    completed="0"
                    remainder="0"
                />
            )}
            {(userType() === "manager" || userType() === "supervisor") && (
                <>
                    <StatsBlock
                        title={"المعلمين"}
                        total="0"
                        completed="0"
                        remainder="0"
                    />
                    <StatsBlock
                        title={"الطلاب"}
                        total="0"
                        completed="0"
                        remainder="0"
                    />
                </>
            )}

            {userType() === "teacher" && (
                <>
                    <div>
                        <p
                            className={`font-bold text-white text-5xl opacity-[24%]`}
                        >
                            00
                        </p>
                        <p>صفوف</p>
                    </div>
                    <div>
                        <p
                            className={`font-bold text-white text-5xl opacity-[24%]`}
                        >
                            00
                        </p>
                        <p>مجموع الطلاب</p>
                    </div>
                    <div>
                        <p
                            className={`font-bold text-white opacity-[24%] text-5xl`}
                        >
                            00
                        </p>
                        <p>مكتمل</p>
                    </div>
                    <div>
                        <p
                            className={`font-bold text-white text-5xl opacity-[24%]`}
                        >
                            00
                        </p>
                        <p>متبقي</p>
                    </div>
                </>
            )}
        </div>
    )
}
