import wingspan from '../../../../assets/images/dashboard/measurement/wingspan.svg'
import wingspanComplete from '../../../../assets/images/dashboard/measurement/wingspan-complete.svg'
import RecordDate from '../MeasurementsElements/RecordDate'

export default function Arms({ value, date = "" }) {
    return (
        <div className="rounded-lg p-5 text-center bg-[#F9F9F9]">
            {value > 0 ?
                <img src={wingspanComplete} className="mx-auto" />
                :
                <img src={wingspan} className="mx-auto" />
            }

            <p className='pb-4 pt-2'>طول الذراعين</p>
            <p>
                <span className="text-nero pl-1">{value}</span>
                <span className='font-bold'>سم</span>
            </p>
            <RecordDate record={date} />
        </div>

    )
}
