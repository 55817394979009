import { useEffect, useState } from 'react'
import { IoChevronDownOutline, IoChevronUpOutline, IoFemaleSharp, IoMaleSharp } from 'react-icons/io5'

export default function Card({ student, setSelectedTeachers }) {
    const [expand, setExpand] = useState(false)
    const [isSelected, setIsSelected] = useState(false)


    useEffect(() => {
        if (isSelected) {
            setSelectedTeachers((prev) => [student.user, ...prev])
        } else {
            setSelectedTeachers((prev) => prev.filter((s) => s !== student.user))
        }
    }, [isSelected])


    return (
        <div className="ml-6">
            <div className="flex items-center gap-10 py-5 cursor-pointer" onClick={() => setExpand((prev) => !prev)}>
                <div className='min-w-[25%]'>
                    <input onClick={() => null} checked={isSelected} onChange={() => setIsSelected((prev) => !prev)} id={student.id} type="checkbox" className="ml-5 form-checkbox focus:ring-0 peer text-darkViolet-100 rounded-sm border-[#BFC2C9] bg-white" />
                    <label htmlFor={student.id} className={`font-bold text-nero}`}>{student.name}</label>
                </div>
                {expand ?
                    <IoChevronUpOutline className="mr-auto text-xl text-nero" />
                    :
                    <IoChevronDownOutline className="mr-auto text-xl text-suvaGrey-20" />
                }
            </div>
            <hr className='border opacity-60' />
            {expand &&
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 content-center gap-4 my-5">

                    <div className='rounded-lg bg-[#F9F9F9] text-center py-5 flex flex-col justify-center'>
                        <p className='pb-4'>الجنس</p>
                        <p>
                            {student.gender === 'M' ?
                                <>
                                    <IoMaleSharp className='font-bold mx-auto text-Shamrock-100 text-2xl inline ml-2' />
                                    <span className='font-bold'>ذكر</span>
                                </>
                                : student.gender === 'F' ?
                                    <>
                                        <span className='font-bold'>أنثى</span>
                                        <IoFemaleSharp className='font-bold mx-auto text-Shamrock-100 text-2xl inline ml-2' />
                                    </>
                                    : ""}
                        </p>
                    </div>
                    <div className='rounded-lg bg-[#F9F9F9] text-center py-5 flex flex-col justify-center'>
                        <p className='pb-4'>الجنسية</p>
                        <p className='font-bold'>{student.nationality || "-"}</p>
                    </div>
                    <div className='rounded-lg bg-[#F9F9F9] text-center py-5 flex flex-col justify-center'>
                        <p className='pb-4'>مكان الميلاد</p>
                        <p className='font-bold p-2'>{student.city_of_birth || "-"}</p>
                    </div>
                    <div className='rounded-lg bg-[#F9F9F9] text-center py-5 flex flex-col justify-center'>
                        <p className='pb-4'>الاسم المدرسة</p>
                        <p className='font-bold'>{student.school || "-"}</p>
                    </div>
                    <div className='rounded-lg bg-[#F9F9F9] text-center py-5 flex flex-col justify-center'>
                        <p className='pb-4'>نوع المدرسة</p>
                        <p className='font-bold p-2'>{student.school_type || "-"}</p>
                    </div>
                    <div className='rounded-lg bg-[#F9F9F9] text-center py-5 flex flex-col justify-center'>
                        <p className='pb-4'>إدارة التعليم</p>
                        <p className='font-bold'>{student.administration_of_education || "-"}</p>
                    </div>
                    <div className='rounded-lg col-span-2 bg-[#F9F9F9] text-center py-5 flex flex-col justify-center'>
                        <p className='pb-4'>مكتب التعليم</p>
                        <p className='font-bold p-2'>{student.educational_office || "-"}</p>
                    </div>
                </div>
            }
        </div>
    )
}
