import ReactSelect from 'react-select'

export default function ClassSelect({ options, name, value, handleChange = () => null, placeholder }) {
    return (
        <ReactSelect
            options={options}
            name={name}
            placeholder={placeholder}
            isSearchable={false}
            value={value}
            className="placeholder:text-suvaGrey-40"
            onChange={(v) => handleChange(v)}
            styles={{
                control: (styles, { menuIsOpen, isFocused }) => ({
                    ...styles,
                    ":hover": { borderColor: '#BFC2C9' },
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    borderBottomLeftRadius: menuIsOpen ? '0' : '8px',
                    borderBottomRightRadius: menuIsOpen ? '0' : '8px',
                    borderBottom: menuIsOpen ? '0' : 'solid 1px #D6D9E0',
                    borderColor: isFocused ? '#D6D9E0' : '#D6D9E0',
                    padding: '5px',
                    boxShadow: '0',
                }),
                container: (styles) => ({
                    ...styles,
                    width: '40%',
                }),
                menu: (styles) => ({
                    ...styles,
                    boxShadow: '0 0 0 0 hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)',
                    margin: 0,
                    border: '1px solid #D6D9E0',
                    borderTopWidth: '0',
                    borderTopLeftRadius: '0',
                    borderTopRightRadius: '0',
                }),
                valueContainer: (styles) => ({
                    ...styles,
                    cursor: 'pointer'
                }),
                placeholder: (styles) => ({
                    ...styles,
                    color: '#D1D1D1'
                }),
                indicatorSeparator: () => null,
                option: (styles, { isSelected }) => ({
                    ...styles,
                    fontWeight: isSelected ? '700' : '400',
                    backgroundColor: 'white',
                    color: 'black',
                    cursor: 'pointer',
                }),
            }}
        />
    )
}