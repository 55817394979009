import { useState } from 'react'
import { FiHelpCircle } from 'react-icons/fi'
import vjumpImage from '../../../../../assets/images/dashboard/measurement/HJump.svg'
import vjumpImageComplete from '../../../../../assets/images/dashboard/measurement/HJump-complete.svg'
import { BsPlusLg } from 'react-icons/bs'
import { Field } from 'formik'
import VideoModal from '../../VideoModal'
import Slider from '../Slider'


export default function Horizontal({ values }) {
    const [showModal, setShowModal] = useState(false)
    const [isJump2Enabled, setIsJump2Enabled] = useState(values[1] >= 1 ? true : false)
    const [isJump3Enabled, setIsJump3Enabled] = useState(values[2] >= 1 ? true : false)
    const [isJump1Active, setIsJump1Active] = useState(false)
    const [isJump2Active, setIsJump2Active] = useState(false)
    const [isJump3Active, setIsJump3Active] = useState(false)

    return (
        <div className="flex flex-col items-center gap-2">
            {values[0] >= 1 & values[1] >= 1 & values[2] >= 1 ?
                <img src={vjumpImageComplete} className="h-20 w-20 mx-auto" />
                :
                <img src={vjumpImage} className="h-20 w-20 mx-auto" />
            }
            <div className="flex items-center gap-2 font-bold text-base sm:text-xl mt-10 mb-2">
                <p>اختبار الوثب العريض من الثبات</p>
                <FiHelpCircle onClick={() => setShowModal(true)} className='text-darkViolet-100 cursor-pointer' />
            </div>
            <p className='text-sm sm:text-lg mb-10'>ادخل مسافة وثب الطالب</p>
            <Slider fieldName={isJump3Enabled ? 'jump_3' : isJump2Enabled ? 'jump_2' : 'jump_1'} startPoint={21} value={values[isJump3Enabled ? 2 : isJump2Enabled ? 1 : 0]} />

            <div className="flex justify-around w-full mt-10 relative">
                {showModal && <VideoModal showModal={showModal} setShowModal={setShowModal} lesson={3} step={122} isJump={true} />}
                <div
                    onFocus={() => setIsJump1Active(true)}
                    onBlur={() => setIsJump1Active(false)}
                    className={`${isJump1Active ? 'border-darkViolet-100 border-2' : 'border-suvaGrey-60 border'} text-darkViolet-100 font-bold rounded-lg px-4 py-5 w-1/4 flex items-center justify-between`}>
                    <Field
                        className="border-none focus:ring-0 placeholder:text-suvaGrey-60 text-xl sm:text-2xl placeholder:text-xl sm:placeholder:text-2xl pl-5 text-center number-arrow w-24 peer"
                        placeholder='0'
                        min="0"
                        type="number"
                        name="jump_1"
                        step="any"
                    />
                    <span className={`peer-focus:text-darkViolet-100 ${values[0] > 0 ? 'text-darkViolet-100' : 'text-suvaGrey-60'} text-lg sm:text-2xl`}>سم</span>
                </div>
            </div>

            <div className="flex justify-around w-full mt-3">
                <div
                    onFocus={() => setIsJump2Active(true)}
                    onBlur={() => setIsJump2Active(false)}
                    className={`${isJump2Enabled ? isJump2Active ? 'border-2 border-darkViolet-100' : 'border border-suvaGrey-60' : 'border border-suvaGrey-20'} text-darkViolet-100 font-bold rounded-lg relative px-4 py-5 w-1/4 flex items-center justify-between`}>
                    {!isJump2Enabled &&
                        <BsPlusLg className='text-xl sm:text-2xl absolute left-1/2 text-darkViolet-100 cursor-pointer' onClick={() => setIsJump2Enabled(true)} />
                    }
                    <Field
                        className="disabled:bg-transparent border-none focus:ring-0 placeholder:text-suvaGrey-60 text-xl sm:text-2xl placeholder:text-xl sm:placeholder:text-2xl text-center number-arrow w-24 peer"
                        placeholder={isJump2Enabled ? '0' : null}
                        min="0"
                        disabled={!isJump2Enabled}
                        name="jump_2"
                        type="number"
                        step="any"
                    />
                    <span className={`peer-focus:text-darkViolet-100 ${values[1] > 0 ? 'text-darkViolet-100' : 'text-suvaGrey-60'} text-lg sm:text-2xl`}>سم</span>
                </div>
            </div>
            {isJump2Enabled &&
                <div className="flex justify-around w-full mt-3">
                    <div onFocus={() => setIsJump3Active(true)}
                        onBlur={() => setIsJump3Active(false)}
                        className={`${isJump3Enabled ? isJump3Active ? 'border-2 border-darkViolet-100' : 'border border-suvaGrey-60' : 'border text-suvaGrey-20 border-suvaGrey-20'} text-darkViolet-100 font-bold rounded-lg relative px-4 py-5 w-1/4 flex items-center justify-between`}>
                        {!isJump3Enabled &&
                            <BsPlusLg className='text-xl sm:text-2xl absolute left-1/2 text-darkViolet-100 cursor-pointer' onClick={() => setIsJump3Enabled(true)} />
                        }
                        <Field
                            className="disabled:bg-transparent border-none focus:ring-0 placeholder:text-suvaGrey-60 text-xl sm:text-2xl placeholder:text-xl sm:placeholder:text-2xl text-center number-arrow w-24 peer"
                            placeholder={isJump3Enabled ? '0' : null}
                            min="0"
                            disabled={!isJump3Enabled}
                            name="jump_3"
                            type="number"
                            step="any"
                        />
                        <span className={`${values[2] > 0 ? 'text-darkViolet-100' : 'text-suvaGrey-60'} text-lg sm:text-2xl peer-focus:text-darkViolet-100`}>سم</span>
                    </div>
                </div>
            }
            <p className='text-bold text-suvaGrey-20 text-xs'>المحاولة 3/{isJump3Enabled ? '3' : isJump2Enabled ? '2' : '1'}</p>
        </div>
    )
}
