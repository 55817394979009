import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
import SVGComponent from "../components/SVGCertificate";
import saveSvgAsPng from 'save-svg-as-png'
import { useEffect, useState } from "react";
import { getCer } from "../utils/apis";
import { useAuth } from '../context/AuthContext';

export default function CertificatePage() {
    const { token } = useAuth();
    const [certInfo, setCertInfo] = useState(null)

    useEffect(() => {
        const getDate = async () => {
            const cc = await getCer()
            setCertInfo(cc)
        }
        getDate()
    }, [])

    useEffect(() => {
        if (certInfo) {
            const func = async () => {
                function dataUrItoBlob(dataUri) {
                    const binary = window.atob(dataUri.split(',')[1]);
                    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
                    const array = [];
                    for (let i = 0; i < binary.length; i++) {
                        array.push(binary.charCodeAt(i));
                    }
                    return new Blob([new Uint8Array(array)], { type: mimeString, fileName: 'sss' });
                }

                const dataURI = await saveSvgAsPng.svgAsPngUri(document.getElementById('svg-chart'))
                const blob = dataUrItoBlob(dataURI)

                const formData = new FormData();
                formData.append("file", blob, "certificate.png");

                fetch(`${process.env.REACT_APP_API_URL}/upload_lms_certificate/`, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log(data)
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
            func()
        }
    }, [certInfo])


    const handleClick = () => {
        saveSvgAsPng.saveSvgAsPng(document.getElementById('svg-chart'), 'certificate.png');
    };

    return (

        <div className="custom-bg flex flex-col justify-center lg:justify-around gap-10 lg:gap-0 py-10">
            {certInfo ?
                <SVGComponent name={certInfo.name} cetDate={certInfo.date} cetNumber={certInfo.id} />
                : null}

            <div className="lg:w-3/5 w-full mx-auto px-4 flex justify-between gap-10">
                <button
                    onClick={handleClick}
                    className="flex items-center justify-between gap-3 shadow-button rounded-lg border border-white text-white lg:border-persianIndigo-100 lg:text-persianIndigo-100 px-10 h-14">
                    <span className="">تحميل الشهادة</span>
                    <FiDownload />
                </button>

                <Link to="/dashboard">
                    <button
                        className="shadow-button rounded-lg text-persianIndigo-100 bg-white font-bold px-16 h-14">
                        الصفحة الرئيسية
                    </button>
                </Link>
            </div>
        </div >
    )
}
