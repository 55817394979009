import { useState } from "react"
import { useAuth } from "../../../context/AuthContext";
import React from 'react'
import { FiTrash, FiUpload } from "react-icons/fi";
import { TbFileText } from "react-icons/tb";

function ProfileCertificate({ allCertificates, setAllCertificates }) {

    const { token } = useAuth();

    const certUpload = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);

        fetch(`${process.env.REACT_APP_API_URL}/upload_certificate/`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Token ${token}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                const temp = [...allCertificates]
                temp.push({ name: file.name, id: data.certificate_URL })
                setAllCertificates(temp)
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <>
            <label htmlFor="cert-upload" className="shadow-[0px_4px_10px_rgba(0,0,0,0.1)] flex items-center justify-between px-4 w-full py-5 rounded-lg text-persianIndigo-100 bg-white cursor-pointer">
                <span>تحميل شهادة</span>
                <FiUpload className="text-xl" />
                <input id="cert-upload" accept="image/*" className="hidden" type="file" onChange={certUpload} />
            </label>
            <div className="flex justify-between w-full items-center my-auto">

            </div>
            {allCertificates.map((c) =>
                <div className="flex items-center justify-between w-full">
                    <p key={c.id} className="font-bold">
                        <TbFileText className="inline text-lg" />{" "}
                        {c.name || "-"}{" "}
                    </p>
                    {/* <FiTrash className="text-red-500 stroke-2 cursor-pointer" /> */}
                </div>
            )}
        </>
    )
}

export default ProfileCertificate