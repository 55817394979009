import { IoClose } from 'react-icons/io5';
import { saveAs } from "file-saver";
import { FiDownload } from 'react-icons/fi';

export default function CertificatesModal({ showModal, setShowModal, url }) {
    return (
        <>
            {showModal ? (
                <>
                    <div className="flex fixed bg-[#00000040] inset-0 z-50 outline-none focus:outline-none">
                        <div className="mx-auto my-auto lg:w-1/2 w-3/4">
                            <div className="border-0 rounded-xl shadow-lg flex flex-col w-full bg-white outline-none focus:outline-none">
                                <IoClose className='text-xl mr-4 mt-4 text-suvaGrey-40 cursor-pointer' onClick={() => setShowModal(false)} />
                                <div className="mb-12 mx-auto px-6">
                                    <img src={url} className="" />
                                    <button
                                        onClick={() => saveAs(url, "certificate.png")}
                                        className="mt-5 mr-auto flex items-center justify-between gap-5 lg:gap-16 text-sm lg:text-base shadow-button rounded-lg border border-persianIndigo-100 text-persianIndigo-100 px-4 h-12 lg:h-16">
                                        <span className="">تحميل الشهادة</span>
                                        <FiDownload />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
}