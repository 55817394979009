import { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo/tamheed-white.svg";

export default function LogoutPage() {
  useEffect(() => {
    document.title = "تمهيد | تسجيل الخروج";
  }, []);
  return (
    <div className="custom-bg flex flex-col items-center pt-16 gap-28">
      <img src={logo} alt="Tamheed Logo" className="w-36 sm:w-44" />
      <div className="sm:w-2/5 w-11/12 bg-persianIndigo-100 text-white gap-10 py-6 sm:py-14 flex flex-col justify-center rounded-2xl shadow-[0px_0px_23px_rgba(35,35,35,0.16)]">
        <h1 className="text-2xl text-center">تم تسجيل خروجك بنجاح</h1>
        <Link
          to="/login"
          className="bg-white text-persianIndigo-100 px-16 py-3 sm:py-5 mx-auto block font-bold rounded-lg shadow-button"
        >
          تسجيل الدخول
        </Link>
      </div>
    </div>
  );
}
