import { useFormikContext } from "formik";

export default function Slider({ startPoint, fieldName, value }) {
    const { setFieldValue } = useFormikContext();
    return (
        <div className="slider min-w-full">
            {Array.apply(null, { length: 41 }).map((d, i) => (
                <div key={i}
                    onClick={() => setFieldValue(fieldName, i + startPoint - 1)}
                    className={`${(i + startPoint - 1) % 10 === 0 ? 'ten' : (i + startPoint - 1) % 5 === 0 ? 'five' : ''} ${(i + startPoint - 1) <= value ? 'acitve' : 'inactive'} ${i + startPoint - 1 == value && 'current-value'} slider-child`}
                ></div>
            ))}
        </div>
    )
}
