export default {
    height_standing: '',
    height_sitting: '',
    arms_length: '',
    body_weight: '',
    ball_length: '',
    basketball: '',
    muk_runing: '',
    jump_1: '',
    jump_2: '',
    jump_3: '',
    run_time_1: '',
    run_time_2: '',
    run_time_3: '',
    notes_1: '',
    notes_2: '',
    is_horizntal: false,
    horizntal_jump: '',
    vertical_jump: ''
};
