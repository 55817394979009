import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import OfficesStats from "../OfficesStats";
import StatsBlock from "../StatsBlock";
import ReportsFiles from "./Files";

export default function ReportHeader({ userType, statistic, children }) {

  return (
    <div className={`${userType() === 'manager' ? 'lg:h-[296px] py-3' : userType() === "admin" ? 'lg:h-[350px] py-3' : 'md:h-[226px] h-[350px]'} flex flex-col justify-center lg:bg-[url('/src/assets/images/dashboard/reports-bg.svg')] bg-no-repeat xs:bg-cover sm:bg-contain sm:bg-[295%_top] px-10 bg-persianIndigo-100 md:bg-[140%_top] xl:bg-[115%_top] 3xl:bg-contain`}>
      <div className="flex items-center flex-wrap xl:gap-16 gap-8 text-white text-sm">
        {userType() === "teacher" || userType() === "supervisor" ? (
          <>
            <div>
              <CircularProgressbar
                value={
                  statistic?.all_student
                    ? (100 * statistic.total_student_completed) /
                    statistic.all_student
                    : 0
                }
                text={`${statistic?.all_student
                  ? Math.round((100 * statistic.total_student_completed) /
                    statistic.all_student)
                  : 0
                  }%`}
                strokeWidth="15"
                className="xl:min-w-[80px] min-w-[70px] h-auto md:max-h-[80px] max-h-[60px]"
                styles={buildStyles({
                  pathColor: "#28DC9C",
                  trailColor: "#d6d9e066",
                  strokeLinecap: "butt",
                  textColor:
                    statistic?.all_student &&
                      (100 * statistic.total_student_completed) /
                      statistic.all_student >
                      0
                      ? "#28DC9C"
                      : "#fff",
                })}
              />
              {children}
            </div>
          </>
        ) : null}

        {userType() === "admin" && (
          <div className='text-white mb-4'>
            <h3>رؤساء الأقسام</h3>
            <div className='mt-3'>
              <p className='leading-none font-bold text-3xl md:text-5xl'>
                {statistic?.all_managers || "00"}
              </p>
              <p className='text-xs md:text-base'>العدد الكلي</p>
            </div>
          </div>
        )}

        {userType() === "manager" || userType() === "admin" && (
          <StatsBlock
            title={"المشرفين"}
            total={statistic?.all_supervisor}
            completed={statistic?.total_completed_supervisor}
            remainder={statistic?.total_not_completed_supervisor}
          />
        )}
        {(userType() === "manager" || userType() === "supervisor") || userType() === "admin" ? (
          <>
            <StatsBlock
              title={"المعلمين"}
              total={statistic?.all_teacher}
              completed={statistic?.total_completed_teacher}
              remainder={statistic?.total_not_completed_teacher}
            />
            <StatsBlock
              title={"الطلاب"}
              total={statistic?.all_student}
              completed={statistic?.total_student_completed}
              remainder={statistic?.total_student_not_completed}
            />
          </>
        ) : null}

        {userType() === "teacher" && (
          <>
            <div>
              <p
                className={`font-bold text-white text-5xl ${statistic?.all_sections == 0
                  ? "opacity-[24%]"
                  : ""
                  }`}
              >
                {statistic?.all_sections || "00"}
              </p>
              <p>صفوف</p>
            </div>
            <div>
              <p
                className={`font-bold text-white text-5xl ${statistic?.all_student == 0 ? "opacity-[24%]" : ""
                  }`}
              >
                {statistic?.all_student || "00"}
              </p>
              <p>مجموع الطلاب</p>
            </div>
            <div>
              <p
                className={`font-bold ${statistic && statistic.total_student_completed
                  ? "text-Shamrock-100"
                  : "text-white opacity-[24%]"
                  } text-5xl`}
              >
                {statistic?.total_student_completed || "00"}
              </p>
              <p>مكتمل</p>
            </div>
            <div>
              <p
                className={`font-bold text-white text-5xl ${statistic?.total_student_not_completed == 0 ? "opacity-[24%]" : ""
                  }`}
              >
                {statistic?.total_student_not_completed || "00"}
              </p>
              <p>متبقي</p>
            </div>
          </>
        )}

        <div className="md:grow-0 grow"><ReportsFiles userType={userType()} /></div>
      </div>
      {userType() === "manager" && (
        <div className="w-full mt-6">
          <OfficesStats />
        </div>
      )}
    </div>
  )
}
