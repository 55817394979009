import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, useOutletContext } from "react-router-dom";
import CompletedCard from "../../components/dashboard/CompletedCard";
import DashboardCard from "../../components/dashboard/DashboardCard";
import EmptyState from "../../components/dashboard/EmptyState";
import ManagerDashboardList from "../../components/dashboard/Manager/ManagerDashboardList";
import SupervisorDashboardList from "../../components/dashboard/supervisor/SupervisorDashboardList";
import { useAuth } from "../../context/AuthContext";
import { getAdminResults, getMangerResults, getSupervisorResults, getTeacherResults, getTeacherSections } from "../../utils/apis";
import WalkThrough from "../../components/modals/WalkThrough";
import padWithLeadingZeros from "../../utils/padWithLeadingZeros";
import StatsBlock from "../../components/dashboard/StatsBlock";
import OfficesStats from "../../components/dashboard/OfficesStats";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import sortBySectionCompletion from "../../hooks/sortBySectionCompletion";
import GeneralStat from "../../components/dashboard/GeneralStat";
import SmallLoading from "../../components/SmalLoading";
import AdminStats from "../../components/dashboard/AdminStats";
import AdminEmptyState from "../../components/dashboard/AdminEmptyState";
import AdminDashboardList from "../../components/dashboard/Admin/AdminDashboardList";
import Loading from "../../components/Loading";

export default function DashboardPage() {
  useEffect(() => {
    document.title = "تمهيد | لوحة القيادة";
  }, []);

  const outletContext = useOutletContext();
  const [popuopPos, setPopuopPos] = outletContext.popuopPos;
  const isWalkthrough = outletContext.isWalkthrough;
  const [statistic, setStatistic] = useState(null);
  const [sectionsList, setSectionsList] = useState([]);
  const { userType } = useAuth();
  const [loaded, setLoaded] = useState(false);

  const [stepsIndex, setStepsIndex] = useState(0);

  useEffect(() => {
    const getData = async () => {
      // const data = await getGeneralStat();
      // setStatistic(data.data[0]);
      if (userType() === "teacher") {
        const data = await getTeacherResults()
        setStatistic(data)
        const sections = await getTeacherSections();
        setSectionsList(sections.results);
        setLoaded(true)
      } else if (userType() === "supervisor") {
        const data = await getSupervisorResults();
        setStatistic(data)
      } else if (userType() === "manager") {
        const data = await getMangerResults();
        setStatistic(data)
      } else if (userType() === "admin") {
        const data = await getAdminResults();
        setLoaded(true)
        setStatistic(data)
      }
    };
    getData();
  }, []);

  return (
    <>
      {userType() === "admin" ? <Loading isLoading={loaded} /> : null}
      {userType() === 'admin' && loaded || userType() !== 'admin' ?
        <div className="w-full h-full">
          <div className="relative">
            {isWalkthrough && stepsIndex == 3 ? (
              <WalkThrough
                title="لوحة القيادة"
                number={`4/5`}
                stepsIndex={5}
                subTitle="لوحة التحكم"
                text="بإمكانك التنقل في المنصة عبر لوحة التحكم."
                colorOne
                colorTwo
                colorThree
                colorFour
                onclosenow={() => {
                  setStepsIndex(4);
                  setPopuopPos("archive");
                }}
                top="30px"
                right="10px"
                arrowClass="right-bottom"
              />
            ) : null}
          </div>
          <div className={`${userType() === "teacher" ? "dashboard-teacher-bg" : "dashboard-bg py-6"}`}>
            <p className="text-[40px] text-white font-bold md:pt-0 pt-8">
              مرحبا بك في منصة تمهيد!
            </p>
            <div className="flex flex-wrap gap-8">
              {userType() === "supervisor" ? (
                <>
                  <div>
                    <CircularProgressbar
                      value={
                        statistic?.all_student
                          ? (100 * statistic.total_student_completed) /
                          statistic.all_student
                          : 0
                      }
                      text={`${statistic?.all_student
                        ? Math.round((100 * statistic.total_student_completed) /
                          statistic.all_student)
                        : 0
                        }%`}
                      strokeWidth="15"
                      className="xl:min-w-[80px] min-w-[70px] h-auto max-h-[80px]"
                      styles={buildStyles({
                        pathColor: "#28DC9C",
                        trailColor: "#d6d9e066",
                        strokeLinecap: "butt",
                        textColor:
                          statistic?.all_student &&
                            (100 * statistic.total_student_completed) /
                            statistic.all_student >
                            0
                            ? "#28DC9C"
                            : "#fff",
                      })}
                    />
                  </div>
                </>
              ) : null}

              {userType() === "admin" && (
                <div className='text-white'>
                  <h3>رؤساء الأقسام</h3>
                  <div className='mt-3'>
                    <p className='leading-none font-bold text-xl md:text-3xl'>
                      {statistic?.all_managers || "00"}
                    </p>
                    <p className='text-xs md:text-base'>العدد الكلي</p>
                  </div>
                </div>
              )}
              {(userType() === "manager" || userType() === "admin") && (
                <StatsBlock
                  title={"المشرفين"}
                  total={statistic?.all_supervisor}
                  completed={statistic?.total_completed_supervisor}
                  remainder={statistic?.total_not_completed_supervisor}
                />
              )}
              {(userType() === "manager" || userType() === "supervisor" || userType() === "admin") && (
                <>
                  <StatsBlock
                    title={"المعلمين"}
                    total={statistic?.all_teacher}
                    completed={statistic?.total_completed_teacher}
                    remainder={statistic?.total_not_completed_teacher}
                  />
                  <StatsBlock
                    title={"الطلاب"}
                    total={statistic?.all_student}
                    completed={statistic?.total_student_completed}
                    remainder={statistic?.total_student_not_completed}
                  />
                </>
              )}

              {userType() === "teacher" && (
                <>
                  <div className="relative md:pt-0">
                    <p
                      className={`${statistic
                        ? statistic.total_students == 0
                          ? "opacity-[24%]"
                          : ""
                        : ""
                        } font-bold text-white text-5xl`}
                    >
                      {statistic
                        ? padWithLeadingZeros(statistic.all_student) || "00"
                        : "00"}
                    </p>
                    <p className="text-white text-lg mt-2">مجموع الطلاب</p>
                    {isWalkthrough && stepsIndex == 0 ? (
                      <WalkThrough
                        title="لوحة القيادة"
                        number={`1/5`}
                        stepsIndex={5}
                        subTitle="إحصائيات"
                        text="بإمكانك الاطلاع على حالة بيانات الطلاب المطلوبة منك اخذ قياسها."
                        colorOne
                        onclosenow={() => setStepsIndex(1)}
                        top="100px"
                        right="1px"
                        arrowClass="top-right"
                      />
                    ) : null}
                  </div>
                  <div>
                    <p
                      className={`font-bold pr-3 ${statistic && statistic.total_student_completed
                        ? "text-Shamrock-100"
                        : "text-white opacity-[24%]"
                        } text-5xl`}
                    >
                      {statistic
                        ? padWithLeadingZeros(statistic.total_student_completed) || "00"
                        : "00"}
                    </p>
                    <p className="text-white text-lg mt-2">مكتمل</p>
                  </div>
                  <div>
                    <p
                      className={`font-bold text-white text-5xl ${statistic
                        ? statistic.total_student_not_completed == 0
                          ? "opacity-[24%]"
                          : ""
                        : ""
                        }`}
                    >
                      {statistic
                        ? padWithLeadingZeros(statistic.total_student_not_completed) ||
                        "00"
                        : "00"}
                    </p>
                    <p className="text-white text-lg mt-2">طالب متبقي</p>
                  </div>
                  {statistic && statistic.total_student_completed !== 0 ? (
                    <Link
                      to="incomplete-data"
                      className="bg-white w-1/4 lg:w-[18%] ml-4 mt-5 mr-auto px-5 h-16 flex justify-between gap-4 rounded-lg items-center text-persianIndigo-100 font-bold"
                    >
                      <span>تسجيل القياسات</span>
                      <AiOutlinePlus className="text-2xl" />
                    </Link>
                  ) : null}
                </>
              )}
            </div>
            <div className="flex gap-5">
              {userType() === "manager" || userType() === "admin" && (
                <div className="">
                  <OfficesStats />
                </div>
              )}

              {(userType() === "manager" || userType() === "admin") && (
                <div className="">
                  <AdminStats />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col relative">
            {isWalkthrough && stepsIndex == 1 ? (
              <WalkThrough
                title="لوحة القيادة"
                number={`2/5`}
                stepsIndex={5}
                subTitle="متوسط القياسات"
                text="هنا سوف يعرض لك متوسط اداء كل طلابك في كل إختبار اداء."
                colorOne
                colorTwo
                onclosenow={() => setStepsIndex(2)}
                top="180px"
                right="20%"
                arrowClass="top-right"
              />
            ) : null}

            {userType() === 'teacher' ?
              <>
                <GeneralStat userType={userType} />
                <hr className="opacity-50 mx-5" />
              </> : null
            }
            <div className="px-5 relative">
              {isWalkthrough && stepsIndex == 2 ? (
                <WalkThrough
                  title="لوحة القيادة"
                  number={`3/5`}
                  stepsIndex={5}
                  subTitle="البيانات"
                  text="هنا سوف يعرض لك بيانات الطلاب الغير مكتملة."
                  colorOne
                  colorTwo
                  colorThree
                  onclosenow={() => setStepsIndex(3)}
                  top="100px"
                  right="10px"
                  arrowClass="left-top"
                />
              ) : null}
              {userType() === "supervisor" ? (
                statistic?.total_teachers == 0 ? (
                  <EmptyState userType="supervisor" />
                ) : (
                  <SupervisorDashboardList />
                )
              ) : null}
              {userType() === "manager" ? (
                statistic?.total_supervisors == 0 ? (
                  <EmptyState userType="manager" />
                ) : (
                  <ManagerDashboardList />
                )
              ) : null}
              {userType() === "admin" ? (
                statistic?.total_supervisors == 0 ? (
                  <AdminEmptyState userType="admin" />
                ) : (
                  <AdminDashboardList />
                )
              ) : null}
              {userType() === "teacher" ?
                <SmallLoading isLoading={loaded} />
                : null}
              {userType() === "teacher" && loaded ? (
                statistic?.all_sections == 0 || !statistic ? (
                  <EmptyState userType="teacher" />
                ) : (
                  <>
                    {statistic?.all_student === statistic?.total_student_completed ? (
                      <>
                        <div className="flex justify-between my-6">
                          <span className="font-bold text-persianIndigo-100 text-2xl">
                            البيانات المسجلة
                          </span>
                          <Link to="/dashboard/archive">
                            <span className="text-darkViolet-100 text-sm underline cursor-pointer">
                              عرض المزيد
                            </span>
                          </Link>
                        </div>
                        <div className="flex gap-6 pb-5">
                          {sectionsList.length
                            ? sectionsList
                              .filter(
                                (s) =>
                                  s.all_student == s.total_student_completed &&
                                  s.all_student !== 0
                              )
                              .slice(0, Math.floor(window.innerWidth / 360))
                              .map((section) => (
                                <CompletedCard
                                  section={section}
                                  key={section.id}
                                />
                              ))
                            : null}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex justify-between my-6">
                          <span className="font-bold text-persianIndigo-100 text-2xl">
                            بيانات غير مكتملة
                          </span>
                          <Link to="/dashboard/incomplete-data">
                            <span className="text-darkViolet-100 text-sm underline cursor-pointer">
                              عرض المزيد
                            </span>
                          </Link>
                        </div>
                        <div className="flex gap-6 flex-wrap pb-5">
                          {sectionsList.length
                            ? sortBySectionCompletion(sectionsList)
                              .filter(
                                (s) =>
                                  s.total_students === 0 ||
                                  s.total_students != s.completed_students
                              )
                              .slice(0, Math.floor(window.innerWidth / 300))
                              .map((section) => (
                                <DashboardCard
                                  section={section}
                                  key={section.id}
                                />
                              ))
                            : null}
                        </div>
                      </>
                    )}
                  </>
                )
              ) :
                null}
            </div>
          </div>
        </div>
        : null}
    </>
  );
}
