import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import convertSecToMinAndSec from "../../utils/convertSecToMinAndSec";
import lessons from "../lessons.json";
import WalkThrough from "../../components/modals/WalkThrough";
import { useOutletContext } from "react-router-dom";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

export default function CoursesPage() {

  useEffect(() => {
    document.title = "تمهيد | مراجعة الفيديوهات";
  }, []);

  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const [isOverflowing, setIsOverflowing] = useState(null);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  useEffect(() => {
    if (elementRef) {
      if (elementRef.current.scrollWidth === elementRef.current.offsetWidth) {
        setIsOverflowing(false);
      } else {
        setIsOverflowing(true);
      }
    }
  }, [elementRef]);

  const outletContext = useOutletContext();
  const [_, setPopuopPos] = outletContext.popuopPos;
  const isWalkthrough = outletContext.isWalkthrough;

  const [currentVideo, setCurrentVideo] = useState(lessons[0]);
  const [stepsIndex, setStepsIndex] = useState(0);

  const calcTotalDur = () => {
    return Math.floor(
      lessons.reduce((accumulator, object) => {
        return accumulator + ~~object.duration;
      }, 0) / 60
    );
  };


  return (
    <>
      <div
        className={`min-h-full w-full learning-bg ${stepsIndex == 4 && "max-h-screen"
          }`}
      >
        <div>
          <div className="bg-black">
            <ReactPlayer
              width="100%"
              height="70%"
              url={currentVideo.url}
              controls
            />
          </div>
          <div className="relative">
            {isWalkthrough && stepsIndex == 0 ? (
              <WalkThrough
                title="الفيديوهات"
                number={`1/${2}`}
                stepsIndex={2}
                subTitle="الفيديوهات"
                text="بإمكانك التصفح بين فيديوهات البرنامج التدريبي للمراجعة."
                colorOne
                onclosenow={() => {
                  setPopuopPos("notifications");
                  setStepsIndex(1);
                }}
                top="10px"
                right="15%"
                arrowClass="top-center"
              />
            ) : null}
          </div>
        </div>

        <div className="flex px-10 my-5 items-center">
          <div className="text-blueDiamond font-bold">
            <span className="ml-5"> {currentVideo.title} </span>
            <span className="text-Shamrock-100">
              {lessons.length}/
              {lessons.findIndex((i) => i.id == currentVideo.id) + 1}
            </span>
          </div>
        </div>
        <hr className="bg-HawkesBlue-40 mr-5 mb-10" />
        {currentVideo.id === 5 && currentVideo.audio ?
          <div className="mx-6 text-blueDiamond mb-6 flex flex-col gap-4 text-lg">
            <p>المواد المساندة</p>
            <div class="flex gap-5 text-darkViolet-60 font-bold"><span>كيفية اداء الاختبار</span></div>
            <audio
              controls
              src="https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/how.mp3">
              <a href="https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/how.mp3">
              </a>
            </audio>
            <div class="flex gap-5 text-darkViolet-60 font-bold"><span>الصافرة</span></div>

            <audio
              controls
              src="https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/whistle.mp3">
              <a href="https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/whistle.mp3">
              </a>
            </audio>
            <div class="flex gap-5 text-darkViolet-60 font-bold"><span>معلومات حول الاختبار</span></div>

            <audio
              controls
              src="https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/info.mp3">
              <a href="https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/info.mp3">
              </a>
            </audio>
          </div> : null}
        <div className="flex my-5 around px-10">
          <div>
            <p className="text-nero font-bold text-3xl mb-3">
              البرنامج التدريبي
            </p>
            <div className="flex gap-5 text-darkViolet-60 font-bold">
              <span>{lessons.length} فيديوهات</span>
              <span className="font-light">
                {calcTotalDur()} د مدة إجمالية{" "}
              </span>
            </div>
          </div>
        </div>
        {isOverflowing === true ? (
          <div className="flex mx-10 text-xl gap-6 justify-end select-none">
            <FiChevronRight
              className={`cursor-pointer ${arrowDisable ? "text-suvaGrey-40" : "text-nero"
                }`}
              onClick={() => {
                handleHorizantalScroll(elementRef.current, 10, 300, 10);
              }}
            />
            <FiChevronLeft
              className="cursor-pointer"
              onClick={() => {
                handleHorizantalScroll(elementRef.current, 10, 300, -10);
              }}
            />
          </div>
        ) : null}
        <div
          className="flex gap-6 m-10 overflow-x-auto hidden-scroll"
          ref={elementRef}
        >
          {lessons.map((l, i) => (
            <div
              className="cursor-pointer min-w-[220px] overflow-hidden"
              key={l.id}
              onClick={() => setCurrentVideo(l)}
            >
              <div
                className={`relative rounded-lg ${l.id == currentVideo.id && "border-4 border-darkViolet-100"
                  }`}
              >
                <img
                  src={l.thumbnail}
                  alt="thumbnail"
                  className="object-cover w-80 aspect-[12/9] rounded-sm"
                />
              </div>
              <div className="flex justify-between mt-2 gap-10 text-nero">
                <span className="font-bold">{l.title}</span>
                <span>
                  {i + 1}/{lessons.length}
                </span>
              </div>
              <p className="text-persianIndigo-100 text-xs">
                {convertSecToMinAndSec(l.duration)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
