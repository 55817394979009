
import { createContext, useContext, useState } from "react";
import Cookies from 'js-cookie';

const WalkthroughContext = createContext(null);

export const WalkthroughProvider = ({ children }) => {
    const [isWalkthrough, setIsWalkthrough] = useState(Cookies.get("walkthrough") == 'false' ? false : true)
    const [popuopPos, setPopuopPos] = useState('')

    const enableWalkthrough = () => {
        Cookies.set("walkthrough", true);
        Cookies.set("expandSideMenu", true);
        setIsWalkthrough(true)
    };
    const disableWalkthrough = () => {
        Cookies.set("walkthrough", false);
        setPopuopPos('')
        setIsWalkthrough(false)
    };

    return (
        <WalkthroughContext.Provider value={{ isWalkthrough, disableWalkthrough, enableWalkthrough, popuopPos, setPopuopPos }}>
            {children}
        </WalkthroughContext.Provider>
    );
};

export const useWalkthrough = () => {
    return useContext(WalkthroughContext);
};