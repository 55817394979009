import { Field, Form, Formik } from "formik";
import { BiChevronRight, BiErrorCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { IoChevronForwardOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import logo from "./assets/images/logo/tamheed-white.svg"
import axios from "axios";

export default function ResetPassPage() {

    const [activeStep, setActiveStep] = useState(0)
    const [errorMessage, setErrorMessage] = useState(false)

    useEffect(() => {
        document.title = "تمهيد | تغيير كلمة المرور";
    }, []);


    const resetPassSchema = Yup.object().shape({
        email: Yup.string().email('البريد الإلكتروني يجب أن يكون عنوانًا صحيحًا').required('هذا الحقل مطلوب'),
    });


    function handleSubmit(values, actions) {

        axios.post(`${process.env.REACT_APP_API_URL}/password/reset/`, values)
            .then(function () {
                setActiveStep(1)
                actions.setTouched({});
                actions.setSubmitting(false);
                setErrorMessage(false)
            })
            .catch(function () {
                setErrorMessage(true)
            })
    }

    return (
        <div className="custom-bg">
            <Formik
                validationSchema={resetPassSchema}
                initialValues={{
                    email: '',
                }}
                onSubmit={handleSubmit}
            >
                {({ errors, touched, isSubmitting }) => (
                    <>
                        {activeStep == 0 ?
                            <Form>
                                <div className="sm:w-2/3 w-11/12 mx-auto py-3 sm:py-10">
                                    <div className="bg-[#F9F9F9] px-12 rounded-lg shadow-page-card py-10 mt-10">
                                        <p className="text-blueDiamond font-bold text-center text-2xl mb-10">تغيير كلمة المرور</p>
                                        <hr className="bg-HawkesBlue-40 mb-10" />
                                        {errorMessage ?
                                            <div className="bg-[rgba(217,30,30,0.08)] rounded-lg py-2 px-4 text-[#D91E1E] font-bold flex items-center gap-2 mb-4">
                                                <BiErrorCircle />
                                                <span>لا يمكنك تغيير كلمة المرور</span>
                                            </div> : null}
                                        <div>
                                            <div className="text-HawkesBlue-100">
                                                <label htmlFor="email" className="text-nero font-bold">ادخل بريدك الإلكتروني المسجل </label>
                                                <Field
                                                    className={`border ${errors.email && touched.email ? 'border-[#DB0000] placeholder:text-[#DB0000]' : 'border-[#D6D9E0]'} w-full py-6 mt-3 placeholder:font-light placeholder:text-[#D6D9E0] px-6 text-black rounded-lg`}
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    placeholder="example@email.com"
                                                />
                                                <div className="h-10 pt-4">
                                                    {errors.email && touched.email ? (
                                                        <div className="flex gap-1">
                                                            <BiErrorCircle className="text-error text-2xl font-light" />
                                                            <span className="text-error">{errors.email}</span>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="sm:w-2/3 w-11/12 mx-auto sm:pb-20 flex">
                                    <Link to="/login" className="border border-persianIndigo-100 flex justify-center items-center px-5 text-xl rounded-lg">
                                        <BiChevronRight />
                                    </Link>
                                    <button disabled={isSubmitting} type="submit" className="flex items-center bg-[#F9F9F9] shadow-button rounded-lg text-persianIndigo-100 font-[600] w-[200px] sm:w-[269px] px-10 mr-auto h-14">
                                        <span className="ml-auto">التالي</span>
                                        {isSubmitting ?
                                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-persianIndigo-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg> :
                                            <IoChevronForwardOutline className="text-xl rotate-180" />
                                        }
                                    </button>
                                </div>
                            </Form>

                            :
                            <div className="custom-bg flex flex-col justify-around py-3 sm:py-16">
                                <img src={logo} alt="Logo" className="mx-auto mb-7 w-36 sm:w-44" />

                                <div className="mx-auto bg-white rounded-2xl my-auto flex flex-col gap-5 items-center py-3 sm:py-10 px-2">
                                    <MdOutlineMailOutline className="text-darkViolet-100" size="42px" />
                                    <span className="text-persianIndigo-100 text-xl sm:text-3xl font-bold w-2/3 text-center">تم إرسال لبريدك الإلكتروني رابط لتغير كلمة المرور</span>
                                    <p className='font-light mb-4'>الرجاء إستخدام الرابط للمتابعة</p>
                                </div>
                                <div className="w-3/5 mx-auto pb-20 flex pr-3 mt-4">
                                    <button onClick={() => setActiveStep(0)} className="border border-persianIndigo-100 flex justify-center items-center px-5 py-5 text-xl rounded-lg">
                                        <BiChevronRight />
                                    </button>
                                </div>
                            </div>
                        }
                    </>
                )}
            </Formik>
        </div>
    )
}


