import { useEffect } from "react";
import { Popover } from "@mantine/core";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getEducationalOffices } from "../../utils/apis";
import { useState } from "react";

function OfficesStats() {
  const [offices, setOffices] = useState([]);

  useEffect(() => {
    const getDate = async () => {
      const officesList = await getEducationalOffices();
      setOffices(officesList.results);
    };
    getDate();
  }, []);

  return (
    <>
      {offices.length ? (
        <div className='text-white'>
          <h2>مكاتب التعليم</h2>

          <div className='flex flex-wrap gap-7 mt-3'>
            {offices?.map((item, index) => (
              <Office
                key={item.name}
                name={item.name}
                percentage={
                  (item.completed_students /
                    (item.completed_students + item.incompleted_students)) *
                    100 || "0"
                }
                data={item}
              />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

function Office({ name, percentage, data }) {
  const color = (t, p) => {
    let basePercentage = p ? p : percentage;

    if (basePercentage > 50) {
      return `${t}-Shamrock-100`;
    } else {
      return `${t}-gold-100`;
    }
  };
  return (
    <>
      <div className='w-fit min-w-[100px]'>
        <div className='flex items-center gap-2'>
          <CircularProgressbar
            value={percentage}
            className='!w-7 !h-7'
            strokeWidth={18}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: percentage > 50 ? "#28DC9C" : "#EDD306",
            })}
          />
          <div className={`${color("text")} w-fit`}>
            {Math.round(percentage)}%
          </div>
        </div>

        <Popover position='bottom' withArrow shadow='md'>
          <Popover.Target>
            <button className='mt-1 font-bold text-base'>{name}</button>
          </Popover.Target>
          <Popover.Dropdown className='text-black rounded-xl max-w-[150px]'>
            <div className='flex gap-2'>
              <div className={`${color("text")}`}>
                {data?.supervisors.total_completed_supervisor || "0"}/
                {data?.supervisors.all_supervisor || "0"}
              </div>
              <div className=''>مشرفين</div>
            </div>
            <div className='flex gap-2 my-2'>
              <div className={`${color("text")}`}>
                {data?.teachers.total_completed_teacher || "0"}/
                {data?.teachers.all_teacher || "0"}
              </div>
              <div className=''>معلمين</div>
            </div>
            <div className='flex gap-2'>
              <div className={`${color("text")}`}>
                {data?.completed_students || "0"}/
                {data?.completed_students + data?.incompleted_students || "0"}
              </div>
              <div className=''>طالب</div>
            </div>
          </Popover.Dropdown>
        </Popover>
      </div>
    </>
  );
}

export default OfficesStats;
