import { useEffect, useState } from "react";
import logo from "../assets/images/logo/tamheed-white.svg"

export default function SmallLoading({ isLoading, size = 200 }) {
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        if (isLoading) {
            setLoading(false);
        }
    }, [isLoading]);

    return (
        <div>
            {loading && (
                <div className="relative">
                    <div className="flex items-center justify-center">
                        <div style={{ width: `${size}px`, height: `${size}px` }} className={`flex items-center justify-center relative`}>
                            <div style={{ width: `${size}px`, height: `${size}px`, borderRadius: `${size}px ${size / 2}px` }} className={`oval-shape-small bg-persianIndigo-100`}></div>
                            <img src={logo} className="mx-auto z-10 w-1/2" alt="" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
