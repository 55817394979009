import { useEffect, useState } from "react"
import { buildStyles, CircularProgressbar } from "react-circular-progressbar"
import { BiCheck } from "react-icons/bi"
import { Link } from "react-router-dom"
import { getSupervisorsTeachers } from "../../../utils/apis"
import empybox from '../../../assets/images/dashboard/emptybox.svg'
import SmallLoading from "../../SmalLoading"


export default function SupervisorSections({ id }) {

    const [teachers, setTeachers] = useState([])
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const getData = async () => {
            const teachersList = await getSupervisorsTeachers(id)
            setLoaded(true)
            // setTeachers(teachersList.filter((t) => t.total_students !== 0))
            setTeachers(teachersList)
        }
        getData()
    }, [])

    return (
        <>
            <div className={`${!loaded ? "mt-16" : ""}`}>
                <SmallLoading isLoading={loaded} size={200} />
            </div>
            {loaded ?
                <div className="w-full p-5 h-[50vh] overflow-y-auto custom-scroll">
                    {teachers.length ?
                        <div className='w-full bg-white md:grid grid-cols-2 gap-4'>
                            {teachers.map((teacher) => (
                                <Link to={`/dashboard/teachers/${teacher.id}`} className="bg-[#F9F9F9] rounded-lg p-[16px_24px] flex cursor-pointer" key={teacher.id}>
                                    <div className={`border-l-2 border-l-suvaGrey-10 ml-5 px-3 w-1/3`}>
                                        <p className="font-light">المعلم</p>
                                        <p className="text-lg text-persianIndigo-100 font-bold">{teacher.name}</p>
                                        <p className="text-xs mt-2 font-light text-persianIndigo-60">{teacher.students.all_student} طالب</p>
                                    </div>
                                    {
                                        teacher.students.all_student == 0 ?
                                            <div className={`w-[36px] h-[36px] my-auto border-[6px] rounded-full flex items-center justify-center border-suvaGrey-40`}>
                                            </div>
                                            : teacher.students?.total_student_completed == teacher.students.all_student ?
                                                <div className={`w-[36px] h-[36px] my-auto border-[6px] rounded-full flex items-center justify-center border-Shamrock-100`}>
                                                    <BiCheck className="text-Shamrock-100 stroke-2" />
                                                </div>
                                                :
                                                <div className="w-[36px] my-auto">
                                                    <CircularProgressbar
                                                        counterClockwise
                                                        value={`${(100 * teacher.students?.total_student_completed) / teacher.students.all_student}`} strokeWidth="17"
                                                        styles={buildStyles({
                                                            pathColor: '#D91E1E',
                                                            trailColor: '#D6D9E0',
                                                            strokeLinecap: 'butt',
                                                        })}
                                                    />
                                                </div>
                                    }
                                    <div className="my-auto mx-3">
                                        <p className={`text-xs text-suvaGrey-80`}> {teacher?.students?.total_student_completed} بيانات طلاب مكتملة</p>
                                        {teacher.students.all_student == 0 ?
                                            <p className={`text-xs mt-2 font-bold text-suvaGrey-60`}> {teacher?.students?.total_student_not_completed || 0} طالب متبقي</p>
                                            : teacher?.students?.total_student_completed == teacher.students?.all_student ?
                                                <p className={`text-xs mt-2 font-bold text-Shamrock-100`}> {teacher?.students?.total_student_not_completed || 0} طالب متبقي</p>
                                                :
                                                <p className={`text-xs mt-2 font-bold text-[#D91E1E]`}> {teacher?.students?.total_student_not_completed || 0} طالب متبقي</p>
                                        }
                                    </div>
                                </Link>
                            ))}
                        </div> :
                        <div className="flex items-center w-full mx-auto flex-col gap-10 justify-center">
                            <img src={empybox} className='text-8xl text-Shamrock-100 mt-5' />
                            <p className='text-3xl text-darkViolet-100 font-bold'>لا توجد بيانات مسجلة</p>
                        </div>
                    }
                </div> : null}
        </>
    )
}
