import { useState } from "react";

const SVGComponent = ({ data }) => {
    const { first, seconed, third } = data
    const [animationClass1, setAnimationClass1] = useState('')
    const [animationClass2, setAnimationClass2] = useState('')
    const [animationClass3, setAnimationClass3] = useState('')

    return (
        <svg width="75%" className="mr-8" height="100%" viewBox="0 0 562 370" fill="none" xmlns="http://www.w3.org/2000/svg" >

            <g className="cursor-pointer" onMouseOver={() => setAnimationClass1('animationRuns-1')} onMouseLeave={() => setAnimationClass1('')}>
                <text x={552} y={66} fill="white" fontSize="14px" fontWeight={200}>1st</text>
                <text x={552} y={97} fill="white" fontSize="14px" fontWeight={200}>
                    {first?.student_first_name || ''} {" "}
                    {first?.student_father_name || ''} {" "}
                    {first?.student_last_name || ''}
                </text>
                <text x={552} y={121} fill="white" fontSize="10px" fontWeight={200}>{first?.student_grade || ''}</text>
            </g>

            <g className={`transition-all duration-70 ${animationClass1}`}>
                <text x={368} y={93} fill="#F8ED9B" fontSize="12px" fontWeight={200}>{first?.total_run || ''}</text>
                <text x={370} y={116} fill="#F8ED9B" fontSize="12px" fontWeight={200}>ثانية / 20 متر</text>
                <path d="M418.203 69.8973C413.858 69.8973 410.316 73.1844 410.316 77.2177C410.316 81.2511 413.858 84.5382 418.203 84.5382C422.549 84.5382 426.09 81.2511 426.09 77.2177C426.09 73.1844 422.549 69.8973 418.203 69.8973Z" fill="white" />

                <path d="M402.47 108.925H402.499C402.661 108.934 402.805 109.022 402.891 109.156L409.936 120.803C410.003 120.909 410.012 121.034 409.984 121.149L407.428 129.429C407.237 130.06 407.361 130.726 407.792 131.241C408.213 131.766 408.864 132.068 409.572 132.068C410.644 132.068 411.525 131.428 411.783 130.504L414.291 121.353C414.607 120.207 414.473 118.981 413.918 117.915L408.481 107.468C408.404 107.317 408.423 107.148 408.519 107.015L417.009 96.0073C417.086 95.9007 417.22 95.8385 417.354 95.8207C417.488 95.803 417.631 95.8474 417.727 95.9362L425.174 102.386C426.188 103.257 427.5 103.736 428.878 103.736H428.926L437.224 103.674C438.22 103.674 439.033 102.91 439.033 101.986V101.356C439.033 100.423 438.22 99.6586 437.205 99.6586H428.16C428.016 99.6586 427.882 99.5965 427.787 99.4987L419.756 90.5347L408.883 84.5114L393.789 85.4353C392.228 85.533 390.783 86.2526 389.826 87.4076L384.916 93.3687C384.6 93.7596 384.475 94.2572 384.581 94.7369C384.686 95.2166 385.002 95.6253 385.461 95.874C386.246 96.2827 387.222 96.1139 387.787 95.4654L392.688 89.8507C392.774 89.7529 392.898 89.6908 393.032 89.6819L407.083 88.8823C407.265 88.8645 407.438 88.9534 407.524 89.0955C407.61 89.2377 407.619 89.4153 407.524 89.5575L391.032 114.93C390.946 115.064 390.783 115.144 390.62 115.144H380.197C378.962 115.144 377.967 116.076 377.967 117.214C377.967 118.351 378.972 119.284 380.197 119.284H391.223C392.937 119.284 394.573 118.519 395.588 117.24L402.077 109.094C402.173 108.978 402.317 108.907 402.47 108.907V108.925Z" fill="white" />
            </g>

            <line x1={295.5} y1={133.5} x2={551.5} y2={133.5} stroke="#EDD306" strokeLinecap="round" />

            <g className="cursor-pointer" onMouseOver={() => setAnimationClass2('animationRuns-2')} onMouseLeave={() => setAnimationClass2('')}>
                <text x={552} y={178} fill="white" fontSize="14px" fontWeight={200}>2nd</text>
                <text x={552} y={210} fill="white" fontSize="14px" fontWeight={200}>
                    {seconed?.student_first_name || ''} {" "}
                    {seconed?.student_father_name || ''} {" "}
                    {seconed?.student_last_name || ''}
                </text>
                <text x={552} y={230} fill="white" fontSize="10px" fontWeight={200}>{seconed?.student_grade || ''}</text>
            </g>

            <g className={`transition-all duration-300 ${animationClass2}`}>
                <text x={223} y={203} fill="#F8ED9B" fontSize="12px" fontWeight={200}>{seconed?.total_run || ''}</text>
                <text x={223} y={226} fill="#F8ED9B" fontSize="12px" fontWeight={200}>ثانية / 20 متر</text>

                <path d="M273.069 179.897C268.737 179.897 265.208 183.184 265.208 187.218C265.208 191.251 268.737 194.538 273.069 194.538C277.4 194.538 280.93 191.251 280.93 187.218C280.93 183.184 277.4 179.897 273.069 179.897Z" fill="white" />
                <path d="M257.386 218.925H257.415C257.577 218.934 257.72 219.022 257.806 219.156L264.827 230.803C264.894 230.909 264.904 231.034 264.875 231.149L262.328 239.429C262.137 240.06 262.261 240.726 262.69 241.241C263.11 241.766 263.759 242.068 264.465 242.068C265.533 242.068 266.411 241.428 266.668 240.504L269.168 231.353C269.483 230.207 269.349 228.981 268.796 227.915L263.377 217.468C263.301 217.317 263.32 217.148 263.415 217.015L271.877 206.007C271.954 205.901 272.087 205.839 272.221 205.821C272.354 205.803 272.497 205.847 272.593 205.936L280.015 212.386C281.026 213.257 282.333 213.736 283.707 213.736H283.755L292.026 213.674C293.018 213.674 293.829 212.91 293.829 211.986V211.356C293.829 210.423 293.018 209.659 292.007 209.659H282.991C282.848 209.659 282.715 209.596 282.619 209.499L274.615 200.535L263.778 194.511L248.733 195.435C247.178 195.533 245.738 196.253 244.784 197.408L239.89 203.369C239.575 203.76 239.451 204.257 239.556 204.737C239.661 205.217 239.975 205.625 240.433 205.874C241.216 206.283 242.189 206.114 242.752 205.465L247.636 199.851C247.722 199.753 247.846 199.691 247.98 199.682L261.984 198.882C262.166 198.865 262.337 198.953 262.423 199.096C262.509 199.238 262.519 199.415 262.423 199.557L245.986 224.93C245.9 225.064 245.738 225.144 245.575 225.144H235.186C233.956 225.144 232.964 226.076 232.964 227.214C232.964 228.351 233.965 229.284 235.186 229.284H246.176C247.884 229.284 249.515 228.519 250.527 227.24L256.995 219.094C257.09 218.978 257.233 218.907 257.386 218.907V218.925Z" fill="white" />

            </g>


            <line x1={150.5} y1={243.5} x2={551.5} y2={243.5} stroke="#EDD306" strokeLinecap="round" />

            <g className="cursor-pointer" onMouseOver={() => setAnimationClass3('animationRuns-3')} onMouseLeave={() => setAnimationClass3('')}>
                <text x={552} y={288} fill="white" fontSize="14px" fontWeight={200}>3rd</text>
                <text x={552} y={318} fill="white" fontSize="14px" fontWeight={200}>
                    {third?.student_first_name || ''} {" "}
                    {third?.student_father_name || ''} {" "}
                    {third?.student_last_name || ''}
                </text>
                <text x={552} y={340} fill="white" fontSize="10px" fontWeight={200}>{third?.student_grade || ''}</text>
            </g>

            <g className={`transition-all duration-500 ${animationClass3}`}>
                <text x={78} y={315} fill="#F8ED9B" fontSize="12px" fontWeight={200}>{third?.total_run || ''}</text>
                <text x={78} y={336} fill="#F8ED9B" fontSize="12px" fontWeight={200}>ثانية / 20 متر</text>


                <path d="M128.069 289.897C123.737 289.897 120.208 293.184 120.208 297.218C120.208 301.251 123.737 304.538 128.069 304.538C132.4 304.538 135.93 301.251 135.93 297.218C135.93 293.184 132.4 289.897 128.069 289.897Z" fill="white" />

                <path d="M112.386 328.925H112.415C112.577 328.934 112.72 329.022 112.806 329.156L119.827 340.803C119.894 340.909 119.904 341.034 119.875 341.149L117.328 349.429C117.137 350.06 117.261 350.726 117.69 351.241C118.11 351.766 118.759 352.068 119.465 352.068C120.533 352.068 121.411 351.428 121.668 350.504L124.168 341.353C124.483 340.207 124.349 338.981 123.796 337.915L118.377 327.468C118.301 327.317 118.32 327.148 118.415 327.015L126.877 316.007C126.954 315.901 127.087 315.839 127.221 315.821C127.354 315.803 127.497 315.847 127.593 315.936L135.015 322.386C136.026 323.257 137.333 323.736 138.707 323.736H138.755L147.026 323.674C148.018 323.674 148.829 322.91 148.829 321.986V321.356C148.829 320.423 148.018 319.659 147.007 319.659H137.991C137.848 319.659 137.715 319.596 137.619 319.499L129.615 310.535L118.778 304.511L103.733 305.435C102.178 305.533 100.738 306.253 99.7836 307.408L94.8896 313.369C94.5748 313.76 94.4508 314.257 94.5557 314.737C94.6606 315.217 94.9755 315.625 95.4334 315.874C96.2157 316.283 97.1887 316.114 97.7516 315.465L102.636 309.851C102.722 309.753 102.846 309.691 102.98 309.682L116.984 308.882C117.166 308.865 117.337 308.953 117.423 309.096C117.509 309.238 117.519 309.415 117.423 309.557L100.986 334.93C100.9 335.064 100.738 335.144 100.575 335.144H90.1864C88.9557 335.144 87.9635 336.076 87.9635 337.214C87.9635 338.351 88.9652 339.284 90.1864 339.284H101.176C102.884 339.284 104.515 338.519 105.527 337.24L111.995 329.094C112.09 328.978 112.233 328.907 112.386 328.907V328.925Z" fill="white" />
            </g>

            <line x1={5.50091} y1={352.501} x2={551.501} y2={353.499} stroke="#EDD306" strokeLinecap="round" />
        </svg>
    )
};
export default SVGComponent;
