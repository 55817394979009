import { useEffect } from "react";
import { useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { TbFileText } from "react-icons/tb";
import CertificateModal from "../Profile/CertificateModal";
import CertificatesModal from "../Profile/CertificatesModal";



export default function Qualifications({ teacher, isSupervisor = false }) {
    const [selectedSports, setSelectedSports] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [certificateID, setCertificateID] = useState(null)

    useEffect(() => {
        if (teacher) {
            if (teacher.sports) {
                setSelectedSports(teacher.sports.length ? teacher.sports.split(',') : [])
            }
        }
    }, [])

    return (
        <>
            {showModal && <CertificatesModal showModal={showModal} setShowModal={setShowModal} url={teacher.certificates.find((c) => certificateID == c.id).certificate_URL} />}
            <div className='rounded-lg shadow-[0px_0px_24px_rgba(35,35,35,0.06)] flex flex-col md:grid grid-cols-2 gap-5 p-8 mb-4 mx-10'>
                <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                    <p className='text-suvaGrey-60 text-sm'>المؤهل</p>
                    <p className='font-bold'>{teacher ? teacher.degree || "-" : '-'}</p>
                </div>
                <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                    <p className='text-suvaGrey-60 text-sm'>التخصص</p>
                    <p className='font-bold'>{teacher ? teacher.specialization || "-" : '-'}</p>
                </div>
                <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                    <p className='text-suvaGrey-60 text-sm'>المستوى</p>
                    <p className='font-bold'>{teacher ? teacher.user_type === 'M' ? 'رئيس قسم' : teacher.user_type === 'S' ? 'مشرف' : teacher.user_type === 'T' ? 'معلم' : '-' : '-'
                    }</p>
                </div>
                <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                    <p className='text-suvaGrey-60 text-sm'>سنوات الخبرة</p>
                    <p className='font-bold'>{teacher ? teacher.years_of_experience || "-" : '-'}</p>
                </div>
                {!isSupervisor ?
                    <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                        <p className='text-suvaGrey-60 text-sm'>حالة البرنامج التدريبي</p>
                        {teacher ? teacher.course_status ?
                            <>
                                <p className="text-Shamrock-100 font-bold">مكتمل <FaRegCheckCircle className="inline stroke-2" /></p>
                                {/* <button className="shadow-[0px_4px_10px_rgba(0,0,0,0.1)] w-full py-4 rounded-lg text-persianIndigo-100 font-bold bg-white" >عرض الشهادة</button> */}
                            </>
                            :
                            <p className="text-[#D91E1E] font-bold">غير مكتمل</p>
                            : "-"}
                    </div> : null}
                <div className={`bg-[#F9F9F9] flex items-center rounded-lg p-5 gap-5 flex-col`}>
                    <p className='text-suvaGrey-60 text-sm'>الشهادات التخصصية في التربية البدينة</p>
                    <div className="lg:grid flex flex-col lg:grid-cols-2 gap-4">
                        {teacher?.certificates?.length ? teacher.certificates.map((c) =>
                            <p key={c.id} className="font-bold cursor-pointer hover:text-persianIndigo-90" onClick={() => {
                                setShowModal(true)
                                setCertificateID(c.id)
                            }}>
                                <TbFileText className="inline text-lg" />{" "}
                                {c.name || "-"}{" "}
                            </p>
                        ) :
                            <p className="font-bold">-</p>
                        }
                    </div>
                    {/* <p className="font-bold"><TbFileText className="inline text-lg" /> {teacher ? teacher.certificate || '-' : '-'}</p> */}
                </div>
                <div className={`bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col ${isSupervisor ? 'col-span-1' : ''}`}>
                    <p className='text-suvaGrey-60 text-sm'>الرياضات التي يمارسها أو مهتم بها</p>

                    <div className="flex gap-4">
                        {selectedSports.map((s) => (
                            <div key={s} className="border border-Shamrock-100 rounded-lg bg-Shamrock-10 text-Shamrock-100 py-1 px-2">{s}</div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
