import React from "react";
import chevronLeft from "../../assets/images/chevron-left.svg";
import { useEffect } from "react";
import { useWalkthrough } from "../../context/WalkthroughContext";

const WalkThrough = ({
  title,
  number,
  subTitle,
  text,
  colorOne,
  colorTwo,
  colorThree,
  colorFour,
  colorFive,
  onclosenow,
  stepsIndex,
  colorSix,
  top,
  right,
  left,
  bottom,
  arrowClass,
}) => {
  const { disableWalkthrough } = useWalkthrough();

  const handleClose = () => {
    disableWalkthrough();
  };

  useEffect(() => {
    let borderone = document.getElementById("border0");
    let bordertwo = document.getElementById("border1");
    let borderthree = document.getElementById("border2");
    let borderfour = document.getElementById("border3");
    let borderfive = document.getElementById("border4");
    let bordersix = document.getElementById("border5");

    const coloringBorder = (borderNumber) => {
      borderNumber.classList.add("border-darkViolet-100");
    };

    if (colorOne) {
      coloringBorder(borderone);
    }
    if (colorTwo) {
      coloringBorder(bordertwo);
    }
    if (colorThree) {
      coloringBorder(borderthree);
    }
    if (colorFour) {
      coloringBorder(borderfour);
    }
    if (colorFive) {
      coloringBorder(borderfive);
    }
    if (colorSix) {
      coloringBorder(bordersix);
    }
  }, []);

  return (
    <>
      <div
        className={`flex absolute inset-0 z-[100] outline-none focus:outline-none`}
      >
        <div className={`relative w-full h-100`}>
          <div
            style={{ top, left, right, bottom }}
            className={`absolute  border-0 text-xs rounded-2xl px-4 shadow-lg flex gap-1 flex-col w-[247px]  bg-white outline-none focus:outline-none py-2`}
          >
            <p className="flex justify-between gap-2 pt-1">
              {Array(stepsIndex)
                .fill("")
                .map((e, index) => (
                  <span
                    key={e + index}
                    className={`block h-full ${stepsIndex} === 2 ? "w-14" : w-24  border-2 rounded-sm `}
                    id={`border${index}`}
                  ></span>
                ))}
            </p>
            <p className="text-darkViolet-100 mb-1">
              {title} {number}
            </p>
            <p className="text-black font-bold ">{subTitle}</p>
            <p className="text-black mb-2">{text}</p>
            <div className="flex justify-between pt-1 pb-2">
              <button
                className="text-[#D91E1E] font-bold underline"
                onClick={handleClose}
              >
                انهاء الجولة التعريفية
              </button>
              <button type="button" onClick={onclosenow}>
                <img src={chevronLeft} className="mx-auto" alt="chevron-Left" />
              </button>
            </div>

            {arrowClass === "top-left" && (
              <div className="absolute top-0 left-[39px] transform -translate-x-1/2 -translate-y-1/2 rotate-45 w-4 h-4 bg-white"></div>
            )}
            {arrowClass === "top-center" && (
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-[-134deg] w-4 h-4 bg-white arrow-design border-white"></div>
            )}
            {arrowClass === "top-right" && (
              <div className="absolute right-[39px] top-0 transform -translate-x-1/2 -translate-y-1/2 rotate-[-136deg] w-4 h-4 bg-white arrow-design border-white"></div>
            )}

            {arrowClass === "right-top" && (
              <div className="absolute top-[35px] right-0 transform translate-x-1/2 -translate-y-1/2 rotate-[-41deg] w-4 h-4 bg-white arrow-design border-white"></div>
            )}

            {arrowClass === "right-center" && (
              <div className="absolute right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2 rotate-[-48deg] w-4 h-4 bg-white arrow-design border-white "></div>
            )}
            {arrowClass === "right-bottom" && (
              <div className="absolute bottom-[35px] right-0 transform translate-x-1/2 -translate-y-1/2 rotate-[-44deg] w-4 h-4 bg-white border-white arrow-design"></div>
            )}

            {arrowClass === "bottom-left" && (
              <div className="absolute left-[39px] bottom-0 transform -translate-x-1/2 translate-y-1/2 rotate-[42deg] w-4 h-4 bg-white arrow-design border-white "></div>
            )}

            {arrowClass === "bottom-center" && (
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-4 h-4 bg-white"></div>
            )}

            {arrowClass === "bottom-right" && (
              <div className="absolute bottom-0 right-[39px] transform -translate-x-1/2 translate-y-1/2 rotate-[43deg] w-4 h-4 bg-white border-white arrow-design"></div>
            )}

            {arrowClass === "left-top" && (
              <div className="absolute top-[35px] left-0 transform -translate-x-1/2 translate-y-1/2 rotate-[-222deg] w-4 h-4 bg-white arrow-design border-white"></div>
            )}
            {arrowClass === "left-center" && (
              <div className="absolute left-0 top-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-4 h-4 bg-white"></div>
            )}
            {arrowClass === "left-bottom" && (
              <div className="absolute bottom-[35px] left-0 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-4 h-4 bg-white"></div>
            )}
          </div>
        </div>
      </div>

      <div className="opacity-25 fixed inset-0 z-[90] bg-black"></div>
    </>
  );
};

export default WalkThrough;
