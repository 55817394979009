import axios from "axios";
import Cookies from "js-cookie";

export const axiosFetch = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    // Authorization: `Token ${Cookies.get("token")}`,
  },
});

axiosFetch.interceptors.request.use((config) => {
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Token ${Cookies.get("token")}`,
  };

  return requestConfig;
});

export const getGeneralStat = async () => {
  try {
    const response = await axiosFetch.get("/generalStat/");
    return response;
  } catch (error) {
    if (error.response.data.detail === "Invalid token.") {
      window.location.reload();
    }
  }
};

export const getAllSupervisors = async () => {
  const res = await axiosFetch.get("/profile/");
  return res.data;
};
export const getSupervisors = async () => {
  const res = await axiosFetch.get("/supervisors/");
  return res.data;
};

export const getSupervisorsByOffice = async (office) => {
  const res = await axiosFetch.get(
    `/supervisors${office ? `/?${office}` : "/"}`
  );
  return res.data;
};

export const getSupervisorById = async (id) => {
  const res = await axiosFetch.get(`/supervisors/${id}/`);
  return res.data;
};

export const getSupervisorsTeachers = async (id) => {
  const res = await axiosFetch.get(`/supervisors/${id}/teachers/`);
  return res.data;
};

export const getTeachers = async () => {
  const res = await axiosFetch.get("/teachers/");
  return res.data;
};

export const getTeacherById = async (id) => {
  const res = await axiosFetch.get(`/teachers/${id}/`);
  return res.data;
};

export const getTeachersSections = async (id) => {
  const res = await axiosFetch.get(`/school-section/${id}/by_teacher/`);
  return res.data;
};

export const getSections = async () => {
  const res = await axiosFetch.get("/sections/");
  return res.data;
};

export const getSectionById = async (id) => {
  const res = await axiosFetch.get(`/sections/${id}`);
  return res.data;
};

export const getSectionsStudents = async (id) => {
  const res = await axiosFetch.get(`/sections/${id}/students/`);
  return res.data;
};

export const getStudents = async () => {
  const res = await axiosFetch.get("/students/");
  return res.data;
};

export const getStudentById = async (id) => {
  const res = await axiosFetch.get(`/students/${id}/`);
  return res.data;
};

export const saveMesurments = async (mesurments) => {
  return await axiosFetch.post("/save_mesurments/", mesurments);
};

export const getCourses = async () => {
  const res = await axiosFetch
    .get("/courses/")
    .then((r) => {
      return r.data;
    })
    .catch((e) => {
      if (e.response.data.detail === "Invalid token.") {
        window.location.reload();
      }
    });
  return res;
};

export const getQuizzes = async () => {
  const res = await axiosFetch.get("/quizzes/");
  return res.data.results;
};

export const getProfile = async (id) => {
  const res = await axiosFetch.get(`/profile/${id}/`);
  return res.data;
};

export const getCurrentProfile = async () => {
  const res = await axiosFetch.get("/users/me/");
  return res.data;
};

export const getMessages = async () => {
  const res = await axiosFetch.get("/massages/all/");
  return res.data;
};

export const getUnreadMessages = async () => {
  const res = await axiosFetch.get("/massages/");
  return res.data;
};

export const getCer = async () => {
  const res = await axiosFetch.get("/cert_info/");
  return res.data;
};

export const getUserProgress = async () => {
  const res = await axiosFetch
    .get("/user_progress/")
    .then((r) => {
      return r.data;
    })
    .catch((e) => {
      return 0;
    });
  return res;
};

export const getMangerResults = async () => {
  const res = await axiosFetch.get("/MangerResultsDashboardAPIView/");
  return res.data;
};

export const getMangerResultsById = async (id) => {
  const res = await axiosFetch.get(`/MangerResultsDashboardAPIView/${id}/`);
  return res.data;
};

export const getMangerResultsWithOffice = async (office) => {
  const res = await axiosFetch.get(`/MangerResultsDashboardAPIView/${office ? `/?${office}` : "/"}`);
  return res.data;
};

export const getMangerCards = async (type, section = "") => {
  const res = await axiosFetch
    .get(`/MangerCardDashboardAPIView/${type}${section ? `/?${section}` : "/"}`)
    .then((r) => {
      return r.data;
    })
    .catch((e) => {
      return 0;
    });
  return res;
};

export const getSupervisorResults = async () => {
  const res = await axiosFetch.get("/SupervisorResultsDashboardAPIView/");
  return res.data;
};

export const getSupervisorCards = async (type, section = "") => {
  const res = await axiosFetch
    .get(
      `/SupervisorCardDashboardAPIView/${type}${section ? `/?${section}` : "/"}`
    )
    .then((r) => {
      return r.data;
    })
    .catch((e) => {
      return 0;
    });
  return res;
};

export const getSupervisorResultsById = async (id) => {
  const res = await axiosFetch.get(`/SupervisorResultsDashboardAPIView/${id ? id + '/' : ''}`);
  return res.data;
};

export const getTeacherResults = async () => {
  const res = await axiosFetch.get("/TeacherResultsDashboardAPIView/");
  return res.data;
};

export const getTeacherResultsById = async (id) => {
  const res = await axiosFetch.get(`/TeacherResultsDashboardAPIView/${id}/`);
  return res.data;
};

export const getTeacherCards = async (type, section = "") => {
  const res = await axiosFetch
    .get(
      `/TeacherCardDashboardAPIView/${type}${section ? `/?${section}` : "/"}`
    )
    .then((r) => {
      return r.data;
    })
    .catch((e) => {
      return 0;
    });
  return res;
};

export const getTeacherSections = async () => {
  const res = await axiosFetch.get("/school-section/");
  return res.data;
};

export const createLink = async () => {
  const res = await axiosFetch.post("generate-invite-link/", {});
  return res.data;
};

export const getSchoolSections = async () => {
  const res = await axiosFetch.get("school-section/");
  return res.data;
};

export const getEducationalAdministrations = async () => {
  const res = await axiosFetch.get("educational-administration/");
  return res.data;
};

export const getEducationalOffices = async () => {
  const res = await axiosFetch.get("manager-educational-offices/");
  return res.data;
};

export const getEducationalOfficesAll = async (admin, gender = '') => {
  const res = await axiosFetch.get(
    `educational-offices/${admin ? `?administration_of_education=${admin}` : ""}${gender ? `${gender}` : ""}`
  );
  return res.data;
};

export const getSectionClasses = async () => {
  const res = await axiosFetch.get("section-class/");
  return res.data;
};

export const getManagers = async () => {
  const res = await axiosFetch.get("/managers/");
  return res.data;
};

export const getManagerById = async (id) => {
  const res = await axiosFetch.get(`/managers/${id}/`);
  return res.data;
};

export const getSupervisorsByManagerId = async (id) => {
  const res = await axiosFetch.get(`/managers/${id}/supervisors/`);
  return res.data;
};

export const getAdminResults = async () => {
  const res = await axiosFetch.get("/AdminResultsDashboardAPIView/");
  return res.data;
};

export const getAdminResultsWithAdministration = async (id) => {
  const res = await axiosFetch.get(`AdminResultsDashboardAPIView/${id ? `?administration_of_education=${id}` : ""}`);
  return res.data;
};

export const getManagerAdministration = async (admin) => {
  const res = await axiosFetch.get(
    `managers/${admin ? `?administration_of_education=${admin}` : ""}`
  );
  return res.data;
};
