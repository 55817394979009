import { useField, useFormikContext } from "formik";
import { useEffect } from "react";

const CustomRunField = ({ time, miliSec, seconds, ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    useEffect(() => {
        if ((miliSec !== '' && miliSec != undefined) && (seconds !== '' && seconds != undefined)) {
            setFieldValue(props.name, `${seconds}.${miliSec}`);
        }
    }, [miliSec, seconds, setFieldValue, props.name])

    return (<input {...field} {...props} type="hidden" />);
};

export default CustomRunField

