import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import NotFoundPage from "./pages/notFound";
import LoginPage from "./pages/login";
import SignupPage from "./pages/signup";
import LogoutPage from "./pages/logout";
import ResetPassPage from "./resetPass";
import SuccessSignup from "./pages/successSignup";
import CoursesPage from "./pages/courses";
import QuizPage from "./pages/quiz";
import ResultsPage from "./pages/results";
import CertificatePage from "./pages/certificate";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import DashboardLayoutComplete from "./components/dashboard/DashboardLayoutComplete";
import DashboardPage from "./pages/dashboard/dashboard";
import IncompleteDatePage from "./pages/dashboard/incompleteDate";
import SettingsPage from "./pages/dashboard/settings";
import ArchivePage from "./pages/dashboard/archive";
import ReviewStudentsPage from "./pages/reviewStudents";
import DashboardCoursesPage from "./pages/dashboard/courses";
import NewStudentsPage from "./pages/dashboard/newStudents";
import ProfilePage from "./pages/profile";
import ProfilePageComplete from "./pages/profileComplete";
import EmailVerification from "./pages/emailVerification";
import { AuthProvider } from "./context/AuthContext";
import ResetPassVerificationPage from "./resetPassVerification";
import StudentPage from "./pages/student";
import AddStudentMeasurementsPage from "./pages/dashboard/students/addStudentMeasurements";
import StudentsSummaryPage from "./pages/dashboard/students/studentsSummary";
import ReviewStudents from "./components/dashboard/students/ReviewStudents";
import TeacherProfile from "./pages/dashboard/teacherProfile";
import NewTacherPage from "./pages/newTacher";
import SupervisorProfle from "./pages/dashboard/supervisorProfile";
import NewSupervisorPage from "./pages/newSupervisor";
import LearningPage from "./pages/learning";
import ChangeEmailVerificationPage from "./pages/changeEmailVerification";
import LoggedinRoute from "./components/LoggedinRoute";
import PrivateRoute from "./components/PrivateRoute";
import PrivateRouteComplete from "./components/PrivateRouteComplete";
import ReportPage from "./pages/report";
import { WalkthroughProvider } from "./context/WalkthroughContext";
import CoursesLayout from "./components/CoursesLayout";
import RegisterPage from "./pages/register";
import PdfManager from "./pages/PdfManager";
import PdfSupervisor from "./pages/PdfSupervisor";
import PdfTeacher from "./pages/PdfTeacher";
import SupervisorsRegister from "./pages/supervisorsRegister";
import ManagerProfle from "./pages/dashboard/managerProfile";
import PdfAdmin from "./pages/PdfAdmin";

function App() {
  return (
    <AuthProvider>
      <WalkthroughProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/register/:token" element={<RegisterPage />} />

          <Route path="/supervisors/register" element={<SupervisorsRegister />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/success-signup" element={<SuccessSignup />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route
            path="/change-email-verification"
            element={<ChangeEmailVerificationPage />}
          />
          <Route path="/reset-password" element={<ResetPassPage />} />
          <Route
            path="/reset-password-verification"
            element={<ResetPassVerificationPage />}
          />

          <Route path="/learning" element={<CoursesLayout />}>
            <Route
              index
              element={
                <PrivateRoute>
                  <LearningPage />
                </PrivateRoute>
              }
            />
            <Route
              path=":id"
              element={
                <PrivateRoute>
                  <CoursesPage />
                </PrivateRoute>
              }
            />
            <Route path="quiz">
              <Route
                path=":id"
                element={
                  <PrivateRoute>
                    <QuizPage />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route
              path="results"
              element={
                <PrivateRoute>
                  <ResultsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="certificate"
              element={
                <PrivateRoute>
                  <CertificatePage />
                </PrivateRoute>
              }
            />
          </Route>

          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <DashboardLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<DashboardPage />} />
            <Route path="learning" element={<DashboardCoursesPage />} />
            <Route path="teachers/:id" element={<TeacherProfile />} />
            <Route path="add-teacher" element={<NewTacherPage />} />
            <Route path="supervisor/:id" element={<SupervisorProfle />} />
            <Route path="add-supervisor" element={<NewSupervisorPage />} />
            <Route path="add-students" element={<NewStudentsPage />} />
            <Route path="manager/:id" element={<ManagerProfle />} />
            <Route
              path="add-students/:id"
              element={<AddStudentMeasurementsPage />}
            />
            <Route path="students-summary" element={<StudentsSummaryPage />} />
            <Route path="review-students/:id" element={<ReviewStudents />} />
            <Route path="incomplete-data">
              <Route index element={<IncompleteDatePage />} />
              <Route path=":id">
                <Route index element={<ReviewStudentsPage />} />
                <Route path="student/:id" element={<StudentPage />} />
              </Route>
            </Route>
            <Route path="settings" element={<SettingsPage />} />
            <Route path="archive" element={<ArchivePage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="reports" element={<ReportPage />} />
          </Route>

          <Route path="/admin-report"
            element={
              <PrivateRoute>
                <PdfAdmin />
              </PrivateRoute>
            }
          ></Route>

          <Route path="/manager-report"
            element={
              <PrivateRoute>
                <PdfManager />
              </PrivateRoute>
            }
          ></Route>

          <Route path="/supervisor-report"
            element={
              <PrivateRoute>
                <PdfSupervisor />
              </PrivateRoute>
            }
          ></Route>

          <Route path="/teacher-report"
            element={
              <PrivateRoute>
                <PdfTeacher />
              </PrivateRoute>
            }
          ></Route>

          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </WalkthroughProvider>
    </AuthProvider>
  );
}

export default App;
