import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import sortByCompletion from "../../../hooks/sortByCompletion";
import { getManagers, getMangerResultsById } from "../../../utils/apis";
import SmallLoading from "../../SmalLoading";
import AdminDashboardCard from "./AdminDashboardCard";

export default function AdminDashboardList() {
  const [managers, setManagers] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const allManagers = await getManagers();
      const allManagers2 = sortByCompletion(allManagers).slice(0, Math.floor(window.innerWidth / 260))
      const tempList = []
      for (const manager of allManagers2) {
        const managerObj = await getMangerResultsById(manager.id)
        if (!tempList.find((s) => s.id === manager.id)) {
          tempList.push({ ...managerObj, ...manager })
        }
      }

      setLoaded(true)
      setManagers(sortByCompletion(tempList))
    };
    getData();
  }, []);


  return (
    <>
      <div className="flex justify-between my-6">
        <span className="font-bold text-persianIndigo-100 text-2xl">
          إحصائيات رؤساء الأقسام
        </span>
        <Link to="/dashboard/archive">
          <span className="text-darkViolet-100 text-sm underline cursor-pointer">
            عرض المزيد
          </span>
        </Link>
      </div>
      <div className={`${!loaded ? "my-10" : ""}`}>
        <SmallLoading isLoading={loaded} size="150" />
      </div>
      {loaded ?
        <div className="flex gap-6 flex-wrap pb-4">
          {managers.map((manager) => (
            <AdminDashboardCard key={manager.id} manager={manager} />
          ))}
        </div>
        : null}
    </>
  );
}
