import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import { BiChevronRight, BiErrorCircle } from 'react-icons/bi'
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { FiLogIn } from "react-icons/fi";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { useWalkthrough } from "../context/WalkthroughContext";

const LoginPage = () => {
    useEffect(() => {
        document.title = "تمهيد | تسجيل الدخول";
    }, []);
    const { disableWalkthrough } = useWalkthrough()
    const navigate = useNavigate();
    const auth = useAuth();

    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showPass, setShowPass] = useState(false)

    const loginSchema = Yup.object().shape({
        email: Yup.string().required('هذا الحقل مطلوب').email('البريد الإلكتروني يجب أن يكون عنوانًا صحيحًا'),
        password: Yup.string().required('هذا الحقل مطلوب'),
    });


    return (
        <div className="custom-bg">
            <Formik
                validationSchema={loginSchema}
                initialValues={{
                    email: '',
                    password: '',
                }}
                onSubmit={(values, actions) => {
                    axios.post(`${process.env.REACT_APP_API_URL}/login/`, {
                        email: values.email.toLowerCase(),
                        password: values.password,
                    })
                        .then(function (response) {
                            setShowErrorMessage(false)
                            actions.setSubmitting(false)
                            auth.login(response.data);
                            disableWalkthrough()
                            if (response.data.type !== 'T') {
                                navigate('/dashboard')
                            } else {
                                if (!response.data.course_status) {
                                    navigate('/learning')
                                } else {
                                    navigate('/dashboard')
                                }
                            }
                        })
                        .catch(function (e) {
                            actions.setSubmitting(false)
                            setShowErrorMessage(true)
                            if (e.response.data.detail === 'User account not verified.') {
                                setErrorMessage('يرجى تفعيل البريد الإلكتروني حتى تتمكن من تسجيل الدخول')
                            } else {
                                setErrorMessage('الرجاء التحقق من البيانات المدخلة')
                            }
                        })
                }}
            >
                {({ errors, touched, isValid, dirty, isSubmitting }) =>
                (
                    <Form>
                        <div className="sm:w-3/5 w-4/5 mx-auto py-6 lg:py-14">
                            <div className="bg-[#F9F9F9] px-12 rounded-lg shadow-page-card">
                                <p className="text-blueDiamond font-bold text-center text-2xl pt-4 xs-pt-10 mb-6">تسجيل الدخول</p>
                                <hr className="bg-HawkesBlue-20 mb-5 xs:mb-10" />
                                {showErrorMessage &&
                                    <div className="bg-[rgba(217,30,30,0.08)] text-sm rounded-lg py-3 px-4 text-[#D91E1E] font-bold flex items-center gap-2 mb-4">
                                        <BiErrorCircle />
                                        <span>{errorMessage}</span>
                                    </div>
                                }
                                <div className="text-HawkesBlue-100 pb-10">
                                    <label htmlFor="email" className="text-nero font-bold">ادخل بريدك الإلكتروني</label>
                                    <Field
                                        className={`border ${errors.email && touched.email ? 'border-[#DB0000] placeholder:text-[#DB0000]' : 'border-[#D6D9E0]'} w-full py-4 lg:py-6 my-3 placeholder:text-[#D6D9E0] px-6 text-black rounded-lg`}
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="example@email.com"
                                    />
                                    <div className="my-2">
                                        {errors.email && touched.email ? (
                                            <div className="flex items-center gap-1">
                                                <BiErrorCircle className="text-error text-lg font-light" />
                                                <span className="text-error">{errors.email}</span>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="text-HawkesBlue-100">
                                        <label htmlFor="password" className="text-nero font-bold">ادخل كلمة المرور</label>
                                        <div className="relative text-HawkesBlue-40 focus-within:text-HawkesBlue-90 block">
                                            <AiOutlineEye onClick={() => setShowPass((s) => !s)} className="cursor-pointer w-8 h-8 absolute lg:bottom-0.5  transform lg:-translate-y-1/2 translate-y-1/2 top-2 lg:top-auto left-5" />
                                            <Field
                                                className={`border ${errors.password && touched.password ? 'border-[#DB0000] placeholder:text-[#DB0000]' : 'border-[#D6D9E0]'} w-full py-4 lg:py-6 mt-3 placeholder:text-[#D6D9E0] px-6 text-black rounded-xl`}
                                                type={showPass ? "text" : "password"}
                                                id="password"
                                                name="password"
                                                placeholder="********"
                                            />
                                        </div>
                                        <div className="my-2">
                                            {errors.password && touched.password ? (
                                                <div className="flex items-center gap-1">
                                                    <BiErrorCircle className="text-error text-lg font-light" />
                                                    <span className="text-error">{errors.password}</span>
                                                </div>
                                            ) : null}
                                        </div>

                                        <Link to="/reset-password" className="font-bold text-darkViolet-100 underline pr-2">
                                            نسيت كلمة المرور؟
                                        </Link>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="sm:w-3/5 w-4/5 mx-auto pb-2 xs:pb-20 flex">

                            <Link to="/" className="border border-persianIndigo-100 flex justify-center items-center px-5 text-xl rounded-lg">
                                <BiChevronRight />
                            </Link>

                            <button disabled={!(isValid && dirty) && isSubmitting} type="submit" className="flex items-center w-1/2 mr-auto bg-[#F9F9F9] shadow-button rounded-lg text-persianIndigo-100 disabled:text-HawkesBlue-90 font-bold md:px-10 px-5 py-5">
                                <span className="ml-auto">تسجيل الدخول</span>
                                {isSubmitting ?
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-persianIndigo-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg> :
                                    <FiLogIn className="text-2xl" />
                                }
                            </button>
                        </div>
                    </Form>
                )
                }
            </Formik >

        </div >
    )
};

export default LoginPage