import * as Yup from 'yup';

export default [
    Yup.object().shape({
        national_id: Yup.number().typeError('رقم الأحوال/ الإقامة يجب أن يكون رقمًا').integer().required('هذا الحقل مطلوب').test('len', 'رقم الأحوال/ الإقامة يجب أن لا يقل عن 10 أرقام', val => val && val.toString().length >= 10),
        // day: Yup.string().required('هذا الحقل مطلوب'),
        // month: Yup.string().required('هذا الحقل مطلوب'),
        // year: Yup.string().required('هذا الحقل مطلوب'),
        // isHijri: Yup.boolean(),
    }),
    Yup.object().shape({}),
    Yup.object().shape({
        email: Yup.string().email('البريد الإلكتروني يجب أن يكون عنوانًا صحيحًا').required('هذا الحقل مطلوب'),
        password: Yup
            .string()
            .required('هذا الحقل مطلوب')
            .min(8, 'كلمة المرور قصيرة'),
        passwordConfirmation: Yup
            .string()
            .required('هذا الحقل مطلوب')
            .oneOf([Yup.ref('password')], 'كلمة المرور لا تتطابق مع تأكيد كلمة المرور')
    }),
];