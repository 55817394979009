import { useEffect, useState } from 'react'
import { getTeacherSections } from '../../../utils/apis'
import ClassCard from '../ClassCard'
import empybox from '../../../assets/images/dashboard/emptybox.svg'
import { Link } from 'react-router-dom'
import Loading from '../../Loading'

export default function TeacherArchive() {
    const [isClassExpanded, setIsClassExpanded] = useState(false)
    const [sectionsList, setSectionsList] = useState([])
    const [lee, setleee] = useState(0)
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setLoaded(true)
            const sections = await getTeacherSections()
            setSectionsList(sections.results)
        }
        getData()
    }, [])


    return (
        <>
            <Loading isLoading={loaded} />
            {loaded ?
                <div className={`w-full p-6 lg:p-10 min-h-screen ${sectionsList.length === 0 ? 'empty-bg' : ''}`}>
                    <p className="font-bold text-persianIndigo-100 text-2xl mb-10">تسجيل القياسات</p>
                    {sectionsList.length === 0 ?
                        <div className="flex mx-32 my-20 items-center justify-center flex-col gap-8">
                            <img src={empybox} className='text-8xl text-Shamrock-100 mt-10' />
                            <p className='text-3xl text-darkViolet-100 font-bold'>ليس لديك بيانت مسجلة</p>
                            <Link to="/dashboard/add-students" className='my-5 flex justify-center items-center bg-persianIndigo-100 shadow-button rounded-lg text-white font-light xl:w-1/4 w-1/3 h-16'>إبدأ بتسجيل قياسات الطلاب</Link>
                        </div>
                        :
                        <div className={`${isClassExpanded ? 'w-2/5' : 'w-full flex flex-col gap-2'}`}>
                            {sectionsList.map(c => (
                                <ClassCard data={c} key={c.id} lee={lee} setleee={setleee} setIsClassExpanded={setIsClassExpanded} isClassExpanded={isClassExpanded} />
                            ))}
                        </div>
                    }
                </div>
                : null}
        </>
    )
}
