import { useState } from 'react'
import ReactSelect from 'react-select'

const options = [
    { value: '', label: 'كل الصفوف' },
    { value: 'الأول الابتدائي', label: 'الصف الأول' },
    { value: 'الثاني الابتدائي', label: 'الصف الثاني' },
    { value: 'الثالث الابتدائي', label: 'الصف الثالث' },
    { value: 'الرابع الابتدائي', label: 'الصف الرابع' },
    { value: 'الخامس الابتدائي', label: 'الصف الخامس' },
    { value: 'السادس الابتدائي', label: 'الصف السادس' },
]

export default function SectionsFilter({ setSelectedSection }) {
    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <div className="lg:w-64">
            <ReactSelect
                options={options}
                defaultValue={[options[0]]}
                onChange={(e) => setSelectedSection(e.value)}
                isSearchable={false}
                onMenuOpen={() => setMenuOpen(true)}
                onMenuClose={() => setMenuOpen(false)}
                styles={{
                    control: (styles, { menuIsOpen, getValue }) => ({
                        ...styles,
                        borderRadius: '8px',
                        borderBottomLeftRadius: menuIsOpen ? '0' : '2',
                        borderBottomRightRadius: menuIsOpen ? '0' : '2',
                        border: 'unset',
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: '0',
                        color: '#712FED',
                        backgroundColor: getValue()[0].value != 0 ? '#712FED' : '#F1EAFD',
                    }),
                    menu: (styles) => ({
                        ...styles,
                        margin: 0,
                        backgroundColor: '#F1EAFD',
                        border: 'unset',
                        borderTopLeftRadius: '0',
                        borderTopRightRadius: '0',
                        boxShadow: 'unset',
                        padding: '8px',
                        ":before": {
                            content: "''",
                            backgroundColor: '#E3D5FB',
                            margin: '0 auto',
                            width: '80%',
                            height: '1px',
                            display: 'block',
                        }
                    }),
                    dropdownIndicator: (styles, { getValue }) => ({ ...styles, scale: menuOpen ? '-1' : '1', cursor: 'pointer', color: getValue()[0].value != 0 ? 'white' : '#712FED', ":hover": { color: '#712FED' } }),
                    indicatorSeparator: () => null,
                    singleValue: (styles, { getValue }) => ({ ...styles, color: getValue()[0].value != 0 ? 'white' : '#712FED', padding: '10px', cursor: 'pointer' }),
                    option: (styles, { isSelected }) => ({
                        ...styles, color: '#712FED', padding: '10px', cursor: 'pointer',
                        backgroundColor: 'unset',
                        fontWeight: isSelected ? 'bold' : 'normal',
                    })
                }}
            />
        </div>
    )
}
