import { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import CustomRunField from './CustomRunField'

export default function Second({ isRun2Enabled, setIsRun2Enabled, value, handleTime }) {
    const [run2Sec, setRun2Sec] = useState('')
    const [run2MilliSec, setRun2MilliSec] = useState('')
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (value) {
            setRun2Sec(handleTime(value)[0])
            setRun2MilliSec(handleTime(value)[1])
        }
    }, [])

    return (
        <div
            onFocus={() => setIsActive(true)}
            onBlur={() => {
                setIsActive(false)
                // setIsTouched(true)
            }}
            className={`border ${isActive ? 'border-darkViolet-100 text-darkViolet-100' : isRun2Enabled ? 'border-suvaGrey-40 text-darkViolet-100' : 'text-suvaGrey-10 border-suvaGrey-10'} font-bold rounded-lg px-4 py-3 flex items-center justify-center w-2/3 max-w-[856px]`}>
            <CustomRunField name="run_time_2" time={value} miliSec={run2MilliSec} seconds={run2Sec} />
            <div className="mx-auto items-center flex justify-end w-1/2">
                <input
                    className={`disabled:bg-transparent border-none focus:ring-0 text-xl sm:text-2xl sm:placeholder:text-2xl placeholder:text-xl p-1 w-16 ${isActive ? 'placeholder:text-suvaGrey-40' : 'placeholder:text-suvaGrey-10'} text-left number-arrow`}
                    min="0"
                    max="60"
                    placeholder="00"
                    dir="ltr"
                    type="number"
                    disabled={!isRun2Enabled}
                    value={run2MilliSec}
                    onChange={(e) => setRun2MilliSec(e.target.value)}
                />
                {isRun2Enabled ?
                    <span className='sm:text-xl text-2xl text-suvaGrey-40 px-1'>:</span>
                    :
                    <FaPlus className='text-suvaGrey-40 my-auto mx-1 cursor-pointer text-sm sm:text-base' onClick={() => setIsRun2Enabled(true)} />
                }
                <input
                    className={`disabled:bg-transparent border-none focus:ring-0 text-xl sm:text-2xl sm:placeholder:text-2xl placeholder:text-xl p-1 w-16 text-right ${isActive ? 'placeholder:text-suvaGrey-40' : 'placeholder:text-suvaGrey-10'} number-arrow`}
                    disabled={!isRun2Enabled}
                    placeholder="00"
                    min="0"
                    dir="ltr"
                    type="number"
                    value={run2Sec}
                    onChange={(e) => setRun2Sec(e.target.value)}
                />
            </div>
            <span className={`text-center text-base sm:text-xl w-1/6 mr-auto ${isActive || run2MilliSec || run2Sec ? 'text-darkViolet-100' : 'text-suvaGrey-40'}`}>ثانية</span>
        </div>
    )
}
