export default function StatPlaceholder() {

    return (
        <>
            <div className='ball-bg text-persianIndigo-20 min-w-[240px] h-[130px] pr-8 rounded-lg flex justify-center flex-col select-none'>
                <p className='text-[16px] text-white'>متوسط بعد الكرة</p>
                <p className='text-[12px]'>رمي الكرة الطبية</p>
                <div className='flex flex-col'>
                    <span className="text-[40px] leading-none">00</span>
                    <span className='font-bold leading-none'>سم</span>
                </div>
            </div>
            <div className='basketball-bg text-darkViolet-10 min-w-[240px] h-[130px] pr-8 rounded-lg flex justify-center flex-col select-none'>
                <p className='text-[16px] text-white'>متوسط بعد الكرة</p>
                <p className='text-[12px]'>رمي الكرة السلة</p>
                <div className='flex flex-col'>
                    <span className="text-[40px] leading-none">00</span>
                    <span className='font-bold leading-none'>سم</span>
                </div>
            </div>
            <div className='jump-bg min-w-[240px] h-[127px] pr-8 text-white rounded-lg flex flex-col justify-center select-none'>
                <p className='text-[16px] text-white'>متوسط مسافة القفز</p>
                <p className='text-[12px]'>القفز العمودي</p>
                <div className='flex flex-col'>
                    <span className="text-[40px] leading-none">00</span>
                    <span className='font-bold leading-none'>سم</span>
                </div>
            </div>
            <div className='vJump-bg min-w-[240px] h-[127px] pr-8 text-white rounded-lg flex flex-col justify-center select-none'>
                <p className='text-[16px] text-white'>متوسط مسافة القفز</p>
                <p className='text-[12px]'>القفز العرضي</p>
                <div className='flex flex-col'>
                    <span className="text-[40px] leading-none">00</span>
                    <span className='font-bold leading-none'>سم</span>
                </div>
            </div>
            <div className='shuttle-bg min-w-[240px] h-[127px] pr-8 text-darkViolet-10 rounded-lg flex flex-col justify-center select-none'>
                <p className='text-[16px] text-white'>متوسط عدد الجولات</p>
                <p className='text-[12px] font-bold'>الجري المكوكي</p>
                <div className='flex flex-col'>
                    <span className="text-[40px] leading-none">00</span>
                    <p className='font-bold leading-none'>جولات</p>
                </div>
            </div>
            <div className='speed-bg min-w-[240px] h-[127px] pr-8 text-[#FBF6CD] rounded-lg flex flex-col justify-center select-none'>
                <p className='text-[16px] text-white'>متوسط سرعة الجري</p>
                <p className='text-[12px] font-bold'>سرعة الجري</p>
                <p className='text-[40px] leading-none'>00</p>
                <p className='font-bold leading-none'>ثانية / 20 متر</p>
            </div>
        </>
    )
}
