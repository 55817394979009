import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import profileImage from "../../assets/images/dashboard/profile-image.png";
import PersonalInfo from "../../components/dashboard/supervisor/PersonalInfo";
import JobInfo from "../../components/dashboard/supervisor/JobInfo";
import Qualifications from "../../components/dashboard/supervisor/Qualifications";
import { getMangerResultsById, getProfile } from "../../utils/apis";
import { BiChevronRight } from "react-icons/bi";
import ManagerSections from "../../components/dashboard/Admin/ManagerSections";

const tabs = [
  "قائمة المشرفين",
  "المعلومات الشخصية",
  "المؤهلات العلمية",
  "المعلومات الوظيفية",
];

export default function ManagerProfle() {
  const [activeTab, setActiveTab] = useState(0);
  const [teacherInfo, setTeacherInfo] = useState(null);
  const [teacher, setTeacher] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      const i = await getMangerResultsById(id);
      setTeacherInfo(i);
      const t = await getProfile(id);
      setTeacher(t);
    };
    getData();
  }, []);

  useEffect(() => {
    document.title = "تمهيد | بيانات المشرف";
  }, []);


  return (
    <>
      <div className="w-full min-h-full">
        <div className="relative">
          <Link to={-1} className="absolute top-3 right-2">
            <BiChevronRight className="text-white text-xl" />
          </Link>
        </div>
        <div className="profile-supervisor-bg flex flex-col justify-center">
          <div className="flex items-center gap-10 px-10">
            <div className="flex lg:flex-row flex-col gap-4 mb-auto">
              <div className="mx-auto">
                <div className="img-wrap img-upload">
                  {teacher?.profile_photo ? (
                    <img className="profile-img" src={teacher.profile_photo} />
                  ) : (
                    <img className="profile-img" src={profileImage} />
                  )}
                </div>
              </div>
              <div className="text-white my-auto">
                <p className="sm:text-2xl lg:text-3xl font-bold pb-2 break-all">
                  {teacher ? teacher.name : "-"}
                </p>
                <p className="sm:text-xl text-darkViolet-20">رئيس قسم</p>
              </div>
            </div>
            <div className="mr-auto">
              <div className="mr-auto flex gap-4 sm:gap-12">
                <div>
                  <p
                    className={`font-bold text-white text-4xl ${teacherInfo
                      ? teacherInfo.all_supervisor == 0
                        ? "opacity-[24%]"
                        : ""
                      : ""
                      }`}
                  >
                    {teacherInfo ? teacherInfo.all_supervisor || "00" : "00"}{" "}
                  </p>
                  <p className="text-white mt-2 text-xs sm:base">مجموع المشرفين</p>
                </div>
                <div>
                  <p
                    className={`font-bold text-white text-4xl ${teacherInfo
                      ? teacherInfo.all_teacher == 0
                        ? "opacity-[24%]"
                        : ""
                      : ""
                      }`}
                  >
                    {teacherInfo ? teacherInfo.all_teacher || "00" : "00"}{" "}
                  </p>
                  <p className="text-white mt-2 text-xs sm:base">مجموع المعلمين</p>
                </div>
                <div>
                  <p
                    className={`font-bold text-white text-4xl ${teacherInfo
                      ? teacherInfo.all_student == 0
                        ? "opacity-[24%]"
                        : ""
                      : ""
                      }`}
                  >
                    {teacherInfo ? teacherInfo.all_student || "00" : "00"}
                  </p>
                  <p className="text-white mt-2 text-xs sm:base">مجموع الطلاب</p>
                </div>
                <div>
                  <p
                    className={`font-bold ${teacherInfo && teacherInfo.total_student_completed
                      ? "text-Shamrock-100"
                      : "text-white opacity-[24%]"
                      } text-4xl`}
                  >
                    {teacherInfo ? teacherInfo.total_student_completed || "00" : "00"}
                  </p>
                  <p className="text-white mt-2 text-xs sm:base">مكتمل</p>
                </div>
                <div>
                  <p
                    className={`font-bold text-white text-4xl ${teacherInfo
                      ? teacherInfo.total_student_not_completed == 0
                        ? "opacity-[24%]"
                        : ""
                      : ""
                      }`}
                  >
                    {teacherInfo ? teacherInfo.total_student_not_completed || "00" : "00"}
                  </p>
                  <p className="text-white mt-2 text-xs sm:base">طالب متبقي</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex my-6 px-10 gap-10 text-center">
          {tabs.map((t, i) => (
            <div
              key={t}
              onClick={() => setActiveTab(i)}
              className={`${activeTab == i
                ? "text-Shamrock-100 border-b-4 rounded border-b-Shamrock-100"
                : "text-persianIndigo-100"
                } cursor-pointer pb-2 w-full font-bold`}
            >
              {t}
            </div>
          ))}
        </div>

        {activeTab == 0 ? (
          <ManagerSections id={id} />
        ) : activeTab == 1 ? (
          <PersonalInfo teacher={teacher} />
        ) : activeTab == 2 ? (
          <Qualifications teacher={teacher} isSupervisor={true} />
        ) : (
          <JobInfo teacher={teacher} />
        )}
      </div>
    </>
  );
}
