function calculateBMI(height, weight) {
  const heightInMeters = height / 100;
  const bmi = weight / (heightInMeters ** 2);

  let message;
  if (bmi < 18.5) {
    message = "نحيل";
  } else if (bmi < 25) {
    message = "طبيعي";
  } else {
    message = "زائد الوزن";
  }
  if (!height || !weight) {
    return `-`;
  }
  return `${Math.round(bmi)} ${message}`;
}

export default calculateBMI