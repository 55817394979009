import { useEffect, useState } from "react";
import Steps from "../../components/dashboard/students/Steps";
import StudentsList from "../../components/dashboard/newStudents/StudentsList";
import StudentsListModal from "../../components/dashboard/students/StudentsListModal";
import { IoChevronBackOutline } from "react-icons/io5";
import empybox from "../../assets/images/dashboard/emptybox.svg";

import { getStudents } from "../../utils/apis";
import { Link } from "react-router-dom";

const steps = [
  "تحديد الطلاب",
  "القياسات الجسدية",
  "قياسات اللياقة البدنية",
  "ملخص البيانات",
];

export default function NewStudentsPage() {
  useEffect(() => {
    document.title = "البيانات | إضافة طالب";
  }, []);

  const [showStudentsList, setShowStudentsList] = useState(false);
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);


  useEffect(() => {
    const getData = async () => {
      const studentsList = await getStudents();
      setStudents(studentsList.filter(s => !s.completed));
    };
    getData();
  }, []);

  return (
    <div className="min-h-screen">
      {showStudentsList && (
        <StudentsListModal
          showModal={showStudentsList}
          setShowModal={setShowStudentsList}
          students={students}
        />
      )}

      <Steps steps={steps} currentStepIndex={0} />
      {students.length ? (
        <>
          <StudentsList
            students={students}
            selectedStudents={selectedStudents}
            setSelectedStudents={setSelectedStudents}
          />
          <div className="w-4/5 mx-auto">
            <Link
              to={selectedStudents.length ? `${selectedStudents[0]}` : ""}
              state={{ selectedStudents }}
              className="mt-5 my-3 flex items-center bg-persianIndigo-100 shadow-button rounded-lg text-white font-light px-10 xl:w-1/4 w-1/2 mr-auto h-14"
            >
              <span className="ml-auto">تأكيد الطلاب</span>
              <IoChevronBackOutline />
            </Link>
          </div>
        </>
      ) : (
        <div className="flex mx-32 my-10 items-center justify-center h-2/3 flex-col gap-10">
          <img src={empybox} className="text-8xl text-Shamrock-100 mt-10" />
          <p className="text-3xl text-darkViolet-100 font-bold">
            لا يوجد طلاب بحاجة للتسجيل
          </p>
          <Link
            to="/dashboard/archive"
            className="my-5 flex justify-center items-center text-xl bg-persianIndigo-100 shadow-button rounded-lg text-white font-light xl:w-1/4 w-1/3 h-14"
          >
            عرض الطلاب
          </Link>
        </div>
      )}
    </div>
  );
}
