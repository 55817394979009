import { useState } from "react"

export default function First({ run1Sec, setRun1Sec, run1MiliSec, setRun1MiliSec }) {
    const [isActive, setIsActive] = useState(false)
    const [isTouched, setIsTouched] = useState(false)

    return (
        <div
            onFocus={() => setIsActive(true)}
            onBlur={() => {
                setIsTouched(true)
                setIsActive(false)
            }}
            className={`border text-darkViolet-100 ${isActive ? 'border-darkViolet-100' : 'border-suvaGrey-40'} font-bold rounded-lg px-4 py-3 flex items-center justify-center w-2/3 max-w-[856px]`}>
            <div className="mx-auto flex justify-end w-1/2 items-center">
                <input
                    className={`border-none focus:ring-0 w-16 p-1 text-left ${isActive ? 'placeholder:text-suvaGrey-40' : 'placeholder:text-suvaGrey-10'} text-xl sm:text-2xl sm:placeholder:text-2xl placeholder:text-xl number-arrow`}
                    min="0"
                    dir="ltr"
                    placeholder="00"
                    type="number"
                    value={run1MiliSec}
                    onChange={(e) => setRun1MiliSec(e.target.value)}
                />
                <span className='sm:text-xl text-2xl px-1 text-suvaGrey-40'>:</span>
                <input
                    className={`border-none text-right focus:ring-0 p-1 w-16 ${isActive ? 'placeholder:text-suvaGrey-40' : 'placeholder:text-suvaGrey-10'} text-xl sm:text-2xl sm:placeholder:text-2xl placeholder:text-xl number-arrow`}
                    min="0"
                    dir="ltr"
                    placeholder="00"
                    type="number"
                    value={run1Sec}
                    onChange={(e) => setRun1Sec(e.target.value)}
                />
            </div>
            <span className={`text-center text-base sm:text-xl w-1/6 mr-auto ${isActive || run1MiliSec || run1Sec ? 'text-darkViolet-100' : 'text-suvaGrey-40'}`}>ثانية</span>
        </div>
    )
}
