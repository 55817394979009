import { useEffect, useState } from 'react'
import { getSupervisorResultsById, getSupervisors } from '../../../utils/apis'
import { Link } from 'react-router-dom'
import { SlSocialDropbox } from 'react-icons/sl'
import { AiOutlinePlus } from 'react-icons/ai'
import SupervisorsList from './SupervisorsList'
import Loading from '../../Loading'

export default function ManagerArchive() {
    const [supervisors, setSupervisor] = useState([])
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setLoaded(true)
            const allsupervisor = await getSupervisors();
            const tempList = []
            for (const superv of allsupervisor) {
                const supervObj = await getSupervisorResultsById(superv.id)
                if (!tempList.find((s) => s.id === superv.id)) {
                    tempList.push({ ...supervObj, ...superv })
                }
            }
            setSupervisor(tempList)
        }
        getData()
    }, [])

    return (
        <>
            <Loading isLoading={loaded} />
            {loaded ?
                <div className="w-full p-5 sm:p-10 min-h-screen flex flex-col">
                    {supervisors.length === 0 ?
                        <div className="flex sm:mx-32 my-10 items-center justify-center text-center h-full flex-col gap-10">
                            <SlSocialDropbox className='text-8xl sm:text-9xl text-Shamrock-100' />
                            <p className='text-3xl text-darkViolet-100 font-bold'>ليس لديك مشرفين مسجلين</p>
                            <Link to="/dashboard/add-supervisor" className='my-5 px-5 flex justify-center items-center bg-persianIndigo-100 shadow-button rounded-lg text-white font-light h-14'>إبدأ بتسجيل المشرفين</Link>
                        </div>
                        :
                        <>
                            <p className="font-bold text-persianIndigo-100 text-2xl mb-10">قائمة المشرفين</p>
                            {supervisors.map(c => (
                                <SupervisorsList data={c} key={c.id} />
                            ))}

                            <Link to="/dashboard/add-supervisor" className='px-5 mt-auto py-4 flex justify-between rounded-lg sm:w-[30%] mr-auto my-10 items-center text-persianIndigo-100 border border-persianIndigo-100 font-bold'>
                                <span>إضافة مشرف</span>
                                <AiOutlinePlus className='text-2xl' />
                            </Link>
                        </>
                    }
                </div> : null}
        </>
    )
}
