import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { CoursesProvider } from "../context/CoursesContext";
import { useAuth } from "../context/AuthContext";

export default function CoursesLayout() {
  const { userType } = useAuth();

  return userType() === "teacher" ? (
    <CoursesProvider>
      <Outlet />
    </CoursesProvider>
  ) : (
    <Navigate to="/dashboard" />
  );
}
