import axios from "axios";
import { useEffect, useState } from "react";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { getQuizzes } from "../utils/apis";
import WalkThrough from "../components/modals/WalkThrough";
import { useWalkthrough } from "../context/WalkthroughContext";

export default function QuizPage() {
  useEffect(() => {
    document.title = "تمهيد | تقييم";
  }, []);

  const { isWalkthrough, popuopPos, setPopuopPos } = useWalkthrough()

  let stepsIndex = 5;

  const { id } = useParams();
  const [quiz, setQuiz] = useState({});
  const [questions, setQuestions] = useState([]);

  const navigate = useNavigate();
  const { token } = useAuth();

  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [isLastCourse, setIsLastCourse] = useState(false);
  const [activeAnswer, setActiveAnswer] = useState({});

  const handleOnCloseNow = () => {
    setPopuopPos('last')
  };

  useEffect(() => {
    const getData = async () => {
      const quizzes = await getQuizzes();
      setQuiz(quizzes.find((q) => q.course == id));
      setIsLastCourse(
        quizzes.find((q) => q.course == id) ==
        quizzes.sort((a, b) => a.id - b.id)[quizzes.length - 1]
      );
      setQuestions(quizzes.find((q) => q.course == id).questions);
    };
    getData();
  }, []);

  const handleAnswer = (answer, qustionId) => {
    const temp = { ...selectedAnswers };
    const temp2 = { ...activeAnswer };
    temp[qustionId] = answer.is_correct;
    temp2[qustionId] = answer.id;
    setSelectedAnswers(temp);
    setActiveAnswer(temp2);
  };

  const handleSelectedOption = (a, qId) => {
    if (Object.values(activeAnswer).find((item) => item === a.id)) {
      return "bg-Shamrock-100 shadow-[0px_0px_24px_2px_rgba(40,220,156,0.4)] text-white font-light border border-transparent";
    } else {
      return "border border-suvaGrey-20";
    }
  };

  const handleSubmit = () => {
    const results = {
      correct: Object.values(selectedAnswers).filter((item) => item === true)
        .length,
      incorrect: Object.values(selectedAnswers).filter((item) => item === false)
        .length,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/finish_quiz/`,
        { quiz_id: quiz.id },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function (res) {
        navigate("/learning/results", {
          state: {
            results,
            name: quiz.quiz_name,
            id,
            isLastCourse,
            stepsIndex,
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {isWalkthrough &&
        popuopPos === 'quiz' ? (
        <WalkThrough
          title="البرنامج التدريبي"
          number={`4/${stepsIndex}`}
          stepsIndex={stepsIndex}
          subTitle="التقييم"
          text="التقيم مكون من 3 الي 6 اسئلة إختيارية. عند النجاح ،بإمكانك التقدم الى الفيديو التالي. لكن في حال الرسوب يجب إعادة الفيديو لتدريبي. "
          colorOne
          colorTwo
          colorThree
          colorFour
          onclosenow={handleOnCloseNow}
          top="5rem"
          right="20vw"
          arrowClass="bottom-right"
        />
      ) : null}

      <div className="custom-bg flex flex-col justify-center pt-14 pb-6">
        <div className="bg-[#F9F9F9] py-10 rounded-lg shadow-page-card w-2/3 mx-auto">
          <p className="font-bold text-persianIndigo-100 text-center text-3xl mb-8">
            تقييم
          </p>
          <p className="text-darkViolet-100 text-center text-xl mb-8">
            {quiz ? quiz.quiz_name : ""}
          </p>

          <hr className="bg-HawkesBlue-40 mr-5 mb-10" />

          {questions?.length &&
            questions.map((qustion) => {
              return (
                <div key={qustion.id}>
                  <div className="mx-auto w-3/4 my-8" key={qustion.id}>
                    <div className="text-center">
                      <p className="text-lg mb-3 font-bold">
                        {qustion.question_text}
                      </p>
                      <div className="grid my-10 gap-4">
                        {qustion.answers.map((a) => (
                          <button
                            key={a.id}
                            onClick={() => handleAnswer(a, qustion.id)}
                            className={`${handleSelectedOption(
                              a,
                              qustion.id
                            )} rounded-lg py-5 texs`}
                          >
                            {a.answer_text}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="mt-12 w-2/3 mx-auto flex">
          <button
            onClick={() => navigate(`/learning/${id}`)}
            className="rounded-lg border border-white text-white lg:border-royalPurple-100 lg:text-royalPurple-100 font-[600] px-5 h-14"
          >
            <IoChevronForwardOutline />
          </button>

          {!isLastCourse ? (
            <button
              onClick={handleSubmit}
              className="flex items-center text-persianIndigo-100 shadow-button rounded-lg bg-white font-[600] px-10 xl:w-1/4 w-2/5 mr-auto h-16"
            >
              <span className="ml-auto">القسم التالي</span>
              <IoChevronBackOutline />
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              className="flex items-center justify-center xl:w-1/4 lg:w-1/3 w-2/4 mr-auto h-14 px-10 rounded-lg bg-white text-persianIndigo-100 shadow-button font-[600]"
            >
              <span className="">إرسال وعرض النتيجة</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
}
