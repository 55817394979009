import { Formik, Form } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BodyMeasurements from '../components/dashboard/students/BodyMeasurements'
import FitnessMeasurements from '../components/dashboard/students/FitnessMeasurements'
import Steps from '../components/dashboard/students/Steps'
import { getStudentById } from '../utils/apis'
// import validationSchema from "../utils/form/measurements/validationSchema";
import formInitialValues from "../utils/form/measurements/initialValues"
import axios from 'axios'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import Cookies from "js-cookie";


const steps = ['مراجعة الطلاب', 'القياسات الجسدية', 'قياسات اللياقة البدنية', 'ملخص البيانات']

export default function StudentPage() {
    const navigate = useNavigate();
    const { id } = useParams()
    const { state } = useLocation()
    const selectedStudents = state?.selectedStudents || []
    const sectionId = state?.sectionId || 0

    const [data, setData] = useState(null)
    const [currentStepIndex, setCurrentStepIndex] = useState(1)
    const [initialValues, setInitialValues] = useState(null)
    const [nextIndex, setNextIndex] = useState(0)

    // const currentValidationSchema = validationSchema[currentStepIndex];

    useEffect(() => {
        setNextIndex(selectedStudents.findIndex((e) => e == id) + 1)
    }, [selectedStudents])

    useEffect(() => {
        const getData = async () => {
            const sections = await getStudentById(id)
            setData(sections)
            if (sections.records === 'No record found') {
                setInitialValues(formInitialValues)
            } else {
                const temp = { ...sections.records }
                Object.keys(temp).map((r) => {
                    if (temp[r] == null) {
                        return temp[r] = ''
                    } else if (temp[r] == false) {
                        return temp[r]
                    } else if (temp[r].toString().includes(".00")) {
                        return temp[r] = Math.trunc(temp[r])
                    } else {
                        return temp[r]
                    }
                })
                setInitialValues(temp)
            }
        }
        getData()
    }, [])


    async function submitForm(values, actions) {
        actions.setSubmitting(false);
        const allValues = {
            ...values
        }

        function removeFalsy(obj) {
            return Object.fromEntries(
                Object.entries(obj).filter(([_, value]) => value)
            );
        }

        if (initialValues.id) {
            allValues.id = initialValues.student
            delete allValues.student
        } else {
            allValues.id = id
            delete allValues.student
        }


        axios.post(`${process.env.REACT_APP_API_URL}/save_mesurments/`, removeFalsy(allValues), {
            headers: {
                Authorization: `Token ${Cookies.get("token")}`,
            }
        })
            .then(function () {
                if (selectedStudents[selectedStudents.length - 1] == ~~id || selectedStudents.length === 0) {
                    navigate(`/dashboard/review-students/${sectionId}`)
                } else {
                    navigate(`/dashboard/incomplete-data/${sectionId}/student/${selectedStudents[nextIndex]}`, { state: { selectedStudents } })
                    setCurrentStepIndex(1)
                    actions.resetForm()
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    function handleSubmit(values, actions) {
        if (currentStepIndex == 2) {
            submitForm(values, actions);
        } else {
            document.querySelector('#scroll-back').scrollTop = 0
            setCurrentStepIndex(2);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    return (
        <div className="w-full min-h-full">
            <Steps currentStepIndex={currentStepIndex} steps={steps} />
            <div className='flex flex-col gap-5 mt-5 w-full'>
                {initialValues &&
                    <Formik
                        enableReinitialize={true}
                        // validationSchema={currentValidationSchema}
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, values, touched }) => {
                            console.log(values)
                            return (
                                <Form >
                                    <div className="h-[70vh] overflow-x-auto hidden-scroll" id='scroll-back'>
                                        {data.records &&
                                            currentStepIndex == 1 ?
                                            <BodyMeasurements data={values} touched={touched} errors={errors} />
                                            :
                                            currentStepIndex == 2 &&
                                            <FitnessMeasurements values={values} touched={touched} errors={errors} />
                                        }
                                    </div>

                                    <div className="flex justify-center gap-16 mb-2">
                                        <button type="button" onClick={() => {
                                            currentStepIndex == 2 ? setCurrentStepIndex(1) : navigate(-1)
                                            document.querySelector('#scroll-back').scrollTop = 0
                                        }} className="p-5 flex items-center text-2xl border border-persianIndigo-100 rounded-lg"><BiChevronRight /></button>
                                        <button type="submit" className='px-10 text-lg py-5 sm:w-[269px] w-[200px] flex items-center justify-between rounded-lg bg-persianIndigo-100 text-white'>
                                            <span>التالي</span>
                                            <BiChevronLeft className='text-2xl' />
                                        </button>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                }


            </div>
        </div>
    )

}
