import axios from "axios";
import { useEffect } from "react";
import { useState } from "react"
import { AiOutlineEdit } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import { getEducationalAdministrations, getEducationalOfficesAll } from "../../../utils/apis";
import CustomSelect from "./CustomSelect";
import CustomSelect2 from "./CustomSelect2";

const types = [
    {
        "label": "بنين",
        "value": "بنين"
    },
    {
        "label": "بنات",
        "value": "بنات"
    }
]

export default function JobInfo({ profile, type, token, setIsProfileComplete }) {
    const [edit, setEdit] = useState()
    const [administration, setAdministration] = useState(profile?.administration_of_education || "");
    const [administrationsList, setAdministrationsList] = useState([]);

    const [office, setOffice] = useState(profile?.educational_office || "");
    const [officesList, setOfficessList] = useState([]);

    const [schoolName, setSchoolName] = useState(profile.school_number)
    const [schoolType, setSchoolType] = useState(profile.school_type)

    const fetchAdministrations = async () => {
        const data = await getEducationalAdministrations()
        setAdministrationsList(
            data.results.map((s) => ({ label: s.name, value: s.id }))
        );
    }

    const handleGender = () => {
        if (profile.gender === "F") {
            return '[F,B]'
        } else if (profile.gender === "M") {
            return '[M,B]'
        }
    }

    const fetchEducationalOffices = async () => {
        const data = await getEducationalOfficesAll('', `?gender=${handleGender()}`)
        setOfficessList(
            data.results.map((s) => ({ label: s.name, value: s.id }))
        );
    }

    useEffect(() => {
        if (administration) {
            getEducationalOfficesAll(administration.value, `&gender=${handleGender()}`).then(
                (res) => {
                    setOfficessList(
                        res.results.map((option) => {
                            return { label: option.name, value: option.id };
                        })
                    );
                }
            );
        } else {
            getEducationalOfficesAll();
        }
    }, [administration]);

    useEffect(() => {
        if (type === 'supervisor' || type === 'teacher') {
            fetchAdministrations()
            fetchEducationalOffices()
        }
    }, [])

    const updateProfile = () => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/update_profile/`,
                {
                    administration_of_education_id: administration.value,
                    educational_office_id: office.value,
                    schoolـministery_number_id: schoolName,
                    school_type: schoolType,
                },
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            )
            .then(function (res) {
                setEdit(false);
                setIsProfileComplete(true)
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <div className='rounded-lg shadow-[0px_0px_24px_rgba(35,35,35,0.06)] flex flex-col sm:grid grid-cols-2 gap-5 p-8 mb-4 mx-10'>
            <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                <p className='text-suvaGrey-60 text-sm'>الرقم الوزاري للمدرسة</p>
                {edit ?
                    <div className="w-full relative">
                        <input
                            value={schoolName}
                            onChange={(e) => setSchoolName(e.target.value)}
                            className="number-arrow focus:ring-0 focus:border-darkViolet-100 form-input rounded-lg placeholder:text-suvaGrey-40 border-gray-300 w-full py-4 px-6"
                        />
                    </div> :
                    <p className='font-bold'>{profile?.school_number || '-'}</p>
                }

            </div>
            <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                <p className='text-suvaGrey-60 text-sm'>نوع المدرسة</p>
                {edit ?
                    <CustomSelect2 options={types} name="schoolType" defaultValue={types.find((g) => g.value === schoolType)} handleChangedSport={(e) => setSchoolType(e.value)} placeholder="إختار الجنس" />
                    :
                    <p className='font-bold'>{schoolType || '-'}</p>
                }
            </div>
            <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                <p className='text-suvaGrey-60 text-sm'>إدارة التعليم</p>
                {/* <p className='font-bold'>{profile.administration_of_education || '-'}</p> */}
                {edit ?
                    <CustomSelect
                        defaultValue={administration}
                        options={administrationsList}
                        name="sections"
                        placeholder="اختر إدارة التعليم"
                        onChange={(v) => setAdministration(v)}
                        isSearchable={true}
                    />
                    :
                    <p className="font-bold">
                        {administration?.label || administration || "-"}
                    </p>
                }
            </div>
            <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                <p className='text-suvaGrey-60 text-sm'>مكتب التعليم</p>
                {edit ?
                    <CustomSelect
                        defaultValue={office}
                        options={officesList}
                        name="sections"
                        placeholder="اختر مكتب التعليم"
                        onChange={(v) => setOffice(v)}
                        isSearchable={true}
                    />
                    :
                    <p className="font-bold">
                        {office?.label || office || "-"}
                    </p>
                }
            </div>
            <div className="col-span-2 mt-8">
                {edit ? (
                    <button
                        onClick={updateProfile}
                        className="w-1/3 mx-auto flex justify-between border border-persianIndigo-100 rounded-lg py-5 px-5"
                    >
                        <span className="text-persianIndigo-100 font-bold">حفظ</span>
                        <FiSave className="text-xl" />
                    </button>
                ) : (
                    <button
                        onClick={() => setEdit(true)}
                        className="w-1/3 mx-auto flex justify-between border border-persianIndigo-100 rounded-lg py-5 px-5"
                    >
                        <span className="text-persianIndigo-100 font-bold">تعديل</span>
                        <AiOutlineEdit className="text-xl" />
                    </button>
                )}
            </div>
        </div>
    )
}
