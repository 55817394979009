import { useEffect, useState } from 'react'
import MedBall from './MedBall';
import BasketBall from './BasketBall';


export default function Ball({ values, errors, touched }) {
    const [activeState, setActiveState] = useState(false)

    useEffect(() => {
        if (!values.ball_length && !values.basketball) {
            setActiveState(false)
        } else if (values.ball_length && values.basketball) {
            setActiveState(false)
        } else if (values.basketball) {
            setActiveState(true)
        } else if (values.ball_length) {
            setActiveState(false)
        }
    }, [])


    return (
        <div>
            <div className="relative mx-auto flex h-9">
                <div className="absolute lg:left-[20%] xl:left-20 md:left-[25.5%] sm:left-[29%] left-[34%] text-sm sm:text-base">
                    <div className="flex gap-3">
                        <span className={`${activeState && 'font-bold text-darkViolet-100'}`}>كرة سلة</span>
                        <div>
                            <input defaultChecked={activeState ? false : true} onChange={() => setActiveState(!activeState)} className="hidden custom-checkbox2" name="is_horizntal" type="checkbox" id="toggle_1" />
                            <label className="flex items-center justify-start w-12 bg-darkViolet-100 h-6 p-1 rounded-full cursor-pointer" htmlFor="toggle_1">
                                <span className="w-5 h-5 bg-white rounded-full"></span>
                            </label>
                        </div>
                        <span className={`${!activeState && 'font-bold text-darkViolet-100'}`}>كرة طبية</span>
                    </div>
                </div>
            </div>

            {!activeState ?
                <MedBall value={values.ball_length} errors={errors} touched={touched} />
                :
                <BasketBall value={values.basketball} errors={errors} touched={touched} />
            }
        </div>
    )
}
