import React, { useEffect, useState } from 'react'
import { IoIosAdd } from 'react-icons/io'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getSectionsStudents } from '../../../utils/apis';
import AddStudentModal from './AddStudentsModal'
import StudentCard from './Card'
import Steps from './Steps';

const steps = ['مراجعة الطلاب', 'القياسات الجسدية', 'قياسات اللياقة البدنية', 'ملخص البيانات']

export default function ReviewStudents() {
    const navigate = useNavigate();
    const { id } = useParams()
    const [students, setStudents] = useState([])

    useEffect(() => {
        const getData = async () => {
            const sections = await getSectionsStudents(id)
            setStudents(sections)
        }
        getData()
    }, [])


    const [showModal, setShowModal] = useState(false)

    return (
        <div className='min-h-screen'>
            <Steps currentStepIndex={3} steps={steps} />
            {/* {showModal && <AddStudentModal showModal={showModal} setShowModal={setShowModal} students={students} />} */}
            <div className='overflow-y-auto custom-scroll max-h-[55vh] my-10 w-11/12 mx-auto flex flex-col'>
                {students.map((student, index) => (
                    <StudentCard key={student.id} student={student} sectionId={id} index={index} />
                ))}
            </div>

            <div className="w-11/12 mx-auto">
                <Link to="/dashboard/add-students" className="border mt-auto border-persianIndigo-100 text-persianIndigo-100 sm:w-[269px] w-[200px] mr-auto font-bold rounded-lg flex justify-between p-4">
                    <span>إضافة طالب</span>
                    <IoIosAdd className='text-2xl' />
                </Link>
            </div>

        </div>
    )
}
