import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import profileImage from "../../assets/images/dashboard/profile-image.png";
import PersonalInfo from "../../components/dashboard/supervisor/PersonalInfo";
import JobInfo from "../../components/dashboard/supervisor/JobInfo";
import Qualifications from "../../components/dashboard/supervisor/Qualifications";
import { getProfile, getSupervisorResultsById, getSupervisors } from "../../utils/apis";
import { BiChevronRight } from "react-icons/bi";
import { TbMessageCircle2 } from "react-icons/tb";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import SupervisorSections from "../../components/dashboard/Manager/SupervisorSections";
import { FiDownload } from "react-icons/fi";
import { useForm } from "react-hook-form";

const tabs = [
  "قائمة المعلمين",
  "المعلومات الشخصية",
  "المؤهلات العلمية",
  "المعلومات الوظيفية",
];

export default function SupervisorProfle() {
  const [activeTab, setActiveTab] = useState(0);
  const [teacherInfo, setTeacherInfo] = useState(null);
  const [teacher, setTeacher] = useState(null);
  const [messsageModal, setShowMessageModal] = useState(false);
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  const { id } = useParams();
  const { token } = useAuth();

  useEffect(() => {
    const getData = async () => {
      const t = await getProfile(id);
      const i = await getSupervisorResultsById(id);
      setTeacher(t);
      setTeacherInfo(i);
    };
    getData();
  }, []);

  useEffect(() => {
    document.title = "تمهيد | بيانات المشرف";
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const sendMessage = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/send_massage/?id=${id}`,
        { content: data.content, title: data.subject, url: "" },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function (res) {
        setShowMessageModal(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="w-full min-h-full">
        {messsageModal && (
          <div className="flex absolute inset-0 bg-[#51515126] z-50 outline-none focus:outline-none">
            <form
              onSubmit={handleSubmit(sendMessage)}
              className="bg-white shadow-[0px_0px_23px_rgba(35,35,35,0.16)] w-3/4 mx-auto p-6 my-auto rounded-lg flex gap-3 justify-center flex-col text-suvaGrey-60 font-bold"
            >
              <IoClose
                className="cursor-pointer"
                onClick={() => setShowMessageModal(false)}
              />
              <p className="text-suvaGrey-40 text-center text-lg font-normal">
                تواصل مع{" "}
                <span className="px-1 text-darkViolet-100 font-bold">
                  {teacher ? teacher.name : ""}
                </span>
              </p>
              <p>الموضوع</p>

              <div>
                <input
                  type="text"
                  className="block rounded-lg w-full border-suvaGrey-20 placeholder:text-suvaGrey-40 placeholder:text-sm placeholder:font-light text-nero font-normal py-4 pr-5"
                  placeholder="أكتب موضوعك هنا"
                  {...register("subject", {
                    required: {
                      message: "مطلوب",
                      value: true,
                    },
                  })}
                />
                <p className="text-xs text-red-600 mt-1">
                  {errors?.subject?.message}
                </p>
              </div>

              <p>الرسالة</p>
              <div>
                <textarea
                  rows={1}
                  className="block rounded-lg border-suvaGrey-20 placeholder:text-suvaGrey-40 placeholder:text-sm placeholder:font-light text-nero font-normal py-4 pr-5 w-full"
                  placeholder="أكتب رسالتك هنا..."
                  {...register("content", {
                    required: {
                      message: "مطلوب",
                      value: true,
                    },
                  })}
                />
                <p className="text-xs text-red-600 mt-1">
                  {errors?.content?.message}
                </p>
              </div>

              <button className="block border-persianIndigo-100 border text-persianIndigo-100 mt-4 w-2/5 mx-auto font-normal rounded-lg text-center py-2">
                إرسال
              </button>
            </form>
          </div>
        )}
        <div className="relative">
          <Link to={-1} className="absolute top-3 right-2">
            <BiChevronRight className="text-white text-xl" />
          </Link>
        </div>
        <div className="profile-supervisor-bg flex flex-col justify-center">
          <div className="flex items-center gap-10 px-10">
            <div className="flex lg:flex-row flex-col gap-4 mb-auto">
              <div className="mx-auto">
                <div className="img-wrap img-upload">
                  {teacher?.profile_photo ? (
                    <img className="profile-img" src={teacher.profile_photo} />
                  ) : (
                    <img className="profile-img" src={profileImage} />
                  )}
                </div>
              </div>
              <div className="text-white my-auto">
                <p className="sm:text-2xl lg:text-3xl font-bold pb-2 break-all">
                  {teacher ? teacher.name : "-"}
                </p>
                <p className="sm:text-xl text-darkViolet-20">مشرف</p>
              </div>
            </div>
            <div className="mr-auto">
              <div className="mr-auto flex gap-4 sm:gap-12">
                <div>
                  <p
                    className={`font-bold text-white text-4xl ${teacherInfo
                      ? teacherInfo.all_teacher == 0
                        ? "opacity-[24%]"
                        : ""
                      : ""
                      }`}
                  >
                    {teacherInfo ? teacherInfo.all_teacher || "00" : "00"}{" "}
                  </p>
                  <p className="text-white mt-2 text-xs sm:base">مجموع المعلمين</p>
                </div>
                <div>
                  <p
                    className={`font-bold text-white text-4xl ${teacherInfo
                      ? teacherInfo.all_student == 0
                        ? "opacity-[24%]"
                        : ""
                      : ""
                      }`}
                  >
                    {teacherInfo ? teacherInfo.all_student || "00" : "00"}
                  </p>
                  <p className="text-white mt-2 text-xs sm:base">مجموع الطلاب</p>
                </div>
                <div>
                  <p
                    className={`font-bold ${teacherInfo && teacherInfo.total_student_completed
                      ? "text-Shamrock-100"
                      : "text-white opacity-[24%]"
                      } text-4xl`}
                  >
                    {teacherInfo ? teacherInfo.total_student_completed || "00" : "00"}
                  </p>
                  <p className="text-white mt-2 text-xs sm:base">مكتمل</p>
                </div>
                <div>
                  <p
                    className={`font-bold text-white text-4xl ${teacherInfo
                      ? teacherInfo.total_student_not_completed == 0
                        ? "opacity-[24%]"
                        : ""
                      : ""
                      }`}
                  >
                    {teacherInfo ? teacherInfo.total_student_not_completed || "00" : "00"}
                  </p>
                  <p className="text-white mt-2 text-xs sm:base">طالب متبقي</p>
                </div>
              </div>
              <div className="mt-10 flex gap-5">
                {/* <button className="flex items-center gap-5 lg:gap-10 justify-between font-bold mr-auto text-white border border-white rounded-lg p-3 lg:px-5 lg:py-4">
                  <p>تحميل تقارير المشرف </p>
                  <FiDownload className="text-xl" />
                </button> */}
                <button
                  className="flex items-center gap-5 lg:gap-10 justify-between font-bold bg-white rounded-lg p-3 lg:px-5 lg:py-4 text-sm sm:text-base"
                  onClick={() => setShowMessageModal(true)}
                >
                  <p>تواصل مع المشرف</p>
                  <TbMessageCircle2 className="text-xl" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex my-6 px-10 gap-10 text-center">
          {tabs.map((t, i) => (
            <div
              key={t}
              onClick={() => setActiveTab(i)}
              className={`${activeTab == i
                ? "text-Shamrock-100 border-b-4 rounded border-b-Shamrock-100"
                : "text-persianIndigo-100"
                } cursor-pointer pb-2 w-full font-bold`}
            >
              {t}
            </div>
          ))}
        </div>

        {activeTab == 0 ? (
          <SupervisorSections id={id} />
        ) : activeTab == 1 ? (
          <PersonalInfo teacher={teacher} />
        ) : activeTab == 2 ? (
          <Qualifications teacher={teacher} isSupervisor={true} />
        ) : (
          <JobInfo teacher={teacher} />
        )}
      </div>
    </>
  );
}
