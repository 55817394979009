import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import logo from "../assets/images/logo/tamheed-black.svg"

export default function HomePage() {
    const [opened, { open, close }] = useDisclosure(false);


    return (
        <>
            <Modal opened={opened} onClose={close} withCloseButton={false} size="xl" centered>
                <div className="bg-black border rounded-lg">
                    <ReactPlayer
                        width="100%"
                        height="70%"
                        playing
                        url="https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/video_final.mp4"
                        controls
                    />
                </div>
            </Modal>
            <div className="custom-bg flex flex-col justify-center items-center">
                <div className="shadow-page-card flex flex-col gap-5 sm:gap-10 bg-white w-3/4 md:w-1/2 lg:w-2/5 xl:w-1/3 p-5 sm:p-10 rounded-xl">
                    <img src={logo} alt="Tamheed logo" className="w-2/5 sm:w-1/2 mx-auto mb-2" />
                    <p className="text-center text-2xl sm:text-4xl text-persianIndigo-100 font-bold">مرحباً بك في تمهيد</p>
                    <p className='text-center'>لإستعراض الفيديو التعريفي{" "}
                        <span onClick={open} className='underline cursor-pointer'>إضغط هنا</span>
                    </p>
                    <div className='flex flex-col gap-2 sm:gap-5'>
                        <Link to="/signup" className="bg-persianIndigo-100 text-center font-bold text-white w-2/5 sm:w-2/3 mx-auto rounded-lg py-4 text-sm">إنشاء حساب جديد</Link>
                        <Link to="/login" className="border border-persianIndigo-100 font-bold text-center text-persianIndigo-100 w-2/5 sm:w-2/3 mx-auto rounded-lg py-3 text-sm">تسجيل دخول</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
