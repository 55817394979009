import { useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FaRegCheckCircle } from "react-icons/fa";
import { FiSave, FiTrash, FiUpload } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { TbFileText } from "react-icons/tb";
import CertificateModal from "./CertificateModal";
import CustomSelect from "./CustomSelect";
import sportsOptions from "../../../data/sports.json";
import axios from "axios";
import { getEducationalAdministrations, getSections, getTeacherSections } from "../../../utils/apis";
import ProfileCertificate from "./ProfileCertificate";

const degrees = [
  { label: "دبلوم", value: "دبلوم" },
  { label: "جامعي", value: "جامعي" },
  { label: "ماجستير", value: "ماجستير" },
  { label: "دكتوراه", value: "دكتوراه" },
];

export default function Qualifications({ profile, token, type }) {
  const [edit, setEdit] = useState(false);
  const [selectedSports, setSelectedSports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [degree, setDegree] = useState(profile.degree);
  const [administration, setAdministration] = useState({ label: profile?.administration_of_education } || "");
  const [administrationsList, setAdministrationsList] = useState([]);
  const [specialization, setSpecialization] = useState(
    profile.specialization || ""
  );
  const [yearsOfExperience, setYearsOfExperience] = useState(
    profile.years_of_experience || ""
  );
  const [allCertificates, setAllCertificates] = useState(profile.certificates || [])

  const updateProfile = () => {
    const body = {
      phone_number: profile.phone_number,
      gender: profile.gender,
      nationality: profile.nationality,
      degree: degree,
      years_of_experience: yearsOfExperience,
      sports: selectedSports.join(","),
      specialization,
    }
    if (profile.administration_of_education !== administration) {
      body.administration_of_education_id = administration.value
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/update_profile/`,
        body,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function (res) {
        setEdit(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChangedSport = (value) => {
    setSelectedSports((prev) =>
      prev.find((s) => s == value.label) &&
        selectedSports.find((s) => s == value.label)
        ? prev
        : [...prev, value.label]
    );
  };

  useEffect(() => {
    if (profile) {
      if (profile.sports) {
        setSelectedSports(
          profile.sports.length ? profile.sports.split(",") : []
        );
      }
    }
  }, []);

  const [schoolSections, setSchoolSections] = useState([]);

  const [selectedSections, setSelectedSections] = useState([]);

  const fetchSchoolSections = async () => {
    const data = await getSections()
    setSchoolSections(
      data.results.map((s) => ({ label: s.grade, value: s.grade }))
    );
  }

  const fetchAdministrations = async () => {
    const data = await getEducationalAdministrations()
    setAdministrationsList(
      data.results.map((s) => ({ label: s.name, value: s.id }))
    );
  }

  const fetchSchoolSelectedSections = async () => {
    const data = await getTeacherSections()
    setSelectedSections(data.results.map((s) => (s.my_section)))
  }

  useEffect(() => {
    fetchSchoolSections();
    fetchSchoolSelectedSections()
    if (type === 'manager') {
      fetchAdministrations()
    }
  }, []);

  const handleSelectedSection = (value) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/school-section/`,
        {
          section: value.value,
          my_section: value.value,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectedSections((prev) => [...prev, value.label]);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleRemoveSection = (value) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/school-section/delete_school_section/`,
        {
          section: value,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectedSections((prev) => prev.filter((val) => val !== value));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {showModal && (
        <CertificateModal
          showModal={showModal}
          setShowModal={setShowModal}
        // url={profile.LMS_certificate}
        />
      )}
      <div className="rounded-lg shadow-[0px_0px_24px_rgba(35,35,35,0.06)] flex flex-col md:grid grid-cols-2 gap-5 p-8 mb-4 mx-10">
        <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
          <p className="text-suvaGrey-60 text-sm">المؤهل</p>
          {edit ? (
            <CustomSelect
              options={degrees}
              name="degree"
              onChange={(e) => setDegree(e.value)}
              placeholder="المؤهل العلمي"
            />
          ) : (
            <p className="font-bold">{degree || "-"}</p>
          )}
        </div>
        <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
          <p className="text-suvaGrey-60 text-sm">التخصص</p>
          {edit ? (
            <input
              placeholder="ادخل التخصص"
              value={specialization}
              onChange={(e) => setSpecialization(e.target.value)}
              type="text"
              className="form-input focus:ring-0 focus:border-darkViolet-100 rounded-lg placeholder:text-suvaGrey-40 border-gray-300 w-full py-4"
            />
          ) : (
            <p className="font-bold">{specialization || "-"}</p>
          )}
        </div>
        <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
          <p className="text-suvaGrey-60 text-sm">
            المستوى في سلم الوظائف التعليمية
          </p>
          <p className="font-bold">
            {profile.user_type === "M"
              ? "رئيس قسم"
              : profile.user_type === "S"
                ? "مشرف"
                : profile.user_type === "T"
                  ? "معلم"
                  : "-" || "-"}
          </p>
        </div>
        <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
          <p className="text-suvaGrey-60 text-sm">سنوات الخبرة</p>
          {edit ? (
            <div className="w-full relative">
              <p className="absolute left-4 bottom-1/3 text-suvaGrey-40">
                سنوات
              </p>
              <input
                type="number"
                placeholder="0"
                value={yearsOfExperience}
                onChange={(e) => setYearsOfExperience(e.target.value)}
                className="number-arrow focus:ring-0 focus:border-darkViolet-100 form-input rounded-lg placeholder:text-suvaGrey-40 border-gray-300 w-full py-4 px-6"
              />
            </div>
          ) : (
            <p className="font-bold">{yearsOfExperience || "-"} سنوات</p>
          )}
        </div>
        {type == "manager" ? (
          <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
            <p className="text-suvaGrey-60 text-sm">إدارة التعليم</p>
            {edit ?
              <CustomSelect
                options={administrationsList}
                name="sections"
                placeholder="اختر إدارة التعليم"
                onChange={(v) => setAdministration(v)}
                isSearchable={true}
              />
              :
              <p className="font-bold">
                {administration.label || "-"}
              </p>
            }
          </div>
        ) : type == "teacher" ? (
          <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
            <p className="text-suvaGrey-60 text-sm">حالة البرنامج التدريبي</p>
            {profile.course_status ? (
              <>
                <p className="text-Shamrock-100 font-bold">
                  مكتمل <FaRegCheckCircle className="inline stroke-2" />
                </p>
                <button
                  className="shadow-button font-bold w-full py-4 rounded-lg text-persianIndigo-100 bg-white"
                  onClick={() => setShowModal(true)}
                >
                  عرض الشهادة
                </button>
              </>
            ) : (
              <p className="text-[#D91E1E] font-bold">غير مكتمل</p>
            )}
          </div>
        ) : null}
        <div className="bg-[#F9F9F9] flex items-center rounded-lg p-5 gap-5 flex-col">
          <p className="text-suvaGrey-60 text-sm">
            الشهادات التخصصية في التربية البدينة
          </p>

          {edit ? (
            <>
              {/* <button className="shadow-[0px_4px_10px_rgba(0,0,0,0.1)] flex items-center justify-between px-4 w-full py-5 rounded-lg text-persianIndigo-100 bg-white">
                <span>تحميل شهادة</span>
                <FiUpload className="text-xl" />
              </button> */}
              {/* <div className="flex justify-between w-full items-center my-auto"> */}
              <ProfileCertificate allCertificates={allCertificates} setAllCertificates={setAllCertificates} imageURL="" />
              {/* <p>
                  <TbFileText className="inline text-lg ml-2" /> شهادة تربية
                  البدينة.pdf{" "}
                </p> */}
              {/* <p className="font-bold">
                  <TbFileText className="inline text-lg" />{" "}
                  {profile.certificate || "-"}{" "}
                </p> */}

              {/* <FiTrash className="text-red-500 stroke-2 cursor-pointer" /> */}
              {/* </div> */}
            </>
          ) : (
            <>
              {allCertificates.length ? allCertificates.map((c) =>
                <p key={c.id} className="font-bold">
                  <TbFileText className="inline text-lg" />{" "}
                  {c.name || "-"}{" "}
                </p>
              ) : <p className="font-bold">{"-"}
              </p>}
            </>
          )}


        </div>
        {type === "teacher" ?
          <div className=" bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
            <p className="text-suvaGrey-60 text-sm">الفصول الدراسيه</p>
            {edit ? (
              <>
                <CustomSelect
                  options={schoolSections}
                  name="sections"
                  placeholder="اختر الفصل الدراسي"
                  onChange={handleSelectedSection}
                />
                <div className="flex gap-4 w-full flex-wrap">
                  {selectedSections?.map((section, index) => (
                    <div
                      key={section + "-" + index}
                      onClick={() => {
                        handleRemoveSection(section);
                      }}
                      className="border border-Shamrock-100 rounded-lg bg-Shamrock-10 text-Shamrock-100 cursor-pointer py-1 px-2"
                    >
                      {section} <IoClose className="inline" />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="flex gap-4 flex-wrap">
                {selectedSections?.map((section, index) => (
                  <div
                    key={section + "-" + index}
                    className="border border-Shamrock-100 rounded-lg bg-Shamrock-10 text-Shamrock-100 py-1 px-2"
                  >
                    {section}
                  </div>
                ))}

                {selectedSections?.length === 0 && (
                  <p className="text-suvaGrey-60 font-bold">لا يوجد</p>
                )}
              </div>
            )}
          </div>
          : null}
        <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
          <p className="text-suvaGrey-60 text-sm">
            الرياضات التي تمارسها أو مهتم بها
          </p>
          {edit ? (
            <>
              <CustomSelect
                options={sportsOptions.filter(
                  (s) => !selectedSports.includes(s.label)
                )}
                name="sport"
                placeholder="اختر رياضة"
                onChange={handleChangedSport}
              />
              <div className="flex gap-4 w-full flex-wrap">
                {selectedSports.map((s) => (
                  <div
                    key={s}
                    onClick={() =>
                      setSelectedSports((prev) => prev.filter((v) => v != s))
                    }
                    className="border border-Shamrock-100 rounded-lg bg-Shamrock-10 text-Shamrock-100 cursor-pointer  py-1 px-2"
                  >
                    {s} <IoClose className="inline" />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="flex gap-4 flex-wrap">
              {selectedSports.map((s) => (
                <div
                  key={s}
                  className="border border-Shamrock-100 rounded-lg bg-Shamrock-10 text-Shamrock-100 py-1 px-2"
                >
                  {s}
                </div>
              ))}
            </div>
          )}

          {selectedSports.length === 0 && (
            <p className="text-suvaGrey-60 font-bold">لا يوجد</p>
          )}
        </div>

        <div className="col-span-2 mt-8">
          {edit ? (
            <button
              onClick={updateProfile}
              className="w-1/3 mx-auto flex justify-between border border-persianIndigo-100 rounded-lg py-5 px-5"
            >
              <span className="text-persianIndigo-100 font-bold">حفظ</span>
              <FiSave className="text-xl" />
            </button>
          ) : (
            <button
              onClick={() => setEdit(true)}
              className="w-1/3 mx-auto flex justify-between border border-persianIndigo-100 rounded-lg py-5 px-5"
            >
              <span className="text-persianIndigo-100 font-bold">تعديل</span>
              <AiOutlineEdit className="text-xl" />
            </button>
          )}
        </div>
      </div>
    </>
  );
}