import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { TbEdit } from "react-icons/tb";
import { Link, useParams } from "react-router-dom";
import StudentCard from "../components/dashboard/students/Card";
import SectionClassCard from "../components/dashboard/students/SectionClassCards";
import Steps from "../components/dashboard/students/Steps";
import { getSectionsStudents } from "../utils/apis";

const steps = [
  "مراجعة الطلاب",
  "القياسات الجسدية",
  "قياسات اللياقة البدنية",
  "ملخص البيانات",
];

export default function ReviewStudentsPage() {
  useEffect(() => {
    document.title = "البيانات | قياسات الطالب";
  }, []);

  const [data, setData] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      const students = await getSectionsStudents(id);
      setData(students.filter((s) => !s.completed));
    };
    getData();
  }, []);

  const groups = data.reduce((acc, student) => {
    const sectionName = student.section_class?.name || "بدون شعبة";
    if (!acc[sectionName]) {
      acc[sectionName] = [];
    }
    acc[sectionName].push(student);
    return acc;
  }, {});


  return (
    <div className="w-full p-10 min-h-full">
      <Steps currentStepIndex={0} steps={steps} />
      {data.length ? (
        <div className="">
          <div className="h-[450px] mb-10 custom-scroll pl-4 overflow-y-auto flex flex-col gap-4 mt-5 mx-6">
            {Object.entries(groups).map(([sectionName, students]) => (
              <SectionClassCard key={sectionName} sectionId={id} data={data} students={students} sectionName={sectionName} />
            ))}

            <Link
              to={`/dashboard/add-students`} style={{display: "none"}}
              className="w-1/4 mt-auto mr-auto text-persianIndigo-100 border border-persianIndigo-100 font-bold rounded-lg flex justify-between py-5 px-4"
            >
              <span>إضافة طالب</span>
              <BiPlus className="text-2xl" />
            </Link>
          </div>
          <Link
            to={`student/${data[0].id}`}
            state={{ selectedStudents: data.map((s) => s.id), sectionId: id }}
            className="w-1/5 mt-auto mr-auto bg-persianIndigo-100 text-white font-bold rounded-lg flex justify-between py-5 px-4"
          >
            <span>إكمال جميع البيانات</span>
            <TbEdit className="text-2xl" />
          </Link>
        </div>
      ) : null}
    </div>
  );
}
