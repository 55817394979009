
export default function Steps({ currentPageIndex, steps }) {
    return (
        <div className="c-stepper w-4/5 md:w-2/3 mx-auto py-5">
            {steps.map((s, i) => {
                const icon = i == currentPageIndex ? `icon-current` : i < currentPageIndex ? 'icon-prev' : `icon-next`;
                return <div key={s.name} className={`c-stepper__item ${icon}`}>
                    <h3 className="text-sm">{s.label}</h3>
                </div>
            })}
        </div>
    )
}
