import { useState } from 'react'
import ReactSelect from 'react-select'

export default function StudentsFilter({ onChange, options, students, studentsLength }) {

    const CustomOption = ({ innerProps, isDisabled, data }) => {
        return !isDisabled ? (
            <div className='custom-opt' {...innerProps}>
                <span>{data.value}</span>
                {data.value == 'عرض الكل' ?
                    <span className='text-darkViolet-40'>{students.length}</span>
                    :
                    <span className='text-darkViolet-40'>{studentsLength.find((s) => data.value.includes(s.name))?.count || 0}</span>
                }
            </div>
        ) : null;
    }

    const [menuOpen, setMenuOpen] = useState(false)
    return (
        <ReactSelect
            components={{ Option: CustomOption }}
            options={options}
            onChange={onChange}
            onMenuOpen={() => setMenuOpen(true)}
            onMenuClose={() => setMenuOpen(false)}
            defaultValue={[options[0]]}
            isSearchable={false}
            styles={{
                control: (styles) => ({
                    ...styles,
                    backgroundColor: '#F1EAFD',
                    borderRadius: '8px',
                    border: 'unset',
                    display: 'flex',
                    alignItems: 'center',
                    boxShadow: '0',
                    padding: '8px 10px'
                }),
                menu: (styles) => ({ ...styles, margin: 0, backgroundColor: '#F1EAFD', color: '#712FED', border: 'unset', borderTop: 'unset', }),
                indicatorSeparator: () => null,
                dropdownIndicator: (styles) => ({ ...styles, scale: menuOpen ? '-1' : '1', cursor: 'pointer', color: '#712FED' }),
                singleValue: (styles) => ({ ...styles, color: '#712FED', padding: '2px 10px', cursor: 'pointer' }),
                placeholder: (styles) => ({ ...styles, color: '#712FED' })
            }}
        />
    )
}
