import { useEffect, useState } from 'react'
import { getTeacherResultsById, getTeachers } from '../../../utils/apis'
import { Link } from 'react-router-dom'
import { SlSocialDropbox } from 'react-icons/sl'
import TeachersList from './TeachersList'
import { AiOutlinePlus } from 'react-icons/ai'
import Loading from '../../Loading'

export default function SupervisorArchive() {
    const [teachers, setTeachers] = useState([])
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const getData = async () => {
            const allTeachers = await getTeachers()
            const tempList = []
            for (const teacher of allTeachers) {
                const teacherObj = await getTeacherResultsById(teacher.id)
                if (!tempList.find((s) => s.id === teacher.id)) {
                    tempList.push({ ...teacherObj, ...teacher })
                }
            }
            setLoaded(true)
            setTeachers(tempList)
        }
        getData()
    }, [])


    return (
        <>
            <Loading isLoading={loaded} />
            {loaded ?
                <div className="w-full p-10 min-h-screen">
                    {teachers.length === 0 ?
                        <div className="flex mx-32 my-10 items-center justify-center h-full flex-col gap-10">
                            <SlSocialDropbox className='text-9xl text-Shamrock-100' />
                            <p className='text-3xl text-darkViolet-100 font-bold'>ليس لديك معلمين مسجلين</p>
                            <Link to="/dashboard/add-teacher" className='my-5 px-5 flex justify-center items-center bg-persianIndigo-100 shadow-button rounded-lg text-white font-light h-14'>إبدأ بتسجيل المعلمين</Link>
                        </div>
                        :
                        <>
                            <p className="font-bold text-persianIndigo-100 text-2xl mb-6">قائمة المعلمين</p>
                            <div className="overflow-y-auto custom-scroll h-[60vh] flex flex-col gap-2">
                                {teachers.map(c => (
                                    <TeachersList data={c} key={c.id} />
                                ))}
                            </div>
                            <Link to="/dashboard/add-teacher" className='px-5 py-4 flex justify-between rounded-lg w-1/4 mt-10 mr-auto items-center text-persianIndigo-100 border border-persianIndigo-100 font-bold'>
                                <span>إضافة معلم</span>
                                <AiOutlinePlus className='text-2xl' />
                            </Link>
                        </>
                    }
                </div> : null}
        </>
    )
}
