
import { createContext, useContext, useState } from "react";
import Cookies from 'js-cookie';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(Cookies.get("token"));
    const [type, setType] = useState(Cookies.get("type"));

    const login = (data) => {
        Cookies.set("token", data.token);
        Cookies.set("type", data.type);
        setToken(data.token);
        setType(data.type)
    };


    const logout = () => {
        setToken(null);
        setType(null)
        Cookies.remove("token");
        Cookies.remove("type");
    };

    const userType = () => {
        if (type.toUpperCase() === 'M') {
            return 'manager'
        } else if (type.toUpperCase() === 'T') {
            return 'teacher'
        } else if (type.toUpperCase() === 'S') {
            return 'supervisor'
        } else if (type.toUpperCase() === 'A') {
            return 'admin'
        } else {
            return ''
        }
    }

    return (
        <AuthContext.Provider value={{ token, login, logout, userType }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};