import { useEffect, useState } from "react"
import { IoMdCheckmarkCircleOutline } from "react-icons/io"
import { useSearchParams, useNavigate } from "react-router-dom"
import axios from "axios"
import logo from "../assets/images/logo/tamheed-white.svg"
import { BiErrorCircle } from "react-icons/bi"

export default function ChangeEmailVerificationPage() {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "تمهيد | تأكيد تغيير البريد الإلكتروني";
    }, []);

    const [searchParams] = useSearchParams();
    const code = searchParams.get('code')
    const [showError, setshowError] = useState(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/email/change/verify/?code=${code}`).then((res) => {
            setTimeout(() => {
                navigate('/login')
            }, 3000);
        }).catch((e) => {
            setshowError(true)
        })
    }, [])



    return (
        <div className="custom-bg flex flex-col justify-around pt-6 sm:py-16">
            <img src={logo} alt="Logo" className="mx-auto mb-7 w-36 sm:w-44" />

            <div className="mx-auto bg-white rounded-lg my-auto flex flex-col gap-5 items-center py-10 px-24">
                {showError ?
                    <>
                        <BiErrorCircle className="text-red-500" size="30px" />
                        <span className="text-persianIndigo-100 text-xl sm:text-3xl font-bold">حدث خطأ ما، الرجاء المحاولة لاحقًا</span>
                    </> :
                    <>
                        <IoMdCheckmarkCircleOutline className="text-Shamrock-100" size="30px" />
                        <span className="text-persianIndigo-100 text-xl sm:text-3xl font-bold">تم تغيير بريدك الإلكتروني بنجاح</span>
                    </>
                }
            </div>
        </div>
    )
}
