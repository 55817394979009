import { useEffect, useState } from 'react'
import { getManagers, getMangerResultsById } from '../../../utils/apis'
import Loading from '../../Loading'
import AdminList from './AdminList'

export default function AdminArchive() {
    const [managers, setManagers] = useState([])
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const getData = async () => {
            const allManagers = await getManagers();
            const tempList = []
            for (const manager of allManagers) {
                const managerObj = await getMangerResultsById(manager.id)
                if (!tempList.find((s) => s.id === manager.id)) {
                    tempList.push({ ...managerObj, ...manager })
                }
            }
            setLoaded(true)
            setManagers(tempList)
        }
        getData()
    }, [])

    return (
        <>
            <Loading isLoading={loaded} />
            {loaded ?
                <div className="w-full p-5 sm:p-10 min-h-screen flex flex-col">
                    {managers.length === 0 ?
                        null
                        :
                        <>
                            <p className="font-bold text-persianIndigo-100 text-2xl mb-10">قائمة رؤساء الأقسام</p>
                            {managers.map(c => (
                                <AdminList data={c} key={c.id} />
                            ))}
                        </>
                    }
                </div> : null}
        </>
    )
}
