import ManagerArchive from "../../components/dashboard/Manager/ManagerArchive";
import SupervisorArchive from "../../components/dashboard/supervisor/SupervisorArchive";
import TeacherArchive from "../../components/dashboard/Teacher/archive";
import { useAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import WalkThrough from "../../components/modals/WalkThrough";
import AdminArchive from "../../components/dashboard/Admin/AdminArchive";

export default function ArchivePage() {
  useEffect(() => {
    document.title =
      userType() === "manager"
        ? "تمهيد | قائمة المشرفين"
        : userType() === "supervisor"
          ? "تمهيد | قائمة المعلمين"
          : userType() === "admin"
            ? "تمهيد | قائمة رؤساء الأقسام"
            : "تمهيد | تسجيل القياسات";
  }, []);

  const outletContext = useOutletContext();
  const [popuopPos, setPopuopPos] = outletContext.popuopPos;
  const isWalkthrough = outletContext.isWalkthrough;
  const { userType } = useAuth();
  const [stepsIndex, setStepsIndex] = useState(0);

  return (
    <div>
      <div className="relative">
        {isWalkthrough && stepsIndex == 0 ? (
          <WalkThrough
            title="لوحة القيادة"
            number={`1/2`}
            stepsIndex={2}
            subTitle="تسجيل القياسات"
            text="في هذة الصفحة سوف يعرض لك  جميع بينات الطلاب مصنفة حسب الصف."
            colorOne
            onclosenow={() => {
              setStepsIndex(1);
              setPopuopPos("reports");
            }}
            top="80px"
            right="5%"
            arrowClass="top-center"
          />
        ) : null}
      </div>
      {userType() == "teacher" ? (
        <TeacherArchive />
      ) : userType() === "manager" ? (
        <ManagerArchive />
      ) : userType() === "admin" ? (
        <AdminArchive />
      ) : (
        <SupervisorArchive />
      )}
    </div>
  );
}
