import { IoFemaleSharp, IoMaleSharp } from "react-icons/io5";

export default function PersonalInfo({ teacher }) {

    return (
        <div className='rounded-lg shadow-[0px_0px_24px_rgba(35,35,35,0.06)] flex flex-col md:grid grid-cols-2 gap-5 p-8 mb-4 mx-10'>
            {/* <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                <p className='text-suvaGrey-60 text-sm'>رقم الأحوال/الإقامة</p>
                <p className='font-bold'>{teacher ? teacher.national_id || '-' : '-'}</p>
            </div> */}
            {/* <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                <p className='text-suvaGrey-60 text-sm'>تاريخ الميلاد</p>
                <p className='font-bold'>{teacher ? teacher.date_of_birth || teacher.date_of_birth_hijri || '-' : '-'}</p>
            </div> */}
            <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                <p className='text-suvaGrey-60 text-sm'>الجنس</p>
                {teacher ? teacher.gender === 'M' ?
                    <p>
                        <IoMaleSharp className='font-bold mx-auto text-suvaGrey-100 text-2xl inline ml-2' />
                        <span className='font-bold'>ذكر</span>
                    </p>
                    : teacher.gender === 'F' ?
                        <p>
                            <IoFemaleSharp className='font-bold mx-auto text-suvaGrey-100 text-2xl inline ml-2' />
                            <span className='font-bold'>أنثى</span>
                        </p>
                        : <p>-</p> : <p>-</p>}
            </div>
            <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                <p className='text-suvaGrey-60 text-sm'>الجنسية</p>
                <p className='font-bold'>{teacher ? teacher.nationality || '-' : '-'}</p>
            </div>
            <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                <p className='text-suvaGrey-60 text-sm'>رقم الجوال</p>
                <p className='font-bold' dir='ltr'><span className='text-suvaGrey-100'>+966</span> <span>{teacher ? teacher.phone_number || '-' : ''}</span></p>
            </div>
            <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
                <p className='text-suvaGrey-60 text-sm'>البريد الإلكتروني</p>
                <p className='font-bold'>{teacher ? teacher.email || '-' : '-'}</p>
            </div>
        </div>
    )
}
