import RunsSVG from "./SVGs/RunsSVG"

export default function Runs({ data }) {
    return (
        <div className=" bg-[#240a63] xl:bg-[url('/src/assets/images/dashboard/report/Vector.svg')] bg-no-repeat xl:bg-contain rounded-2xl xl:grid grid-cols-3 text-white xl:mx-5 bg-[url('/src/assets/images/dashboard/report/bg-2.svg')]  bg-contain">
            <div className="xl:col-span-2 xl:pb-10">
                <RunsSVG data={{ first: data[0], seconed: data[1], third: data[2] }} />
            </div>
            <div className="pl-5 pr-5 xl:pr-0 xl:pl-3 pt-8 pb-5 xl:col-span-1 xl:mt-6">
                {data.map((s, i) => {
                    if (i > 2) {
                        return (
                            <div className="w-full" key={i}>
                                <div className="grid grid-flow-col items-center xl:gap-x-6 text-center">
                                    <span className="font-light text-[10px] lg:text-xs text-center">{i + 1}</span>
                                    <span className="font-bold text-[12px] lg:text-sm text-center">
                                        {s?.student_first_name || ''} {" "}
                                        {s?.student_father_name || ''} {" "}
                                        {s?.student_last_name || ''}
                                    </span>
                                    <span className="font-light text-[10px] lg:text-xs text-center">{s?.student_grade}</span>
                                    <span className="font-bold text-[10px] lg:text-xs text-center">{Math.round(s?.total_run) || 0} د/20 متر</span>
                                </div>
                                {i > 2 &&
                                    <hr className="opacity-40 my-4" />
                                }
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    )
}
