import { useEffect, useState } from "react";


const steps = {
    standing: '31,108',
    sitting: '109,143',
    wingspan: '143,186',
    weight: '187',
}

export default function VideoModal({ showModal, lesson = 1, isJump = false, step, isFirst = false }) {
    const [url, setUrl] = useState("")
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        setIsLoaded(true)
    }, [])

    useEffect(() => {
        if (lesson == 1) {
            setUrl(`https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/01.mp4#t=${steps[step]}`)
        } else {
            setUrl(`https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/0${lesson}.mp4#t=${step}`)
        }
    }, [isLoaded])

    return (
        <>
            {showModal && isLoaded ? (
                <>
                    <div className={`flex flex-col z-[100] bg-darkViolet-100 outline-none w-96 ${isJump ? '-top-[470px]' : isFirst ? '-top-1' : '-top-36'} mx-auto teeest absolute`}>
                        <div className="bg-darkViolet-100">
                            <video controls autoPlay className={`mx-auto pb-3 h-[212px]`}>
                                <source src={url} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
}