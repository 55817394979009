import { Field } from 'formik'
import React, { useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import { BiErrorCircle } from 'react-icons/bi'

export default function CustomPasswordInput({ errors, touched, name, label }) {
    const [showPass, setShowPass] = useState(false)
    return (
        <div className="text-HawkesBlue-100">
            <label htmlFor={name} className="text-HawkesBlue-100 font-bold">{label}</label>
            <div className="relative text-HawkesBlue-40 focus-within:text-HawkesBlue-90 block">
                <AiOutlineEye onClick={() => setShowPass((s) => !s)} className="cursor-pointer w-8 h-8 absolute bottom-0.5 transform -translate-y-1/2 left-5" />
                <Field
                    className={`border ${errors[name] && touched[name] ? 'border-[#DB0000] placeholder:text-[#DB0000]' : 'border-[#D6D9E0]'} w-full py-6 mt-3 placeholder:text-[#D6D9E0] px-6 text-black rounded-lg form-input focus:ring-0 focus:border-darkViolet-100`}
                    type={showPass ? "text" : "password"}
                    id={name}
                    name={name}
                    placeholder="********"
                />
            </div>
            <div className="h-12 pt-4">
                {errors[name] && touched[name] ? (
                    <div className="flex gap-1">
                        <BiErrorCircle className="text-error text-2xl font-light" />
                        <span className="text-error">{errors[name]}</span>
                    </div>
                ) : null}
            </div>
        </div>
    )
}
