import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { BiChevronRight, BiLink } from "react-icons/bi";
import { BsCheck2Circle } from "react-icons/bs";
import { FiSend } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import AddTeacherList from "../components/dashboard/supervisor/Teachers/AddStudentsList";
import { useAuth } from "../context/AuthContext";
import { createLink, getAllSupervisors, getCurrentProfile } from "../utils/apis";
import empybox from "../assets/images/dashboard/emptybox.svg";
import Loading from "../components/Loading";
import copy from 'copy-to-clipboard';


export default function NewTacherPage() {
  useEffect(() => {
    document.title = "تمهيد | إضافة المعلمين";
  }, []);

  const [teachers, setTeachers] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const { token } = useAuth();
  const [showMessage, setShowMessage] = useState(false);
  const [copied, setCopied] = useState(false);
  const [privateLink, setPrivateLink] = useState()
  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  const handleLink = async () => {
    const link = await createLink()
    copy(`${window.location.origin}/register/${link.invite_token}/`);
    setCopied(true)
  }

  useEffect(() => {
    const getData = async () => {
      setLoaded(true)
      const teachersAll = await getAllSupervisors();
      setTeachers(teachersAll);
    };
    getData();
  }, []);

  const handleSend = async () => {
    const user = await getCurrentProfile();
    const content = `<span className="sender">${user.profile.name}</span> قام بدعوتك، لقبول الدعوة`;
    const url = `accept/?id=${user.profile.user}`;
    const title = "دعوة";
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/send_invites/`,
        { reciever: selectedTeachers, content, title, url },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function (res) {
        setShowMessage(true);
        console.log(res);
        setTimeout(() => {
          navigate("/dashboard");
        }, 2500);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  return (
    <>
      {showMessage && (
        <div className="flex absolute inset-0 bg-[#51515126] z-50 outline-none focus:outline-none">
          <div className="bg-white shadow-[0px_0px_23px_rgba(35,35,35,0.16)] w-2/5 h-1/4 mx-auto my-auto rounded-lg flex gap-4 justify-center flex-col items-center">
            <BsCheck2Circle className="text-Shamrock-100 text-3xl stroke-1" />
            <p className="font-bold text-persianIndigo-100 text-2xl">
              تم إرسال دعوات للمعلمين
            </p>
            <p className="font-light text-persianIndigo-100">
              سيتم عرضهم عند تسجيلهم في المنصة
            </p>
          </div>
        </div>
      )}

      <Loading isLoading={loaded} />
      {loaded ?
        <div className={`px-5 py-6 overflow-y-auto h-screen flex flex-col ${teachers.length ? "" : "empty-bg"}`}>
          <Link to={"/dashboard/archive"} className="mb-4 text-lg block">
            <BiChevronRight />
          </Link>
          <p className="text-persianIndigo-100 font-bold text-lg">
            إضافة المعلمين
          </p>


          {teachers.length ? (
            <>
              <button onClick={() => handleLink()} className={`px-3 py-2 flex justify-between rounded-lg w-40 mt-2 mr-auto items-center text-persianIndigo-100 border ${copied && privateLink !== "" ? "border-Shamrock-100" : "border-persianIndigo-100"} text-sm`}>
                {copied && privateLink !== "" ?
                  <>
                    <span className="text-Shamrock-100">تم نسخ الرابط بنجاح</span>
                    <BiLink className='text-lg text-Shamrock-100' />
                  </>
                  :
                  <>
                    <span>إنشاء رابط خاص</span>
                    <BiLink className='text-lg' />
                  </>
                }
              </button>
              <div>
                <AddTeacherList
                  teachers={teachers}
                  setSelectedTeachers={setSelectedTeachers}
                  placeholder="ابحث عن طريق اسم المعلم"
                />
              </div>

              <button
                onClick={handleSend}
                className="w-1/3 mt-auto mr-auto bg-persianIndigo-100 text-white font-bold rounded-lg flex items-center justify-between py-5 px-4"
              >
                <span>إرسال الدعوات</span>
                <FiSend className="text-xl -scale-x-100" />
              </button>
            </>
          ) : (
            <div className="flex mx-32 mt-18 items-center justify-center flex-col gap-10">
              <img src={empybox} className="text-8xl text-Shamrock-100 mt-10" />
              <p className="text-3xl text-darkViolet-100 font-bold">
                لا يوجد معلمين بحاجة للإضافة
              </p>
              <button onClick={() => handleLink()} className={`px-3 mx-auto py-2 flex justify-between rounded-lg w-40 mt-2 mr-auto items-center text-persianIndigo-100 border ${copied && privateLink !== "" ? "border-Shamrock-100" : "border-persianIndigo-100"} text-sm`}>
                {copied && privateLink !== "" ?
                  <>
                    <span className="text-Shamrock-100">تم نسخ الرابط بنجاح</span>
                    <BiLink className='text-lg text-Shamrock-100' />
                  </>
                  :
                  <>
                    <span>إنشاء رابط خاص</span>
                    <BiLink className='text-lg' />
                  </>
                }
              </button>
            </div>
          )}
        </div>
        : null}
    </>
  );
}
