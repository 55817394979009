import { FiHelpCircle } from "react-icons/fi";
import weightImg from '../../../../assets/images/dashboard/measurement/weight.svg'
import weightImgComplete from '../../../../assets/images/dashboard/measurement/weight-complete.svg'
import { Field } from "formik";
import { useState } from "react";
import Slider from "./Slider";
import VideoModal from "../VideoModal";
import { BiErrorCircle } from "react-icons/bi";


export default function Weight({ value, touched, errors }) {
    const [showModal, setShowModal] = useState(false)
    const [active, setActive] = useState(false)
    return (
        <div className="flex flex-col items-center gap-2 relative" onClick={() => showModal ? setShowModal(false) : null}>
            {showModal && <VideoModal showModal={showModal} setShowModal={setShowModal} lesson="1" step={'weight'} />}
            {errors['weight'] && touched['weight'] ?
                <img src={weightImg} className="h-20 w-20 mx-auto" />
                :
                <img src={weightImgComplete} className="h-20 w-20 mx-auto" />
            }
            <div className="flex items-center gap-2 text-xl my-10">
                <p>وزن الجسم</p>
                <FiHelpCircle onClick={() => setShowModal(true)} className='text-darkViolet-100 text-xl  cursor-pointer' />
            </div>
            <Slider fieldName="body_weight" startPoint={21} value={value} />

            <div className="flex justify-around w-3/4 mt-10">
                <div onFocus={() => setActive(true)} onBlur={() => setActive(false)} className={`${active ? 'border-2 border-darkViolet-100' : errors['body_weight'] && touched['body_weight'] ? 'border-[#D91E1E]' : 'border border-suvaGrey-40'} text-darkViolet-100 font-bold rounded-lg px-4 py-5 w-1/4 mx-auto flex items-center justify-between`}>
                    <Field
                        className="border-none focus:ring-0 number-arrow w-24 placeholder:text-suvaGrey-40 sm:text-2xl sm:placeholder:text-2xl text-xl placeholder:text-xl pl-5"
                        placeholder="00"
                        min="0"
                        type="number"
                        name="body_weight"
                    />
                    <span className={`${value ? 'text-darkViolet-100' : errors['body_weight'] && touched['body_weight'] ? 'text-[#D91E1E]' : 'text-suvaGrey-40'} text-xl sm:text-2xl`}>كغم</span>

                </div>

            </div>
            {errors['body_weight'] && touched['body_weight'] ?
                <p className='text-[#D91E1E] mt-1 flex items-center gap-2'> <BiErrorCircle />هذا الحقل مطلوب</p>
                : null}
        </div>
    )
}
