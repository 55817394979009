import React, { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import CustomRunField from './CustomRunField'

export default function Third({ isRun3Enabled, setIsRun3Enabled, value, handleTime }) {
    const [isActive, setIsActive] = useState(false)
    const [run3Sec, setRun3Sec] = useState(value ? value : '')
    const [run3MilliSec, setRun3MilliSec] = useState(value ? value : '')

    const [isInvalid, setIsInvalid] = useState(false)

    useEffect(() => {
        if (value) {
            setRun3Sec(handleTime(value)[0])
            setRun3MilliSec(handleTime(value)[1])
        }
    }, [])

    return (
        <div
            onFocus={() => setIsActive(true)}
            onBlur={() => {
                setIsActive(false)
            }}
            className={`border ${isInvalid && isRun3Enabled ? 'border-[#D91E1E] text-[#D91E1E]' : isActive ? 'border-darkViolet-100 text-darkViolet-100' : isRun3Enabled ? 'border-suvaGrey-40 text-darkViolet-100' : 'text-suvaGrey-10 border-suvaGrey-10'} font-bold rounded-lg mx-auto px-4 py-3 flex items-center justify-center w-2/3 max-w-[856px]`}>
            <CustomRunField name="run_time_3" time={value} miliSec={run3MilliSec} seconds={run3Sec} />
            <div className="mx-auto flex justify-end items-center gap-2 w-1/2">
                <input
                    className={`disabled:bg-transparent border-none focus:ring-0 text-xl sm:text-2xl sm:placeholder:text-2xl placeholder:text-xl p-1 w-16 ${isActive ? 'placeholder:text-suvaGrey-40' : 'placeholder:text-suvaGrey-10'} text-left number-arrow`}
                    min="0"
                    dir="ltr"
                    max="60"
                    type="number"
                    placeholder="00"
                    disabled={!isRun3Enabled}
                    value={run3MilliSec}
                    onChange={(e) => setRun3MilliSec(e.target.value)}
                />
                {isRun3Enabled ?
                    <span className='sm:text-xl text-2xl text-suvaGrey-40 px-1'>:</span>
                    :
                    <FaPlus className='text-suvaGrey-40 my-auto mx-1 cursor-pointer text-sm sm:text-base' onClick={() => setIsRun3Enabled(true)} />
                }
                <input
                    className={`disabled:bg-transparent border-none focus:ring-0 text-xl sm:text-2xl sm:placeholder:text-2xl placeholder:text-xl p-1 w-16 text-right ${isActive ? 'placeholder:text-suvaGrey-40' : 'placeholder:text-suvaGrey-10'} number-arrow`}
                    min="0"
                    dir="ltr"
                    max="60"
                    type="number"
                    placeholder="00"
                    disabled={!isRun3Enabled}
                    value={run3Sec}
                    onChange={(e) => setRun3Sec(e.target.value)}
                />
            </div>
            <span className={`text-center sm:text-xl w-1/6 mr-auto ${isInvalid && isRun3Enabled ? 'text-[#D91E1E]' : isActive || run3MilliSec || run3Sec ? 'text-darkViolet-100' : 'text-suvaGrey-40'}`}>ثانية</span>
        </div>
    )
}
