import { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import StudentCard from './StudentCard'
import { AiOutlinePlus } from 'react-icons/ai'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { Link } from 'react-router-dom'
import { BiCheck } from 'react-icons/bi'
import { getSectionsStudents, getSectionClasses } from '../../utils/apis'
import { useDisclosure } from '@mantine/hooks'
import { Modal } from '@mantine/core'
import axios from 'axios'
import Cookies from 'js-cookie'
import SectionClassCard from './SectionClassCard'

export default function ClassCard({ setIsClassExpanded, isClassExpanded, data, setleee, lee }) {
    const [expand, setExpand] = useState(false)
    const [students, setStudents] = useState()
    const [allStudents, setAllStudents] = useState([])
    const [search, setSearch] = useState('')
    const [opened, { open, close }] = useDisclosure(false);
    const [value, setValue] = useState('')
    const [sectionClasses, setSectionClasses] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        setExpand(lee === data.id ? true : false)
    }, [lee])

    useEffect(() => {
        const getData = async () => {
            const sections = await getSectionsStudents(data.id)
            setStudents(sections)
            setAllStudents(sections)
            const classes = await getSectionClasses()
            setSectionClasses(classes.filter((s) => s.section === data.my_section).map((s) => ({ label: s.name, value: s.id })))
        }
        getData()
    }, [])


    const handleExpanding = () => {
        setIsClassExpanded(expand ? false : true)
        setleee((prev) => prev == data.id ? 0 : data.id)
    }

    const handleSearch = (name) => {
        setSearch(name)
        setAllStudents(students.filter((s) => `${s.first_name} ${s.father_name} ${s.last_name}`.includes(name.toLowerCase())))
    }

    const handleSubmit = async () => {
        setIsSubmitting(true)
        axios.post(`${process.env.REACT_APP_API_URL}/section-class/`, {
            section: data.my_section,
            name: value,
        }, {
            headers: {
                Authorization: `Token ${Cookies.get("token")}`,
            }
        })
            .then(function (res) {
                close()
                setValue("")
                setSectionClasses([res.data, ...sectionClasses])
                setIsSubmitting(false)
            })
            .catch(function (error) {
                console.log(error);
                setIsSubmitting(false)
            })
    }

    const groups = allStudents.reduce((acc, student) => {
        const sectionName = student.section_class?.name || "بدون شعبة";
        if (!acc[sectionName]) {
            acc[sectionName] = [];
        }
        acc[sectionName].push(student);
        return acc;
    }, {});

    return (
        <>
            <Modal opened={opened} onClose={close} dir="rtl" withCloseButton={false}>
                <p className='text-ceter text-lg'>إضافة شعبة</p>
                <hr className='opacity-60 w-3/4 my-2' />
                <label htmlFor="class-name" className='font-bold'>اسم الشعبة:</label>
                <input id="class-name" type="text" value={value} onChange={(e) => setValue(e.target.value)} className='form-input focus:ring-0 mr-3 focus:border-darkViolet-100 rounded-lg border-gray-300 w-2/3 my-3 py-2' />
                <div className="flex gap-4 w-2/3 mt-2">
                    <button onClick={handleSubmit} className='bg-persianIndigo-100 text-white py-2 w-2/3 px-6 rounded-md'>
                        {isSubmitting ?
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-persianIndigo-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg> : 'حفظ'}
                    </button>
                    <button onClick={() => {
                        close()
                        setValue('')
                    }} className='text-persianIndigo-100 border border-persianIndigo-100 py-2 w-2/3 px-6 rounded-md'>إلغاء</button>
                </div>
            </Modal>
            <div onClick={handleExpanding} className={`shadow-[0px_0px_16px_rgba(35,35,35,0.08)] ${expand ? 'bg-Shamrock-100' : 'bg-white'} flex items-center gap-5 px-2 lg:px-8 py-4 my-2 cursor-pointer rounded-md`}>
                {!expand && !isClassExpanded &&
                    <div className="font-light text-xs text-suvaGrey-40">
                        <p>{new Date(data.last_change).toLocaleTimeString([], { timeStyle: 'short' })}</p>
                        <p>{new Date(data.last_change).toLocaleDateString('ar-MA', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                    </div>
                }
                <div className={`border-l-2 ${expand ? 'border-l-Shamrock-80 border-r-Shamrock-80 lg:px-4 px-2 lg:w-1/2' : isClassExpanded && !expand ? 'w-1/2 px-2' : 'border-l-[#F9F9F9] border-r-[#F9F9F9] border-r-2 px-10 w-1/3'}`}>
                    <p className={`lg:text-xl font-bold ${expand ? 'text-white' : 'text-darkViolet-100'}`}>{data.my_section}</p>
                    <p className={`text-[10px] lg:text-xs mt-2 font-bold ${expand ? 'text-Shamrock-40' : 'text-persianIndigo-100'}`}>{data.total_students} طالب</p>
                </div>
                {
                    data.completed_students == 0 && data.incompleted_students == 0 ?
                        <div className={`lg:w-6 lg:h-6 w-5 h-5 min-w-[1.25rem] min-h-[1.25rem] lg:min-w-[1.5rem] lg:min-h-[1.5rem] border-4 rounded-full ${expand ? 'border-white' : 'text-suvaGrey-80'}`}></div>
                        : data.incompleted_students == 0 ?
                            <div className={`w-6 h-6 border-4 rounded-full flex items-center justify-center ${expand ? 'border-white' : 'border-Shamrock-100'}`}>
                                <BiCheck className={`${expand ? 'text-white' : 'text-Shamrock-100'} stroke-2`} />
                            </div>
                            :
                            <div className="w-6 h-6">
                                <CircularProgressbar
                                    counterClockwise
                                    value={`${(100 * data.incompleted_students) / data.total_students}`} strokeWidth="20"
                                    styles={buildStyles({
                                        pathColor: '#D91E1E',
                                        trailColor: '#D6D9E0',
                                        strokeLinecap: 'butt',
                                    })}
                                />
                            </div>
                }
                <div className=''>
                    <p className={`text-[10px] lg:text-xs ${expand ? 'text-white' : 'text-suvaGrey-80'}`}> {data.completed_students} بيانات طلاب مكتملة</p>
                    {data.completed_students == 0 && data.incompleted_students == 0 ?
                        <p className={`text-[10px] lg:text-xs mt-2 font-bold ${expand ? 'text-white' : 'text-suvaGrey-80'}`}> {data.incompleted_students} طالب متبقي</p>
                        : data.incompleted_students == 0 ?
                            <p className={`text-[10px] lg:text-xs mt-2 font-bold ${expand ? 'text-darkViolet-100' : 'text-Shamrock-100'}`}> {data.incompleted_students} طالب متبقي</p>
                            :
                            <p className={`text-[10px] lg:text-xs mt-2 font-bold ${expand ? 'text-darkViolet-100' : 'text-[#D91E1E]'}`}> {data.incompleted_students} طالب متبقي</p>
                    }
                </div>
                {!expand && <FiChevronLeft className='text-HawkesBlue-100 text-3xl mr-auto' />}
            </div>
            {expand &&
                <div className='absolute top-0 left-0 h-screen w-[45%] px-5 py-8 bg-[#F9F9F9] overflow-y-auto custom-scroll'>
                    <div className="search-input-container bg-white">
                        <input type="text" className="search-input" placeholder="ابحث عن طريق اسم الطالب" value={search} onChange={(e) => handleSearch(e.target.value)} />
                    </div>
                    {Object.entries(groups).map(([sectionName, students]) => (
                        <SectionClassCard key={sectionName} sectionName={sectionName} students={students} data={data} sectionClasses={sectionClasses} />
                    ))}


                    <button onClick={open} to="/dashboard/add-students" className='px-5 py-5 flex justify-between rounded-lg w-full my-4 items-center text-persianIndigo-100 border border-persianIndigo-100 font-bold'>
                        <span>إضافة شعبة</span>
                        <AiOutlinePlus className='text-2xl' />
                    </button>


                    <Link to="/dashboard/add-students" className='px-5 py-5 flex justify-between rounded-lg w-full my-4 items-center text-persianIndigo-100 border border-persianIndigo-100 font-bold' style={{display: "none"}}>
                        <span>إضافة طالب</span>
                        <AiOutlinePlus className='text-2xl' />
                    </Link>
                </div>
            }
        </>
    )
}