import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { BsCheck } from "react-icons/bs";
import { FiChevronLeft } from "react-icons/fi";
import handlePercentage from "../../../hooks/handleRecordPercentage";

export default function StudentsListModal({ showModal, setShowModal, students, studentId }) {


    return (
        <>
            {showModal ? (
                <>
                    <div className="flex flex-col z-[100] outline-none p-3 w-1/3 bg-[#F9F9F9] rounded-r-lg fixed left-0 top-32 focus:outline-none shadow-[0px_0px_8px_rgba(35,35,35,0.16)]">
                        <div className="flex justify-between p-2 cursor-pointer w-full gap-7 items-center font-bold text-persianIndigo-100" onClick={() => setShowModal((prev) => !prev)}>
                            <span>قائمة الطلاب</span>
                            <FiChevronLeft className="text-2xl" />
                        </div>
                        <div className="flex flex-col w-full">
                            {students.map((s, i) => (
                                <div key={s.id}
                                    className="shadow[0px_4px_6px_rgba(35,35,35,0.06)] flex items-center gap-5 rounded-md px-8 py-3 border-b my-2"
                                >
                                    <p className={`${s.id == studentId ? 'text-darkViolet-100' : 'text-suvaGrey-100'}`}>{i + 1}</p>
                                    <div className="w-6">
                                        {s.completed ?
                                            <BsCheck className='inline border-4 stroke-1 border-Shamrock-100 rounded-full text-Shamrock-100 text-lg h-6 w-6' />
                                            :
                                            <CircularProgressbar
                                                value={handlePercentage(s)} strokeWidth="15"
                                                counterClockwise
                                                styles={buildStyles({
                                                    pathColor: '#D91E1E',
                                                    trailColor: '#D6D9E0',
                                                    strokeLinecap: 'butt',
                                                })}
                                            />
                                        }
                                    </div>
                                    <p className={`font-bold ${s.id == studentId ? 'text-darkViolet-100' : 'text-suvaGrey-100'} `}>{s.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
}