import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { Link } from 'react-router-dom'
import { BiCheck } from 'react-icons/bi'

export default function AdminList({ data }) {

    return (
        <>
            <Link to={`/dashboard/manager/${data.id}`} className={`shadow-[0px_0px_16px_rgba(35,35,35,0.08)] flex items-center gap-5 px-8 py-4 my-2 cursor-pointer rounded-md`}>
                <div className={`border-l-2 w-1/2 md:w-1/3 border-l-[#F9F9F9] border-r-[#F9F9F9] px-5`}>
                    <p className="text-xl font-bold truncate text-darkViolet-100">{data.name}</p>
                    <p className={`text-xs mt-2 text-persianIndigo-100`}>
                        <span className='pl-3'>{data.all_teacher} مشرفون</span>
                        <span className='pl-3'>{data.all_teacher} معلمون</span>
                        <span>{data.students.all_student} طالب</span>
                    </p>
                </div>
                {
                    data.all_student == 0 ?
                        <div className={`w-6 h-6 border-4 rounded-full flex items-center justify-center border-suvaGrey-40`}></div> :
                        data.total_student_not_completed == 0 ?
                            <div className={`w-6 h-6 border-4 rounded-full flex items-center justify-center border-Shamrock-100`}>
                                <BiCheck className="text-Shamrock-100 stroke-2" />
                            </div>
                            :
                            <div className="w-6">
                                <CircularProgressbar
                                    value={`${(100 * data.students.total_student_not_completed) / data.students.all_student}`} strokeWidth="20"
                                    styles={buildStyles({
                                        pathColor: '#D91E1E',
                                        trailColor: '#D6D9E0',
                                        strokeLinecap: 'butt',
                                    })}
                                />
                            </div>
                }
                <div>
                    <p className={`text-xs text-suvaGrey-80`}> {data.students.total_student_completed} بيانات طلاب مكتملة</p>
                    {
                        data.students.total_student_completed == 0 ?
                            <p className={`text-xs mt-2 font-bold text-suvaGrey-40`}> {data.students.total_student_not_completed} طالب متبقي</p> :
                            data.students.total_student_not_completed == 0 ?
                                <p className={`text-xs mt-2 font-bold text-Shamrock-100`}> {data.students.total_student_not_completed} طالب متبقي</p>
                                :
                                <p className={`text-xs mt-2 font-bold  text-[#D91E1E]`}> {data.students.total_student_not_completed} طالب متبقي</p>
                    }
                </div>
            </Link>
        </>
    )
}
