import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { getMangerCards, getSupervisorCards, getTeacherCards } from "../../../utils/apis";

export default function JumpStatsCard({ setActiveStat, setTotalJump, selectedSection, selectedOffices, selectedTeachers, selectedSupervisor }) {
    const [statistic, setStatistic] = useState(null)

    const { userType } = useAuth();


    useEffect(() => {
        const getData = async () => {
            if (userType() === 'teacher') {
                const data = await getTeacherCards("total_jump_h", selectedSection !== "" ? `student__school_section__section=${selectedSection}` : "")
                const data2 = await getTeacherCards("total_jump_v", selectedSection !== "" ? `student__school_section__section=${selectedSection}` : "")
                setStatistic({ total_jump_h: data.total_jump_h, total_jump_v: data2.total_jump_v })
                setTotalJump({ total_jump_h: data.total_jump_h, total_jump_v: data2.total_jump_v })
            } else if (userType() === 'supervisor') {
                const param = `${selectedSection ? `student__school_section__section=${selectedSection}&` : ""}${selectedOffices ? `student__school_section__teacher__profile__educational_office=${selectedOffices}&` : ""}${selectedTeachers ? `${selectedTeachers}&` : ""}${selectedSupervisor ? `${selectedSupervisor}&` : ""}`
                const data = await getSupervisorCards("total_jump_h", param)
                const data2 = await getSupervisorCards("total_jump_v", param)
                setStatistic({ total_jump_h: data.total_jump_h, total_jump_v: data2.total_jump_v })
                setTotalJump({ total_jump_h: data.total_jump_h, total_jump_v: data2.total_jump_v })
            } else if (userType() === 'manager') {
                const param = `${selectedOffices ? `student__school_section__teacher__profile__educational_office=${selectedOffices}&` : ""}${selectedSupervisor ? `student__school_section__teacher__supervisor_user=${selectedSupervisor}&` : ""}`
                const data = await getMangerCards("total_jump_h", param)
                const data2 = await getMangerCards("total_jump_v", param)
                setStatistic({ total_jump_h: data.total_jump_h, total_jump_v: data2.total_jump_v })
                setTotalJump({ total_jump_h: data.total_jump_h, total_jump_v: data2.total_jump_v })
            }
        }
        getData()
    }, [selectedSection, selectedOffices, selectedTeachers, selectedSupervisor])

    if (statistic) {
        return (
            <div
                className="reports-jump-bg text-center py-4 w-[522px] h-[160px] rounded-2xl cursor-pointer"
                onClick={(e) => {
                    setActiveStat("total_jump");
                }}
            >
                <p className="text-2xl my-2 font-bold">القفز</p>
                <div className="flex justify-around">
                    <div className="">
                        <p className="text-xl my-2 font-bold">
                            {Math.round(statistic?.total_jump_v?.top_total_jump_v_virtical) || 0} سم
                        </p>
                        <p>افضل قياسات القفز العامودي</p>
                    </div>
                    <div className="">
                        <p className="text-xl my-2 font-bold">
                            {Math.round(statistic?.total_jump_h?.top_total_jump_h_virtical) || 0} سم
                        </p>
                        <p>افضل قياسات القفز العرضي</p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="reports-jump-bg text-center py-4 w-[522px] h-[160px] rounded-2xl cursor-pointer"
                onClick={() => {
                    setActiveStat("total_jump");
                }}
            >
                <p className="text-2xl my-2 font-bold">القفز</p>
                <div className="flex justify-around">
                    <div className="">
                        <p className="text-xl my-2 font-bold">
                            00 سم
                        </p>
                        <p>افضل قياسات القفز العامودي</p>
                    </div>
                    <div className="">
                        <p className="text-xl my-2 font-bold">
                            00 سم
                        </p>
                        <p>افضل قياسات القفز العرضي</p>
                    </div>
                </div>
            </div>
        );
    }
}