import { useEffect, useState } from "react";
import JobInfo from "../components/dashboard/Profile/JobInfo";
import PersonalInfo from "../components/dashboard/Profile/PersonalInfo";
import Qualifications from "../components/dashboard/Profile/Qualifications";
import { useAuth } from "../context/AuthContext";
import { getCurrentProfile } from "../utils/apis";
import WalkThrough from "../components/modals/WalkThrough";
import { useOutletContext } from "react-router-dom";
import ProfileImage from '../components/dashboard/Profile/ProfileImage'

const tabs = ["المعلومات الشخصية", "المؤهلات العلمية", "المعلومات الوظيفية"];

export default function ProfilePage() {
  const outletContext = useOutletContext();
  const [popuopPos, setPopuopPos] = outletContext.popuopPos;
  const isWalkthrough = outletContext.isWalkthrough;
  const { userType, token } = useAuth();
  const [activeTab, setActiveTab] = useState(0);
  const [profile, setProfile] = useState(null);
  const [stepsIndex, setStepsIndex] = useState(0);

  useEffect(() => {
    const getData = async () => {
      const userProfile = await getCurrentProfile();
      setProfile(userProfile);
    };
    getData();
  }, []);

  useEffect(() => {
    document.title = "تمهيد | الحساب الشخصي";
  }, []);

  let defaultBg =
    "bg-[url('/src/assets/images/dashboard/profile-bg.svg')] bg-no-repeat bg-cover bg-top";
  let teacherBg =
    "bg-darkViolet-100 bg-[url('/src/assets/images/dashboard/profile-teacher.svg')] bg-no-repeat sm:bg-contain xs:bg-cover bg-right-top";
  let supervisorBg =

    "bg-darkViolet-100 bg-[url('/src/assets/images/dashboard/bg-supervisor.svg')] bg-no-repeat xs:bg-contain lg:bg-[length:63%_108%] xl:bg-[length:69%_162%] 2xl:bg-contain";
  const [bgType, setBgtype] = useState(defaultBg);
  const defineBackground = () => {
    if (userType() === "teacher") {
      setBgtype(teacherBg);
    } else if (userType() === "supervisor") {
      setBgtype(supervisorBg);
    }
  };
  useEffect(() => {
    defineBackground();
  }, [userType()]);

  return (
    <>
      <div
        className={`w-full pb-10 min-h-full ${stepsIndex === 3 && "max-h-screen"
          }`}
      >
        <div className="relative">
          {isWalkthrough && stepsIndex == 0 ? (
            <WalkThrough
              title="الحساب الشخصي"
              number={`4/${1}`}
              stepsIndex={4}
              subTitle="الحساب الشخصي"
              text="يعرض لك معلومات المسجلة بالمنصة و بإمكانك الإضافة وتعديل بعض التفاصيل الشخصية"
              colorOne="darkViolet-100"
              onclosenow={() => {
                setStepsIndex(1);
              }}
              left="50%"
              top="40px"
              arrowClass="right-top"
            />
          ) : null}
        </div>
        <div className={`h-60 ${bgType}   flex items-center gap-10 px-10`}>
          {profile ?
            <ProfileImage imageURL={profile.profile.profile_photo} /> : null
          }
          <div className="text-white">
            <p className="text-xl text-darkViolet-20">
              {userType() === "teacher"
                ? "المعلم"
                : userType() == "supervisor"
                  ? "المشرف"
                  : "رئيس القسم"}
            </p>
            <p className="text-3xl font-bold pt-2">
              {profile ? profile.profile.name : ""}
            </p>
          </div>
          {userType() !== "manager" && (
            <div className="text-white mr-auto">
              <p className="text-lg text-Shamrock-100 font-light text-center pb-4">
                {userType() === "teacher" ? "تحت إشراف" : "رئيس القسم"}
              </p>
              <p className="text-2xl">
                {userType() === "teacher"
                  ? profile?.manager
                  : profile?.manager || "-"}
              </p>
            </div>
          )}
        </div>
        <div className="flex my-6 px-10 gap-10 text-center">
          {tabs.map((t, i) => {
            if (i === 2 && userType() === "manager") {
              return null;
            }
            return (
              <div
                key={t}
                onClick={() => setActiveTab(i)}
                className={`${activeTab == i
                  ? "text-Shamrock-100 border-b-4 rounded border-b-Shamrock-100"
                  : "text-persianIndigo-100"
                  } cursor-pointer pb-2 w-full text-sm sm:text-base`}
              >
                {t}
              </div>
            );
          })}
        </div>
        <div className="relative">
          {isWalkthrough && stepsIndex == 1 ? (
            <WalkThrough
              title="الحساب الشخصي"
              number={`2/4`}
              stepsIndex={4}
              subTitle="المعلومات"
              text="بإمكانك الضغط على العنوان لعرض المعومات الاخرى."
              colorOne="darkViolet-100"
              colorTwo="darkViolet-100"
              onclosenow={() => setStepsIndex(2)}
              right="50%"
              arrowClass="top-center"
            />
          ) : null}
        </div>
        {profile ? (
          activeTab == 0 ? (
            <PersonalInfo
              isWalkthrough={isWalkthrough}
              stepsIndex={stepsIndex}
              setStepsIndex={setStepsIndex}
              setPopuopPos={setPopuopPos}
              profile={profile && profile.profile}
              token={token}
            />
          ) : activeTab == 1 ? (
            <Qualifications
              type={userType()}
              profile={profile && profile.profile}
              token={token}
            />
          ) : (
            <JobInfo profile={profile && profile.profile} token={token} type={userType()} />
          )
        ) : null}
      </div>
    </>
  );
}
