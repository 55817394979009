import { useRef, useState, useEffect } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import getDashboadStat from "../../utils/getDashboadStat";
import StatPlaceholder from "./StatPlaceholder";

export default function GeneralStat({ userType }) {
    const elementRef = useRef(null)
    const [arrowDisable, setArrowDisable] = useState(true)
    const [isOverflowing, setIsOverflowing] = useState(null)
    const [statistic, setStatistic] = useState()

    useEffect(() => {
        const getData = async () => {
            const data = await getDashboadStat(userType)
            setStatistic(data)
        };
        getData();
    }, []);

    const handleHorizantalScroll = (element, speed, distance, step) => {
        let scrollAmount = 0
        const slideTimer = setInterval(() => {
            element.scrollLeft += step
            scrollAmount += Math.abs(step)
            if (scrollAmount >= distance) {
                clearInterval(slideTimer)
            }
            if (element.scrollLeft === 0) {
                setArrowDisable(true)
            } else {
                setArrowDisable(false)
            }
        }, speed)
    }

    useEffect(() => {
        if (elementRef) {
            if (elementRef.current.scrollWidth === elementRef.current.offsetWidth) {
                setIsOverflowing(false)
            } else {
                setIsOverflowing(true)
            }
        }
    }, [elementRef])

    return (
        <div className="py-4 px-3 mt-4">
            {statistic ?
                <div className="flex gap-6 overflow-x-auto hidden-scroll" ref={elementRef}>
                    <div className='ball-bg text-persianIndigo-20 min-w-[240px] h-[130px] pr-8 rounded-lg flex justify-center flex-col select-none'>
                        <p className='text-[16px] text-white'>متوسط بعد الكرة</p>
                        <p className='text-[12px]'>رمي الكرة الطبية</p>
                        <div className='flex flex-col'>
                            <span className="text-[40px] leading-none">{Math.round(statistic.ball_length?.ball_length?.average_ball_length_virtical) || '00'}</span>
                            <span className='font-bold leading-none'>سم</span>
                        </div>
                    </div>
                    <div className='basketball-bg text-darkViolet-10 min-w-[240px] h-[130px] pr-8 rounded-lg flex justify-center flex-col select-none'>
                        <p className='text-[16px] text-white'>متوسط بعد الكرة</p>
                        <p className='text-[12px]'>رمي الكرة السلة</p>
                        <div className='flex flex-col'>
                            <span className="text-[40px] leading-none">{Math.round(statistic.basketball?.basketball?.average_basketball_virtical) || '00'}</span>
                            <span className='font-bold leading-none'>سم</span>
                        </div>
                    </div>
                    <div className='jump-bg min-w-[240px] h-[127px] pr-8 text-white rounded-lg flex flex-col justify-center select-none'>
                        <p className='text-[16px] text-white'>متوسط مسافة القفز</p>
                        <p className='text-[12px]'>القفز العمودي</p>
                        <div className='flex flex-col'>
                            <span className="text-[40px] leading-none">{Math.round(statistic.total_jump?.total_jump?.average_total_jump_virtical) || '00'}</span>
                            <span className='font-bold leading-none'>سم</span>
                        </div>
                    </div>
                    <div className='vJump-bg min-w-[240px] h-[127px] pr-8 text-white rounded-lg flex flex-col justify-center select-none'>
                        <p className='text-[16px] text-white'>متوسط مسافة القفز</p>
                        <p className='text-[12px]'>القفز العرضي</p>
                        <div className='flex flex-col'>
                            <span className="text-[40px] leading-none">{Math.round(statistic.total_jump?.total_jump?.average_total_jump_virtical) || '00'}</span>
                            <span className='font-bold leading-none'>سم</span>
                        </div>
                    </div>
                    <div className='shuttle-bg min-w-[240px] h-[127px] pr-8 text-darkViolet-10 rounded-lg flex flex-col justify-center select-none'>
                        <p className='text-[16px] text-white'>متوسط عدد الجولات</p>
                        <p className='text-[12px] font-bold'>الجري المكوكي</p>
                        <div className='flex flex-col'>
                            <span className="text-[40px] leading-none">{Math.round(statistic.muk_runing?.muk_runing?.average_muk_runing_virtical) || '00'}</span>
                            <p className='font-bold leading-none'>جولات</p>
                        </div>
                    </div>
                    <div className='speed-bg min-w-[240px] h-[127px] pr-8 text-[#FBF6CD] rounded-lg flex flex-col justify-center select-none'>
                        <p className='text-[16px] text-white'>متوسط سرعة الجري</p>
                        <p className='text-[12px] font-bold'>سرعة الجري</p>
                        <p className='text-[40px] leading-none'>{statistic.total_run?.total_run?.average_total_run_virtical || '00'}</p>
                        <p className='font-bold leading-none'>ثانية / 20 متر</p>
                    </div>
                </div>
                :
                <div className="flex gap-6 overflow-x-auto hidden-scroll" ref={elementRef}>
                    <StatPlaceholder />
                </div>
            }
            {isOverflowing === true ?
                <div className="flex mx-10 text-xl gap-6 justify-end select-none">
                    <FiChevronRight className={`cursor-pointer ${arrowDisable ? 'text-suvaGrey-40' : 'text-nero'}`} onClick={() => {
                        handleHorizantalScroll(elementRef.current, 10, 300, 10)
                    }} />
                    <FiChevronLeft className="cursor-pointer"
                        onClick={() => {
                            handleHorizantalScroll(elementRef.current, 10, 300, -10)
                        }}
                    />
                </div>
                : null}
        </div >
    )
}
