import { useEffect, useState } from 'react'
import HeightStanding from './MeasurementsElements/HeightStanding'
import HeightSitting from './MeasurementsElements/HeightSitting'
import WingspanHeight from './MeasurementsElements/WingspanHeight'
import Weight from './MeasurementsElements/Weight'
import { useFormikContext } from 'formik'


export default function BodyMeasurements({ data, touched, errors }) {
    const { setFieldValue } = useFormikContext();

    const [bmi, setBmi] = useState(data.bmi !== undefined ? data.bmi : 0)
    const [bmiResult, setBmiResult] = useState('طبيعي')

    useEffect(() => {
        if (data.height_standing && data.body_weight) {
            setBmi(data.body_weight / ((data.height_standing * 0.01) * data.height_standing * 0.01))
        }
    }, [data.height_standing, data.body_weight])


    useEffect(() => {
        if (bmi) {
            let bmiResultTemp = ''
            if (bmi < 18.5) {
                bmiResultTemp = 'اقل من الطبيعي'
            } else if (bmi >= 18.5 && bmi <= 24.9) {
                bmiResultTemp = 'طبيعي'
            } else if (bmi >= 25) {
                bmiResultTemp = 'وزن زائد'
            }
            setBmiResult(bmiResultTemp)
            setFieldValue('bmi', Math.floor(bmi))
        }
    }, [bmi])



    return (
        <div className="flex flex-col items-center gap-28 mt-10 overflow-hidden">

            <HeightStanding value={data.height_standing} touched={touched} errors={errors} />

            <HeightSitting value={data.height_sitting} touched={touched} errors={errors} />

            <WingspanHeight value={data.arms_length} touched={touched} errors={errors} />

            <Weight value={data.body_weight} touched={touched} errors={errors} />



            <div className="flex flex-col items-center gap-10 w-full">
                <div className="flex items-center gap-2">
                    <p>مؤشر كتلة الجسم</p>
                </div>
                <div className="bg-Shamrock-100 text-white font-bold rounded-lg px-4 py-6 flex justify-center items-center gap-10 w-[268px]">
                    <span>{Math.floor(bmi)}</span>
                    {bmiResult &&
                        <span>{bmiResult}</span>
                    }
                </div>
            </div>

            <div className="flex flex-col items-center gap-10 w-full mb-10">
                <p>ملاحظات</p>
                <textarea className='border-suvaGrey-20 w-2/3 rounded-lg resize-none placeholder:text-suvaGrey-40 text-sm p-5' rows="10" placeholder='إذا لديك اي ملاحظة...' />
            </div>

        </div>
    )
}
