import { useEffect, useState } from "react";
import Filter from "../../Filters";
import Card from "./Card";
import filtersOptions from "../../../../data/filters.json";
import { IoClose } from "react-icons/io5";
import { useAuth } from "../../../../context/AuthContext";
import {
  getEducationalAdministrations,
  getEducationalOfficesAll,
} from "../../../../utils/apis";

export default function AddTeacherList({
  teachers,
  setSelectedTeachers,
  placeholder,
}) {
  const [teacherList, setTeachrsList] = useState(teachers);
  const [filtersObj, setFiltersObj] = useState({});

  const [q, setQ] = useState("");
  const { userType } = useAuth();
  const [administrations, setAdministrations] = useState([
    { label: "جميع ادارات التعليم", value: "" },
  ]);
  const [offices, setOffices] = useState([
    { label: "جميع مكاتب التعليم", value: "" },
  ]);

  useEffect(() => {
    const getData = async () => {
      const ea = await getEducationalAdministrations();
      setAdministrations(
        ea.results.map((option) => {
          return { label: option.name, value: option.id, gender: option.gender };
        })
      );
      const ef = await getEducationalOfficesAll();
      setOffices(
        ef.results.map((option) => {
          return { label: option.name, value: option.id, gender: option.gender };
        })
      );
    };
    if (userType() === "manager") {
      getData();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(filtersObj).length) {
      setTeachrsList(() => {
        return teachers.filter((item) => {
          return Object.entries(filtersObj).every((newItem) => {
            if (newItem[0] === "name") {
              return item.name.toLowerCase().includes(newItem[1].toLowerCase())
                ? true
                : false;
            }
            const collator = new Intl.Collator("ar");
            const order = collator.compare(item[newItem[0]], newItem[1]);
            return order === 0 ? true : false;
          });
        });
      });
    } else {
      setTeachrsList(teachers);
    }
  }, [filtersObj]);

  const handleFilter = (e, filterType) => {
    if (filterType === "administration_of_education") {
      getEducationalOfficesAll(e.value).then((res) => {
        setOffices(
          res.results.map((option) => {
            return { label: option.name, value: option.id, gender: option.gender };
          })
        );
      });
    }

    setFiltersObj((prev) => {
      return { ...prev, [filterType]: e.label };
    });
  };

  const handleRemoveFilter = (i, s) => {
    if (s && s[0] === "administration_of_education") {
      getEducationalOfficesAll().then((res) => {
        setOffices(
          res.results.map((option) => {
            return { label: option.name, value: option.id, gender: option.gender };
          })
        );
      });
    }

    setFiltersObj((prev) => {
      const temp = { ...prev };
      if (s[0] === 'administration_of_education') {
        delete temp.educational_office
      }
      delete temp[Object.keys(temp).find((_, index) => index === i)];
      return {
        ...temp,
      };
    });
    setTeachrsList(() => {
      return teacherList.filter((item) => {
        return Object.entries(filtersObj).every((newItem) => {
          if (newItem[0] === "name") {
            return item.name.toLowerCase().includes(newItem[1].toLowerCase())
              ? true
              : false;
          }
          const collator = new Intl.Collator("ar");
          const order = collator.compare(item[newItem[0]], newItem[1]);
          return order === 0 ? true : false;
        });
      });
    });
  };

  return (
    <>
      <div className="flex flex-col mt-6">
        <div className="flex gap-3 mb-4 flex-wrap">
          {userType() === "manager" ? (
            <>
              <div className="min-w-[35%] lg:min-w-[20%]">
                <Filter
                  filtersObj={filtersObj}
                  options={administrations}
                  handleFilter={handleFilter}
                  filterType="administration_of_education"
                  placeholder="إدارة التعليم"
                />
              </div>
              <div className="min-w-[35%] lg:min-w-[20%]">
                <Filter
                  filtersObj={filtersObj}
                  options={offices}
                  handleFilter={handleFilter}
                  filterType="educational_office"
                  placeholder="مكتب التعليم"
                />
              </div>
            </>
          ) : null}
          {userType() === "supervisor" ? (
            <>
              <div className="min-w-[25%] lg:min-w-[15%]">
                <Filter
                  filtersObj={filtersObj}
                  options={filtersOptions.authority}
                  handleFilter={handleFilter}
                  filterType="authority"
                  placeholder="نوع التعليم"
                  isSearchable={true}
                />
              </div>
              <div className="min-w-[20%] lg:min-w-[15%]">
                <Filter
                  filtersObj={filtersObj}
                  options={filtersOptions.school_type}
                  handleFilter={handleFilter}
                  filterType="school_type"
                  placeholder="طلاب المدرسة"
                  isSearchable={true}
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="flex gap-4 w-full flex-wrap mb-4">
          {Object.entries(filtersObj).map((s, i) =>
            s[0] === "name" ? null : (
              <div
                key={s[1] + "-" + i}
                onClick={() => handleRemoveFilter(i, s)}
                className="border border-Shamrock-100 rounded-lg text-Shamrock-100 cursor-pointer  py-1 px-3"
              >
                {s[1]} <IoClose className="inline" />
              </div>
            )
          )}
        </div>
        <div className="search-input-container w-full bg-[#f9f9f9]">
          <input
            type="text"
            className="search-input"
            placeholder={placeholder}
            value={q}
            onChange={(e) => {
              setFiltersObj((prev) => {
                if (e.target.value == "") {
                  const temp = prev;
                  delete temp.name;
                  return {
                    ...temp,
                  };
                } else {
                  return {
                    ...prev,
                    name: e.target.value,
                  };
                }
              });
              setQ(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="overflow-y-auto custom-scroll mx-auto flex flex-col max-h-[40%]">
        {teacherList.map((s) => (
          <Card
            key={s.id}
            student={s}
            setSelectedTeachers={setSelectedTeachers}
          />
        ))}
      </div>
    </>
  );
}
