import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { getMangerCards, getSupervisorCards, getTeacherCards } from "../../../utils/apis";

export default function BallStatsCard({ setActiveStat, selectedSection, selectedOffices, selectedTeachers, selectedSupervisor, setTotalBall }) {
    const [statistic, setStatistic] = useState(null)

    const { userType } = useAuth();

    useEffect(() => {
        const getData = async () => {
            if (userType() === 'teacher') {
                const data = await getTeacherCards("ball_length", selectedSection !== "" ? `student__school_section__section=${selectedSection}` : "")
                const data2 = await getTeacherCards("basketball", selectedSection !== "" ? `student__school_section__section=${selectedSection}` : "")
                setStatistic({ ball_length: data.ball_length, basketball: data2.basketball })
                setTotalBall({ ball_length: data.ball_length, basketball: data2.basketball })
            } else if (userType() === 'supervisor') {
                const param = `${selectedSection ? `student__school_section__section=${selectedSection}&` : ""}${selectedOffices ? `student__school_section__teacher__profile__educational_office=${selectedOffices}&` : ""}${selectedTeachers ? `${selectedTeachers}&` : ""}${selectedSupervisor ? `${selectedSupervisor}&` : ""}`
                const data = await getSupervisorCards("ball_length", param)
                const data2 = await getSupervisorCards("basketball", param)
                setStatistic({ ball_length: data.ball_length, basketball: data2.basketball })
                setTotalBall({ ball_length: data.ball_length, basketball: data2.basketball })
            } else if (userType() === 'manager') {
                const param = `${selectedOffices ? `student__school_section__teacher__profile__educational_office=${selectedOffices}&` : ""}${selectedSupervisor ? `student__school_section__teacher__supervisor_user=${selectedSupervisor}&` : ""}`
                const data = await getMangerCards("ball_length", param)
                const data2 = await getMangerCards("basketball", param)
                setStatistic({ ball_length: data.ball_length, basketball: data2.basketball })
                setTotalBall({ ball_length: data.ball_length, basketball: data2.basketball })
            }
        }
        getData()
    }, [selectedSection, selectedOffices, selectedTeachers, selectedSupervisor])

    if (statistic) {
        return (
            <div
                className="reports-ball-bg text-center py-4 w-[522px] h-[160px] text-white rounded-2xl cursor-pointer"
                onClick={() => {
                    setActiveStat("ball_length");
                }}
            >
                <p className="text-2xl my-2 font-bold">رمي الكرة</p>
                <div className="flex justify-around">
                    <div className="">
                        <p className="text-xl my-2 font-bold">
                            {Math.round(statistic?.ball_length?.top_ball_length_virtical) || 0} سم
                        </p>
                        <p>افضل قياسات رمي الكرة الطبية</p>
                    </div>
                    <div className="">
                        <p className="text-xl my-2 font-bold">
                            {Math.round(statistic?.basketball?.top_basketball_virtical) || 0} سم
                        </p>
                        <p>افضل قياسات رمي كرة السلة</p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                className="reports-ball-bg text-center py-4 w-[522px] h-[160px] text-white rounded-2xl cursor-pointer"
                onClick={() => {
                    setActiveStat("ball_length");
                }}
            >
                <p className="text-2xl my-2 font-bold">رمي الكرة</p>
                <div className="flex justify-around">
                    <div className="">
                        <p className="text-xl my-2 font-bold">
                            0 سم
                        </p>
                        <p>افضل قياسات رمي الكرة الطبية</p>
                    </div>
                    <div className="">
                        <p className="text-xl my-2 font-bold">
                            0 سم
                        </p>
                        <p>افضل قياسات رمي كرة السلة</p>
                    </div>
                </div>
            </div>
        );
    }
}