import { Field, useFormikContext } from "formik";
import { Select } from "../FormTools/CustomSelect";
import { BiErrorCircle } from "react-icons/bi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Popover } from "@mantine/core";
import options from "../../data/register.json";
import { useState } from "react";
import {
  getEducationalAdministrations,
  getEducationalOfficesAll,
} from "../../utils/apis";
import { useEffect } from "react";

const SchoolDataForm = ({ showMessage, message, data, errors, touched }) => {
  const [educationalOffices, setEducationalOffices] = useState([]);
  const [educationalAdministrations, setEducationalAdministrations] = useState(
    []
  );

  const { values, setFieldValue } = useFormikContext();

  const handleGender = () => {
    if (values.gender === "F") {
      return '[F,B]'
    } else if (values.gender === "M") {
      return '[M,B]'
    }
  }

  const fetchEducationalOffices = async () => {
    const officesOptions = await getEducationalOfficesAll('', `?gender=${handleGender()}`);
    setEducationalOffices(
      officesOptions.results.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );
  };

  const fetchEducationalAdministrations = async () => {
    const administrationOptions = await getEducationalAdministrations();
    setEducationalAdministrations(
      administrationOptions.results.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );
  };

  useEffect(() => {
    fetchEducationalAdministrations();
  }, []);

  useEffect(() => {
    if (values.administration_of_education) {
      getEducationalOfficesAll(values.administration_of_education, `&gender=${handleGender()}`).then(
        (res) => {
          setFieldValue("educational_office", "");
          setEducationalOffices(
            res.results.map((option) => {
              return { label: option.name, value: option.id };
            })
          );
        }
      );
    } else {
      fetchEducationalOffices();
    }
  }, [values.administration_of_education]);

  return (
    <>
      <div className="w-2/3 mx-auto my-10">
        <div className="bg-[#F9F9F9] px-12 rounded-lg shadow-page-card">
          <p className="text-darkViolet-100 font-bold text-center text-2xl pt-10 mb-6">
            بيانات المدرسة
          </p>
          <hr className="bg-HawkesBlue-40 mb-10" />
          {showMessage && (
            <div className="bg-[rgba(217,30,30,0.08)] rounded-lg py-2 px-4 text-[#D91E1E] font-bold flex items-center gap-2 mb-4">
              <BiErrorCircle />
              {message === 'national_id' ?
                <span> رقم الهوية موجود مسبقًا، يمكنك التسجيل من خلال {" "}
                  <a className="underline" href={`${window.location.origin}/signup`}>هذا الرابط</a>
                </span>
                :
                <span>{message}</span>
              }
            </div>
          )}

          <div className="text-HawkesBlue-100 pb-10">
            <div className="grid lg:grid-cols-2 lg:gap-5">
              <div>
                <label
                  htmlFor="school_name"
                  className="text-HawkesBlue-100 font-bold"
                >
                  اسم المدرسة
                </label>
                <Field
                  className={`number-arrow border ${errors.school_name && touched.school_name
                    ? "border-[#DB0000] placeholder:text-[#DB0000]"
                    : "border-[#D6D9E0]"
                    } w-full py-4 mt-3 placeholder:text-[#D6D9E0] px-6 text-black rounded-lg`}
                  type="text"
                  id="school_name"
                  name="school_name"
                  placeholder="اسم المدرسة"
                />
                <div className="my-5">
                  {errors.school_name && touched.school_name ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.school_name}</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <label
                    htmlFor="school_ministery_number"
                    className="text-HawkesBlue-100 font-bold"
                  >
                    رقم المدرسة الوزاري
                  </label>
                  <SchoolNumberPopover />
                </div>
                <Field
                  className={`number-arrow border ${errors.school_ministery_number &&
                    touched.school_ministery_number
                    ? "border-[#DB0000] placeholder:text-[#DB0000]"
                    : "border-[#D6D9E0]"
                    } w-full py-4 mt-3 placeholder:text-[#D6D9E0] px-6 text-black rounded-lg`}
                  type="number"
                  id="school_ministery_number"
                  name="school_ministery_number"
                  placeholder="رقم المدرسة الوزاري"
                />
                <div className="my-5">
                  {errors.school_ministery_number &&
                    touched.school_ministery_number ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">
                        {errors.school_ministery_number}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="grid lg:grid-cols-2 lg:gap-5">
              <div className="">
                <label
                  htmlFor="school_type"
                  className="text-HawkesBlue-100 font-bold mb-3 block"
                >
                  نوع المدرسة
                </label>
                <div className="w-full">
                  <Field
                    id="school_type"
                    name="school_type"
                    component={Select}
                    options={options.schoolTypes}
                    placeholder="إختار نوع المدرسة"
                    isSearchable={true}
                  />
                </div>

                <div className="my-5">
                  {errors.school_type && touched.school_type ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.school_type}</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="">
                <label
                  htmlFor="authority"
                  className="text-HawkesBlue-100 font-bold mb-3 block"
                >
                  تصنيف المدرسة
                </label>
                <div className="w-full">
                  <Field
                    id="authority"
                    component={Select}
                    name="authority"
                    options={options.schoolAuthority}
                    placeholder="إختار تصنيف المدرسة"
                  />
                </div>

                <div className="my-5">
                  {errors.authority && touched.authority ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.authority}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="grid lg:grid-cols-2 lg:gap-5">
              <div className="">
                <label
                  htmlFor="administration_of_education"
                  className="text-HawkesBlue-100 font-bold mb-3 block"
                >
                  الادارة التعليمية
                </label>
                <div className="w-full">
                  <Field
                    id="administration_of_education"
                    component={Select}
                    name="administration_of_education"
                    options={educationalAdministrations}
                    placeholder="إختار الإدارة التعليمية"
                    onChange={(e) => {
                      console.log(e);
                    }}
                  />
                </div>

                <div className="my-5">
                  {errors.administration_of_education &&
                    touched.administration_of_education ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">
                        {errors.administration_of_education}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="">
                <label
                  htmlFor="educational_office"
                  className="text-HawkesBlue-100 font-bold mb-3 block"
                >
                  المكتب التعليمي
                </label>
                <div className="w-full">
                  <Field
                    id="educational_office"
                    name="educational_office"
                    component={Select}
                    options={educationalOffices}
                    placeholder="إختار المكتب التعليمي"
                  />
                </div>

                <div className="my-5">
                  {errors.educational_office && touched.educational_office ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">
                        {errors.educational_office}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="">
              <label
                htmlFor="educational_region"
                className="text-HawkesBlue-100 font-bold mb-3 block"
              >
                المنطقة التعليمية
              </label>
              <div className="w-full">
                <Field
                  id="educational_region"
                  name="educational_region"
                  component={Select}
                  options={options.educationalRegion}
                  placeholder="إختار المنطقة التعليمية"
                />
              </div>

              <div className="my-5">
                {errors.educational_region && touched.educational_region ? (
                  <div className="flex gap-1">
                    <BiErrorCircle className="text-error text-2xl font-light" />
                    <span className="text-error">
                      {errors.educational_region}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolDataForm;

const SchoolNumberPopover = () => {
  return (
    <Popover position="bottom" width={250} withArrow shadow="md">
      <Popover.Target>
        <button type="button" className="">
          <AiOutlineQuestionCircle className="cursor-pointer" />
        </button>
      </Popover.Target>
      <Popover.Dropdown className="text-nero rounded-xl">
        <p>
          بإمكانك الحصول على الرقم الوزاري من خلال{" "}
          <a
            className="text-persianIndigo-100 underline"
            target="_blank"
            href="https://noor.moe.gov.sa/Noor/Login.aspx"
            rel="noreferrer"
          >
            موقع نور
          </a>
        </p>
      </Popover.Dropdown>
    </Popover>
  );
};
