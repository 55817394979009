function str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
}


const convertSecToMinAndSec = (time) => {
    if (time) {
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;
        return str_pad_left(Math.floor(minutes), '0', 2) + ':' + str_pad_left(Math.floor(seconds), '0', 2);
    }
    return "00:00"
}

export default convertSecToMinAndSec