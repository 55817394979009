import { Link } from "react-router-dom";
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import logo from "../assets/images/logo/tamheed-black.svg"

export default function Success({ msg1, msg2, btnMsg, btnLink, onClick = null }) {
    return (
        <div className="custom-bg flex flex-col justify-evenly text-white">
            <div className="custom-bg flex flex-col justify-center items-center">
                <div className="shadow-page-card flex flex-col gap-10 bg-white w-1/2 lg:w-1/3 p-10 rounded-xl">
                    <img src={logo} alt="Tamheed logo" className="w-1/2 mx-auto mb-2" />
                    <div className="flex mx-auto gap-3 items-center">
                        <IoMdCheckmarkCircleOutline className="text-3xl text-Shamrock-100" />
                        <p className="text-center text-2xl text-nero">{msg1}</p>
                    </div>
                    {/* <p className="text-center text-3xl text-persianIndigo-100 font-bold">{msg2}</p> */}
                    <div className='flex flex-col gap-5'>
                        <button onClick={onClick} className="bg-persianIndigo-100 text-center font-bold text-white w-2/3 mx-auto rounded-lg py-4 text-sm">{btnMsg}</button>
                        {/* <Link to={btnLink} className="border border-persianIndigo-100 font-bold text-center text-persianIndigo-100 w-2/3 mx-auto rounded-lg py-3 text-sm">الذهاب الى لوحة القيادة</Link> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
