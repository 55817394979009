import { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsCheck2Circle } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import WalkThrough from "../components/modals/WalkThrough";
import { useWalkthrough } from "../context/WalkthroughContext";
import { useCourses } from "../context/CoursesContext";

export default function ResultsPage() {
  const { isWalkthrough, popuopPos, setPopuopPos } = useWalkthrough();

  const location = useLocation();
  const {
    results,
    name,
    id,
    isLastCourse,
    stepsIndex,
  } = location.state;

  const { courses } = useCourses()

  const [total, setTotal] = useState(0);
  const [correct, setCorrect] = useState([]);
  const [incorrect, setInCorrect] = useState({});
  const [course, setCourse] = useState([]);

  useEffect(() => {
    const correct = results.correct;
    const incorrect = results.incorrect;
    const total = correct + incorrect;
    setCorrect(correct);
    setInCorrect(incorrect);
    setTotal(total);
  }, []);

  useEffect(() => {
    if (courses.length) {
      const getData = () => {
        const nextInedx = courses.findIndex((c) => c.id == id) === courses.length - 1 ? 0 : courses.findIndex((c) => c.id == id) + 1;
        setCourse(courses[nextInedx].id);
      };
      getData();
    }
  }, [courses]);

  return (
    <>
      <div className="custom-bg flex flex-col justify-center gap-10 py-10">
        <div className="text-center py-10 bg-[#F9F9F9] rounded-lg shadow-page-card w-2/3 mx-auto px-10">
          <p className="text-xl font-bold text-persianIndigo-100">
            تقييم {name}
          </p>
          <hr className="bg-suvaGrey-20 mr-5 my-6" />
          {correct > incorrect ? (
            <>
              <div className="relative">
                {isWalkthrough &&
                  isLastCourse && popuopPos === 'last' ? (
                  <WalkThrough
                    title="البرنامج التدريبي"
                    number={`5/${stepsIndex}`}
                    stepsIndex={stepsIndex}
                    subTitle="إكمال البرنامج"
                    text="عند اجتيازك للبرنامج التدريبي، سوف تتكرم بشهادة والقدرة على استخدام المنصه. "
                    colorOne
                    colorTwo
                    colorThree
                    colorFour
                    colorFive
                    onclosenow={() => setPopuopPos('')}
                    right="57vw"
                    top="12vw"
                    arrowClass="right-bottom"
                  />
                ) : null}
              </div>
              <div className="min-h-[75%]">
                <p className="text-5xl font-bold text-Shamrock-100">تهانينا.</p>
                <p className="text-nero my-5">لقد أتممت التقييم بنجاح.</p>
                <div className="text-4xl text-Shamrock-100 flex justify-center gap-3 my-4">
                  <p className="">
                    {correct}/{total}
                  </p>
                  <BsCheck2Circle />
                </div>
                {isLastCourse ? (
                  <p className="pt-4">الأن بإمكانك أخذ بيانات الطلاب بنجاح</p>
                ) : (
                  <p className="pt-4">الأن بإمكانك التقدم الى الفيديو التالي</p>
                )}
              </div>
              {isLastCourse ? (
                <Link to="/learning/certificate" className="mt-10 block">
                  <button className="w-4/5 mx-auto block h-14 px-10 rounded-lg bg-persianIndigo-100 text-white shadow-button">
                    عرض الشهادة
                  </button>
                </Link>
              ) : (
                <Link to={`/learning/${course}`} className="mt-10 block">
                  <button className="w-4/5 mx-auto block h-14 px-10 rounded-lg bg-persianIndigo-100 text-white shadow-button">
                    إكمال البرنامج التدريبي
                  </button>
                </Link>
              )}
            </>
          ) : (
            <>
              <div className="min-h-[75%]">
                <p className="text-5xl font-bold text-[#DB0000]">للأسف...</p>
                <p className="text-nero my-5">لم تجتز التقيم</p>

                <div className="text-4xl text-[#DB0000] flex justify-center gap-3 my-4">
                  <p className="">
                    {correct}/{total}
                  </p>
                  <AiOutlineCloseCircle />
                </div>
                <p className="pt-4">يجب ان تعيد الفيديو التدريبي</p>
              </div>
              <Link to={`/learning/${id}`} className="mt-10 block">
                <button className="w-4/5 mx-auto block h-14 px-10 rounded-lg bg-persianIndigo-100 text-white shadow-button">
                  إعادة مشاهدة الفيديو
                </button>
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
}
