import * as Yup from "yup";

const phoneRegExp = /^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;

const schema = [
  Yup.object().shape({
    national_id: Yup.number()
      .typeError("رقم الأحوال/ الإقامة يجب أن يكون رقمًا")
      .integer()
      .required("هذا الحقل مطلوب")
      .test(
        "len",
        "رقم الأحوال/ الإقامة يجب أن يكون 10 أرقام",
        (val) => val && val.toString().length === 10
      ),
    name: Yup.string().required("هذا الحقل مطلوب"),
    phone_number: Yup.string().required("هذا الحقل مطلوب").matches(phoneRegExp, "يجب ان يكون رقم جوال صحيح"),
    IDday: Yup.string().required("هذا الحقل مطلوب"),
    IDmonth: Yup.string().required("هذا الحقل مطلوب"),
    IDyear: Yup.string().required("هذا الحقل مطلوب"),
    Bday: Yup.string().required("هذا الحقل مطلوب"),
    Bmonth: Yup.string().required("هذا الحقل مطلوب"),
    Byear: Yup.string().required("هذا الحقل مطلوب"),
    nationality: Yup.string().required("هذا الحقل مطلوب"),
    gender: Yup.string().required("هذا الحقل مطلوب"),
    educational_office: Yup.string(),
    administration_of_education: Yup.string().required("هذا الحقل مطلوب"),
    educational_region: Yup.string().required("هذا الحقل مطلوب"),
  }),
  Yup.object().shape({
    email: Yup.string()
      .email("البريد الإلكتروني يجب أن يكون عنوانًا صحيحًا")
      .required("هذا الحقل مطلوب"),
    password: Yup.string()
      .required("هذا الحقل مطلوب")
      .min(8, "كلمة المرور قصيرة"),
    passwordConfirmation: Yup.string()
      .required("هذا الحقل مطلوب")
      .oneOf(
        [Yup.ref("password")],
        "كلمة المرور لا تتطابق مع تأكيد كلمة المرور"
      ),
  }),
];

export default schema;
