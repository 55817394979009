import { useEffect, useState } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import standingHeight from '../../assets/images/dashboard/measurement/standing-height.svg'
import standingHeightComplete from '../../assets/images/dashboard/measurement/standing-height-complete.svg'
import sittingHeight from '../../assets/images/dashboard/measurement/sitting-height.svg'
import sittingHeightComplete from '../../assets/images/dashboard/measurement/sitting-height-complete.svg'
import wingspan from '../../assets/images/dashboard/measurement/wingspan.svg'
import wingspanComplete from '../../assets/images/dashboard/measurement/wingspan-complete.svg'
import weight from '../../assets/images/dashboard/measurement/weight.svg'
import weightComplete from '../../assets/images/dashboard/measurement/weight-complete.svg'
import medBall from '../../assets/images/dashboard/measurement/med-ball.svg'
import medBallComplete from '../../assets/images/dashboard/measurement/med-ball-complete.svg'
import { Link, useNavigate } from 'react-router-dom'
import Run from './students/ReviewCards/Run'
import ShuttleRun from './students/ReviewCards/ShuttleRun'
import Jump from './students/ReviewCards/Jump'
import { BsCheck } from 'react-icons/bs'
import handlePercentage from '../../hooks/handleRecordPercentage'
import RecordDate from './students/MeasurementsElements/RecordDate'
import ClassSelect from './Profile/ClassSelect'
import axios from 'axios'
import Cookies from 'js-cookie'

export default function StudentCard({ data, sectionId, index, sectionClasses }) {
    const [expand, setExpand] = useState(false)
    const [generalInfo, setGeneralInfo] = useState(false)
    const [studentClass, setStudentClass] = useState(false)
    const [studentClassValue, setStudentClassValue] = useState('')
    const [studentClassSuccess, setStudentClassSuccess] = useState(false)
    const [bmi, setBmi] = useState(0)
    const [bmiResult, setBmiResult] = useState('طبيعي')
    const navigate = useNavigate()

    useEffect(() => {
        if (data.section_class) {
            const { name, id } = data.section_class
            setStudentClassValue({ label: name, value: id })
        }
    }, [])


    useEffect(() => {
        if (data.records.height_standing && data.records.body_weight) {
            setBmi(data.records.body_weight / ((data.records.height_standing * 0.01) * data.records.height_standing * 0.01))
        }
        if (bmi) {
            let bmiResultTemp = ''
            if (bmi < 18.5) {
                bmiResultTemp = 'اقل من الطبيعي'
            } else if (bmi >= 18.5 && bmi <= 24.9) {
                bmiResultTemp = 'طبيعي'
            } else if (bmi >= 25) {
                bmiResultTemp = 'وزن زائد'
            }
            setBmiResult(bmiResultTemp)
        }
    }, [bmi])

    const handleClick = () => {
        navigate(`/dashboard/incomplete-data/${sectionId}/student/${data.id}`, { state: { sectionId } })
    }

    const handleClassChange = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/students/${data.id}/set_section_class/`, {
            section_class_id: studentClassValue.value
        }, {
            headers: {
                Authorization: `Token ${Cookies.get("token")}`,
            }
        })
            .then(function (res) {
                setStudentClassSuccess(true)
            })
            .catch(function (error) {
                console.log(error);
                setStudentClassSuccess(false)
            })
    }

    return (
        <div className='border-b-2 border-suvaGrey-10'>
            <div
                onClick={() => setExpand((prev) => !prev)}
                className={`shadow[0px_4px_6px_rgba(35,35,35,0.06)] ${expand && 'bg-white'} flex items-center gap-5 px-8 py-6 my-2 cursor-pointer 
                `}
            >
                <p className='text-suvaGrey-40'>{index + 1}</p>
                <div className="w-6">
                    {
                        data.records == "No record found" ?
                            <span className='border-4 border-HawkesBlue-100 rounded-full text-lg w-6 h-6 block'></span>
                            : data.completed ?
                                <BsCheck className='inline border-4 stroke-1 border-Shamrock-100 rounded-full text-Shamrock-100 text-lg h-6 w-6' />
                                :
                                <span className='w-6 h-6 block'>
                                    <CircularProgressbar
                                        value={handlePercentage(data)} strokeWidth="16"
                                        // counterClockwise
                                        styles={buildStyles({
                                            pathColor: '#D6D9E0',
                                            trailColor: '#D91E1E',
                                            strokeLinecap: 'butt',
                                        })}
                                    />
                                </span>
                    }
                </div>
                <p className={`font-bold text-nero`}>{data.first_name}{" "} {data.father_name} {" "} {data.last_name}</p>
                {expand ?
                    <FiChevronUp className={`text-HawkesBlue-100 text-3xl mr-auto`} />
                    :
                    <FiChevronDown className='text-HawkesBlue-100 text-3xl mr-auto' />
                }
            </div>
            {expand &&
                <>
                    <div className='px-4 py-5'>
                        <div>
                            <div onClick={() => setStudentClass((prev) => !prev)} className="border border-suvaGrey-20 rounded-lg flex items-center p-3 mb-5">
                                <span className='text-suvaGrey-80 cursor-pointer font-bold text-lg'>{studentClass ? 'تغيير الشعبة' : 'اختيار شعبة'}</span>
                                {studentClass ?
                                    <FiChevronUp className={`text-suvaGrey-40 text-2xl mr-auto`} />
                                    :
                                    <FiChevronDown className='text-suvaGrey-40 text-2xl mr-auto' />
                                }
                            </div>

                            {studentClass && <>
                                <div className="border rounded-lg p-4 cursor-default">
                                    <div className="flex gap-4 items-center mt-4 flex-wrap">
                                        <ClassSelect options={sectionClasses}
                                            value={studentClassValue}
                                            name="gender"
                                            handleChange={(e) => setStudentClassValue(e)}
                                            placeholder="اختيار شعبة" />
                                        <button onClick={handleClassChange} className='rounded-md bg-persianIndigo-100 text-white px-5 py-2'>حفظ</button>
                                        <button onClick={() => setStudentClassValue('')} className='border border-suvaGrey-40 text-persianIndigo-100 rounded-md bg-white px-5 py-2'>الغاء</button>
                                    </div>
                                    {studentClassSuccess ?
                                        <p className='mt-4 text-Shamrock-100 font-bold pr-1'>تم {studentClass ? 'تغيير' : 'إضافة'} الشعبة بنجاح</p>
                                        : null}
                                </div>
                            </>}


                            <div onClick={() => setGeneralInfo((prev) => !prev)} className="border border-suvaGrey-20 rounded-lg flex items-center p-3 cursor-pointer mb-5">
                                <span className='text-suvaGrey-80 font-bold text-lg'>معلومات عامة</span>
                                {generalInfo ?
                                    <FiChevronUp className={`text-suvaGrey-40 text-2xl mr-auto`} />
                                    :
                                    <FiChevronDown className='text-suvaGrey-40 text-2xl mr-auto' />
                                }
                            </div>

                            {generalInfo && <>
                                <div className="flex flex-col sm:grid grid-cols-2 gap-4">
                                    <div className='border rounded-lg p-4 text-center font-bold'>
                                        <p className='pb-2 font-light'>رقم الأحوال/الإقامة</p>
                                        <p>{data.national_id}</p>
                                    </div>
                                    <div className='border rounded-lg p-4 text-center font-bold'>
                                        <p className='pb-2 font-light'>تاريخ الميلاد</p>
                                        <p>{data?.birth_date?.split(' ')[0] || data?.hijri_birth_date?.split(' ')[0] || "-"}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col sm:grid grid-cols-3 gap-4 mt-4">
                                    <div className='border rounded-lg p-4 text-center font-bold'>
                                        <p className='pb-2 font-light'>الجنس</p>
                                        <p>{data.gender || '-'}</p>
                                    </div>
                                    <div className='border rounded-lg p-4 text-center font-bold'>
                                        <p className='pb-2 font-light'>مكان الميلاد</p>
                                        <p>
                                            <span className='pl-1'>{data?.place_of_birth || '-'}</span>
                                        </p>
                                    </div>
                                    <div className='border rounded-lg p-4 text-center font-bold'>
                                        <p className='pb-2 font-light'>الجنسية</p>
                                        <p>{data.nationality || '-'}</p>
                                    </div>
                                </div>
                            </>}

                        </div>
                    </div>
                    <div className='p-4 mb-10'>
                        <div>
                            <p className='font-bold text-suvaGrey-80 text-xl text-center mb-6'>القياسات الجسدية</p>
                            <div className="flex flex-col sm:grid grid-cols-2 gap-4">
                                <Link to={`/dashboard/incomplete-data/${sectionId}/student/${data.id}`} state={{ sectionId }} className={`border rounded-lg p-4 text-center ${data.records.height_standing == 0 ? 'border-[#D91E1E] shadow-[0px_0px_6px_rgba(35,35,35,0.08)] bg-white' : 'border-suvaGrey-20'}`}>
                                    {!data.records.height_standing || data.records == 'No record found' ?
                                        <img src={standingHeight} className="mx-auto" />
                                        :
                                        <img src={standingHeightComplete} className="mx-auto" />
                                    }
                                    <p className='pb-2 pt-2'>الطول وقوفاً</p>
                                    <p>
                                        <span className="text-nero pl-1">{Math.round(data.records.height_standing) || '0'}</span>
                                        <span className='font-bold'>سم</span>
                                    </p>
                                    <RecordDate record={data.records.height_standing_date || ""} />
                                </Link>

                                <Link to={`/dashboard/incomplete-data/${sectionId}/student/${data.id}`} state={{ sectionId }} className={`border rounded-lg p-5 text-center ${data.records.height_standing == 0 ? 'border-[#D91E1E] shadow-[0px_0px_6px_rgba(35,35,35,0.08)] bg-white' : 'border-suvaGrey-20'}`}>
                                    {!data.records.height_standing || data.records == 'No record found' ?
                                        <img src={sittingHeight} className="mx-auto" />
                                        :
                                        <img src={sittingHeightComplete} className="mx-auto" />
                                    }

                                    <p className='pb-2 pt-2'>الطول جلوساً</p>
                                    <p>
                                        <span className="text-nero pl-1">{Math.round(data.records.height_standing || '0')}</span>
                                        <span className='font-bold'>سم</span>
                                    </p>
                                    <RecordDate record={data.records.height_standing_date || ""} />
                                </Link>

                                <Link to={`/dashboard/incomplete-data/${sectionId}/student/${data.id}`} state={{ sectionId }} className={`border rounded-lg p-5 text-center ${data.records.arms_length == 0 ? 'border-[#D91E1E] shadow-[0px_0px_6px_rgba(35,35,35,0.08)] bg-white' : 'border-suvaGrey-20'}`}>
                                    {!data.records.arms_length || data.records == 'No record found' ?
                                        <img src={wingspan} className="mx-auto" />
                                        :
                                        <img src={wingspanComplete} className="mx-auto" />
                                    }

                                    <p className='pb-2 pt-2'>طول الذراعين</p>
                                    <p>
                                        <span className="text-nero pl-1">{Math.round(data.records.arms_length) || '0'}</span>
                                        <span className='font-bold'>سم</span>
                                    </p>
                                    <RecordDate record={data.records.arms_length_date || ""} />
                                </Link>

                                <Link to={`/dashboard/incomplete-data/${sectionId}/student/${data.id}`} state={{ sectionId }} className={`border rounded-lg p-5 text-center ${data.records.body_weight == 0 ? 'border-[#D91E1E] shadow-[0px_0px_6px_rgba(35,35,35,0.08)] bg-white' : 'border-suvaGrey-20'}`}>
                                    {!data.records.body_weight || data.records == 'No record found' ?
                                        <img src={weight} className="mx-auto" />
                                        :
                                        <img src={weightComplete} className="mx-auto" />
                                    }

                                    <p className='pb-2 pt-240'>وزن الجسم</p>
                                    <p>
                                        <span className="text-nero pl-1">{Math.round(data.records.body_weight) || '0'}</span>
                                        <span className='font-bold'>كغم</span>
                                    </p>
                                    <RecordDate record={data.records.body_weight_date || ""} />
                                </Link>
                            </div>
                            <div className="col-span-2 border p-3 text-center rounded-lg my-4">
                                <p className='pb-4 font-light'>مؤشر كتلة الجسم</p>
                                <p>
                                    <span>{Math.round(bmi)}</span>
                                    <span className='font-bold pr-3'>{bmiResult}</span>
                                </p>
                            </div>

                            <div className="col-span-2 border p-3 text-center rounded-lg">
                                <p className='pb-4 font-light'>الملاحظات</p>
                                <p className="font-bold">{data.records.notes_1 || '-'}</p>
                            </div>

                            <div className='p-4 mb-10 flex-1'>
                                <p className='font-bold text-xl text-center mb-6'>قياسات اللياقة البدنية</p>
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                    <div className="rounded-lg p-5 border text-center bg-[#F9F9F9] cursor-pointer" onClick={handleClick}>
                                        {data.records.ball_length > 0 || data.records.basketball > 0 ?
                                            <img src={medBallComplete} className="mx-auto" />
                                            :
                                            <img src={medBall} className="mx-auto" />
                                        }

                                        {/* <p className='pt-2'>رمي الكرة الطبية</p>
                                        <p className='py-2'>مسافة بعد الكرة</p>
                                        <p className='my-2'>
                                            <span className="text-nero pl-1">{Math.round(data.records.ball_length) || 0}</span>
                                            <span className='font-bold'>سم</span>
                                        </p>
                                        <RecordDate record={data.records.height_standing_date} /> */}

                                        <p className='py-2'>رمي الكرة</p>
                                        <p className='py-2 text-sm'>مسافة بعد الكرة الطبية</p>
                                        <p className='text-sm'>
                                            <span className="text-nero pl-1">{Math.round(data.records.ball_length) || 0}</span>
                                            <span className='font-bold'>سم</span>
                                        </p>
                                        {data.ball_length ?
                                            <RecordDate record={data.records.ball_length_date} />
                                            : null}
                                        <p className='py-2 text-sm'>مسافة بعد كرة السلة</p>
                                        <p className='text-sm'>
                                            <span className="text-nero pl-1">{Math.round(data.records.basketball) || 0}</span>
                                            <span className='font-bold'>سم</span>
                                        </p>
                                        <RecordDate record={data.records.basketball_date} />
                                    </div>


                                    <div className="cursor-pointer border rounded-lg">
                                        <Run
                                            values={[data.records.run_time_1 || 0, data.records.run_time_2 || 0, data.records.run_time_3 || 0]}
                                            dates={[data.records.run_time_1_date || "", data.records.run_time_2_date || "", data.records.run_time_3_date || ""]}
                                            handleClick={handleClick} />
                                    </div>

                                    <div className="cursor-pointer border rounded-lg" onClick={handleClick}>
                                        <ShuttleRun value={Math.round(data.records.muk_runing) || 0} date={data.records.muk_runing_date || ""} />
                                    </div>

                                    <div className="cursor-pointer border rounded-lg">
                                        <Jump
                                            values={[Math.round(data.records.jump_1) || 0, Math.round(data.records.jump_2) || 0, Math.round(data.records.jump_3) || 0]}
                                            dates={[data.records.jump_1_date || "", data.records.jump_2_date || "", data.records.jump_3_date || ""]}
                                            handleClick={handleClick}
                                        />
                                    </div>


                                    <div className="lg:col-span-2 p-3 text-center border rounded-lg bg-[#F9F9F9]">
                                        <p className='pb-4 font-light'>الملاحظات</p>
                                        <p>{data.records.notes_2 || '-'}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}