import { useEffect, useState } from "react";
import DashboardCard from "../../components/dashboard/DashboardCard";
import { getSchoolSections, getSections } from "../../utils/apis";

export default function IncompleteDatePage() {

    const [sectionsList, setSectionsList] = useState([])

    useEffect(() => {
        const getData = async () => {
            const sections = await getSchoolSections()
            setSectionsList(sections.results)
        }
        getData()
    }, [])
    useEffect(() => {
        document.title = "تمهيد | بيانات غير مكتملة";
    }, []);


    return (
        <div className="w-full p-10 bg-white min-h-full">
            <p className="font-bold text-persianIndigo-100 text-2xl">بيانات غير مكتملة</p>
            <div className="flex flex-wrap gap-6 mt-6">

                {sectionsList.filter((s) => s.completed_students !== s.total_students).map((section) => (
                    <DashboardCard key={section.id} section={section} />
                ))}
            </div>
        </div >
    )
}
