import { useState } from "react"
import { FiChevronDown, FiChevronUp } from "react-icons/fi"
import StudentCard from "./Card"

export default function SectionClassCard({ sectionName, data, sectionId, students }) {
    const [expandClass, setExpandClass] = useState(false)
    return (
        <div className={`shadow[0px_4px_6px_rgba(35,35,35,0.06)] ${expandClass ? 'bg-suvaGrey-20 rounded-md' : 'bg-suvaGrey-10 rounded-md'} py-2 cursor-pointer `}>
            <div onClick={() => setExpandClass(!expandClass)} className="flex items-center justify-around mt-3 mb-4 mx-4">
                <p className='font-bold text-lg pr-3 text-center'>{sectionName}</p>
                {expandClass ?
                    <FiChevronUp className={`text-suvaGrey-40 text-2xl mr-auto`} />
                    :
                    <FiChevronDown className='text-suvaGrey-40 text-2xl mr-auto' />
                }
            </div>
            {expandClass ?
                <div>
                    <hr className='opacity-60' />
                    {students.map((student, index) => (
                        <StudentCard
                            key={student.id}
                            student={student}
                            sectionId={sectionId}
                            index={index}
                        />
                    ))}
                </div> : null}
        </div>
    )
}
