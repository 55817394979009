import { useState } from 'react'
import { FiHelpCircle } from 'react-icons/fi'
import medBallImage from '../../../../assets/images/dashboard/measurement/med-ball.svg'
import medBallCompelteImage from '../../../../assets/images/dashboard/measurement/med-ball-complete.svg'
import { Field } from 'formik'
import VideoModal from '../VideoModal'
import Slider from './Slider'
import { BiErrorCircle } from 'react-icons/bi'

export default function MedBall({ value, errors, touched }) {
    const [showModal, setShowModal] = useState(false)
    const [active, setActive] = useState(false)

    return (
        <div className="flex flex-col items-center gap-2 relative" onClick={() => showModal ? setShowModal(false) : null}>
            {showModal && <VideoModal isFirst={true} showModal={showModal} setShowModal={setShowModal} lesson={2} step={16} />}

            {errors['ball_length'] && touched['ball_length'] ?
                <img src={medBallImage} className="h-20 w-20 mx-auto" />
                :
                <img src={medBallCompelteImage} className="h-20 w-20 mx-auto" />
            }
            <div className="flex items-center gap-2 font-bold text-base sm:text-xl mt-10 mb-2">
                <p>اختبار رمي الكرة الطبية من الجلوس على مستوى الصدر</p>
                <FiHelpCircle onClick={() => setShowModal(true)} className='text-darkViolet-100 text-xl cursor-pointer' />
            </div>
            <p className='text-sm sm:text-lg mb-10'>ادخل بعد الكرة</p>
            <Slider fieldName="ball_length" startPoint={21} value={value} />
            <div className="flex justify-around w-3/4 mt-10">
                <div onFocus={() => setActive(true)} onBlur={() => setActive(false)} className={`border-2 ${active ? 'border-darkViolet-100' : errors['ball_length'] && touched['ball_length'] ? 'border-[#D91E1E]' : 'border-suvaGrey-40'} text-darkViolet-100 font-bold rounded-lg px-4 py-5 w-1/4 mx-auto flex items-center justify-between`}>
                    <Field
                        className="border-none focus:ring-0 number-arrow w-24 placeholder:text-suvaGrey-40 text-lg sm:text-2xl placeholder:text-lg sm:placeholder:text-2xl pl-5"
                        placeholder="0"
                        step="1"
                        min="0"
                        name="ball_length"
                        type="number"
                    />
                    <span className={`${value ? 'text-darkViolet-100' : errors['ball_length'] && touched['ball_length'] ? 'text-[#D91E1E]' : 'text-suvaGrey-40'} text-lg sm:text-2xl`}>سم</span>
                </div>
            </div>
            {errors['ball_length'] && touched['ball_length'] ?
                <p className='text-[#D91E1E] mt-1 flex items-center gap-2'> <BiErrorCircle />هذا الحقل مطلوب</p>
                : null}
        </div>
    )
}
