import { useState } from 'react'
import { FiHelpCircle } from 'react-icons/fi'
import shuttleImage from '../../../../assets/images/dashboard/measurement/shuttle.svg'
import shuttleCompleteImage from '../../../../assets/images/dashboard/measurement/shuttle-complete.svg'
import { Field } from 'formik'
import VideoModal from '../VideoModal'
import { BiErrorCircle } from 'react-icons/bi'


export default function ShuttleRun({ value, errors, touched }) {
    const isInvalid = errors.muk_runing && touched.muk_runing ? true : false
    const [showModal, setShowModal] = useState(false)
    const [isActive, setIsActive] = useState(false)

    return (
        <div className='relative w-full flex items-center flex-col' onClick={() => showModal ? setShowModal(false) : null}>
            {showModal && <VideoModal showModal={showModal} setShowModal={setShowModal} lesson={5} step={16} />}
            <div className="flex flex-col items-center gap-8 w-2/3">
                {isInvalid ?
                    <img src={shuttleImage} className="h-20 w-20 mx-auto" />
                    :
                    <img src={shuttleCompleteImage} className="h-20 w-20 mx-auto" />
                }
                <div className="flex items-center gap-2 font-bold text-base sm:text-xl">
                    <p>اختبار التحمل الهوائي: الجري المكوكي</p>
                    <FiHelpCircle onClick={() => setShowModal(true)} className='text-darkViolet-100 text-xl cursor-pointer' />
                </div>
                <p className='text-sm sm:text-lg'>ادخل عدد جولات الطالب </p>

                <div
                    onFocus={() => setIsActive(true)} onBlur={() => setIsActive(false)}
                    className={`${isInvalid ? 'border-[#D91E1E] text-[#D91E1E] border' : value ? 'text-darkViolet-100 border' : isActive ? 'border-2 border-darkViolet-100 text-darkViolet-100' : 'border-suvaGrey-60 text-suvaGrey-60 border'} font-bold rounded-lg px-4 py-4 flex items-center justify-between w-11/12`}>
                    <Field
                        className={`mr-auto border-none text-center focus:ring-0 text-xl sm:text-2xl sm:placeholder:text-2xl placeholder:text-xl p-1 placeholder:text-suvaGrey-60 number-arrow w-24`}
                        min="0"
                        placeholder='00'
                        type="number"
                        dir="ltr"
                        name="muk_runing"
                    />
                    <span className="mr-auto pl-5 text-lg sm:text-xl">جولات</span>
                </div>
            </div>
            {isInvalid ?
                <p className='text-[#D91E1E] mt-3 flex items-center gap-2'> <BiErrorCircle />هذا الحقل مطلوب</p>
                : null}
        </div>
    )
}
