import runImage from '../../../../assets/images/dashboard/measurement/run.svg'
import runCompleteImage from '../../../../assets/images/dashboard/measurement/run-complete.svg'
import { useEffect, useState } from 'react'
import { FiHelpCircle } from 'react-icons/fi'
import First from './Run/First'
import Second from './Run/Second'
import Third from './Run/Third'
import CustomRunField from './Run/CustomRunField'
import VideoModal from '../VideoModal'

const handleTime = (time) => {
    if (time.toString().includes(".")) {
        const timeToArray = time.toString().split(".")
        return [timeToArray[0], timeToArray[1]]
    } else {
        return [time, 0]
    }
}
export default function Run({ values }) {
    const [showModal, setShowModal] = useState(false)

    const [run1Sec, setRun1Sec] = useState('')
    const [run1MiliSec, setRun1MiliSec] = useState('')

    const [isRun2Enabled, setIsRun2Enabled] = useState(values[1] >= 1 ? true : false)
    const [isRun3Enabled, setIsRun3Enabled] = useState(values[2] >= 1 ? true : false)


    useEffect(() => {
        if (values[0]) {
            setRun1Sec(handleTime(values[0])[0])
            setRun1MiliSec(handleTime(values[0])[1])
        }
    }, [])


    return (
        <div className="relative w-full flex flex-col items-center" onClick={() => showModal ? setShowModal(false) : null}>
            {showModal && <VideoModal showModal={showModal} setShowModal={setShowModal} lesson={4} step={16} />}
            <div className="flex flex-col items-center gap-8 w-full">
                <CustomRunField name="run_time_1" time={values[0]} miliSec={run1MiliSec} seconds={run1Sec} />
                {run1Sec >= 1 || run1MiliSec >= 1 ?
                    <img src={runCompleteImage} className="h-20 w-20 mx-auto" />
                    :
                    <img src={runImage} className="h-20 w-20 mx-auto" />
                }
                <div className="flex items-center gap-2 font-bold text-base sm:text-xl">
                    <p>اختبار سرعة الجري</p>
                    <FiHelpCircle onClick={() => setShowModal(true)} className='text-darkViolet-100 text-xl  cursor-pointer' />
                </div>
                <p className='text-sm sm:text-lg'>ادخل مدة جري الطالب</p>

                <First run1Sec={run1Sec} setRun1Sec={setRun1Sec} run1MiliSec={run1MiliSec} setRun1MiliSec={setRun1MiliSec} />

                <Second isRun2Enabled={isRun2Enabled} setIsRun2Enabled={setIsRun2Enabled} value={values[1]} handleTime={handleTime} />

                {isRun2Enabled &&
                    <Third isRun3Enabled={isRun3Enabled} setIsRun3Enabled={setIsRun3Enabled} value={values[2]} handleTime={handleTime} />
                }
                <p className='text-bold text-suvaGrey-20 text-xs'>المحاولة 3/{isRun3Enabled ? '3' : isRun2Enabled ? '2' : '1'}</p>
            </div>
        </div>
    )
}
