import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FiBell,
  FiMonitor,
  FiSettings,
  FiChevronsLeft,
  FiChevronsRight,
  FiUser,
} from "react-icons/fi";
import logo from "../../assets/images/logo/tamheed.svg";
import Notifications from "./Notifications";
import { IoDocumentTextOutline } from "react-icons/io5";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { useEffect } from "react";
import { getUnreadMessages } from "../../utils/apis";
import { useAuth } from "../../context/AuthContext";
import Cookies from "js-cookie";
import WalkThrough from "../modals/WalkThrough";
import useMediaQuery from "../../hooks/useMediaQuery";
import { TbBook2 } from "react-icons/tb";
import { MdOutlineLogout } from "react-icons/md";
import LogoutModal from "./LogoutModal";

export default function SideMenu({
  showNotifications,
  setShowNotifications,
  popuopPos,
  isWalkthrough,
  setPopuopPos,
}) {
  const mobileView = useMediaQuery("(max-width: 768px)");

  const [activePage, setActivePage] = useState("dashboard");
  const [expand, setExpand] = useState(() => {
    if (mobileView) {
      return false;
    }
    return Cookies.get("expandSideMenu") === "false" ? false : true;
  });
  const [unReadMessages, setUnreadMessages] = useState([]);
  const [logout, setLogout] = useState(false);

  const { userType } = useAuth();

  useEffect(() => {
    const getData = async () => {
      const msgs = await getUnreadMessages();
      setUnreadMessages(msgs);
    };
    if (userType() !== 'admin') {
      getData();
    }
    setExpand(mobileView ? false : expand)
  }, [showNotifications]);

  useEffect(() => {
    Cookies.set("expandSideMenu", expand);
  }, [expand]);

  const handleActivePage = (pageName) => {
    setActivePage(pageName);
    setShowNotifications(false);
    setExpand(mobileView ? false : expand)
  };

  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (location.pathname.includes("archive")) {
      setActivePage("archive");
    } else if (location.pathname.includes("reports")) {
      setActivePage("reports");
    } else if (location.pathname.includes("learning")) {
      setActivePage("learning");
    } else if (location.pathname.includes("profile")) {
      setActivePage("profile");
    } else if (location.pathname.includes("setting")) {
      setActivePage("setting");
    } else {
      setActivePage("dashboard");
    }
  }, [pathname, popuopPos]);

  return (
    <div
      className={`${mobileView && expand && "fixed w-full h-full bg-[#2323237A] z-20"
        }`}
    >
      {logout ? (
        <LogoutModal showModal={logout} setShowModal={setLogout} />
      ) : null}
      <div
        className={`h-screen sticky ${mobileView && expand ? "!absolute" : ""
          } top-0 right-0 ${expand
            ? "min-w-[170px] w-[170px]"
            : "md:min-w-[77px] md:w-[77px] min-w-[55px] w-[55px] items-center"
          } bg-persianIndigo-100 flex flex-col z-20 shadow-sidebar text-white text-2xl`}
      >
        <Link to="/dashboard" onClick={() => handleActivePage("dashboard")}>
          <img
            src={logo}
            alt="logo"
            className={`mt-10 mx-auto ${window.innerHeight <= 623 && expand
              ? "w-[80px]"
              : expand
                ? "w-[120px]"
                : "w-full"
              }`}
          />
        </Link>
        <hr className="my-6 border-[#8004EB] w-3/4  mx-auto" />
        <div className="relative">
          {isWalkthrough && popuopPos === "archive" ? (
            <WalkThrough
              title="لوحة القيادة"
              number={`5/${5}`}
              stepsIndex={5}
              subTitle={
                userType() === "manager"
                  ? "قائمة المشرفين"
                  : userType() === "supervisor"
                    ? "قائمة المعلمين"
                    : "تسجيل القياسات"
              }
              text={`الرجاء الضغط على “${userType() === "manager"
                ? "قائمة المشرفين"
                : userType() === "supervisor"
                  ? "قائمة المعلمين"
                  : "تسجيل القياسات"
                }” لعرض الصفحة.`}
              colorOne
              colorTwo
              colorThree
              colorFour
              colorFive
              onclosenow={() => {
                setPopuopPos("");
              }}
              top="-20px"
              right="60px"
              arrowClass="right-top"
            />
          ) : null}
        </div>
        <Link
          to="/dashboard/archive"
          onClick={() => handleActivePage("archive")}
          className={`${window.innerHeight <= 623 ? "mb-8" : "mb-12"} ${expand && "pr-5"
            } flex items-center gap-3`}
        >
          <AiOutlineUnorderedList
            className={`${(activePage === "archive" || pathname.includes("archive")) &&
              !showNotifications &&
              "text-Shamrock-100"
              } w-6 h-6`}
          />
          {expand && (
            <p
              className={`${(activePage === "archive" || pathname.includes("archive")) &&
                !showNotifications &&
                "text-Shamrock-100"
                } text-xs`}
            >
              {userType() === "manager"
                ? "قائمة المشرفين"
                : userType() === "supervisor"
                  ? "قائمة المعلمين"
                  : userType() === "admin"
                    ? "قائمة رؤساء الأقسام"
                    : "تسجيل القياسات"}
            </p>
          )}
        </Link>
        <div className="relative">
          {isWalkthrough && popuopPos === "reports" ? (
            <WalkThrough
              title="تسجيل القياسات"
              number={`2/${2}`}
              stepsIndex={2}
              subTitle="التقارير"
              text="الرجاء الضغط على “التقارير” لعرض الصفحة التالية."
              colorOne
              colorTwo
              onclosenow={() => {
                setPopuopPos("");
              }}
              top="-20px"
              right="150px"
              arrowClass="right-top"
            />
          ) : null}
        </div>
        <Link
          to="/dashboard/reports"
          onClick={() => handleActivePage("reports")}
          className={`${window.innerHeight <= 623 ? "mb-8" : "mb-12"} ${expand && "pr-5"
            } flex items-center gap-3`}
        >
          <IoDocumentTextOutline
            className={`${(activePage === "reports" || pathname.includes("reports")) &&
              !showNotifications &&
              "text-Shamrock-100"
              } w-6 h-6`}
          />
          {expand && (
            <p
              className={`${(activePage === "report" || pathname.includes("report")) &&
                !showNotifications &&
                "text-Shamrock-100"
                } text-xs`}
            >
              التقارير
            </p>
          )}
        </Link>

        {userType() === "admin" ?
          <button
            onClick={() => setLogout(true)}
            className={`${expand && "pr-5"} ${window.innerHeight <= 623 ? "mb-8" : "mb-12"} flex items-center gap-3`}
          >
            <MdOutlineLogout
              className={`${(activePage === "logout" || pathname.includes("logout")) &&
                !showNotifications &&
                "text-Shamrock-100"
                } w-6 h-6`}
            />
            {expand && (
              <p
                className={`text-xs ${(activePage === "logout" || pathname.includes("logout")) &&
                  !showNotifications &&
                  "text-Shamrock-100"
                  }`}
              >
                تسجيل خروج
              </p>
            )}
          </button> : null}

        {userType() !== "admin" ?
          <>
            <div className="relative">
              {isWalkthrough && popuopPos === "learning" ? (
                <WalkThrough
                  title="الفيديوهات"
                  number={`6/6`}
                  stepsIndex={6}
                  subTitle="الإعدادات"
                  text="الرجاء الضغط على “الفيديوهات” لعرض الصفحة."
                  colorOne
                  colorTwo
                  colorThree
                  colorFour
                  colorFive
                  colorSix
                  onclosenow={() => {
                    setPopuopPos("");
                  }}
                  top="-80px"
                  right="155px"
                  arrowClass="right-bottom"
                />
              ) : null}
            </div>

            <Link
              to="/dashboard/learning"
              onClick={() => handleActivePage("learning")}
              className={`${window.innerHeight <= 623 ? "mb-8" : "mb-12"} ${expand && "pr-5"
                } flex items-center gap-3`}
            >
              <FiMonitor
                className={`${(activePage === "learning" || pathname.includes("learning")) &&
                  !showNotifications &&
                  "text-Shamrock-100"
                  } w-6 h-6`}
              />
              {expand && (
                <p
                  className={`${(activePage === "learning" || pathname.includes("learning")) &&
                    !showNotifications &&
                    "text-Shamrock-100"
                    } text-xs`}
                >
                  مراجعة الفيديوهات
                </p>
              )}
            </Link> </> : null}
        {userType() !== "admin" ?
          <>
            <div className="relative">
              {isWalkthrough && popuopPos === "notifications" ? (
                <WalkThrough
                  title="الفيديوهات"
                  number={`2/${2}`}
                  stepsIndex={2}
                  subTitle="التنبيهات"
                  text="الرجاء الضغط على “التنبيهات” لعرض الصفحة التالية."
                  colorOne
                  colorTwo
                  onclosenow={() => {
                    setPopuopPos("");
                  }}
                  top="-20px"
                  right="150px"
                  arrowClass="right-top"
                />
              ) : null}
            </div>
            <button
              onClick={() => {
                setShowNotifications((prev) => !prev);
                setActivePage("notifications");
              }}
              className={`flex items-center gap-3 ${expand && "pr-5"}`}
            >
              {unReadMessages.length ? (
                <span className="absolute h-4 w-4 bg-red-600 rounded-full text-xs right-5 mb-4">
                  {unReadMessages.length}
                </span>
              ) : null}
              <FiBell
                className={`${showNotifications && "text-Shamrock-100"} w-6 h-6`}
              />
              {expand && (
                <p
                  className={`text-xs ${showNotifications && "text-Shamrock-100"
                    } text-xs`}
                >
                  التنبيهات
                </p>
              )}
            </button>
          </>
          : null}
        {userType() !== "admin" ?
          <>
            <div className="relative">
              {isWalkthrough && popuopPos === "profile" ? (
                <WalkThrough
                  title="التنبيهات"
                  number={`2/${2}`}
                  stepsIndex={2}
                  subTitle="الحساب الشخصي"
                  text="الرجاء الضغط على “الحساب الشخصي” لعرض الصفحة التالية."
                  colorOne
                  colorTwo
                  onclosenow={() => {
                    setPopuopPos("");
                  }}
                  top="-40px"
                  right="155px"
                  arrowClass="right-bottom"
                />
              ) : null}
            </div>
            <Link
              to="/dashboard/profile"
              onClick={() => handleActivePage("profile")}
              className={`${expand && "pr-5"} ${window.innerHeight <= 623 ? "my-8" : "my-12"
                } flex items-center gap-3`}
            >
              <FiUser
                className={`${(activePage === "profile" || pathname.includes("profile")) &&
                  !showNotifications &&
                  "text-Shamrock-100"
                  } w-6 h-6`}
              />
              {expand && (
                <p
                  className={`text-xs ${(activePage === "profile" || pathname.includes("profile")) &&
                    !showNotifications &&
                    "text-Shamrock-100"
                    }`}
                >
                  الحساب الشخصي
                </p>
              )}
            </Link>
          </> : null}
        {userType() === "supervisor" ?
          <a
            target="_blank"
            href="https://objectstorage.me-jeddah-1.oraclecloud.com/n/axc1qs8rzqmq/b/mahdacademy/o/guide.png"
            className={`${window.innerHeight <= 623 ? "mb-8" : "mb-12"} ${expand && "pr-5"
              } flex items-center gap-3`}
          >
            <TbBook2
              className={`${(activePage === "learning" || pathname.includes("learning")) &&
                !showNotifications &&
                "text-Shamrock-100"
                } w-6 h-6`}
            />
            {expand && (
              <p
                className={`${(activePage === "learning" || pathname.includes("learning")) &&
                  !showNotifications &&
                  "text-Shamrock-100"
                  } text-xs`}
              >
                دليل المستخدم
              </p>
            )}
          </a> : null}
        {userType() !== "admin" ?
          <>
            <div className="relative">
              {isWalkthrough && popuopPos === "setting" ? (
                <WalkThrough
                  title="التنبيهات"
                  number={`4/4`}
                  stepsIndex={4}
                  subTitle="الإعدادات"
                  text="الرجاء الضغط على “الإعدادات” لعرض الصفحة التالية."
                  colorOne
                  colorTwo
                  colorThree
                  colorFour
                  onclosenow={() => {
                    setPopuopPos("");
                  }}
                  top="-80px"
                  right="155px"
                  arrowClass="right-bottom"
                />
              ) : null}
            </div>
            <Link
              to="/dashboard/settings"
              onClick={() => handleActivePage("setting")}
              className={`flex items-center ${expand && "pr-5"} gap-3`}
            >
              <FiSettings
                className={`${(activePage === "setting" || pathname.includes("setting")) &&
                  !showNotifications &&
                  "text-Shamrock-100"
                  } w-6 h-6`}
              />
              {expand && (
                <p
                  className={`text-xs ${(activePage === "setting" || pathname.includes("setting")) &&
                    !showNotifications &&
                    "text-Shamrock-100"
                    }`}
                >
                  الإعدادات
                </p>
              )}
            </Link>
          </> : null}
        <Link
          onClick={() => {
            setExpand((prev) => !prev);
            setPopuopPos(!expand ? "" : "isExpanded");
          }}
          className={`mt-auto py-12 flex items-center gap-3 ${expand && "pr-5"
            }`}
        >
          {expand ? (
            <>
              <FiChevronsRight />
              <p className="text-xs">تصغير</p>
            </>
          ) : (
            <FiChevronsLeft />
          )}
        </Link>
        <div className="relative">
          {isWalkthrough && popuopPos === "expand" ? (
            <WalkThrough
              title="الإعدادات"
              number={`2/3`}
              stepsIndex={3}
              subTitle="تصغير"
              text="الرجاء الضغط على “تصغير” لإخفاء تفاصيل لوحة التحكم."
              colorOne
              colorTwo
              onclosenow={() => {
                setPopuopPos("");
              }}
              bottom="20px"
              right="155px"
              arrowClass="right-bottom"
            />
          ) : null}
        </div>
        {(showNotifications && userType() !== 'admin') && (
          <Notifications
            isWalkthrough={isWalkthrough}
            setPopuopPos={setPopuopPos}
            expand={expand}
            mobileView={mobileView}
          />
        )}
      </div>
    </div>
  );
}
