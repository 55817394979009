export default {
    national_id: '',
    // day: '',
    // month: '',
    // year: '',
    // isHijri: false,
    email: '',
    password: '',
    passwordConfirmation: '',
};
