import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import SideMenu from "./SideMenu";
import { useWalkthrough } from "../../context/WalkthroughContext";
import { getCurrentProfile } from "../../utils/apis";
import { useAuth } from "../../context/AuthContext";

export default function DashboardLayout() {
    const { isWalkthrough, disableWalkthrough, enableWalkthrough, popuopPos, setPopuopPos } = useWalkthrough()
    const [showNotifications, setShowNotifications] = useState(false);
    const [isProfileNotComplete, setIsProfileNotComplete] = useState(false);
    const auth = useAuth();

    useEffect(() => {
        async function checkProfile() {
            const userProfile = await getCurrentProfile();
            setIsProfileNotComplete(!userProfile.profile.gender || !userProfile.profile.nationality || !userProfile.profile.administration_of_education);
        }

        if (auth.token) {
            checkProfile();
        }
    }, [auth.token]);

    return (

        <div className={`flex min-h-screen bg-white`}>
            <SideMenu
                popuopPos={popuopPos}
                setPopuopPos={setPopuopPos}
                isWalkthrough={isWalkthrough}
                showNotifications={showNotifications}
                enableWalkthrough={enableWalkthrough}
                disableWalkthrough={disableWalkthrough}
                setShowNotifications={setShowNotifications} />
            <div className="w-full overflow-hidden">
                <div onClick={() => setShowNotifications(false)}>
                    <Outlet context={
                        {
                            popuopPos: [popuopPos, setPopuopPos],
                            isWalkthrough
                        }
                    } />
                </div>
            </div>
            {isProfileNotComplete && auth.userType() !== 'admin' ?
                <div className="w-full fixed top-0  z-50 bg-Shamrock-100 text-persianIndigo-100 text-center py-1">الرجاء اكمال الملف الشخصي كاملاً ( الجنس والإدارة والمكتب) </div>
                : null}
        </div>
    )
}
