import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useCourses } from '../context/CoursesContext';

export default function LearningPage() {

    const { courses, userProgress } = useCourses()

    const navigate = useNavigate();

    useEffect(() => {
        const getData = () => {
            if (userProgress === 0) {
                navigate(`/learning/${courses[0].id}`)
            } else {
                if (courses.length && userProgress) {
                    if (userProgress.completed_courses.length) {
                        const completedCourses = userProgress.completed_courses
                        const lastCourse = courses.find((c) => c.id == completedCourses[completedCourses.length - 1])
                        const nextCourseIndex = courses.findIndex((c) => c.id == lastCourse.id) + 1

                        // if (userProgress.completed_courses.length == courses.length) {
                        //     navigate("/dashboard")
                        // } else 
                        if (courses[nextCourseIndex]) {
                            navigate(`/learning/${courses[nextCourseIndex]?.id}`)
                        } else if (lastCourse) {
                            navigate(`/learning/${lastCourse.id}`)
                        } else {
                            navigate(`/learning/${courses[0].id}`)
                        }
                    } else {
                        navigate(`/learning/${courses[0].id}`)
                    }
                } else {
                    setTimeout(() => {
                        if (courses.length && userProgress) {
                            navigate("/dashboard")
                        }
                    }, 3000);
                }
            }
        }
        getData()
    }, [userProgress, courses])

    useEffect(() => {
        document.title = "تمهيد | البرنامج التدريبي";
    }, []);

    return (
        <div className="min-h-full w-full learning-bg">
            <div className='h-[500px] w-full bg-black'></div>
            <div className="bg-black">
            </div>
            <div className="flex px-10 my-8 items-center"> </div>
            <hr className="bg-HawkesBlue-40 mr-5 mb-10" />

            <div className="flex my-5 px-10">
                <div>
                    <p className='text-nero font-bold text-3xl mb-3'>البرنامج التدريبي</p>
                    <div className='flex gap-5 text-darkViolet-60 font-bold'>
                        <span>0 فيديوهات</span>
                        <span className='font-light'>0 د مدة إجمالية </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
