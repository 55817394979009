import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import sortByCompletion from "../../../hooks/sortByCompletion";
import { getSupervisorResultsById, getSupervisors, getTeacherResultsById, getTeachers } from "../../../utils/apis";
import SmallLoading from "../../SmalLoading";
import ManagerDashboardCard from "./ManagerDashboardCard";

export default function ManagerDashboardList() {
  const [supervisors, setSupervisors] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const allsupervisor = await getSupervisors();
      const allTeachers = await getTeachers();
      const tempList = []
      for (const superv of allsupervisor) {
        const supervObj = await getSupervisorResultsById(superv.id)
        if (!tempList.find((s) => s.id === superv.id)) {
          tempList.push({ ...supervObj, ...superv })
        }
      }
      for (const teacher of allTeachers) {
        const teacherObj = await getTeacherResultsById(teacher.id)
        if (!tempList.find((s) => s.id === teacher.id)) {
          tempList.push({ ...teacherObj, ...teacher })
        }
      }
      setLoaded(true)
      setSupervisors(sortByCompletion(tempList).slice(0, Math.floor(window.innerWidth / 260)))
    };
    getData();
  }, []);


  return (
    <>

      <div className="flex justify-between my-6">
        <span className="font-bold text-persianIndigo-100 text-2xl">
          إحصائيات المشرفين والمعلمين
        </span>
        <Link to="/dashboard/archive">
          <span className="text-darkViolet-100 text-sm underline cursor-pointer">
            عرض المزيد
          </span>
        </Link>
      </div>
      <div className={`${!loaded ? "my-10" : ""}`}>
        <SmallLoading isLoading={loaded} size="150" />
      </div>
      {loaded ?
        <div className="flex gap-6 flex-wrap pb-4">
          {supervisors.map((supervisor) => (
            <ManagerDashboardCard key={supervisor.id} supervisor={supervisor} />
          ))}
        </div>
        : null}
    </>
  );
}
