import { useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { BiCheck } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import ReactTimeAgo from 'javascript-time-ago'

import ar from '../../../data/javascript-time-ago/locale/ar.json'

ReactTimeAgo.addDefaultLocale(ar)
const timeAgo = new ReactTimeAgo('ar')

export default function SupervisorDashboardCard({ teacher }) {
    const [onHover, setOnHover] = useState(false)
    const navigate = useNavigate();

    return (
        <div
            onMouseOver={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            onClick={() => navigate(`/dashboard/teachers/${teacher.id}`)}
            className='bg-white w-[249px] h-[205px] shadow-dashboardCard hover:shadow-[0px_0px_24px_2px_rgba(40,220,156,0.4)] rounded-lg flex flex-col pr-4 py-4 cursor-pointer group hover:bg-Shamrock-100 active:bg-Shamrock-100'
        >
            <p className={`${onHover ? 'group-hover:text-white' : 'text-persianIndigo-100'} text-xl font-bold leading-10 pl-3`}>{teacher.name}</p>
            <p className={`my-4 ${onHover ? 'group-hover:text-white' : 'text-persianIndigo-100'}`}>
                <span>{teacher.all_student} طالب</span>
            </p>
            <div className="font-light flex items-center gap-2 text-xs">
                <p className={`${onHover ? 'text-white' : 'text-suvaGrey-60'}`}>اخر نشاط</p>
                <p className={`font-bold ${onHover ? 'text-white' : 'text-suvaGrey-80'}`}> {timeAgo.format(new Date(teacher.last_login))} </p>
            </div>
            <div className='flex items-center gap-2 mt-4'>
                {teacher.all_student == 0 ?
                    <div className={`w-9 h-9 text-xl border-[5px] rounded-full flex items-center ${onHover ? 'border-white' : 'border-suvaGrey-40'} justify-center`}></div>
                    : teacher.all_student == teacher.total_student_completed && teacher.total_student_not_completed == 0 ?
                        <div className={`w-8 h-8 text-xl border-4 rounded-full flex items-center justify-center ${onHover ? 'border-white' : 'border-Shamrock-100'}`}>
                            <BiCheck className={`${onHover ? 'text-white' : 'text-Shamrock-100'} stroke-2`} />
                        </div>
                        :
                        <div className='w-9 h-9'>
                            <CircularProgressbar
                                counterClockwise
                                value={(100 * teacher.total_student_not_completed) / teacher.all_student} strokeWidth="15"
                                styles={buildStyles({
                                    trailColor: onHover ? '#FFFFFF' : '#D6D9E0',
                                    pathColor: onHover ? '#712FED' : '#DB0000',
                                    strokeLinecap: 'butt',
                                })}
                            />
                        </div>
                }
                <div className="">
                    <p className={`${onHover ? 'text-white' : 'text-suvaGrey-80'} mb-1 text-xs`}> {teacher.total_student_completed} بيانات طلاب مكتملة</p>
                    {
                        teacher.all_student == 0 ?
                            <p className={`${onHover ? 'text-white' : 'text-suvaGrey-80'} mb-1 text-xs`}> {teacher.total_student_not_completed} طالب متبقي</p>
                            : teacher.all_student !== teacher.completed && teacher.total_student_not_completed !== 0 ?
                                <p className={`${onHover ? 'text-darkViolet-100' : 'text-[#DB0000]'} font-bold text-xs`}> {teacher.total_student_not_completed} طالب متبقي</p>
                                :
                                <p className={`${onHover ? 'text-white' : 'text-Shamrock-100'} font-bold text-xs`}> {teacher.total_student_not_completed} طالب متبقي</p>
                    }
                </div>
            </div>

        </div>
    )
}
