import { Field } from "formik";
import Checkbox from "../FormTools/CustomCheckbox";
import { Select } from "../FormTools/CustomSelect";
import { BiErrorCircle } from "react-icons/bi";
import dates from "../../data/dates.json";

import nationalities from "../../data/nationalities.json";

const PersonalDataForm = ({
  showMessage,
  message,
  errors,
  touched,
  values
}) => {

  const genders = [
    { label: "أنثى", value: "F" },
    { label: "ذكر", value: "M" },
  ];

  return (
    <>
      <div className="w-2/3 mx-auto my-10">
        <div className="bg-[#F9F9F9] px-12 rounded-lg shadow-page-card">
          <p className="text-darkViolet-100 font-bold text-center text-2xl pt-10 mb-6">
            المعلومات الشخصية
          </p>
          <hr className="bg-HawkesBlue-40 mb-5 lg:mb-10" />
          {showMessage && (
            <div className="bg-[rgba(217,30,30,0.08)] rounded-lg py-2 px-4 text-[#D91E1E] font-bold flex items-center gap-2 mb-4">
              <BiErrorCircle />
              <span>{message}</span>
            </div>
          )}

          <div className="text-HawkesBlue-100 pb-10">
            <div className="grid lg:grid-cols-2 lg:gap-5">
              <div>
                <label htmlFor="name" className="text-HawkesBlue-100 font-bold">
                  الأسم رباعي{" "}
                </label>
                <Field
                  className={`number-arrow border ${errors.name && touched.name
                    ? "border-[#DB0000] placeholder:text-[#DB0000]"
                    : "border-[#D6D9E0]"
                    } w-full py-4 mt-3 placeholder:text-[#D6D9E0] px-6 text-black rounded-lg`}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="الأسم رباعي"
                />
                <div className="my-5">
                  {errors.name && touched.name ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.name}</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="id" className="text-HawkesBlue-100 font-bold">
                  ادخل/ي رقم بطاقة الأحوال/الإقامة
                </label>
                <Field
                  className={`number-arrow border ${errors.national_id && touched.national_id
                    ? "border-[#DB0000] placeholder:text-[#DB0000]"
                    : "border-[#D6D9E0]"
                    } w-full py-4 mt-3 placeholder:text-[#D6D9E0] px-6 text-black rounded-lg`}
                  type="number"
                  id="id"
                  name="national_id"
                  placeholder="رقم الأحوال/الإقامة"
                />
                <div className="my-5">
                  {errors.national_id && touched.national_id ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.national_id}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div>
              <p className="text-HawkesBlue-100 font-bold">
                ادخل/ي تاريخ انتهاء الهوية{" "}
              </p>

              <div className="grid md:grid-cols-3 mt-3 gap-x-5 gap-y-3">
                <Field
                  name="IDday"
                  component={Select}
                  // options={dates[values.isHijriID ? "hijri" : "gregorian"].days}
                  options={dates["gregorian"].days}
                  placeholder="اليوم"
                />
                <Field
                  name="IDmonth"
                  component={Select}
                  // options={
                  //   dates[values.isHijriID ? "hijri" : "gregorian"].months
                  // }
                  options={dates["gregorian"].months}
                  placeholder="الشهر"
                />
                <Field
                  name="IDyear"
                  component={Select}
                  // options={
                  //   dates[values.isHijriID ? "hijri" : "gregorian"].years
                  // }
                  options={dates["gregorian"].years.filter(
                    (year) => year.value > 2022
                  )}
                  placeholder="السنة"
                />

                <div>
                  {errors.IDday && touched.IDday ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.IDday}</span>
                    </div>
                  ) : null}
                </div>

                <div>
                  {errors.IDmonth && touched.IDmonth ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.IDmonth}</span>
                    </div>
                  ) : null}
                </div>

                <div>
                  {errors.IDyear && touched.IDyear ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.IDyear}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="mt-5">
              <p className="text-HawkesBlue-100 font-bold mt-3">
                ادخل/ي تاريخ الميلاد
              </p>

              <div className="grid md:grid-cols-3 mt-3 gap-x-5 gap-y-3">
                <Field
                  name="Bday"
                  component={Select}
                  options={dates[values.isHijri ? "hijri" : "gregorian"].days}
                  placeholder="اليوم"
                />
                <Field
                  name="Bmonth"
                  component={Select}
                  options={dates[values.isHijri ? "hijri" : "gregorian"].months}
                  placeholder="الشهر"
                />
                <Field
                  name="Byear"
                  component={Select}
                  options={dates[values.isHijri ? "hijri" : "gregorian"].years}
                  placeholder="السنة"
                />

                <div>
                  {errors.Bday && touched.Bday ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.Bday}</span>
                    </div>
                  ) : null}
                </div>

                <div>
                  {errors.Bmonth && touched.Bmonth ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.Bmonth}</span>
                    </div>
                  ) : null}
                </div>

                <div>
                  {errors.Byear && touched.Byear ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.Byear}</span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="lg:mt-3 mt-4">
                <Checkbox
                  label="تاريخ هجري"
                  name="isHijri"
                  id="isHijri"
                  bgColor="#522EA1"
                />
              </div>
            </div>

            <div className="mt-5 grid lg:grid-cols-2 lg:gap-5">
              <div className="">
                <p className="text-HawkesBlue-100 font-bold mb-3">الجنسية</p>
                <div className="w-full">
                  <Field
                    name="nationality"
                    component={Select}
                    options={nationalities}
                    placeholder="إختار الجنسية"
                  />
                </div>

                <div className="my-5">
                  {errors.nationality && touched.nationality ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.nationality}</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="">
                <p className="text-HawkesBlue-100 font-bold mb-3">الجنس</p>
                <div className="w-full">
                  <Field
                    component={Select}
                    name="gender"
                    options={genders}
                    placeholder="إختار الجنس"
                  />
                </div>

                <div className="my-5">
                  {errors.gender && touched.gender ? (
                    <div className="flex gap-1">
                      <BiErrorCircle className="text-error text-2xl font-light" />
                      <span className="text-error">{errors.gender}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="mt-5">
              <p className="text-HawkesBlue-100 font-bold mb-3">رقم الجوال</p>
              <div className="w-full relative">
                <p className="absolute left-4 top-4 text-suvaGrey-80">966+</p>
                <Field
                  className={`form-input focus:ring-0 focus:border-darkViolet-100 rounded-lg ${errors.phone_number && touched.phone_number
                    ? "border-[#DB0000] placeholder:text-[#DB0000]"
                    : "border-[#D6D9E0] placeholder:text-[#D6D9E0]"
                    } w-full py-4 pl-16 pr-6 text-nero number-arrow`}
                  dir="ltr"
                  type="number"
                  name="phone_number"
                  placeholder="رقم الجوال"
                />
              </div>
              <div className="my-5">
                {errors.phone_number && touched.phone_number ? (
                  <div className="flex gap-1">
                    <BiErrorCircle className="text-error text-2xl font-light" />
                    <span className="text-error">{errors.phone_number}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDataForm;
