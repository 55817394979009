import { Field, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import HeightSitting from '../students/MeasurementsElements/HeightSitting'
import HeightStanding from '../students/MeasurementsElements/HeightStanding'
import Weight from '../students/MeasurementsElements/Weight'
import WingspanHeight from '../students/MeasurementsElements/WingspanHeight'


export default function BodyMeasurements({ values, errors, touched }) {
    const { setFieldValue } = useFormikContext();

    const [bmi, setBmi] = useState(0)
    const [bmiResult, setBmiResult] = useState('طبيعي')
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (values.height_standing && values.body_weight) {
            setBmi(values.body_weight / ((values.height_standing * 0.01) * values.height_standing * 0.01))
        }
    }, [values.height_standing, values.body_weight])


    useEffect(() => {
        if (bmi) {
            let bmiResultTemp = ''
            if (bmi < 18.5) {
                bmiResultTemp = 'اقل من الطبيعي'
            } else if (bmi >= 18.5 && bmi <= 24.9) {
                bmiResultTemp = 'طبيعي'
            } else if (bmi >= 25) {
                bmiResultTemp = 'وزن زائد'
            }
            setBmiResult(bmiResultTemp)
            setFieldValue('bmi', Math.floor(bmi))
        }
    }, [bmi])


    return (
        <div className="flex flex-col items-center gap-28 mt-10">
            <HeightStanding value={0} touched={touched} errors={errors} />

            <HeightSitting value={0} touched={touched} errors={errors} />

            <WingspanHeight value={0} touched={touched} errors={errors} />

            <Weight value={0} touched={touched} errors={errors} />



            <div className="flex flex-col items-center gap-10 w-full" onClick={() => showModal ? setShowModal(false) : null}>
                <div className="flex items-center gap-2">
                    <p>مؤشر كتلة الجسم</p>
                </div>
                <div className="bg-Shamrock-100 text-white font-bold rounded-lg px-4 py-6 flex justify-center items-center gap-10 w-[268px]">
                    <span>{Math.floor(bmi)}</span>
                    {bmiResult &&
                        <span>{bmiResult}</span>
                    }
                </div>
            </div>

            <div className="flex flex-col items-center gap-10 w-full mb-10">
                <p>ملاحظات</p>
                <Field name="notes_1" as="textarea" className='border-suvaGrey-20 w-2/3 rounded-lg resize-none placeholder:text-suvaGrey-40 text-sm p-5' rows="10" placeholder='إذا لديك اي ملاحظة...' />
            </div>

        </div>
    )
}
