import { Link } from 'react-router-dom'
import empybox from '../../assets/images/dashboard/emptybox.svg'

export default function AdminEmptyState({ userType }) {

    return (
        <div className="flex justify-center items-center flex-col gap-5 mb-5">

            <img src={empybox} className='text-4xl w-20 text-darkViolet-100 mt-5' />
            <p className='text-xl font-bold'>
                {userType === 'manager' ? 'ليس لديك مشرفين أو معلمين مسجلين' : userType === 'supervisor' ? 'ليس لديك معلمين مسجلين' : userType === 'teacher' ? 'ليس لديك بيانات مسجلة' : ''}
            </p>
            <Link
                to={userType === 'manager' ? '/dashboard/add-supervisor' : userType === 'supervisor' ? '/dashboard/add-teacher' : userType === 'teacher' ? '/dashboard/add-students' : ''}
                className='mt- flex justify-center items-center bg-persianIndigo-100 shadow-button rounded-lg text-white text-sm font-light w-52 h-14'>
                {userType === 'manager' ? 'إبدأ بتسجيل المشرفين والمعلمين' : userType === 'supervisor' ? 'إبدأ بتسجيل المعلمين' : userType === 'teacher' ? 'إبدأ بتسجيل قياسات الطلاب' : ''}
            </Link>
        </div>
    )
}
