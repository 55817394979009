import { Form, Formik } from "formik";
import { FiSave } from "react-icons/fi";
import validationSchema from "../utils/form/supervisors/register/validationSchema";
import formInitialValues from "../utils/form/supervisors/register/initialValues";
import logo from "../assets/images/logo/tamheed-white.svg";
import { useEffect, useState } from "react";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import Steps from "../components/Steps";
import SignupForm from "../components/forms/Signup";
import axios from "axios";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { useWalkthrough } from "../context/WalkthroughContext";
import SupPersonalDataForm from "../components/forms/Supervisors/PersonalDataForm";

const steps = [
    {
        name: "signup",
        label: "المعلومات الشخصية للمشرف",
    },
    {
        name: "email",
        label: "البريد الإلكتروني",
    },
];

export default function SupervisorsRegister() {
    useEffect(() => {
        document.title = "تمهيد | نموذج تسجيل المشرفين";
    }, []);

    let stepsIndex = 3;

    const [activeStep, setActiveStep] = useState(0);
    const [showMessage, setShowMessage] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState(false);
    const [profileId, setProfileId] = useState(null);
    const [showVerification, setShowVerification] = useState(false);
    const [message, setMessage] = useState("");

    const { enableWalkthrough, setPopuopPos } = useWalkthrough();

    const currentValidationSchema = validationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1;

    useEffect(() => {
        enableWalkthrough();
        setPopuopPos("init");
    }, []);

    async function submitForm(values, actions) {
        actions.setSubmitting(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/signup/`, {
                profile: profileId,
                email: values.email.toLowerCase(),
                password: values.password,
            })
            .then(function (response) {
                setShowVerification(true);
                actions.setSubmitting(false);
            })
            .catch(function () {
                actions.setSubmitting(false);
                setEmailErrorMessage(true);
            });
    }

    function handleSubmit(values, actions) {
        if (activeStep === 0) {
            actions.setSubmitting(true);

            const data = {
                name: values.name,
                national_id: values.national_id,
                national_id_expiry: `${values.IDyear}-${values.IDmonth}-${values.IDday}`,
                date_of_birth_hijri: `${values.IDyear}-${values.IDmonth}-${values.IDday}`,
                date_of_birth: `${values.Byear}-${values.Bmonth}-${values.Bday}`,
                gender: values.gender,
                nationality: values.nationality,
                phone_number: values.phone_number,
                administration_of_education: values.administration_of_education,
                educational_office: values.educational_office,
                educational_region: values.educational_region,
            };

            if (values.isHijri) {
                delete data.date_of_birth;
            } else {
                delete data.date_of_birth_hijri;
            }

            axios
                .post(`${process.env.REACT_APP_API_URL}/user_create_supervisor/`, data)
                .then(function (response) {
                    actions.setSubmitting(false);
                    if (response.data == false) {
                        setShowMessage(true);
                        setMessage("حدث خطأ ما، الرجاء التواصل مع المشرف لإرسال رابط تسجيل جديد");
                    } else if (response.data === "user already registered") {
                        setShowMessage(true);
                        setMessage("رقم الهوية مرتبط بحساب");
                    } else {
                        setActiveStep(activeStep + 1);
                        setShowMessage(false);
                        setProfileId(response.data.id);
                    }
                })
                .catch(function (error) {
                    if (error.response.data.national_id) {
                        setMessage('national_id');
                    } else {
                        setMessage("حدث خطأ ما، الرجاء التواصل مع المشرف لإرسال رابط تسجيل جديد");
                    }
                    setShowMessage(true);
                });
        } else if (isLastStep) {
            submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    return (
        <>
            {showVerification ? (
                <div className="custom-bg flex flex-col justify-around py-16">
                    <img src={logo} alt="Logo" className="mx-auto w-44" />

                    <div className="mx-auto bg-white rounded-2xl my-auto flex flex-col gap-5 items-center py-10 px-2">
                        <MdOutlineMailOutline className="text-darkViolet-100" size="42px" />
                        <span className="text-persianIndigo-100 text-3xl font-bold w-2/3 text-center">
                            تم إرسال لبريدك الإلكتروني رابط التحقق
                        </span>
                        <p className="font-light mb-4">الرجاء إستخدام الرابط للمتابعة</p>
                    </div>
                </div>
            ) : (
                <div className="custom-bg flex flex-col text-white pb-20">
                    <Steps currentPageIndex={activeStep} steps={steps} />
                    <p className="text-center font-bold text-2xl pt-2">نموذج تسجيل المشرفين</p>
                    <Formik
                        enableReinitialize={true}
                        validationSchema={currentValidationSchema}
                        initialValues={formInitialValues}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => {
                            const { errors, touched, isValid, dirty, values, isSubmitting } =
                                formik;
                            return (
                                <Form>
                                    {activeStep == 0 ? (
                                        <SupPersonalDataForm
                                            showMessage={showMessage}
                                            message={message}
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    ) : (<SignupForm
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        emailErrorMessage={emailErrorMessage}
                                        isWalkthrough={false}
                                        stepsIndex={stepsIndex}
                                        popuopPos=""
                                        setPopuopPos={() => null}
                                    />
                                    )}
                                    <div className="w-2/3 mx-auto mb-10 flex">
                                        {activeStep !== 0 ? (
                                            <button
                                                type="button"
                                                onClick={() => setActiveStep((prev) => prev - 1)}
                                                className="flex items-center border border-white rounded-lg text-white px-6"
                                            >
                                                <IoChevronForwardOutline />
                                            </button>
                                        ) : (
                                            <Link
                                                to="/"
                                                className="border border-persianIndigo-100 text-persianIndigo-100 flex justify-center items-center px-5 text-xl rounded-lg"
                                            >
                                                <BiChevronRight />
                                            </Link>
                                        )}
                                        <button
                                            type="submit"
                                            className="flex items-center bg-[#F9F9F9] shadow-button rounded-lg text-persianIndigo-100 disabled:text-HawkesBlue-90 font-[600] px-10 xl:w-1/4 lg:w-1/3 w-1/2 mr-auto h-14"
                                        >
                                            <span className={`${isSubmitting && ""} ml-auto`}>
                                                {activeStep == 0
                                                    ? "حفظ والإستمرار"
                                                    : isLastStep
                                                        ? "الحفظ والإتمام"
                                                        : "التالي"}
                                            </span>
                                            {isSubmitting ? (
                                                <svg
                                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-persianIndigo-100"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            ) : isLastStep ? (
                                                <FiSave />
                                            ) : (
                                                <IoChevronBackOutline />
                                            )}
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            )}
        </>
    );
}
