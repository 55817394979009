import { Field } from "formik";

export default function Checkbox({ label, name, id, bgColor }) {
    return (
        <div className="flex gap-3 items-center">
            <Field type="checkbox" className={`form-checkbox focus:ring-0 text-[${bgColor}] form-checkbox focus:ring-0 peer text-Shamrock-100 rounded-sm border-[#BFC2C9] bg-white`} name={name} id={id} />
            <label htmlFor={id}>{label}</label>
        </div>
    )
}
