import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { getMangerCards, getSupervisorCards, getTeacherCards } from "../../../utils/apis";

export default function StatsCard({ setActiveStat, type, selectedSection, selectedOffices, selectedTeachers, selectedSupervisor, setTotalMuk, setTotalRun }) {
    const [statistic, setStatistic] = useState(null)

    const { userType } = useAuth();

    useEffect(() => {
        const getData = async () => {
            if (userType() === 'teacher') {
                const data = await getTeacherCards(type, selectedSection !== "" ? `student__school_section__section=${selectedSection}` : "")
                setStatistic(data[type])
                if (type === 'total_run') {
                    setTotalRun(data[type])
                } else if (type === 'muk_runing') {
                    setTotalMuk(data[type])
                }
            } else if (userType() === 'supervisor') {
                const param = `${selectedSection ? `student__school_section__section=${selectedSection}&` : ""}${selectedOffices ? `student__school_section__teacher__profile__educational_office=${selectedOffices}&` : ""}${selectedTeachers ? `${selectedTeachers}&` : ""}${selectedSupervisor ? `${selectedSupervisor}&` : ""}`
                const data = await getSupervisorCards(type, param)
                setStatistic(data[type])
                if (type === 'total_run') {
                    setTotalRun(data[type])
                } else if (type === 'muk_runing') {
                    setTotalMuk(data[type])
                }
            } else if (userType() === 'manager') {
                const param = `${selectedOffices ? `student__school_section__teacher__profile__educational_office=${selectedOffices}&` : ""}${selectedSupervisor ? `student__school_section__teacher__supervisor_user=${selectedSupervisor}&` : ""}`
                const data = await getMangerCards(type, param)
                setStatistic(data[type])
                if (type === 'total_run') {
                    setTotalRun(data[type])
                } else if (type === 'muk_runing') {
                    setTotalMuk(data[type])
                }
            }
        }
        getData()
    }, [selectedSection, selectedOffices, selectedTeachers, selectedSupervisor])

    if (statistic) {
        if (type === "muk_runing") {
            return (
                <div
                    className="reports-shuttle-bg text-center py-4 w-[522px] h-[160px] text-white rounded-2xl cursor-pointer"
                    onClick={() => setActiveStat("muk_runing")}
                >
                    <p className="text-2xl mb-5 font-bold">الجري المكوكي</p>
                    <p className="text-3xl my-2 font-bold">
                        {Math.round(statistic.top_muk_runing_virtical)} جولات
                    </p>
                    <p>افضل القياسات</p>
                </div>
            );
        } else if (type === "total_run") {
            return (
                <div
                    className="reports-speed-bg text-center py-4 w-[522px] h-[160px] rounded-2xl cursor-pointer"
                    onClick={() => setActiveStat("total_run")}
                >
                    <p className="text-2xl mb-5 font-bold">سرعة الجري</p>
                    <p className="text-3xl my-2 font-bold text-persianIndigo-100">
                        {statistic.top_total_run_virtical} د/20 متر
                    </p>
                    <p>افضل القياسات</p>
                </div>
            );
            return <></>;
        }
        else {
            return null
        }
    } else {
        if (type === "muk_runing") {
            return (
                <div
                    className="reports-shuttle-bg text-center py-4 w-[522px] h-[160px] text-white rounded-2xl cursor-pointer"
                    onClick={() => setActiveStat("muk_runing")}
                >
                    <p className="text-2xl mb-5 font-bold">الجري المكوكي</p>
                    <p className="text-3xl my-2 font-bold">
                        0 جولات
                    </p>
                    <p>افضل القياسات</p>
                </div>
            );
        } else if (type === "total_run") {
            return (
                <div
                    className="reports-speed-bg text-center py-4 w-[522px] h-[160px] rounded-2xl cursor-pointer"
                    onClick={() => setActiveStat("total_run")}
                >
                    <p className="text-2xl mb-5 font-bold">سرعة الجري</p>
                    <p className="text-3xl my-2 font-bold text-persianIndigo-100">
                        0 د/20 متر
                    </p>
                    <p>افضل القياسات</p>
                </div>
            );
            return <></>;
        }
        else {
            return null
        }
    }
}