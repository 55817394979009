import { useEffect, useState } from "react";
// import cover from "../assets/pdf/Mcover.png";
import header from "../assets/pdf/Mheader.png";
import axios from "axios";
import { useAuth } from "../context/AuthContext";

export default function PdfSupervisor() {
  const { token } = useAuth();

  const [supData, setSupData] = useState(null);

  const [pagesData, setPagesData] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/supervisor-combined-results/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSupData(res.data);
      });
  }, []);

  useEffect(() => {
    document.title = "تقرير عام";
  }, []);

  const date = new Date().toLocaleDateString("ar-UA", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  function divideArray(x) {
    const dividedArray = [];

    dividedArray.push(x.slice(0, 12));

    for (let i = 12; i < x.length; i += 17) {
      dividedArray.push(x.slice(i, i + 17));
    }

    return dividedArray;
  }

  useEffect(() => {
    if (supData) {
      if (supData.teachers.length > 12) {
        setPagesData(divideArray(supData.teachers));
      } else {
        setPagesData([supData.teachers]);
      }
    }
  }, [supData]);

  return (
    <div className="flex flex-col items-center print:items-start gap-16 print:gap-0 py-16 print:p-0 bg-gray-200">
      <button
        className="print:hidden bg-daisyBush text-white px-4 py-2 rounded-md fixed top-14 right-10 font-bold z-50"
        onClick={() => {
          window.print();
        }}
      >
        طباعة
      </button>

      {supData &&
        pagesData?.map((data, index) => {
          return (
            <div
              id={`page-${index + 1}`}
              className="bg-white w-[210mm] h-[297mm] relative shadow-2xl"
            >
              <div className="header h-[180px] relative isolate text-white">
                <img className="-z-10 w-full h-full" src={header} alt="" />
                <div className="absolute top-[25%] left-[50%] translate-x-[-50%] flex flex-col items-center justify-center gap-4">
                  <div className="">المشرف</div>
                  <div className=" text-xl font-bold text-center max-w-[200px]">
                    {supData?.name}
                  </div>
                </div>

                <div className="absolute top-[38px] left-[37px] flex gap-2 items-center">
                  <div className="">إدارة التعليم</div>
                  <div className="text-xs font-bold max-w-[100px] text-center">
                    {supData?.administration_of_education}
                  </div>
                </div>
                <div className="absolute top-[85px] left-[37px] flex gap-2 items-center">
                  <div className="">مكتب التعليم</div>
                  <div className="text-xs font-bold max-w-[100px] text-center">
                    {supData?.education_office}
                  </div>
                </div>
              </div>

              <div className="p-10">
                {index === 0 && (
                  <div className="stats mt-9">
                    <div className="w-full flex gap-10">
                      <StatsBlock
                        title={"المعلمين"}
                        total={supData?.general_results?.all_teacher}
                        completed={
                          supData?.general_results?.total_completed_teacher
                        }
                        remainder={
                          supData?.general_results?.total_not_completed_teacher
                        }
                      />
                      <StatsBlock
                        title={"الطلاب"}
                        total={supData?.general_results?.all_student}
                        completed={
                          supData?.general_results?.total_student_completed
                        }
                        remainder={
                          supData?.general_results?.total_student_not_completed
                        }
                      />
                    </div>
                  </div>
                )}

                <div className="second-table mt-11">
                  <div className=" flex gap-[2px] text-white font-bold mb-2">
                    <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center rounded-r-2xl">
                      المعلم
                    </div>
                    <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                      الحالة
                    </div>

                    <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                      عدد الطلاب
                    </div>
                    <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                      الطلاب المنجزون
                    </div>
                    <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center rounded-l-2xl">
                      {" "}
                      الطلاب المتبقين
                    </div>
                  </div>

                  <div className="flex flex-col gap-[2px]">
                    {data.map((teacher, index) => (
                      <div key={index} className=" flex gap-[2px] text-sm">
                        <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                          {teacher.name}
                        </div>
                        <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                          <span
                            className={`${teacher.general_results
                                .total_student_completed ===
                                teacher.general_results?.all_student
                                ? "text-Shamrock-100 bg-Shamrock-10"
                                : "text-gold-100 bg-gold-10"
                              } py-1 px-2`}
                          >
                            {teacher.general_results
                              ?.total_student_completed ===
                              teacher.general_results?.all_student
                              ? "منجز"
                              : "غير منجز"}
                          </span>
                        </div>
                        <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                          {teacher.general_results?.all_student}
                        </div>
                        <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center text-Shamrock-100">
                          {teacher.general_results?.total_student_completed}
                        </div>
                        <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center text-gold-100">
                          {teacher.general_results?.total_student_not_completed}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="absolute bottom-[44px] right-[0] pr-10 text-[#240A63] flex gap-3 items-center font-bold text-l">
                تاريخ التصدير
                <div className=" flex gap-2">
                  <div>{date}</div>
                </div>
              </div>
              <div className="absolute bottom-[44px] left-[0] pl-10 text-[#240A63] font-bold text-l">
                {index + 1}
              </div>
            </div>
          );
        })}
    </div>
  );
}

const StatsBlock = (props) => {
  const { title, total, completed, remainder, isWide } = props;
  let percentage = (completed / total) * 100;

  if (isNaN(percentage)) {
    percentage = 0;
  }

  const color = (t) => {
    // commented class names has to be here for tailwind compiler
    // text-Shamrock-100
    // bg-Shamrock-100
    // text-gold-100
    // bg-gold-100
    if (percentage > 50) {
      return `${t}-Shamrock-100`;
    } else {
      return `${t}-gold-100`;
    }
  };

  return (
    <div className="text-black flex flex-col gap-6">
      <h3 className="text-2xl">{title}</h3>
      <div className={`flex ${isWide ? "gap-16" : "gap-7"}`}>
        <div className="">
          <p className="leading-none font-bold text-5xl">{total || "00"}</p>
          <p className="">العدد الكلي</p>
        </div>
        <div className="">
          <p
            className={`leading-none font-bold text-5xl ${completed ? "text-Shamrock-100" : "text-[#E8E8E8]"
              }`}
          >
            {completed || "00"}
          </p>
          <p className="">العدد المنجز</p>
        </div>
        <div className="">
          <p className={`leading-none font-bold text-5xl text-[#E8E8E8]`}>
            {remainder || "00"}
          </p>
          <p className="">العدد المتبقي</p>
        </div>
      </div>
      <div className="flex items-center gap-2 overflow-hidden">
        <div className={`${color("text")} w-fit`}>{percentage.toFixed(1)}%</div>
        <div className="h-2 grow bg-[#E8E8E8] mt-1">
          <div
            className={`${color("bg")} h-2`}
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};
