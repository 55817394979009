import { useState } from "react";
import { useEffect } from "react";
import { getAdminResultsWithAdministration, getMangerResultsById } from "../../../utils/apis";

export default function AdminStatsCard({ selectedAdministration, selectedManager, initStatistic }) {
    const [stuPercentage, setStuPercentage] = useState(0)
    const [teacherPercentage, setTeacherPercentage] = useState(0)
    const [supervisorsPercentage, setSupervisorsPercentage] = useState(0)
    const [statistic, setStatistic] = useState(null)

    const color = (t, percentage) => {
        if (percentage > 50) {
            return `${t}-Shamrock-100`;
        } else {
            return `${t}-gold-100`;
        }
    };

    useEffect(() => {
        const getData = async () => {
            if (selectedAdministration === '' && selectedManager === '') {
                setStatistic(initStatistic)
            } else if (selectedManager !== '') {
                const data = await getMangerResultsById(selectedManager)
                setStatistic(data)
            } else if (selectedAdministration !== '' && selectedManager === '') {
                const data = await getAdminResultsWithAdministration(selectedAdministration)
                setStatistic(data)
            }
        }
        getData()
    }, [selectedAdministration, selectedManager])

    useEffect(() => {
        if (statistic) {
            setStuPercentage((statistic.total_student_completed / statistic.all_student) * 100 || 0)
            setTeacherPercentage((statistic.total_completed_teacher / statistic.all_teacher) * 100 || 0)
            setSupervisorsPercentage((statistic.total_completed_supervisor / statistic.all_supervisor) * 100 || 0)
        }
    }, [statistic])

    return (
        <div className="flex flex-wrap mt-5 gap-10 justify-center">
            <div className="w-[340px] h-[226px] px-6 bg-blueDiamond text-white rounded-2xl shadow-[0px_0px_24px_rgba(35,35,35,0.06)] flex justify-center items-center flex-col">
                <p className="">عدد المشرفين</p>
                <p className="my-3 text-3xl">{statistic?.all_supervisor || 0}</p>
                <p className="mb-5">مشرف</p>
                <div className="w-full">
                    <div className='flex items-center flex-col mt-1 gap-2 overflow-hidden'>
                        <div className='h-2 grow bg-white/40 w-full'>
                            <div
                                className={`bg-Shamrock-100 h-2`}
                                style={{ width: `${supervisorsPercentage}%` }}
                            ></div>
                        </div>
                        <div className={`${color("text", supervisorsPercentage)} w-fit text-sm`}>{supervisorsPercentage.toFixed(1)}%</div>
                    </div>
                </div>
            </div>
            <div className="w-[340px] h-[226px] px-6 bg-persianIndigo-100 text-white rounded-2xl shadow-[0px_0px_24px_rgba(35,35,35,0.06)] flex justify-center items-center flex-col">
                <p className="">عدد المعلمين</p>
                <p className="my-3 text-3xl">{statistic?.all_teacher || 0}</p>
                <p className="mb-5">معلم</p>
                <div className="w-full">
                    <div className='flex items-center flex-col mt-1 gap-2 overflow-hidden'>
                        <div className='h-2 grow bg-white/40 w-full'>
                            <div
                                className={`bg-Shamrock-100 h-2`}
                                style={{ width: `${teacherPercentage}%` }}
                            ></div>
                        </div>
                        <div className={`${color("text", teacherPercentage)} w-fit text-sm`}>{teacherPercentage.toFixed(1)}%</div>
                    </div>
                </div>
            </div>
            <div className="w-[340px] h-[226px] px-6 bg-darkViolet-100 text-white rounded-2xl shadow-[0px_0px_24px_rgba(35,35,35,0.06)] flex justify-center items-center flex-col">
                <p className="">عدد الطلاب</p>
                <p className="my-3 text-3xl">{statistic?.all_student || 0}</p>
                <p className="mb-5">طالب</p>
                <div className="w-full">
                    <div className='flex items-center flex-col mt-1 gap-2 overflow-hidden'>
                        <div className='h-2 grow bg-white/40 w-full'>
                            <div
                                className={`bg-Shamrock-100 h-2`}
                                style={{ width: `${stuPercentage}%` }}
                            ></div>
                        </div>
                        <div className={`${color("text", stuPercentage)} w-fit text-sm`}>{stuPercentage.toFixed(1)}%</div>
                    </div>
                </div>
            </div>
            <div className="w-[340px] gap-6 h-[226px] px-6 flex justify-center items-center flex-col">
                <div className="bg-Shamrock-10 w-[340px] h-[101px] border border-[#28DC9C] text-[#28DC9C] shadow-[0px_0px_24px_rgba(35,35,35,0.06)] rounded-2xl flex flex-col items-center justify-center gap-1">
                    <p className="text-4xl font-bold">{statistic?.total_student_completed || 0}</p>
                    <p>طالب منجز</p>
                </div>
                <div className="bg-gold-10 w-[340px] h-[101px] border border-[#EDD306] text-[#EDD306] shadow-[0px_0px_24px_rgba(35,35,35,0.06)] rounded-2xl flex flex-col items-center justify-center gap-1">
                    <p className="text-4xl font-bold">{statistic?.total_student_not_completed || 0}</p>
                    <p>طالب غير منجز</p>
                </div>
            </div>
        </div>
    )
}
