import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import sortByCompletion from '../../../hooks/sortByCompletion'
import { getTeacherResultsById, getTeachers } from '../../../utils/apis'
import SupervisorDashboardCard from './SupervisorDashboardCard'

export default function SupervisorDashboardList() {

    const [teachers, setTeachers] = useState([])

    useEffect(() => {
        const getData = async () => {
            const allTeachers = await getTeachers()
            const tempList = []
            for (const teacher of allTeachers) {
                const teacherObj = await getTeacherResultsById(teacher.id)
                if (!tempList.find((s) => s.id === teacher.id)) {
                    tempList.push({ ...teacherObj, ...teacher })
                }
            }
            setTeachers(sortByCompletion(tempList).slice(0, Math.floor(window.innerWidth / 260)))
        }
        getData()
    }, [])


    return (
        <>
            <div className='flex justify-between my-6'>
                <span className='font-bold text-persianIndigo-100 text-2xl'>إحصائيات المعلمين</span>
                <Link to="/dashboard/archive">
                    <span className='text-darkViolet-100 text-sm underline cursor-pointer'>عرض المزيد</span>
                </Link>
            </div>
            <div className="flex gap-6 pb-5">
                {teachers ? teachers.map((teacher) => (
                    <SupervisorDashboardCard key={teacher.id} teacher={teacher} />
                )) : null}
            </div>
        </>
    )
}
