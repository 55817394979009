import { useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import { getSupervisorsByOffice } from '../../../../utils/apis'

export default function SupervisorsFilter({ setSelectedSupervisor, selectedOffices }) {
    const [menuOpen, setMenuOpen] = useState(false)
    const [options, setOptions] = useState([{ label: "جميع المشرفين", value: "" }])

    useEffect(() => {
        const getData = async () => {
            const allOptions = await getSupervisorsByOffice(`educational_office=${selectedOffices}`)
            setOptions([{ label: "جميع المشرفين", value: "" }, ...allOptions.map((option) => ({ label: option.name, value: option.id }))])
        };
        getData()
    }, [selectedOffices])

    return (
        <div className="lg:w-72">
            <ReactSelect
                options={options}
                defaultValue={[options[0]]}
                onChange={(e) => setSelectedSupervisor(e.value)}
                isSearchable={false}
                onMenuOpen={() => setMenuOpen(true)}
                onMenuClose={() => setMenuOpen(false)}
                styles={{
                    control: (styles, { menuIsOpen, getValue }) => ({
                        ...styles,
                        borderRadius: '8px',
                        borderBottomLeftRadius: menuIsOpen ? '0' : '2',
                        borderBottomRightRadius: menuIsOpen ? '0' : '2',
                        border: 'unset',
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: '0',
                        color: '#712FED',
                        backgroundColor: getValue()[0].value != 0 ? '#712FED' : '#F1EAFD',
                    }),
                    menu: (styles) => ({
                        ...styles,
                        margin: 0,
                        backgroundColor: '#F1EAFD',
                        border: 'unset',
                        borderTopLeftRadius: '0',
                        borderTopRightRadius: '0',
                        boxShadow: 'unset',
                        padding: '8px',
                        ":before": {
                            content: "''",
                            backgroundColor: '#E3D5FB',
                            margin: '0 auto',
                            width: '80%',
                            height: '1px',
                            display: 'block',
                        }
                    }),
                    dropdownIndicator: (styles, { getValue }) => ({ ...styles, scale: menuOpen ? '-1' : '1', cursor: 'pointer', color: getValue()[0].value != 0 ? 'white' : '#712FED', ":hover": { color: '#712FED' } }),
                    indicatorSeparator: () => null,
                    singleValue: (styles, { getValue }) => ({ ...styles, color: getValue()[0].value != 0 ? 'white' : '#712FED', padding: '10px', cursor: 'pointer' }),
                    option: (styles, { isSelected }) => ({
                        ...styles, color: '#712FED', padding: '10px', cursor: 'pointer',
                        backgroundColor: 'unset',
                        fontWeight: isSelected ? 'bold' : 'normal',
                    })
                }}
            />
        </div>
    )
}
