import React from "react";

function StatsBlock({ title, total, completed, remainder }) {
  let percentage = (completed / total) * 100;

  if (isNaN(percentage)) {
    percentage = 0;
  }

  const color = (t) => {
    // commented class names has to be here for tailwind compiler
    // text-Shamrock-100
    // bg-Shamrock-100
    // text-gold-100
    // bg-gold-100
    if (percentage > 50) {
      return `${t}-Shamrock-100`;
    } else {
      return `${t}-gold-100`;
    }
  };

  return (
    <div className='text-white'>
      <h3>{title}</h3>
      <div className='mt-3 flex gap-4'>
        <div className=''>
          <p className='leading-none font-bold text-3xl md:text-5xl'>
            {total || "00"}
          </p>
          <p className='text-xs md:text-base'>العدد الكلي</p>
        </div>
        <div className=''>
          <p
            className={`leading-none font-bold text-3xl md:text-5xl ${
              completed ? "text-Shamrock-100" : "text-persianIndigo-60"
            }`}
          >
            {completed || "00"}
          </p>
          <p className='text-xs md:text-base'>العدد المنجز</p>
        </div>
        <div className=''>
          <p
            className={`leading-none font-bold text-3xl md:text-5xl ${
              remainder ? "text-white" : "text-persianIndigo-60"
            }`}
          >
            {remainder || "00"}
          </p>
          <p className='text-xs md:text-base'>العدد المتبقي</p>
        </div>
      </div>
      <div className='flex items-center gap-2 overflow-hidden'>
        <div className={`${color("text")} w-fit`}>{percentage.toFixed(1)}%</div>
        <div className='h-2 grow bg-white/40 mt-1'>
          <div
            className={`${color("bg")} h-2`}
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default StatsBlock;
