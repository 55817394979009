import axios from "axios";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { MdOutlineLogout } from "react-icons/md";
import { CgLock } from "react-icons/cg";
import LogoutModal from "../../components/dashboard/LogoutModal";
import { useAuth } from "../../context/AuthContext";
import { Field, Form, Formik } from "formik";
import { BiCheckCircle, BiErrorCircle } from "react-icons/bi";
import { RiErrorWarningLine } from "react-icons/ri";
import { getCurrentProfile } from "../../utils/apis";
import { useNavigate, useOutletContext } from "react-router-dom";
import WalkThrough from "../../components/modals/WalkThrough";
import { useWalkthrough } from "../../context/WalkthroughContext";

export default function SettingsPage() {
  const { token } = useAuth();
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const [popuopPos, setPopuopPos] = outletContext.popuopPos;
  const isWalkthrough = outletContext.isWalkthrough;
  const { disableWalkthrough, enableWalkthrough } = useWalkthrough();
  const [isResetPass, setIsResetPass] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [new_reg_notify, setNewRegNotify] = useState(false);
  const [deadline_notify, setDeadlineNotify] = useState(false);
  const [average_change_notify, setAverageChangeNotify] = useState(false);
  const [complete_data_notify, setCompleteDataNotify] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [isFetched, setIsFeched] = useState(false);
  const [stepsIndex, setStepsIndex] = useState(0);
  const [defaultPassValue, setDefaultPassValue] = useState('')

  useEffect(() => {
    if (isResetPass) {
      setDefaultPassValue('')
    } else {
      setDefaultPassValue('********')
    }
  }, [isResetPass])


  useEffect(() => {
    document.title = "تمهيد | الإعدادات";
  }, []);

  useEffect(() => {
    const getData = async () => {
      const settings = await getCurrentProfile();
      if (settings) {
        setIsFeched(true);
        setNewRegNotify(settings.profile.new_reg_notify || false);
        setDeadlineNotify(settings.profile.deadline_notify || false);
        setAverageChangeNotify(settings.profile.average_change_notify || false);
        setCompleteDataNotify(settings.profile.complete_data_notify || false);
      }
    };
    getData();
  }, []);

  const resetPassSchema = Yup.object().shape({
    password: Yup.string()
      .required("هذا الحقل مطلوب")
      .min(8, "كلمة المرور قصيرة"),
    passwordConfirmation: Yup.string()
      .required("هذا الحقل مطلوب")
      .oneOf(
        [Yup.ref("password")],
        "كلمة المرور لا تتطابق مع تأكيد كلمة المرور"
      ),
  });

  useEffect(() => {
    if (isFetched) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/update_settings/`,
          {
            new_reg_notify,
            deadline_notify,
            average_change_notify,
            complete_data_notify,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then(function (res) {
          // console.log(res)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [
    new_reg_notify,
    deadline_notify,
    average_change_notify,
    complete_data_notify,
  ]);

  const handleSubmit = (values, actions) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/password/change/`,
        { password: values.password },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function (res) {
        setSuccessMsg(true);
        actions.setTouched({});
        actions.setSubmitting(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleOnCloseNow = () => {
    disableWalkthrough();
    setPopuopPos("");
  };

  const handleWalkthrough = () => {
    enableWalkthrough();
    navigate("/dashboard");
  };

  return (
    <>
      {showModal ? (
        <LogoutModal showModal={showModal} setShowModal={setShowModal} />
      ) : null}
      {isWalkthrough && popuopPos === "isExpanded" ? (
        <WalkThrough
          title="الإعدادات"
          number={`3/${3}`}
          stepsIndex={3}
          subTitle="لوحة القيادة"
          text="الرجاء الضغط على شعار تمهيد للعودة إلى لوحة القيادة."
          colorOne
          colorTwo
          colorThree
          onclosenow={handleOnCloseNow}
          top="30px"
          right="90px"
          arrowClass="right-top"
        />
      ) : null}
      <div className={`w-full px-3 md:px-6 py-6 setting-bg min-h-screen`} >
        <p className="font-bold text-persianIndigo-100 text-2xl">الإعدادات</p>
        <hr className="my-5 opacity-25" />
        <div className="relative">
          {isWalkthrough && stepsIndex == 0 ? (
            <WalkThrough
              title="الإعدادات "
              number={`1/3`}
              stepsIndex={3}
              subTitle="الإعدادات"
              text="عن طريق صفحة الإعدادات بإمكانك تغير لغة المنصة، تعديل التنبيهات، تغير كلمة المرور، تسجيل الخروج."
              colorOne
              onclosenow={() => {
                setStepsIndex(stepsIndex + 1);
                setPopuopPos("expand");
              }}
              top="15px"
              right="35%"
              arrowClass="right-center"
            />
          ) : null}
        </div>
        <div className="flex justify-between gap-6 mt-5 flex-col md:flex-row">

          <div className="flex flex-col gap-5 w-full">
            <Formik
              validationSchema={resetPassSchema}
              enableReinitialize
              initialValues={{
                password: defaultPassValue,
                passwordConfirmation: "",
              }}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  {successMsg ? (
                    <div className="col-span-2 w-[215px]">
                      <div className="bg-[rgba(97,219,95,0.24)] text-sm rounded-lg py-3 px-4 text-Shamrock-100 flex items-center gap-2 mb-4">
                        <BiCheckCircle />
                        <span>تم تغيير كملة المرور بنجاح</span>
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <label htmlFor="password" className="font-bold block">
                      {isResetPass ? 'ادخل كلمة المرور الجديدة' : 'كلمة المرور'}
                    </label>
                    <Field
                      className={`border ${errors.password && touched.password && isResetPass
                        ? "border-[#DB0000] placeholder:text-[#DB0000]"
                        : "border-HawkesBlue-100"
                        } w-[415px] lg:w-[415px] md:w-[370px] py-3 lg:py-4 rounded-lg mt-3 px-6 text-black form-input focus:ring-0 focus:border-darkViolet-100 placeholder:text-suvaGrey-40`}
                      id={"password"}
                      name={"password"}
                      placeholder="********"
                      type="password"
                      disabled={!isResetPass}
                    />
                    <div className="pt-3">
                      {errors.password && touched.password && isResetPass ? (
                        <div className="flex gap-1">
                          <BiErrorCircle className="text-error text-lg font-light" />
                          <span className="text-error text-sm">
                            {errors.password}
                          </span>
                        </div>
                      ) : null}
                    </div>

                    {isResetPass ? (
                      <>
                        <label htmlFor="passwordConfirmation" className="font-bold block" >تأكيد كلمة المرور</label>
                        <Field
                          className={`border ${errors.passwordConfirmation &&
                            touched.passwordConfirmation
                            ? "border-[#DB0000] placeholder:text-[#DB0000]"
                            : "border-HawkesBlue-100"
                            } w-[415px] lg:w-[415px] md:w-[370px] py-3 lg:py-4 rounded-lg mt-3 px-6 text-black form-input focus:ring-0 focus:border-darkViolet-100 placeholder:text-suvaGrey-40`}
                          id={"passwordConfirmation"}
                          name={"passwordConfirmation"}
                          placeholder="********"
                          type="password"
                        />
                        <div className="pt-3 mb-3">
                          {errors.passwordConfirmation &&
                            touched.passwordConfirmation ? (
                            <div className="flex gap-1">
                              <BiErrorCircle className="text-error text-lg font-light" />
                              <span className="text-error text-sm">
                                {errors.passwordConfirmation}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div className="flex gap-4 items-center pr-2">
                          <button
                            type="button"
                            onClick={() => setIsResetPass(false)}
                            className="text-[#D91E1E] border border-[#D91E1E] rounded-lg font-bold py-3 lg:py-4 w-[122px]"
                          >
                            إلغاء
                          </button>

                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className="bg-persianIndigo-100 flex items-center justify-between rounded-lg px-4 py-3 lg:py-4 w-[220px] lg:w-[269px] text-white"
                          >
                            <span className="font-bold text-lg">حفظ</span>
                            {isSubmitting ? (
                              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-persianIndigo-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" >
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                            ) : (
                              <CgLock className="text-lg" />
                            )}
                          </button>
                        </div>
                      </>
                    ) : (
                      <span
                        onClick={() => setIsResetPass(true)}
                        className="text-[#DB0000] font-bold underline w-1/4 text-rigth cursor-pointer"
                      >
                        تغيير كلمة المرور
                      </span>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="flex flex-col gap-5 lg:gap-7 w-full">
            <p className="font-bold">التنبيهات</p>
            <div className="flex gap-3 items-center group">
              <input
                id="incomplete-data"
                checked={complete_data_notify}
                onChange={() => setCompleteDataNotify(!complete_data_notify)}
                type="checkbox"
                className={`form-checkbox focus:ring-0 peer text-Shamrock-100 rounded-sm border-[#BFC2C9] bg-white`}
              />
              <label htmlFor="incomplete-data" className="peer-checked:text-Shamrock-100" > تنبيه على البيانات الغير مكتملة </label>
            </div>
            <div className="flex gap-3 items-center group">
              <input
                id="delivery"
                type="checkbox"
                checked={deadline_notify}
                onChange={() => setDeadlineNotify(!deadline_notify)}
                className={`form-checkbox focus:ring-0 peer text-Shamrock-100 rounded-sm border-[#BFC2C9] bg-white`}
              />
              <label htmlFor="delivery" className="peer-checked:text-Shamrock-100"> تنبيه بقرب وقت التسليم</label>
            </div>
            <div className="flex gap-3 items-center group">
              <input
                id="updates"
                type="checkbox"
                checked={average_change_notify}
                onChange={() => setAverageChangeNotify(!average_change_notify)}
                className={`form-checkbox focus:ring-0 peer text-Shamrock-100 rounded-sm border-[#BFC2C9] bg-white`}
              />
              <label htmlFor="updates" className="peer-checked:text-Shamrock-100">تحديثات في متوسط البيانات </label>
            </div>
            <div className="flex gap-3 items-center group">
              <input
                id="percentage"
                type="checkbox"
                checked={new_reg_notify}
                onChange={() => setNewRegNotify(!new_reg_notify)}
                className={`form-checkbox focus:ring-0 peer text-Shamrock-100 rounded-sm border-[#BFC2C9] bg-white`}
              />
              <label htmlFor="percentage" className="peer-checked:text-Shamrock-100">تحديثات في نسبة إكمال البيانات</label>
            </div>
            <div className="w-full mt-2">
              <button
                onClick={handleWalkthrough}
                className="border lg:w-[269px] md:w-[210px] w-[269px] border-persianIndigo-100 text-persianIndigo-100 flex items-center justify-around gap-6 py-3 lg:py-5 font-bold bg-white rounded-lg"
              >
                <span>إبدأ الجولة التعريفية</span>
                <RiErrorWarningLine className="text-xl rotate-180" />
              </button>
            </div>
          </div>
        </div>

        <div className="w-full">
          <hr className="my-8 opacity-25" />
          <button
            onClick={() => setShowModal(true)}
            className="w-[269px] bg-persianIndigo-100 flex items-center justify-between rounded-lg px-4 py-3 lg:py-5 text-white"
          >
            <span className="font-bold text-lg">تسجيل الخروج</span>
            <MdOutlineLogout className="text-2xl rotate-180" />
          </button>
        </div>
      </div>
    </>
  );
}
