import axios from 'axios'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BodyMeasurements from '../../../components/dashboard/newStudents/BodyMeasurements'
import FitnessMeasurements from '../../../components/dashboard/students/FitnessMeasurements'
import Steps from '../../../components/dashboard/students/Steps'
import formInitialValues from '../../../utils/form/measurements/initialValues'
// import validationSchema from '../../../utils/form/measurements/validationSchema'
import Cookies from "js-cookie";
import { FiChevronRight } from 'react-icons/fi'
import { getStudents } from '../../../utils/apis'
import StudentsListModal from '../../../components/dashboard/students/StudentsListModal'

const steps = [
    'تحديد الطلاب',
    'القياسات الجسدية',
    'قياسات اللياقة البدنية',
    'ملخص البيانات'
]

export default function AddStudentMeasurementsPage() {

    const { id } = useParams()
    const { state } = useLocation()
    const navigate = useNavigate();

    const [currentStepIndex, setcurrentStepIndex] = useState(1)
    const [showStudentsList, setShowStudentsList] = useState(false)
    const [students, setStudents] = useState([])
    const [nextIndex, setNextIndex] = useState(0)

    const selectedStudents = state?.selectedStudents || []
    // const currentValidationSchema = validationSchema[currentStepIndex];

    useEffect(() => {
        setNextIndex(selectedStudents.findIndex((e) => e == id) + 1)
    }, [selectedStudents])

    useEffect(() => { document.querySelector('#scroll-back').scrollTop = 0 }, [currentStepIndex]);

    async function submitForm(values, actions) {
        actions.setSubmitting(false);

        if (values.num_rounds) {
            delete values.num_rounds
        }

        function removeFalsy(obj) {
            return Object.fromEntries(
                Object.entries(obj).filter(([_, value]) => value)
            );
        }

        axios.post(`${process.env.REACT_APP_API_URL}/save_mesurments/`, { id, ...removeFalsy(values) }, {
            headers: {
                Authorization: `Token ${Cookies.get("token")}`,
            }
        })
            .then(function () {
                if (selectedStudents[selectedStudents.length - 1] == ~~id) {
                    navigate('/dashboard/students-summary', {
                        state: { selectedStudents }
                    })
                } else {
                    navigate(`/dashboard/add-students/${selectedStudents[nextIndex]}`, { state: { selectedStudents } })
                    setcurrentStepIndex(1)
                    actions.resetForm()
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    function handleSubmit(values, actions) {
        if (currentStepIndex == 2) {
            submitForm(values, actions);
        } else {
            setcurrentStepIndex(2);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    useEffect(() => {
        const getData = async () => {
            const studentsList = await getStudents()
            const temp = studentsList.filter((s) => selectedStudents.includes(~~s.id))
            setStudents(temp)
        }
        getData()
    }, [])


    return (
        <div className='w-full min-h-full'>
            {showStudentsList && <StudentsListModal showModal={showStudentsList} setShowModal={setShowStudentsList} students={students} studentId={id} />}
            <div className="w-40 bg-darkViolet-10 p-3 fixed left-0 top-48 rounded-r-lg cursor-pointer z-50">
                <div className="flex justify-around items-center font-bold text-persianIndigo-100" onClick={() => setShowStudentsList((prev) => !prev)}>
                    <FiChevronRight />
                    <span>قائمة الطلاب</span>
                </div>
            </div>
            <Steps steps={steps} currentStepIndex={currentStepIndex} />

            <Formik
                // validationSchema={currentValidationSchema}
                enableReinitialize={true}
                initialValues={formInitialValues}
                onSubmit={handleSubmit}
            >
                {({ values, touched, errors }) => (
                    <Form>
                        <div className="h-[70vh] overflow-x-auto hidden-scroll" id='scroll-back'>
                            {currentStepIndex == 1 ?
                                <BodyMeasurements values={values} errors={errors} touched={touched} />
                                :
                                currentStepIndex == 2 &&
                                <FitnessMeasurements values={values} errors={errors} touched={touched} />
                            }
                        </div>

                        <div className="flex justify-center gap-16">
                            <button type="button" onClick={() => currentStepIndex == 2 ? setcurrentStepIndex(1) : navigate('/dashboard/add-students')} className="p-5 flex items-center text-2xl border border-persianIndigo-100 rounded-lg"><BiChevronRight /></button>
                            <button type="submit" className='px-10 text-lg py-5 sm:w-[269px] w-[200px] flex items-center justify-between rounded-lg bg-persianIndigo-100 text-white'>
                                <span>التالي</span>
                                <BiChevronLeft className='text-2xl' />
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
