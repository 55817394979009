import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { getAdminResults, getMangerResults, getSupervisorResults, getTeacherResults } from "../utils/apis";
import SectionsFilter from "../components/dashboard/Reports/filters/sections";
import WalkThrough from "../components/modals/WalkThrough";
import { useOutletContext } from "react-router-dom";
import { useRef } from "react";
import StatsCard from "../components/dashboard/Reports/StatsCard";
import ReportHeader from "../components/dashboard/Reports/ReportHeader";
import OfficesFilter from "../components/dashboard/Reports/filters/offices";
import TeachersFilter from "../components/dashboard/Reports/filters/teachers";
import SupervisorsFilter from "../components/dashboard/Reports/filters/supervisors";
import ReportHeaderEmpty from "../components/dashboard/Reports/ReportHeaderEmpty";
import ManagerStatsCard from "../components/dashboard/Reports/ManagerStatsCard";
import BallStatsCard from "../components/dashboard/Reports/BallStatsCard";
import ActiveStats from "../components/dashboard/Reports/ActiveStats";
import JumpStatsCard from "../components/dashboard/Reports/JumpStatsCard";
import AdministrationsFilter from "../components/dashboard/Reports/filters/administrations";
import ManagersFilter from "../components/dashboard/Reports/filters/managers";
import AdminStatsCard from "../components/dashboard/Reports/AdminStatsCard";
import Loading from "../components/Loading";


export default function ReportPage() {
  const details = useRef(null);

  useEffect(() => {
    document.title = "تمهيد | التقارير";
  }, []);

  let stepsIndex = 6;
  const [courseSteps, setCourseSteps] = useState(1);

  const [activeStat, setActiveStat] = useState("");
  const [statistic, setStatistic] = useState(null);
  const [statistic2, setStatistic2] = useState(null);
  const [selectedSection, setSelectedSection] = useState("")
  const [selectedOffices, setSelectedOffices] = useState("")
  const [selectedTeachers, setSelectedTeachers] = useState("")
  const [selectedSupervisor, setSelectedSupervisor] = useState("")
  const [selectedManager, setSelectedManager] = useState("")
  const [selectedAdministration, setSelectedAdministrations] = useState("")
  const [totalBall, setTotalBall] = useState(null)
  const [totalRun, setTotalRun] = useState(null)
  const [totalJump, setTotalJump] = useState(null)
  const [totalMuk, setTotalMuk] = useState(null)

  const [loaded, setLoaded] = useState(false);
  const { userType } = useAuth();

  const outletContext = useOutletContext();
  const [popuopPos, setPopuopPos] = outletContext.popuopPos;
  const isWalkthrough = outletContext.isWalkthrough;


  useEffect(() => {
    const getData = async () => {
      if (userType() === "teacher") {
        const data = await getTeacherResults()
        setStatistic(data)
      } else if (userType() === "supervisor") {
        const data = await getSupervisorResults();
        setStatistic(data)
      } else if (userType() === "manager") {
        const data = await getMangerResults();
        setStatistic(data)
      } else if (userType() === "admin") {
        const data = await getAdminResults();
        setLoaded(true)
        setStatistic(data)
      }
    };
    getData();
  }, []);


  const handleStepFive = () => {
    setCourseSteps(5);
    setActiveStat("ball_length")
  };
  useEffect(() => {
    if (activeStat) {
      details.current.scrollIntoView();
    }
  }, [activeStat]);

  return (
    <>
      {userType() === "admin" ? <Loading isLoading={loaded} /> : null}
      {userType() === 'admin' && loaded || userType() !== "admin" ?
        <>

          <div className="relative">
            {isWalkthrough && courseSteps === 2 ? (
              <WalkThrough
                title="التقارير"
                number={`2/${stepsIndex}`}
                stepsIndex={stepsIndex}
                subTitle="تحميل"
                text="  بإمكانك تحميل التقارير كملف PDF ملخص أو كملف اكسل مفصل. "
                colorOne
                colorTwo
                onclosenow={() => setCourseSteps(3)}
                top="40px"
                right="40vw"
                arrowClass="left-top"
              />
            ) : null}
          </div>
          {isWalkthrough && courseSteps === 3 ? (
            <WalkThrough
              title="التقارير"
              number={`3/${stepsIndex}`}
              stepsIndex={stepsIndex}
              subTitle="تصفيات"
              text="  بإمكانك ان تصفي المعلومات المعروضة في الصفحة. "
              colorOne
              colorTwo
              colorThree
              onclosenow={() => setCourseSteps(4)}
              top="325px"
              right="15vw"
              arrowClass="top-right"
            />
          ) : null}

          {isWalkthrough && courseSteps === 4 ? (
            <WalkThrough
              title="التقارير"
              number={`4/${stepsIndex}`}
              stepsIndex={stepsIndex}
              subTitle="تقارير قياسات تدريبات اللياقة البدنية"
              text="  بإمكانك الضغط على الكرت لعرض تفاصيل الرياضة.. "
              colorOne
              colorTwo
              colorThree
              colorFour
              onclosenow={handleStepFive}
              top="380px"
              right="40vw"
              arrowClass="right-top"
            />
          ) : null}

          <div className="w-full pb-10 min-h-full">
            {statistic ?
              <ReportHeader userType={userType} statistic={statistic}>
                <div className="relative">
                  {isWalkthrough && courseSteps === 1 ? (
                    <WalkThrough
                      title="التقارير"
                      number={`1/${stepsIndex}`}
                      stepsIndex={stepsIndex}
                      subTitle="الإحصائيات"
                      text=" بإمكانك الاطلاع على حالة بيانات الطلاب المطلوبة منك اخذ قياسها. "
                      colorOne
                      onclosenow={() => setCourseSteps(2)}
                      top="20px"
                      arrowClass="top-right"
                    />
                  ) : null}
                </div>
              </ReportHeader>
              : <ReportHeaderEmpty userType={userType}>
                <div className="relative">
                  {isWalkthrough && courseSteps === 1 ? (
                    <WalkThrough
                      title="التقارير"
                      number={`1/${stepsIndex}`}
                      stepsIndex={stepsIndex}
                      subTitle="الإحصائيات"
                      text=" بإمكانك الاطلاع على حالة بيانات الطلاب المطلوبة منك اخذ قياسها. "
                      colorOne
                      onclosenow={() => setCourseSteps(2)}
                      top="20px"
                      arrowClass="top-right"
                    />
                  ) : null}
                </div>
              </ReportHeaderEmpty>}
            <div className="m-8">
              <div className="flex gap-5">
                {userType() === "teacher" ?
                  <SectionsFilter setSelectedSection={setSelectedSection} />
                  : userType() === "supervisor" ?
                    <>
                      <TeachersFilter setSelectedTeachers={setSelectedTeachers} />
                      <SectionsFilter setSelectedSection={setSelectedSection} />
                    </> :
                    userType() === "manager" ?
                      <>
                        <OfficesFilter setSelectedOffices={setSelectedOffices} />
                        <SupervisorsFilter selectedOffices={selectedOffices} setSelectedSupervisor={setSelectedSupervisor} />
                      </> :
                      userType() === "admin" ?
                        <>
                          <AdministrationsFilter setSelectedAdministration={setSelectedAdministrations} />
                          <ManagersFilter selectedAdministration={selectedAdministration} setSelectedAdministrations={setSelectedManager} />
                        </> :
                        null
                }
              </div>
              {userType() !== "manager" && userType() !== "admin" ?
                <>
                  <p className="text-suvaGrey-40 text-sm font-bold mt-4 mb-5">
                    عرض تقارير قياسات تدريبات اللياقة البدنية لـ
                    {statistic?.all_student} طالب
                  </p>
                  <hr />
                </>
                : <div className="mt-4 mb-5"></div>}
              {userType() === 'manager' ?
                <ManagerStatsCard selectedOfices={selectedOffices} selectedSupervisor={selectedSupervisor} />
                : userType() === "admin" ?
                  <AdminStatsCard initStatistic={statistic} selectedAdministration={selectedAdministration} selectedManager={selectedManager} />
                  : null}
              {statistic && userType() !== 'manager' && userType() !== "admin" ?
                <>
                  <div className="flex flex-wrap gap-6 mx-auto justify-center my-8">
                    <BallStatsCard
                      setActiveStat={setActiveStat}
                      statistic={statistic}
                      setStatistic2={setStatistic2}
                      statistic2={statistic2}
                      selectedSection={selectedSection}
                      selectedOffices={selectedOffices}
                      selectedTeachers={selectedTeachers}
                      selectedSupervisor={selectedSupervisor}
                      setTotalBall={setTotalBall}
                    />

                    <StatsCard
                      setActiveStat={setActiveStat}
                      type={"total_run"}
                      selectedSection={selectedSection}
                      selectedOffices={selectedOffices}
                      selectedTeachers={selectedTeachers}
                      selectedSupervisor={selectedSupervisor}
                      setTotalRun={setTotalRun}
                      setTotalMuk={setTotalMuk}
                    />

                    <StatsCard
                      setActiveStat={setActiveStat}
                      type={"muk_runing"}
                      selectedSection={selectedSection}
                      selectedOffices={selectedOffices}
                      selectedTeachers={selectedTeachers}
                      selectedSupervisor={selectedSupervisor}
                      setTotalRun={setTotalRun}
                      setTotalMuk={setTotalMuk}
                    />

                    <JumpStatsCard
                      setActiveStat={setActiveStat}
                      selectedSection={selectedSection}
                      selectedOffices={selectedOffices}
                      selectedTeachers={selectedTeachers}
                      selectedSupervisor={selectedSupervisor}
                      setTotalJump={setTotalJump}
                    />

                    <div className="absolute top-[100%] left-[100%] h-4 w-4 bg-red-700 z-50"></div>

                  </div>

                </>
                : null}
            </div>
            {activeStat ? (
              <div ref={details} className="flex justify-center items-center">
                <ActiveStats
                  activeStat={activeStat}
                  statistic2={statistic2}
                  total_ball={totalBall}
                  total_jump={totalJump}
                  total_run={totalRun}
                  muk_runing={totalMuk}
                >
                  <div className="relative">
                    {activeStat !== "" && isWalkthrough && courseSteps === 5 ? (
                      <WalkThrough
                        title="التقارير"
                        number={`5/${stepsIndex}`}
                        stepsIndex={stepsIndex}
                        subTitle="التفاصيل"
                        text="بإمكانك الإطلاع على افضل 10 طلاب في الرياضة ومقارنة متوسط الاداء عن طريق مخطط شريطي"
                        colorOne
                        colorTwo
                        colorThree
                        colorFour
                        colorFive
                        onclosenow={() => {
                          setCourseSteps(6);
                          setPopuopPos("learning");
                          setActiveStat("")
                        }}
                        top="-100px"
                        right="30vw"
                        arrowClass="bottom-right"
                      />
                    ) : null}
                  </div>

                </ActiveStats>
              </div>
            ) : null}
          </div>
        </> : null}
    </>
  );
}