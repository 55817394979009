import axios from 'axios';
import { useEffect } from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import { useAuth } from '../../context/AuthContext';

export default function AcceptModal({ showModal, setShowModal, url }) {

    const { token } = useAuth()

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_URL}/${url.url}`, { 'accepted': 'true' }, {
            headers: {
                Authorization: `Token ${token}`,
            }
        }).then((res) => {
            axios.post(`${process.env.REACT_APP_API_URL}/massages/${url.id}/read/`, {}, {
                headers: {
                    Authorization: `Token ${token}`,
                }
            }).then((res) => {
                console.log(res)
                setTimeout(() => {
                    setShowModal(false)
                }, 3000);
            }).catch((e) => {
                console.log(e)
            })
        }).catch((e) => {
            setShowModal(false)
        })
    }, [])

    return (
        <>
            {showModal ? (
                <>
                    <div className="flex fixed inset-0 z-50 outline-none focus:outline-none bg-[rgba(35,35,35,0.23)]">
                        <div className="relative my-auto mx-auto w-1/3">
                            <div className="mx-auto bg-white rounded-2xl my-auto text-sm justify-center shadow-lg flex gap-3 items-center py-10 px-2">
                                <BiCheckCircle className="text-Shamrock-100" size="30px" />
                                <p className='font-light text-nero'>تم قبول الدعوة بنجاح</p>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}
