import { useEffect, useRef, useState } from "react";
import { CgSoftwareDownload } from "react-icons/cg";
import ExportPdf from "../../../assets/images/dashboard/report/ExportPdf.svg";
import ExportExcel from "../../../assets/images/dashboard/report/ExportExcel.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

export default function ReportsFiles({ userType }) {
  const [isExpand, setInExpand] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setInExpand(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div className="mr-auto flex flex-col w-[186px] relative items-center bg-white text-persianIndigo-100 rounded-lg">
      <div
        onClick={() => setInExpand(true)}
        className={`w-full cursor-pointer bosrder border-error flex items-center justify-between ${isExpand
          ? "bg-persianIndigo-20 shadow-[0px_4px_10px_rgba(0,0,0,0.1)]"
          : "bg-white"
          } gap-5 p-5 rounded-lg font-bold`}
      >
        <span className="text-sm lg:text-lg">تحميل التقارير </span>
        <CgSoftwareDownload className="text-xl lg:text-2xl" />
      </div>
      {isExpand ? (
        <div className={`${userType === 'admin' ? 'bottom-[-3.1rem] rounded-b-lg' : 'bottom-[-6.7rem]'} flex flex-col w-full absolute z-10`} ref={modalRef}>
          <Link
            className={`${userType === 'admin' ? 'rounded-b-lg' : ''} w-full px-5 py-4 flex items-center justify-between gap-5 bg-white hover:bg-Shamrock-100`}
            target="_blank"
            to={
              userType === "teacher"
                ? "/teacher-report"
                : userType === "manager"
                  ? "/manager-report"
                  : userType === "admin"
                    ? "/admin-report"
                    : "/supervisor-report"
            }
          >
            <span className="text-xs lg:text-base">تقرير عام</span>
            <img src={ExportPdf} alt="" />
          </Link>
          {userType !== "admin" ?
            <>
              <hr className="opacity-40 w-4/5 mx-auto" />
              <ExcelDownload userType={userType} />
            </> : null}
        </div>
      ) : null}
    </div>
  );
}



const ExcelDownload = ({ userType }) => {
  const handleDownloadClick = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${userType}-combined-results/?excel`,
        {
          responseType: 'blob', // Make sure we receive the response as a blob
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${Cookies.get("token")}`,
          },
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', userType === "teacher"
        ? "تقارير المعلم.xlsx"
        : userType === "manager"
          ? "تقارير رئيس القسم.xlsx"
          : userType === "admin"
            ? "تقرير مفصل.xlsx"
            : "تقارير المشرف.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  };

  return (
    <div className="w-full px-5 py-4 flex cursor-pointer items-center rounded-b-lg justify-between gap-5 bg-white hover:bg-Shamrock-100" onClick={handleDownloadClick}>
      <span className="text-xs lg:text-base">تقرير مفصل</span>
      <img src={ExportExcel} alt="" />
    </div>
  );
};
