import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { Link } from 'react-router-dom'
import { BiCheck } from 'react-icons/bi'
import ReactTimeAgo from 'javascript-time-ago'

import ar from '../../../data/javascript-time-ago/locale/ar.json'

ReactTimeAgo.addDefaultLocale(ar)
const timeAgo = new ReactTimeAgo('ar')

export default function TeachersList({ data }) {

    return (
        <>
            <Link to={`/dashboard/teachers/${data.id}`} className={`shadow-[0px_0px_6px_rgba(35,35,35,0.08)] flex items-center gap-5 px-8 py-5 my-2 cursor-pointer rounded-md`}>
                <div className="font-light text-xs text-suvaGrey-80">
                    <p>اخر نشاط</p>
                    <p className='font-bold mt-2'>{timeAgo.format(new Date(data.last_login))} </p>
                </div>
                <div className={`border-l-2 min-w-[25%] border-l-[#F9F9F9] border-r-[#F9F9F9] border-r-2 px-5`}>
                    <p className="text-xl font-bold  text-darkViolet-100">{data.name}</p>
                    <p className={`text-xs mt-2 text-persianIndigo-60`}>{data.all_student} طالب</p>
                </div>
                {data.all_student == 0 ?
                    <div className={`w-6 h-6 border-4 rounded-full justify-center border-suvaGrey-40`}></div>
                    : data.total_student_not_completed == 0 ?
                        <div className={`w-6 h-6 border-4 rounded-full flex items-center justify-center border-Shamrock-100`}>
                            <BiCheck className="text-Shamrock-100 stroke-2" />
                        </div>
                        :
                        <div className="w-6">
                            <CircularProgressbar
                                counterClockwise
                                value={`${(100 * data.total_student_not_completed) / data.all_student}`} strokeWidth="20"
                                styles={buildStyles({
                                    pathColor: '#D91E1E',
                                    trailColor: '#D6D9E0',
                                    strokeLinecap: 'butt',
                                })}
                            />
                        </div>
                }
                <div>
                    <p className={`text-xs text-suvaGrey-80`}> {data.total_student_completed} بيانات طلاب مكتملة</p>
                    {data.total_student_not_completed == 0 ?
                        <p className={`text-xs mt-2 font-bold text-suvaGrey-40`}> {data.total_student_not_completed} طالب متبقي</p>
                        : data.total_student_not_completed == 0 ?
                            <p className={`text-xs mt-2 font-bold text-Shamrock-100`}> {data.total_student_not_completed} طالب متبقي</p>
                            :
                            <p className={`text-xs mt-2 font-bold  text-[#D91E1E]`}> {data.total_student_not_completed} طالب متبقي</p>
                    }
                </div>
            </Link>
        </>
    )
}
