import { getMangerCards, getSupervisorCards, getTeacherCards } from "./apis"

const getDashboadStat = async (userType) => {
    const data = {}
    if (userType() === 'teacher') {
        data.basketball = await getTeacherCards("basketball")
        data.total_jump = await getTeacherCards("total_jump")
        data.ball_length = await getTeacherCards("ball_length")
        data.total_run = await getTeacherCards("total_run")
        data.muk_runing = await getTeacherCards("muk_runing")
    } else if (userType() === 'manager') {
        data.total_jump = await getMangerCards("total_jump")
        data.basketball = await getMangerCards("basketball")
        data.ball_length = await getMangerCards("ball_length")
        data.total_run = await getMangerCards("total_run")
        data.muk_runing = await getMangerCards("muk_runing")
    } else if (userType() === "supervisor") {
        data.total_jump = await getSupervisorCards("total_jump")
        data.basketball = await getSupervisorCards("basketball")
        data.ball_length = await getSupervisorCards("ball_length")
        data.total_run = await getSupervisorCards("total_run")
        data.muk_runing = await getSupervisorCards("muk_runing")
    }
    return data
}

export default getDashboadStat