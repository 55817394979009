import { useEffect, useState } from 'react'
import { IoChevronDownOutline, IoChevronUpOutline, IoFemaleSharp, IoMaleSharp } from 'react-icons/io5'

export default function Card({ student, setSelectedStudents, selectedStudents }) {
    const [expand, setExpand] = useState(false)
    const [isSelected, setIsSelected] = useState(false)



    return (
        <div>
            <div className="flex items-center gap-10 py-5 cursor-pointer" onClick={() => setExpand((prev) => !prev)}>
                <div className='min-w-[25%]'>
                    <input onClick={(e) => e.stopPropagation()} checked={selectedStudents.includes(student.id)} onChange={(e) => {
                        if (!selectedStudents.includes(student.id)) {
                            setSelectedStudents((prev) => [student.id, ...prev])
                        } else {
                            setSelectedStudents((prev) => prev.filter((s) => s !== student.id))
                        }
                    }} id={student.id} type="checkbox" className="ml-5 form-checkbox focus:ring-0 peer text-darkViolet-100 rounded-sm border-[#BFC2C9] bg-white" />
                    <label htmlFor={student.national_id} className={`font-bold text-nero`}>
                        {student.first_name}{" "} {student.father_name} {" "} {student.last_name}
                    </label>
                </div>
                <p className="text-sm mx-auto">{student.section}</p>
                {expand ?
                    <IoChevronUpOutline className="mr-auto text-xl text-nero" />
                    :
                    <IoChevronDownOutline className="mr-auto text-xl text-suvaGrey-20" />
                }
            </div>
            <hr className='border opacity-60' />
            {expand &&
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 content-center gap-4 my-5">
                    <div className='rounded-lg bg-[#F9F9F9] text-center py-5 flex flex-col justify-center'>
                        <p className='pb-4'>رقم الأحوال/الإقامة</p>
                        <p className='font-bold'>{student.national_id}</p>
                    </div>
                    <div className='rounded-lg bg-[#F9F9F9] text-center py-5 flex flex-col justify-center'>
                        <p className='pb-4'>تاريخ الميلاد</p>
                        <p className='font-bold'>{student?.birth_date?.split(' ')[0] || student?.hijri_birth_date?.split(' ')[0] || "-"}</p>
                    </div>
                    {/* <div className='rounded-lg bg-[#F9F9F9] text-center py-5 flex flex-col justify-center'>
                        <p className='pb-4'>الجنس</p>
                        <p>
                            {student.gender === 'M' ?
                                <>
                                    <IoMaleSharp className='font-bold mx-auto text-Shamrock-100 text-2xl inline ml-2' />
                                    <span className='font-bold'>ذكر</span>
                                </>
                                : student.gender === 'F' ?
                                    <>
                                        <span className='font-bold'>أنثى</span>
                                        <IoFemaleSharp className='font-bold mx-auto text-Shamrock-100 text-2xl inline ml-2' />
                                    </>
                                    : ""}
                        </p>
                    </div> */}
                    {/* <div className='rounded-lg bg-[#F9F9F9] text-center py-5 flex flex-col justify-center'>
                        <p className='pb-4'>الجنسية</p>
                        <p className='font-bold'>{student.nationality}</p>
                    </div> */}
                    {/* <div className='rounded-lg bg-[#F9F9F9] text-center py-5 flex flex-col justify-center'>
                        <p className='pb-4'>مكان الميلاد</p>
                        <p className='font-bold p-2'>{student.city_of_birth}</p>
                    </div> */}
                </div>
            }
        </div>
    )
}
