import { useState } from "react";
import { Form, Formik } from "formik";
import { FiSave } from "react-icons/fi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from 'yup';
import { useEffect } from "react";
import CustomPasswordInput from "./components/FormTools/CustomPasswordInput";
import axios from "axios";
import logo from "./assets/images/logo/tamheed-white.svg"
import { BiChevronRight } from "react-icons/bi";

export default function ResetPassVerificationPage() {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const code = searchParams.get('code')

    const [success, setSuccess] = useState(false)

    const resetPassSchema = Yup.object().shape({
        password: Yup
            .string()
            .required('هذا الحقل مطلوب')
            .min(8, 'كلمة المرور قصيرة'),
        passwordConfirmation: Yup
            .string()
            .required('هذا الحقل مطلوب')
            .oneOf([Yup.ref('password')], 'كلمة المرور لا تتطابق مع تأكيد كلمة المرور')
    })

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/password/reset/verify/?code=${code}`).then((res) => {
        }).catch((er) => {
            navigate('/reset-password')
        })
    }, [])


    function handleSubmit(values, actions) {
        axios.post(`${process.env.REACT_APP_API_URL}/password/reset/verified/`, {
            code,
            password: values.password
        })
            .then(function () {
                setSuccess(true)
                setTimeout(() => {
                    navigate('/login')
                }, 1500);
            })
            .catch(function (error) {
                console.log(error);
            })
        actions.setTouched({});
        actions.setSubmitting(false);
    }

    return (
        <div className="custom-bg">
            <Formik
                validationSchema={resetPassSchema}
                initialValues={{
                    password: '',
                    passwordConfirmation: '',
                }}
                onSubmit={handleSubmit}
            >
                {({ errors, touched }) => (
                    <>
                        {success ?
                            <div className="custom-bg flex flex-col justify-around py-16">
                                <img src={logo} alt="Logo" className="mx-auto w-44" />
                                <div className="mx-auto bg-white rounded-lg my-auto flex flex-col gap-5 items-center py-10 px-24">
                                    <IoMdCheckmarkCircleOutline className="text-Shamrock-100" size="30px" />
                                    <span className="text-persianIndigo-100 text-3xl font-bold">تم تغيير كلمة المرور بنجاح</span>
                                </div>
                            </div> :

                            <Form>
                                <div className="w-3/5 mx-auto py-10">
                                    <div className="bg-[#F9F9F9] px-12 rounded-lg shadow-page-card pt-10 mt-10">
                                        <p className="text-blueDiamond font-bold text-center text-2xl mb-10">تغيير كلمة المرور</p>
                                        <hr className="bg-HawkesBlue-40 mb-10" />

                                        <div>
                                            <CustomPasswordInput errors={errors} touched={touched} name="password" label="ادخل كلمة المرور الجديدة" />

                                            <CustomPasswordInput errors={errors} touched={touched} name="passwordConfirmation" label="تأكيد كلمة المرور الجديدة" />

                                        </div>

                                    </div>
                                </div>

                                <div className="w-3/5 mx-auto pb-20 flex mt-10">
                                    <Link to="/login" className="border border-persianIndigo-100 flex justify-center items-center px-5 text-xl rounded-lg">
                                        <BiChevronRight />
                                    </Link>
                                    <button type="submit" className="flex items-center bg-[#F9F9F9] shadow-button rounded-lg text-persianIndigo-100 font-[600] w-2/4 px-10 mr-auto h-16 text-sm">
                                        <span className="ml-auto">حفظ وتغيير كلمة المرور</span>
                                        <FiSave className="text-lg" />
                                    </button>
                                </div>
                            </Form>
                        }
                    </>
                )}
            </Formik>
        </div>
    )
}


