import axios from "axios";
import { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { BiCheckCircle, BiErrorCircle } from "react-icons/bi";
import { FiSave } from "react-icons/fi";
import { IoFemaleSharp, IoMaleSharp } from "react-icons/io5";
import CustomSelect from "./CustomSelect2";
import nationalities from '../../../data/nationalities.json'
import WalkThrough from "../../modals/WalkThrough";

const genders = [
  { label: 'أنثى', value: 'F' },
  { label: 'ذكر', value: 'M' },
]

export default function PersonalInfo({ profile, token, isWalkthrough, stepsIndex, setStepsIndex, setPopuopPos }) {
  const [edit, setEdit] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(profile.phone_number)
  const [nationalId, setNationalId] = useState(profile.national_id)
  const [dateOfBirth, setDateOfBirth] = useState(profile.date_of_birth)
  const [email, setEmail] = useState(profile.email)
  const [gender, setGender] = useState(profile.gender)
  const [nationality, setNationality] = useState(profile.nationality)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showEmailChangedMessage, setShowEmailChangedMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [walkthroughModal, setWalkthroughModal] = useState(true);

  const updateProfile = () => {
    if (phoneNumber == profile.phone_number && nationality == profile.nationality && gender == profile.gender && email == profile.email && nationalId === profile.national_id && dateOfBirth === profile.date_of_birth) {
      setEdit(false)
    }
    if (email !== profile.email) {
      axios.post(`${process.env.REACT_APP_API_URL}/email/change/`, { 'email': email }, {
        headers: {
          Authorization: `Token ${token}`,
        }
      })
        .then(function (res) {
          setEdit(false)
          setShowEmailChangedMessage(true)
        })
        .catch(function (error) {
          setErrorMessage('البريد الإلكتروني مستخدم مسبقاً')
          setShowErrorMessage(true)
          setEdit(true)
        })
    }
    if (phoneNumber !== profile.phone_number || nationality !== profile.nationality || gender !== profile.gender || nationalId !== profile.national_id | dateOfBirth !== profile.date_of_birth) {
      axios.post(`${process.env.REACT_APP_API_URL}/update_profile/`, {
        phone_number: phoneNumber,
        gender: gender,
        nationality: nationality,
        degree: profile.degree,
        years_of_experience: profile.years_of_experience,
        sports: profile.sports,
        specialization: profile.specialization,
        national_id: nationalId,
        date_of_birth: dateOfBirth
      }, {
        headers: {
          Authorization: `Token ${token}`,
        }
      })
        .then(function (res) {
          setEdit(false)
          setShowErrorMessage(false)
        })
        .catch(function (error) {
          setErrorMessage('رقم الهاتف مستخدم مسبقاً')
          setShowErrorMessage(true)
          setEdit(false)
          setPhoneNumber(profile.phone_number)
        })
    }
  }


  return (
    <div className='rounded-lg relative shadow-[0px_0px_24px_rgba(35,35,35,0.06)] flex flex-col sm:grid grid-cols-2 gap-5 p-8 mb-4 mx-10'>
      {showErrorMessage &&
        <div className="col-span-2">
          <div className="bg-[rgba(217,30,30,0.08)] w-1/2 rounded-lg py-2 px-4 text-[#D91E1E] font-bold flex items-center gap-2 mb-4">
            <BiErrorCircle />
            <span>{errorMessage}</span>
          </div>
        </div>
      }
      {showEmailChangedMessage &&
        <div className="col-span-2">
          <div className="bg-[rgba(97,219,95,0.24)] text-sm rounded-lg py-3 px-4 text-Shamrock-100 font-bold flex items-center gap-2 mb-4">
            <BiCheckCircle />
            <span>تم إرسال لبريدك الإلكتروني رابط لتغير البريد الإلكتروني</span>
          </div>
        </div>
      }
      <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
        <p className='text-suvaGrey-60 text-sm'>رقم الأحوال/الإقامة</p>
        {edit ?
          <div className="w-full relative">
            <input
              type="number"
              placeholder="0"
              value={nationalId}
              onChange={(e) => setNationalId(e.target.value)}
              className="number-arrow focus:ring-0 focus:border-darkViolet-100 form-input rounded-lg placeholder:text-suvaGrey-40 border-gray-300 w-full py-4 px-6"
            />
          </div> : <p className='font-bold'>{nationalId || "-"}</p>}

      </div>
      <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
        <p className='text-suvaGrey-60 text-sm'>تاريخ الميلاد</p>
        {edit ?
          <input type="date" defaultValue={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} className="focus:ring-0 focus:border-darkViolet-100 form-input rounded-lg placeholder:text-suvaGrey-40 border-gray-300 w-full py-4 px-6" id="" />
          :
          <p className='font-bold'>{dateOfBirth || "-"}</p>
        }
      </div>
      <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
        <p className='text-suvaGrey-60 text-sm'>الجنس</p>
        {edit ?
          <div className="w-full">
            <CustomSelect options={genders} name="gender" defaultValue={genders.find((g) => g.value === gender)} handleChangedSport={(e) => setGender(e.value)} placeholder="إختار الجنس" />
          </div>
          : gender ? gender === 'M' ?
            <p>
              <IoMaleSharp className='font-bold mx-auto text-suvaGrey-60 text-2xl inline ml-2' />
              <span className='font-bold'>ذكر</span>
            </p> :
            <p>
              <IoFemaleSharp className='font-bold mx-auto text-suvaGrey-60 text-2xl inline ml-2' />
              <span className='font-bold'>أنثى</span>
            </p>
            : <span className='font-bold'>-</span>
        }
      </div>
      <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
        <p className='text-suvaGrey-60 text-sm'>الجنسية</p>
        {edit ?
          <div className="w-full">
            <CustomSelect options={nationalities} name="nationality" defaultValue={nationalities.find((n) => n.value === nationality)} handleChangedSport={(e) => setNationality(e.value)} placeholder="إختار الجنسية" />
          </div>
          : nationality ?
            <span className='font-bold'>{nationality}</span>
            : <span className='font-bold'>-</span>
        }
      </div>
      <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
        <p className='text-suvaGrey-60 text-sm'>رقم الجوال</p>
        {edit ?
          <div className="w-full relative">
            <p className="absolute left-4 top-4 text-suvaGrey-80">966+</p>
            <input max="10" dir="ltr" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className='form-input focus:ring-0 focus:border-darkViolet-100 rounded-lg border-gray-300 w-full py-4 pl-16 pr-6 font-bold number-arrow' type="number" />
          </div>
          :
          <p className='font-bold' dir='ltr'><span className='text-suvaGrey-100'>+966</span> <span>{phoneNumber || '-'}</span></p>
        }
      </div>
      <div className="bg-[#F9F9F9] flex justify-center items-center rounded-lg p-5 gap-5 flex-col">
        <p className='text-suvaGrey-60 text-sm'>البريد الإلكتروني</p>
        {edit ?
          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className='form-input focus:ring-0 focus:border-darkViolet-100 rounded-lg border-gray-300 w-full py-4' dir='ltr' />
          :
          <p className='font-bold'>{email || '-'}</p>
        }
      </div>
      <div className="col-span-2 mt-8">
        <div className="relative">
          {isWalkthrough && walkthroughModal && stepsIndex == 2 ? (
            <WalkThrough
              walkthroughModal={walkthroughModal}
              setWalkthroughModal={setWalkthroughModal}
              title="الحساب الشخصي"
              number={`3/4`}
              stepsIndex={4}
              subTitle="تعديل"
              text="بإمكانك الضغط على زر التعديل لتعديل بعض معلوماتك."
              colorOne="darkViolet-100"
              colorTwo="darkViolet-100"
              colorThree="darkViolet-100"
              onclosenow={() => {
                setStepsIndex(4)
                setPopuopPos('setting')
              }}
              bottom="20px"
              right="25%"
              arrowClass="bottom-left"
            />
          ) : null}
        </div>
        {edit ?
          <button onClick={updateProfile} className="w-1/3 mx-auto flex justify-between border border-persianIndigo-100 rounded-lg py-5 px-5">
            <span className='text-persianIndigo-100 font-bold'>حفظ</span>
            <FiSave className='text-xl' />
          </button>
          :
          <button onClick={() => {
            setEdit(true)
            setErrorMessage('')
            setShowErrorMessage(false)
          }} className="w-1/3 mx-auto flex justify-between border border-persianIndigo-100 rounded-lg py-5 px-5">
            <span className='text-persianIndigo-100 font-bold'>تعديل</span>
            <AiOutlineEdit className='text-xl' />
          </button>
        }
      </div>

    </div>
  )
}