import sittingHeightComplete from '../../../../assets/images/dashboard/measurement/sitting-height-complete.svg'
import sittingHeight from '../../../../assets/images/dashboard/measurement/sitting-height.svg'
import RecordDate from '../MeasurementsElements/RecordDate'

export default function HeightSitting({ value, date = "" }) {
    return (
        <div className="rounded-lg p-5 text-center bg-[#F9F9F9]">
            {value > 0 ?
                <img src={sittingHeightComplete} className="mx-auto" />
                :
                <img src={sittingHeight} className="mx-auto" />
            }

            <p className='pb-3 pt-2'>الطول جلوساً</p>
            <p>
                <span className="text-nero pl-1">{value}</span>
                <span className='font-bold'>سم</span>
            </p>
            <RecordDate record={date} />
        </div>
    )
}
