import { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import StudentCard from '../StudentCard'
import { AiOutlinePlus } from 'react-icons/ai'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { Link } from 'react-router-dom'
import { BiCheck } from 'react-icons/bi'
import { getSectionsStudents } from '../../../utils/apis'
import SupStudentCard from './SupStudentCard'

export default function SectionCards({ setIsClassExpanded, isClassExpanded, data, setleee, lee }) {
    const [expand, setExpand] = useState(false)
    const [allStudents, setAllStudents] = useState([])

    useEffect(() => {
        setExpand(lee === data.id ? true : false)
    }, [lee])

    useEffect(() => {
        const getData = async () => {
            const sections = await getSectionsStudents(data.id)
            setAllStudents(sections)
        }
        getData()
    }, [])


    const handleExpanding = () => {
        setIsClassExpanded(expand ? false : true)
        setleee((prev) => prev == data.id ? 0 : data.id)
    }


    return (
        <div className="w-full flex flex-col">
            <div onClick={handleExpanding} className={`${expand ? 'bg-Shamrock-100 shadow-[0px_0px_16px_rgba(35,35,35,0.08)]' : 'bg-[#F9F9F9]'} flex items-center gap-5 px-8 py-4 my-2 cursor-pointer rounded-md`}>
                {!expand && !isClassExpanded &&
                    <div className="font-light text-xs text-suvaGrey-40">
                        <p>{new Date(data.last_change).toLocaleTimeString([], { timeStyle: 'short' })}</p>
                        <p className='mt-2'>{
                            data.last_change ?
                                new Date(data.last_change).toLocaleDateString('ar-MA', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
                                : new Date().toLocaleDateString('ar-MA', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
                        }
                        </p>
                    </div>
                }
                <div className={`border-l-2 ${expand ? 'border-l-Shamrock-80 border-r-Shamrock-80 px-4 w-1/2' : isClassExpanded && !expand ? 'w-1/2' : 'border-l-[#F9F9F9] border-r-[#F9F9F9] border-r-2 px-10 w-1/3'}`}>
                    <p className={`text-lg font-bold ${expand ? 'text-white' : 'text-persianIndigo-100'}`}>{data.my_section}</p>
                    <p className={`text-xs mt-2 font-bold ${expand ? 'text-Shamrock-40' : 'text-suvaGrey-60'}`}>{data.total_students} طالب</p>
                </div>
                {
                    data.total_students == 0 ?
                        <div className={`w-6 h-6 border-4 rounded-full flex items-center justify-center border-suvaGrey-40 `}>
                        </div> :
                        data.incompleted_students == 0 ?
                            <div className={`w-6 h-6 border-4 rounded-full flex items-center justify-center ${expand ? 'border-white' : 'border-Shamrock-100'}`}>
                                <BiCheck className="text-Shamrock-100 stroke-2" />
                            </div>
                            :
                            <div className="w-6">
                                <CircularProgressbar
                                    value={`${(100 * data.incompleted_students) / data.total_students}`} strokeWidth="20" counterClockwise
                                    styles={buildStyles({
                                        pathColor: '#D91E1E',
                                        trailColor: '#D6D9E0',
                                        strokeLinecap: 'butt',
                                    })}
                                />
                            </div>
                }
                <div>
                    <p className={`text-xs ${expand ? 'text-white' : 'text-suvaGrey-80'}`}> {data.completed_students} بيانات طلاب مكتملة</p>
                    {
                        data.total_students == 0 ?
                            <p className={`text-xs mt-2 font-bold text-suvaGrey-80`}> {data.incompleted_students} طالب متبقي</p> :
                            data.incompleted_students == 0 ?
                                <p className={`text-xs mt-2 font-bold ${expand ? 'text-darkViolet-100' : 'text-Shamrock-100'}`}> {data.incompleted_students} طالب متبقي</p>
                                :
                                <p className={`text-xs mt-2 font-bold ${expand ? 'text-darkViolet-100' : 'text-[#D91E1E]'}`}> {data.incompleted_students} طالب متبقي</p>
                    }
                </div>
                {!expand && <FiChevronLeft className='text-HawkesBlue-100 text-3xl mr-auto' />
                }
            </div>
            {expand &&
                <div className='px-5 py-2 bg-white'>
                    {allStudents.length ? allStudents.map((s, i) => (
                        <SupStudentCard key={s.id} student={s} sectionId={data.id} index={i} />
                    )) : null}
                </div>
            }
        </div>
    )
}
