import { useEffect, useState } from "react";
import { FiSave } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import Steps from "../../../components/dashboard/students/Steps";
import StudentsListModal from "../../../components/dashboard/students/StudentsListModal";
import { getStudents } from "../../../utils/apis";
import SummrayCard from "./SummrayCard";


const steps = [
    'تحديد الطلاب',
    'القياسات الجسدية',
    'قياسات اللياقة البدنية',
    'ملخص البيانات'
]

export default function StudentsSummaryPage() {

    const [students, setStudents] = useState([])

    const { state } = useLocation()

    const { selectedStudents } = state


    useEffect(() => {
        const getData = async () => {
            const studentsList = await getStudents()
            const temp = studentsList.filter((s) => selectedStudents.includes(~~s.id))
            setStudents(temp)
        }
        getData()
    }, [])

    return (
        <div className='min-h-screen'>

            <Steps steps={steps} currentStepIndex={3} />
            {students.length ?
                <div className="overflow-y-auto custom-scroll max-h-[55vh] my-10 w-11/12 mx-auto flex flex-col">
                    {students.map((s, i) => (
                        <SummrayCard key={s.id} index={i} student={s} />
                    ))}
                </div> : null
            }


            <div className="w-11/12 mx-auto">
                <Link to="/dashboard" type="submit" className="mt-5 my-10 flex items-center bg-persianIndigo-100 shadow-button rounded-lg text-white font-light px-10 sm:w-[269px] w-[250px] mr-auto h-14">
                    <span className="ml-auto">حفظ البيانات والإتمام</span>
                    <FiSave />
                </Link>
            </div>
        </div>
    )
}
