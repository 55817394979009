import { useEffect, useState } from "react"
import { getTeachersSections } from "../../../utils/apis"
import SectionCards from "./SectionCards"
import empybox from '../../../assets/images/dashboard/emptybox.svg'


export default function TeacherSections({ id }) {

    const [section, setSections] = useState([])
    const [isClassExpanded, setIsClassExpanded] = useState(false)
    const [lee, setleee] = useState(0)


    useEffect(() => {
        const getData = async () => {
            const t = await getTeachersSections(id)
            setSections(t.filter((t) => t.total_students !== 0))
        }
        getData()
    }, [])

    return (
        <div className="w-full p-3">
            <div className='w-full bg-white h-[45vh] overflow-y-auto custom-scroll px-2'>
                {section.length ? section.map(c => (
                    <SectionCards data={c} key={c.id} lee={lee} setleee={setleee} setIsClassExpanded={setIsClassExpanded} isClassExpanded={isClassExpanded} />
                )) : <div className="flex items-center flex-col gap-10 justify-center">
                    <img src={empybox} className='text-8xl text-Shamrock-100 mt-10' />
                    <p className='text-3xl text-darkViolet-100 font-bold'>لا توجد بيانات مسجلة</p>
                </div>}
            </div>
        </div >
    )
}
