import { useEffect, useState } from "react";
// import cover from "../assets/pdf/Mcover.png";
import header from "../assets/pdf/Mheader.png";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

export default function PdfManager() {
  const { token } = useAuth();

  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/manager-combined-results/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
      });
  }, []);

  useEffect(() => {
    document.title = "تقرير عام";
  }, []);

  const date = new Date().toLocaleDateString("ar-UA", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  function divideArray(x) {
    const dividedArray = [];

    dividedArray.push(x.slice(0, 12));

    for (let i = 12; i < x.length; i += 16) {
      dividedArray.push(x.slice(i, i + 16));
    }

    return dividedArray;
  }

  let pageNumber = 1;

  return (
    <div className="flex flex-col items-center print:items-start gap-16 print:gap-0 py-16 print:p-0 bg-gray-200">
      <button
        className="bg-daisyBush text-white px-4 py-2 rounded-md fixed print:hidden top-14 right-10 font-bold z-50"
        onClick={() => {
          window.print();
        }}
      >
        طباعة
      </button>

      <div
        id="page-1"
        className="bg-white w-[210mm] h-[297mm] relative shadow-2xl"
      >
        <div className="header h-[180px] relative isolate text-white">
          <img className="-z-10 w-full h-full" src={header} alt="" />
          <div className="absolute top-[30%] left-[50%] translate-x-[-50%] flex flex-col items-center justify-center gap-4">
            <div className="">رئيس القسم</div>
            <div className=" text-xl font-bold text-center">{data?.name}</div>
          </div>

          <div className="absolute top-[38px] left-[37px] flex gap-2 items-center">
            <div className="">إدارة التعليم</div>
            <div className="text-xs font-bold max-w-[100px] text-center">
              {data?.administration_of_education}
            </div>
          </div>
        </div>

        <div className="flex p-10">
          <div className="w-2/3 flex flex-col gap-14 pl-7">
            <StatsBlock
              title={"المشرفين"}
              total={data?.general_results.all_supervisor}
              completed={data?.general_results.total_completed_supervisor}
              remainder={data?.general_results.total_not_completed_supervisor}
            />
            <StatsBlock
              title={"المعلمين"}
              total={data?.general_results.all_teacher}
              completed={data?.general_results.total_completed_teacher}
              remainder={data?.general_results.total_not_completed_teacher}
            />
            <StatsBlock
              title={"الطلاب"}
              total={data?.general_results.all_student}
              completed={data?.general_results.total_student_completed}
              remainder={data?.general_results.total_student_not_completed}
            />
          </div>
          <div className="w-1/3 pr-5">
            <div className="text-2xl">مكاتب التعليم </div>
            <div className="flex flex-col gap-6 mt-6">
              {data?.educational_offices_results.map((item, index) => {
                const p = (
                  (item.incompleted_students /
                    (item.completed_students + item.incompleted_students)) *
                  100
                ).toFixed(0);

                return (
                  <OfficeStats key={index} percentage={p} name={item.name} />
                );
              })}
            </div>
          </div>
        </div>

        <div className="absolute bottom-[44px] right-[0] pr-10 text-[#240A63] flex gap-3 items-center font-bold text-l">
          تاريخ التصدير
          <div className=" flex gap-2">
            <div>{date}</div>
          </div>
        </div>
        <div className="absolute bottom-[44px] left-[0] pl-10 text-[#240A63] font-bold text-l">
          {pageNumber.toFixed(0).padStart(2, "0")}
        </div>
      </div>

      {data?.offices?.map((office, index) => {
        const sups = divideArray(office.supervisors);
        console.log(
          "🚀 ~ file: PdfManager.js:214 ~ {offices.map ~ sups:",
          sups
        );

        return sups.map((supData, index) => {
          pageNumber++;
          return (
            <div
              // id="page-3"
              key={index}
              className="bg-white w-[210mm] h-[297mm] relative shadow-2xl"
            >
              <div className="header h-[180px] relative isolate text-white">
                <img className="-z-10 w-full h-full" src={header} alt="" />

                <div className="absolute top-[38px] left-[37px] flex gap-2 items-center">
                  <div className="">إدارة التعليم</div>
                  <div className="text-xs font-bold max-w-[100px] text-center">
                    {data?.administration_of_education}
                  </div>
                </div>
                <div className="absolute top-[85px] left-[37px] flex gap-2 items-center">
                  <div className="">مكتب التعليم</div>
                  <div className="text-xs font-bold max-w-[100px] text-center">
                    {office.name}
                  </div>
                </div>
              </div>

              <div className="p-10">
                {index === 0 && (
                  <div className="first-table flex gap-2">
                    <div className="flex flex-col gap-[2px] text-white font-bold">
                      <div className="h-10 w-32 bg-[#712FED] flex items-center justify-center opacity-0  mb-2"></div>
                      <div className="h-10 w-32 bg-[#712FED] flex items-center justify-center rounded-t-2xl ">
                        <div className="">مشرفين</div>
                      </div>
                      <div className="h-10 w-32 bg-[#712FED] flex items-center justify-center">
                        <div className="">معلمين </div>
                      </div>
                      <div className="h-10 w-32 bg-[#712FED] flex items-center justify-center rounded-b-2xl">
                        <div className="">طلاب </div>
                      </div>
                    </div>

                    <div className="grow ">
                      <div className=" flex gap-[2px] text-white font-bold mb-2">
                        <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center rounded-r-2xl">
                          كلي
                        </div>
                        <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                          منجز
                        </div>
                        <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                          متبقي
                        </div>
                        <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center rounded-l-2xl">
                          نسبة الإنجاز
                        </div>
                      </div>
                      <div className="flex flex-col gap-[2px]">
                        <div className=" flex gap-[2px]">
                          <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                            {office.general_results.all_supervisor}
                          </div>
                          <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center text-Shamrock-100">
                            {office.general_results.total_completed_supervisor}
                          </div>
                          <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center text-gold-100">
                            {
                              office.general_results
                                .total_not_completed_supervisor
                            }
                          </div>
                          <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                            {(
                              office.general_results
                                .total_completed_supervisor /
                              office.general_results.all_supervisor
                            ).toFixed(2) * 100}
                            %
                          </div>
                        </div>
                        <div className=" flex gap-[2px]">
                          <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                            {office.general_results.all_teacher}
                          </div>
                          <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center text-Shamrock-100">
                            {office.general_results.total_completed_teacher}
                          </div>
                          <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center text-gold-100">
                            {office.general_results.total_not_completed_teacher}
                          </div>
                          <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                            {(
                              office.general_results.total_completed_teacher /
                              office.general_results.all_teacher
                            ).toFixed(2) * 100}
                            %
                          </div>
                        </div>
                        <div className=" flex gap-[2px]">
                          <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                            {office.general_results.all_student}
                          </div>
                          <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center text-Shamrock-100">
                            {office.general_results.total_student_completed}
                          </div>
                          <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center text-gold-100">
                            {office.general_results.total_student_not_completed}
                          </div>
                          <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                            {(
                              office.general_results.total_student_completed /
                              office.general_results.all_student
                            ).toFixed(2) * 100}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="second-table mt-20">
                  <div className=" flex gap-[2px] text-white font-bold mb-2">
                    <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center rounded-r-2xl">
                      المشرف
                    </div>
                    <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                      الحالة
                    </div>
                    <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                      عدد المعلمين
                    </div>
                    <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                      عدد الطلاب
                    </div>
                    <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center">
                      المنجز
                    </div>
                    <div className="h-10 grow shrink basis-0 bg-[#712FED] flex items-center justify-center rounded-l-2xl">
                      المتبقي
                    </div>
                  </div>

                  <div className="flex flex-col gap-[2px]">
                    {supData.map((supervisor) => (
                      <div className=" flex gap-[2px]">
                        <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                          {supervisor.name}
                        </div>
                        <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                          <span
                            className={`${
                              supervisor.students.total_student_completed ===
                              supervisor.students.all_student
                                ? "text-Shamrock-100 bg-Shamrock-10"
                                : "text-gold-100 bg-gold-10"
                            } py-1 px-2`}
                          >
                            {supervisor.students.total_student_completed ===
                            supervisor.students.all_student
                              ? "منجز"
                              : "غير منجز"}
                          </span>
                        </div>
                        <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                          {supervisor.teachers.all_teacher}
                        </div>
                        <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center">
                          {supervisor.students.all_student}
                        </div>
                        <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center text-Shamrock-100">
                          {supervisor.students.total_student_completed}
                        </div>
                        <div className="h-10 grow shrink basis-0 bg-[#f9f9f9] flex items-center justify-center text-gold-100">
                          {supervisor.students.total_student_not_completed}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="absolute bottom-[44px] right-[0] pr-10 text-[#240A63] flex gap-3 items-center font-bold text-l">
                تاريخ التصدير
                <div className=" flex gap-2">
                  <div>{date}</div>
                </div>
              </div>
              <div className="absolute bottom-[44px] left-[0] pl-10 text-[#240A63] font-bold text-l">
                {pageNumber.toFixed(0).padStart(2, "0")}
              </div>
            </div>
          );
        });
      })}
    </div>
  );
}

const StatsBlock = ({ title, total, completed, remainder }) => {
  let percentage = (completed / total) * 100;

  if (isNaN(percentage)) {
    percentage = 0;
  }

  const color = (t) => {
    // commented class names has to be here for tailwind compiler
    // text-Shamrock-100
    // bg-Shamrock-100
    // text-gold-100
    // bg-gold-100
    if (percentage > 50) {
      return `${t}-Shamrock-100`;
    } else {
      return `${t}-gold-100`;
    }
  };

  return (
    <div className="text-black flex flex-col gap-6">
      <h3 className="text-2xl">{title}</h3>
      <div className="flex gap-4">
        <div className="">
          <p className="leading-none font-bold text-5xl">{total || "00"}</p>
          <p className="">العدد الكلي</p>
        </div>
        <div className="">
          <p
            className={`leading-none font-bold text-5xl ${
              completed ? "text-Shamrock-100" : "text-[#E8E8E8]"
            }`}
          >
            {completed || "00"}
          </p>
          <p className="">العدد المنجز</p>
        </div>
        <div className="">
          <p className={`leading-none font-bold text-5xl text-[#E8E8E8]`}>
            {remainder || "00"}
          </p>
          <p className="">العدد المتبقي</p>
        </div>
      </div>
      <div className="flex items-center gap-2 overflow-hidden">
        <div className={`${color("text")} w-fit`}>{percentage.toFixed(1)}%</div>
        <div className="h-2 grow bg-[#E8E8E8] mt-1">
          <div
            className={`${color("bg")} h-2`}
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const OfficeStats = ({ name, percentage }) => {
  return (
    <div className="flex items-center gap-2 ">
      <CircularProgressbar
        value={percentage}
        className="!w-16 !h-16"
        strokeWidth={18}
        styles={buildStyles({
          strokeLinecap: "butt",
          pathColor: percentage > 50 ? "#28DC9C" : "#EDD306",
          textColor: percentage > 50 ? "#28DC9C" : "#EDD306",
        })}
        text={`${percentage}%`}
      />

      <div className="mt-1 font-bold text-base">{name}</div>
    </div>
  );
};
