import { useEffect, useState } from "react";
import { TbMessageCircle2 } from "react-icons/tb";
import { getMessages } from "../../utils/apis";
import MessageModal from "../modals/Message";
import parse from 'html-react-parser';
import ReactTimeAgo from 'javascript-time-ago'
import ar from '../../data/javascript-time-ago/locale/ar.json'
import AcceptModal from "../modals/AcceptModal";
import WalkThrough from "../modals/WalkThrough";

ReactTimeAgo.addDefaultLocale(ar)
const timeAgo = new ReactTimeAgo('ar')

export default function Notifications({ expand, isWalkthrough, setPopuopPos, mobileView }) {
    const [showMessage, setShowMessage] = useState(false)
    const [messages, setMessages] = useState([])
    const [activeMessage, setActiveMessage] = useState(null)
    const [showAccseptModal, setShowAccseptModal] = useState(false)
    const [stepsIndex, setStepsIndex] = useState(0)

    const handleMessage = (i) => {
        setShowMessage(true)
        setActiveMessage(i)
    }

    const handleAcceptModal = (i) => {
        setShowAccseptModal(true)
        setActiveMessage(i)
    }
    const handleOnCloseNow = () => {
        setStepsIndex(stepsIndex + 1)
    };


    useEffect(() => {
        const getData = async () => {
            const msgs = await getMessages()
            setMessages(msgs.filter((m) => !m.is_read).sort((a, b) => b.id - a.id))
        }
        getData()
    }, [])

    return (
        <div className={`${expand ? 'mr-[170px]' : mobileView ? 'mr-[55px]' : 'mr-[77px]'} flex fixed inset-0 z-50 outline-none focus:outline-none bg-[rgba(35,35,35,0.23)]`}>
            {messages.length && showMessage ? <MessageModal showModal={showMessage} setShowModal={setShowMessage} message={messages[activeMessage]} msgDate={timeAgo.format(new Date(messages[activeMessage].date))} /> : null}
            {messages.length && showAccseptModal ? <AcceptModal showModal={showAccseptModal} setShowModal={setShowAccseptModal} url={messages[activeMessage]} /> : null}
            <div className="relative">
                {isWalkthrough && stepsIndex == 0 ? (
                    <WalkThrough
                        title="التنبيهات"
                        number={`1/2`}
                        stepsIndex={2}
                        subTitle="التنبيهات"
                        text="يعرض لك احدث التنبيهات هنا."
                        colorOne="darkViolet-100"
                        onclosenow={() => {
                            setPopuopPos("profile")
                            setStepsIndex(2)
                        }}
                        top="20%"
                        right="30px"
                        arrowClass="top-center"
                    />
                ) : null}
            </div>
            <div className={`${expand ? 'mr-[171px]' : mobileView ? 'mr-[55px]' : 'mr-[78px]'} lg:w-2/5 w-1/2 px-3 py-10 fixed inset-0 top-0 z-10 bg-white overflow-y-auto text-base text-nero shadow-[-4px_0px_16px_rgba(35,35,35,0.1)]`}>
                <p className="font-bold text-daisyBush text-xl">الإشعارات</p>
                {messages.length ?
                    messages.map((s, i) => (
                        <div className="" key={i}>
                            {s.title === 'دعوة' ?
                                <div className="pr-4 mb-2">
                                    <p className="mr-auto text-[10px] text-suvaGrey-100 font-light text-left">{timeAgo.format(new Date(s.date))}</p>
                                    <p className="paresed-link">{parse(s.content)}
                                        <span className="text-darkViolet-100 pr-1 underline cursor-pointer" onClick={() => handleAcceptModal(i)}>اضغط هنا</span>
                                    </p>
                                </div>
                                :
                                <div>
                                    <div className="flex gap-1 items-center mt-10 font-bold mb-2">
                                        <TbMessageCircle2 className="ml-1 text-xl text-[#EC5E2A]" />
                                        <p>
                                            <span>لديك رسالة من
                                                {s.type === 'M' ? ' رئيس القسم ' : s.type === 'S' ? ' المشرف ' : s.type === 'T' ? ' المعلم ' : null}
                                            </span>
                                            <span className="text-darkViolet-100">{s.sender}</span>
                                        </p>
                                        <span className="mr-auto text-[10px] text-suvaGrey-100 font-light">{timeAgo.format(new Date(s.date))}</span>
                                    </div>
                                    {/* <p className="px-4 text-suvaGrey-80 mb-1 h-10 truncate">{s.content}</p> */}
                                    <button onClick={() => handleMessage(i)} className="px-4 text-darkViolet-100 font-bold underline">عرض الرسالة</button>
                                </div>
                            }
                        </div>
                    ))

                    :
                    <p className="text-suvaGrey-80 text-center mt-40">ليس لديك إشعارات</p>
                }
            </div>
        </div>
    )
}
