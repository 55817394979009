import { useState } from 'react'
import runComplete from '../../../../assets/images/dashboard/measurement/run-complete.svg'
import run from '../../../../assets/images/dashboard/measurement/run.svg'
import RecordDate from '../MeasurementsElements/RecordDate';

const average = arr => arr.reduce((p, c) => p + ~~c, 0) / arr.length;


export default function Run({ values, handleClick, dates = ["", "", ""] }) {
    const [activerecRecord, setActiveRecord] = useState(0)

    return (
        <div className="rounded-lg py-5 text-center bg-[#F9F9F9]">
            {average(values) ?
                <img src={runComplete} className="mx-auto cursor-pointer" onClick={handleClick} />
                :
                <img src={run} className="mx-auto cursor-pointer" onClick={handleClick} />
            }

            <p className='pt-2'>سرعة الجري</p>
            <p className='py-2'>مدة الجري</p>
            <div className="flex justify-center gap-2 mx-auto my-3">
                {values.map((rec, i) => (
                    <div
                        key={i}
                        onClick={() => setActiveRecord(i)}
                        className={`rounded-lg ${i == activerecRecord && rec < 1 ? 'bg-red-500 text-white' : i == activerecRecord ? 'bg-Shamrock-100 text-white' : 'bg-white text-nero'} cursor-pointer flex items-center justify-center w-7 h-7 text-sm   `}>
                        {i + 1}
                    </div>
                ))}
                <div onClick={() => setActiveRecord('avg')} className={`rounded-xl ${activerecRecord == 'avg' && !average(values) ? 'bg-red-500 text-white' : activerecRecord == 'avg' ? 'bg-Shamrock-100 text-white' : 'bg-white text-nero'} cursor-pointer px-2 flex items-center justify-centerw-14 h-7 text-sm`}>المعدل</div>
            </div>
            <p>
                <span className="text-nero font-bold text-sm pl-1">{activerecRecord == 'avg' ? average(values) : values[activerecRecord]}</span>
                <span className='font-bold text-sm'>ثانية</span>
            </p>
            <RecordDate record={dates[activerecRecord]} />
        </div>
    )
}
