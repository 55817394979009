import { useEffect, useState } from "react";
import ReactSelect from "react-select";

export default function Filter({ options, handleFilter, placeholder, filterType, filtersObj, isSearchable = false }) {
    const [selectedValue, setSelectedValue] = useState("")

    useEffect(() => {
        if (filtersObj[filterType] === undefined) {
            setSelectedValue('')
        }
    }, [filtersObj])

    const onChange = (option) => {
        console.log(option)
        handleFilter(option, filterType)
        setSelectedValue(option)
    }

    return (
        <ReactSelect
            placeholder={placeholder}
            options={options}
            onChange={onChange}
            value={selectedValue}
            isSearchable={isSearchable}
            styles={{
                control: (styles) => ({
                    ...styles,
                    backgroundColor: '#F1EAFD',
                    borderRadius: '8px',
                    border: 'unset',
                    display: 'flex',
                    alignItems: 'center',
                    boxShadow: '0',
                    padding: '8px 10px'
                }),
                menu: (styles) => ({ ...styles, margin: 0, backgroundColor: '#F1EAFD', color: '#712FED', border: 'unset', borderTop: 'unset', }),
                indicatorSeparator: () => null,
                singleValue: (styles) => ({ ...styles, color: '#712FED', padding: '2px 10px', cursor: 'pointer' }),
                placeholder: (styles) => ({ ...styles, color: '#9C6DF2' })
            }}
        />
    )
}
