import { useEffect } from "react"
import { IoMdCheckmarkCircleOutline } from "react-icons/io"
import { useSearchParams, Link } from "react-router-dom"
import axios from "axios"
import logo from "../assets/images/logo/tamheed-white.svg"
import { useState } from "react"
import Loading from "../components/Loading"

export default function EmailVerification() {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code')
    const [success, setSuccess] = useState(false)
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/signup/verify/?code=${code}`).then((res) => {
            setSuccess(true)
            setLoaded(true)
        }).catch(e => {
            console.log(e)
            setSuccess(false)
            setLoaded(true)
        })
        document.title = "تمهيد | تأكيد البريد الإلكتروني";
    }, [])


    return (
        <>
            <Loading isLoading={loaded} />
            {loaded ?
                <>
                    <div className="box1"></div>
                    <div className={`custom-bg flex flex-col justify-around lg:py-16 py-10 lg:gap-0 gap-6`}>
                        <img src={logo} alt="Logo" className="mx-auto lg:w-44 w-36" />

                        <div className="mx-auto bg-white rounded-lg my-auto flex flex-col gap-5 items-center py-10 px-24">
                            {success ?
                                <>
                                    <IoMdCheckmarkCircleOutline className="text-Shamrock-100" size="30px" />
                                    <span className="text-persianIndigo-100 text-xl sm:text-3xl font-bold">تم تأكيد بريدك الإلكتروني بنجاح</span>
                                    <Link to="/login" className="bg-persianIndigo-100 text-center w-full rounded-md shadow-button text-sm text-white py-4 mt-5">الذهاب إلى صفحة تسجيل الدخول</Link>
                                </> :
                                <span className="text-persianIndigo-100 text-2xl font-bold">حدث خطأ، الرجاء المحاولة لاحقاً</span>
                            }
                        </div>
                    </div>
                </>
                : null}
        </>
    )
}
