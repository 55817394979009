import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import React from 'react'

const LoggedinRoute = ({ children }) => {
    const { token } = useAuth();

    const location = useLocation()

    return token ?
        (<Navigate
            replace={true}
            to={-1}
            state={{ from: `${location.pathname}${location.search}` }}
        />) :
        (<>{children}</>)
}

export default LoggedinRoute