import { FiHelpCircle } from 'react-icons/fi'
import wingspan from '../../../../assets/images/dashboard/measurement/wingspan.svg'
import wingspanComplete from '../../../../assets/images/dashboard/measurement/wingspan-complete.svg'
import { Field } from 'formik'
import Slider from './Slider'
import VideoModal from '../VideoModal'
import { useState } from 'react'
import { BiErrorCircle } from 'react-icons/bi'

export default function WingspanHeight({ value, touched, errors }) {
    const [showModal, setShowModal] = useState(false)
    const [active, setActive] = useState(false)

    return (
        <div className="flex flex-col items-center relative gap-2" onClick={() => showModal ? setShowModal(false) : null}>
            {showModal && <VideoModal showModal={showModal} setShowModal={setShowModal} lesson="1" step={'arms_length'} />}
            {errors['arms_length'] && touched['arms_length'] ?
                <img src={wingspan} className="h-20 w-20 mx-auto" />
                :
                <img src={wingspanComplete} className="h-20 w-20 mx-auto" />
            }
            <div className="flex items-center gap-2 text-xl my-10">
                <p>طول الذراعين</p>
                <FiHelpCircle onClick={() => setShowModal(true)} className='text-darkViolet-100 text-xl  cursor-pointer' />
            </div>
            <Slider fieldName="arms_length" startPoint={21} value={value} />
            <div className="flex justify-around w-3/4 mt-10">
                {/* <span className="text-darkViolet-40 text-lg ml-auto">20</span> */}
                <div onFocus={() => setActive(true)} onBlur={() => setActive(false)} className={`${active ? 'border-2 border-darkViolet-100' : errors['arms_length'] && touched['arms_length'] ? 'border-[#D91E1E]' : 'border-suvaGrey-40 border'} text-darkViolet-100 font-bold rounded-lg px-4 py-5 w-1/4 mx-auto flex items-center justify-between`}>
                    <Field
                        className="border-none focus:ring-0 number-arrow w-24 placeholder:text-suvaGrey-40 sm:text-2xl sm:placeholder:text-2xl text-xl placeholder:text-xl pl-5"
                        placeholder="00"
                        type="number"
                        min="0"
                        name="arms_length"
                    />
                    <span className={`${value ? 'text-darkViolet-100' : errors['arms_length'] && touched['arms_length'] ? 'text-[#D91E1E]' : 'text-suvaGrey-40'} text-xl sm:text-2xl`}>سم</span>
                </div>
                {/* <span className="text-suvaGrey-40 text-lg mr-auto">60</span> */}
            </div>
            {errors['arms_length'] && touched['arms_length'] ?
                <p className='text-[#D91E1E] mt-1 flex items-center gap-2'> <BiErrorCircle />هذا الحقل مطلوب</p>
                : null}
        </div>
    )
}
