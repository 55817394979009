import { useEffect, useState } from "react";
import ReactSelect from "react-select";

export const Select = ({ field, placeholder, options, isHijri, form: { touched, errors, setFieldValue, setFieldTouched, }, isSearchable = false }) => {
    const isInvalid = touched[field.name] && errors[field.name] ? true : false;
    const [menuOpen, setMenuOpen] = useState(false)

    useEffect(() => {
        setFieldValue(field.name, "")
        setFieldTouched(field.name, false)
    }, [isHijri])

    return (
        <div>
            <ReactSelect
                name={field.name}
                options={options}
                onMenuOpen={() => setMenuOpen(true)}
                onMenuClose={() => setMenuOpen(false)}
                value={options ? options.find(option => option.value === field.value) || '' : ''}
                onChange={(option) => setFieldValue(field.name, option.value)}
                onBlur={() => setFieldTouched(field.name)}
                placeholder={placeholder}
                isSearchable={isSearchable}
                styles={{
                    control: (styles, { menuIsOpen, isFocused }) => ({
                        ...styles,
                        ":hover": { borderColor: isInvalid ? '#DB0000' : '#BFC2C9' },
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        borderBottomLeftRadius: menuIsOpen ? '0' : '8px',
                        borderBottomRightRadius: menuIsOpen ? '0' : '8px',
                        borderBottom: menuIsOpen ? '0' : isInvalid ? 'solid 1px #DB0000' : 'solid 1px #D6D9E0',
                        borderColor: isInvalid ? '#DB0000' : isFocused ? '#D6D9E0' : '#D6D9E0',
                        padding: '16px 10px',
                        boxShadow: '0',
                        cursor: 'pointer'
                    }),
                    placeholder: (styles) => ({
                        ...styles,
                        color: isInvalid ? '#DB0000' : '#D6D9E0',
                        fontSize: '14px',
                        width: '90%',
                    }),
                    menu: (styles) => ({
                        ...styles,
                        boxShadow: '0 0 0 0 hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)',
                        margin: 0,
                        border: '1px solid #D6D9E0',
                        borderTopWidth: '0',
                        borderTopLeftRadius: '0',
                        borderTopRightRadius: '0',
                    }),
                    valueContainer: (styles) => ({ ...styles, fontSize: '14px', textAlign: window.innerWidth <= 855 && window.innerWidth > 755 ? 'center' : 'right', padding: '0', border: '1px solisd green' }),
                    dropdownIndicator: (styles) => ({ ...styles, scale: menuOpen ? '-1' : '1', cursor: 'pointer', color: '#E8E8E8', ":hover": { color: '#E8E8E8' }, padding: '0' }),
                    indicatorSeparator: () => null,
                    option: (styles, { isFocused, isSelected }) => ({
                        ...styles,
                        width: '85%',
                        ":active": {
                            backgroundColor: isSelected ? '#28DC9C' : 'white',
                        },
                        margin: '20px 10px',
                        fontWeight: isSelected ? '700' : '400',
                        backgroundColor: isSelected ? '#28DC9C' : 'white',
                        borderRadius: '8px',
                        color: isSelected ? 'white' : isFocused ? '#28DC9C' : 'black',
                        cursor: 'pointer',
                    }),
                }}
            />

        </div>)
};