import Jump from './MeasurementsElements/Jump'
import Run from './MeasurementsElements/Run'
import ShuttleRun from './MeasurementsElements/ShuttleRun'
import { Field } from 'formik'
import Ball from './MeasurementsElements/Ball'


export default function FitnessMeasurements({ values, errors, touched }) {

    return (
        <div className="flex flex-col items-center gap-28 mt-10 overflow-hidden">

            <Ball values={{ ball_length: values.ball_length, basketball: values.basketball }} is_horizntal={values.is_horizntal} errors={errors} touched={touched} />

            <Jump values={[values.jump_1, values.jump_2, values.jump_3]} is_horizntal={values.is_horizntal} errors={errors} touched={touched} />

            <Run values={[values.run_time_1, values.run_time_2, values.run_time_3]} />

            <ShuttleRun value={values.muk_runing} errors={errors} touched={touched} />

            <div className="flex flex-col items-center gap-10 w-full mb-10">
                <p>ملاحظات</p>
                <Field as="textarea" name='notes_2' className='border-suvaGrey-20 w-2/3 rounded-lg resize-none placeholder:text-suvaGrey-40 text-sm p-5' rows="10" placeholder='إذا لديك اي ملاحظة...' />
            </div>

        </div>
    )
}
