import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { BiChevronRight } from "react-icons/bi";
import { BsCheck2Circle } from "react-icons/bs";
import { FiSend } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import AddTeacherList from "../components/dashboard/supervisor/Teachers/AddStudentsList";
import Loading from "../components/Loading";
import { useAuth } from "../context/AuthContext";
import { getAllSupervisors, getCurrentProfile } from "../utils/apis";

export default function NewSupervisorPage() {
    useEffect(() => {
        document.title = "تمهيد | إضافة المشرفين";
    }, []);

    const [teachers, setTeachers] = useState([])
    const [selectedTeachers, setSelectedTeachers] = useState([])
    const { token } = useAuth()
    const [showMessage, setShowMessage] = useState(false)
    const [loaded, setLoaded] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            setLoaded(true)
            const allSupervisors = await getAllSupervisors()
            setTeachers(allSupervisors)
        }
        getData()
    }, [])


    const handleSend = async () => {
        const user = await getCurrentProfile()

        const content = `<span className="sender">${user.profile.name}</span> قام بدعوتك، لقبول الدعوة`
        const url = `accept/?id=${user.profile.user}`
        const title = 'دعوة'
        axios.post(`${process.env.REACT_APP_API_URL}/send_invites/`, { reciever: selectedTeachers, content, title, url }, {
            headers: {
                Authorization: `Token ${token}`,
            }
        })
            .then(function (res) {
                setShowMessage(true)
                console.log(res)
                setTimeout(() => {
                    navigate('/dashboard')
                }, 2500);
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    return (
        <>
            {showMessage &&
                <div className="flex absolute inset-0 bg-[#51515126] z-50 outline-none focus:outline-none">
                    <div className="bg-white shadow-[0px_0px_23px_rgba(35,35,35,0.16)] w-2/5 h-1/4 mx-auto my-auto rounded-lg flex gap-4 justify-center flex-col items-center">
                        <BsCheck2Circle className="text-Shamrock-100 text-3xl stroke-1" />
                        <p className="font-bold text-persianIndigo-100 text-2xl">تم إرسال دعوات للمشرفين</p>
                        <p className="font-light text-persianIndigo-100">سيتم عرضهم عند تسجيلهم في المنصة</p>
                    </div>
                </div>
            }
            <Loading isLoading={loaded} />
            {loaded ?
                <div className="px-5 py-6">
                    <Link to={-1} className="mb-4 text-lg block"><BiChevronRight /></Link>
                    <p className="text-persianIndigo-100 font-bold text-lg">إضافة المشرفين</p>
                    {teachers.length ?
                        <>
                            <AddTeacherList teachers={teachers} setSelectedTeachers={setSelectedTeachers} placeholder="ابحث عن طريق اسم المشرف" />
                            <button disabled={!selectedTeachers.length} onClick={handleSend} className="w-1/4 mt-10 mr-auto bg-persianIndigo-100 text-white font-bold rounded-lg flex items-center justify-between py-5 px-4">
                                <span>إرسال الدعوات</span>
                                <FiSend className='text-xl -scale-x-100' />
                            </button>
                        </>
                        :
                        <p className="text-persianIndigo-100 text-lg text-center mt-44">لا يوجد مشرفون</p>
                    }
                </div>
                : null}
        </>
    )
}
