import { useEffect, useState } from "react";
import logo from "../assets/images/logo/tamheed-white.svg"

export default function Loading({ isLoading }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (isLoading) {
            const timer = setTimeout(() => {
                setLoading(false);
            }, 200);

            return () => clearTimeout(timer);
        }
    }, [isLoading]);

    return (
        <div>
            {loading && (
                <div className="relative h-screen">
                    <div className="h-screen flex items-center justify-center">
                        <div className="flex items-center justify-center w-[240px] h-[240px] relative">
                            <div className="oval-shape bg-persianIndigo-100"></div>
                            <img src={logo} className="mx-auto z-10 w-1/2" alt="" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
